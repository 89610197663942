import React from "react";
import styles from "../styles/style.module.css";
import infoicon from "../../../../assets/images/infoicon.svg";
import { InterviewQus } from "../../Contracts/interface";
import { Button, Modal, ModalBody, ModalFooter } from "react-bootstrap";
import TextEditorCustomComp from "../../../contractBeforeLogin/HourlyContractType/HourlyContract/StepsComonents/TextEditorCustomComp/TextEditorCustomComp";
import { onChange } from "react-toastify/dist/core/store";

interface InterViewQusProps {
  onClose: () => void;
  onNext: () => void;
  showModal: boolean;
  interviewQus: InterviewQus[],
  answerChange: (value: string, index: number, id: string) => void;
  showSaveInterviewBtn: boolean
}
const InterViewQuestions: React.FC<InterViewQusProps> = ({
  onClose,
  onNext,
  interviewQus,
  answerChange,
  showModal,
  showSaveInterviewBtn,
}) => {

  return (
    <>
      <Modal show={showModal} onHide={onClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Interview Questions</Modal.Title>
        </Modal.Header>
        <ModalBody>
          <div className="modal_catch d-flex flex-column justify-content-start text-start rounded-0 pt-4">
            <div className={styles.EligibalModal}>
              <div className="row">
                <div className="col-md-8">
                  <div className={styles.leftEligibal}>
                    <form>
                      {interviewQus?.map((data, idx) => {
                        return (
                          <div key={idx} className={styles.formGroup}>
                            <h5 className="text-20 fw-semibold text-capitalize">
                              {idx + 1}.{data?.question}
                            </h5>
                            {/* <textarea placeholder="Enter Answer here...." onChange={(e) => answerChange(e.target.value, idx, data?._id)}></textarea> */}
                            <TextEditorCustomComp data={""} setDesc={(value: string) => answerChange(value, idx, data?._id)} />
                          </div>
                        )
                      })}


                    </form>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className={styles.rightEligibal}>
                    <img src={infoicon} alt="" />
                    <p>
                      These questions can help gauge your experience and
                      skills in various aspects of project management and
                      technical tasks. Be prepared to provide specific
                      examples from your past work to support your answers.
                      These questions can help gauge your experience and
                      skills in various aspects of project management and
                      technical tasks. Be prepared to provide specific
                      examples from your past work to support your answers.
                      These questions can help gauge your experience and
                      skills in various aspects of project{" "}
                    </p>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </ModalBody>
        {showSaveInterviewBtn && (
          <ModalFooter className="btn-hover-effect justify-content-start border-top-0 mb-0">
            <Button
              type="button"
              variant="secondary"
              onClick={onNext}
              className="btn bg-blue arrowblue border border-1 rounded-3 position-relative py-2 w-auto mb-3"
            >
              <span className="px-lg-4 px-2 text-12 fw-semibold text-white">
                Save & Next{" "}
              </span>
            </Button>
          </ModalFooter>
        )}
      </Modal>
    </>
  );
};

export default InterViewQuestions;
