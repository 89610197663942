import React, { useEffect, useState } from "react";
import Header from "../../header/routes";
import info from "../../../assets/images/tip.png";
import exportt from "../../../assets/images/exportt.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import styles from "../../common/timesheets/styles/styles/style.module.css";
import moment from "moment";
import { changeExpenseStatus, getExpense } from "./api";
import { TIMESHEET_OR_EXPENSE_TYPE } from "../../../utils/enums/timesheetOrExpenseStatusTypes";
import { toast } from "react-toastify";
import Confirm from "../Contracts/OpenContracts/modals/Confirm";
import { Tooltip } from "react-bootstrap";
import Toltip from "../toltip/page";
import tip from "../../../assets/images/tip.png";
import { CONSTANT_MANAGER } from "../../../utils/constans/constants";
import blackarrow from "../../../assets/images/blackarrow.svg";

const ExpenseTable = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [approvedExpenseData, setApprovedExpenseData] = useState<any>();
  const [pendingExpenseData, setPendingExpenseData] = useState<any>();
  const [loader, setLoader] = useState<boolean>(true);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [selectedId, setSelectedId] = useState<string>("");

  const getApprovedData = async () => {
    const response = await getExpense(id, TIMESHEET_OR_EXPENSE_TYPE.APPROVED);
    setApprovedExpenseData(response?.data);
    setLoader(false);
  };
  const getPendingdata = async () => {
    const response = await getExpense(id, TIMESHEET_OR_EXPENSE_TYPE.PENDING);
    setPendingExpenseData(response?.data);
    setLoader(false);
  };
  useEffect(() => {
    getApprovedData();
    getPendingdata();
  }, []);

  const handleApproveExpense = (status: string, id: string) => {
    setConfirmModal(true);
    setSelectedStatus(status);
    setSelectedId(id);
  };
  const handleRejectExpense = (status: string, id: string) => {
    setConfirmModal(true);
    setSelectedStatus(status);
    setSelectedId(id);
  };
  const handleClose = () => {
    setConfirmModal(false);
  };
  const changeStatus = () => {
    const payload = {
      selectedId: selectedId,
      selectedStatus: selectedStatus,
    };
    toast.promise(
      changeExpenseStatus(payload.selectedId, payload.selectedStatus),
      {
        pending: {
          render() {
            return "Changing Status";
          },
        },
        success: {
          render({}) {
            handleClose();
            getApprovedData();
            getPendingdata();
            return "Status Changed Successfully";
          },
        },
        error: {
          render(error) {
            return "Status Change Failed";
          },
        },
      }
    );
  };
  return (
    <>
      <Header />
      <div className={styles.TimeShettTable}>
        <Confirm
          action="Select"
          handleClose={handleClose}
          showModal={confirmModal}
          changeStatus={changeStatus}
          status={selectedStatus}
        />
        <div className="container">
          <h3>Expenses</h3>
          <button
              className={`btn bg-white text-14 fw-bold font-inter d-flex align-items-center w-auto mb-3 border`}
              onClick={() => navigate('/home')}
            >
              <i className="me-2">
                <img
                  src={blackarrow}
                  alt=""
                  style={{ transform: "rotate(180deg)" }}
                />
              </i>{" "}
              Back to Dashboard
            </button>
          {loader ? (
            <div className="spinner-grow text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <>
              <div className={styles.TimeShettTableOuter}>
                <div className={styles.TimeShettTableHead}>
                {/* <div className="row align-items-center mb-3"> */}
                  <div className="col-8 topTipHover">
                    <div className="w-100 d-flex align-items-center">
                      <h4 className="form-check-label text-20 fw-bold mb-0 font-inter">
                      Expense Approved
                      </h4>
                      <i>
                          <img className="customeTolImg" src={info} alt="" />
                      </i>
                      <Toltip
                        slug={CONSTANT_MANAGER.SLUG_FEED.FEED}
                        labelId={
                          CONSTANT_MANAGER.FEED_DATA.ADD_MISSING_PROFILE_INFORMATION
                        }
                      />
                    </div>
                  </div>
                  <div className="col-4 text-end">
                    <button className="btn bg-white text-14 fw-bold font-inter d-flex align-items-center w-auto float-end">
                      {" "}
                      <i className="me-2"><img src={exportt} alt="" /></i>
                      Export Data
                    </button>
                  </div>
                  {/* <h4>
                    Expense Approved
                    <img src={info} alt="" />
                  </h4>
                  <img className="customeTolImg" src={tip} alt="" />
                  <Toltip
                    slug={CONSTANT_MANAGER.SLUG_FEED.FEED}
                    labelId={
                      CONSTANT_MANAGER.FEED_DATA.ADD_MISSING_PROFILE_INFORMATION
                    }
                  /> */}
                
                </div>
                <div className="table-responsive">
                  <table className="table align-middle">
                      <thead>
                  <tr>
                    <th>WR#</th>
                    <th>PROFESSIONAL NAME</th>
                    <th>LOCATION</th>
                    <th>START DATE</th>
                    <th>END DATE</th>
                    <th>APPROVED DATE</th>
                    <th>TOTAL AMOUNT</th>
                    <th>STATUS</th>
                    <th>ACTIONS</th>
                  </tr>
                  </thead>
                  <tbody>
                 
                    {approvedExpenseData?.length > 0 ? (
                      approvedExpenseData?.map((data: any, idx: number) => {
                        return (
                          <>
                 
<tr>
                            <td key={idx}>#{data?.work?.workNumber}</td>
                            <td>{data?.user?.name}</td>
                            <td>{data?.work?.workNumber}</td>
                            <td>
                              {moment(data.startDate).format("MM-DD-YYYY")}
                            </td>
                            <td>
                              {moment(data.endDate).format("MM-DD-YYYY")}
                            </td>

                            <td>
                              {moment(data.approvedDate).format("MM-DD-YYYY")}
                            </td>
                            <td>${data?.total}</td>
                            <td>
                              <span className={`text-capitalize ${styles.approvedf}`}>
                                {data.status}
                              </span>
                            </td>
                            <td>
                              <Link to={`/expense-detail/${data?._id}`}>
                                View Details
                              </Link>
                            </td>
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <tr><td colSpan={12} className="text-14 fw-semibold text-center">No Expenses Found</td></tr>
                    )}
                  </tbody>
                </table>
              </div>
              </div>
              <div className={styles.TimeShettTableOuter}>
                <div className={styles.TimeShettTableHead}>
                <div className="col-8 topTipHover">
                    <div className="w-100 d-flex align-items-center">
                      <h4 className="form-check-label text-20 fw-bold mb-0 font-inter">
                      Expense Pending for approval
                      </h4>
                      <i>
                          <img className="customeTolImg" src={info} alt="" />
                      </i>
                      <Toltip
                          slug={CONSTANT_MANAGER.SLUG_FEED.FEED}
                          labelId={
                            CONSTANT_MANAGER.FEED_DATA.ADD_MISSING_PROFILE_INFORMATION
                          }
                        />
                    </div>
                  </div>
                  <div className="col-4 text-end">
                    <button className="btn bg-white text-14 fw-bold font-inter d-flex align-items-center w-auto float-end">
                      {" "}
                      <i className="me-2"><img src={exportt} alt="" /></i>
                      Export Data
                    </button>
                  </div>
                
                </div>
              <div className="table-responsive">
                <table className="table align-middle">
                    <thead>
                      <tr>
                        <th>WR#</th>
                        <th>PROFESSIONAL NAME</th>
                        <th>LOCATION</th>
                        <th>START DATE</th>
                        <th>END DATE</th>
                        <th>APPROVED DATE</th>
                        <th>TOTAL AMOUNT</th>
                        <th>Status</th>
                        <th>View Details</th>
                        <th>ACTIONS</th>
                      </tr>
                    </thead>
                    <tbody>
                      
                        {pendingExpenseData?.length > 0 ? (
                          pendingExpenseData?.map((data: any, idx: number) => {
                            return (
                              <>
                              <tr>
                                <td key={idx}>#{data?.work?.workNumber}</td>
                                <td>{data?.user?.name}</td>
                                <td>{data?.work?.workNumber}</td>
                                <td>
                                  {moment(data.startDate).format("MM-DD-YYYY")}
                                </td>
                                <td>
                                  {moment(data.endDate).format("MM-DD-YYYY")}
                                </td>

                                <td>
                                  {moment(data.approvedDate).format("MM-DD-YYYY")}
                                </td>
                                <td>${data?.total}</td>
                                <td>
                                  {data?.status === "pending" ? (
                                    <span>Pending</span>
                                  ) : data?.status === "approved" ? (
                                    <span className="bg-blue rounded-pill text-white px-2 py-1">
                                      Approved
                                    </span>
                                  ) : data?.status === "rejected" ? (
                                    <span className="bg-danger rounded-pill text-white px-2 py-1">
                                      Declined
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td>
                                  <Link to={`/expense-detail/${data?._id}`}>
                                    View Details
                                  </Link>
                                </td>
                                <td className="d-flex">
                                  <button className="bg-blue rounded-pill text-white me-2 px-2 py-1"
                                    onClick={() =>
                                      handleApproveExpense(
                                        TIMESHEET_OR_EXPENSE_TYPE.APPROVED,
                                        data._id
                                      )
                                    }
                                  >
                                    {" "}
                                    Approve{" "}
                                  </button>
                                  {/* <button className="bg-danger rounded-pill text-white px-2 py-1"
                                    onClick={() =>
                                      handleRejectExpense(
                                        TIMESHEET_OR_EXPENSE_TYPE.REJECT,
                                        data._id
                                      )
                                    }
                                  >
                                    {" "}
                                    Reject{" "}
                                  </button> */}
                                </td>
                              </tr>
                              </>
                            );
                          })
                       
                        ) : (
                          <tr>
                           <td colSpan={12} className="text-12 text-center fw-semibold">No Expenses Found</td>
                          </tr>
                        )}
                     
                    </tbody>
                </table>
              </div>
            </div>

            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ExpenseTable;
