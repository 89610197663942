import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../redux/hooks';
import { RootState } from '../../../../redux/store';
import {
  initContract,
  setOccupationsList,
} from '../../../../redux/reducers/addContract';
import storage from '../../../../utils/storage';
import AddKeyInformation from '../../../../components/contractBeforeLogin/HourlyContractType/HourlyContract/StepsComonents/AddNewKeyInformation';
import AddLocation from '../../../../components/contractBeforeLogin/HourlyContractType/HourlyContract/StepsComonents/AddnewLocation';
import AddMoreExpense from '../../../../components/contractBeforeLogin/HourlyContractType/HourlyContract/StepsComonents/AddNewMoreExpenses';
import AddRates from '../../../../components/contractBeforeLogin/HourlyContractType/HourlyContract/StepsComonents/AddNewRates';
import AddTravel from '../../../../components/contractBeforeLogin/HourlyContractType/HourlyContract/StepsComonents/AddNewTravelExpense';
import AddWorkDescription from '../../../../components/contractBeforeLogin/HourlyContractType/HourlyContract/StepsComonents/AddNewWorkDescription';
import ChooseOccupations from '../../../../components/contractBeforeLogin/HourlyContractType/HourlyContract/StepsComonents/ChooseNewOccupations';
import BackGroundCheck from '../../../../components/contractBeforeLogin/HourlyContractType/HourlyContract/AdditionalSteps/NewbackgroundCheck';
import DrugTest from '../../../../components/contractBeforeLogin/HourlyContractType/HourlyContract/AdditionalSteps/NewDrugTest';
import AddInterViewQuestion from '../../../../components/contractBeforeLogin/HourlyContractType/HourlyContract/AdditionalSteps/NewInterviewQuestions';
import AddLegalInfo from '../../../../components/contractBeforeLogin/HourlyContractType/HourlyContract/AdditionalSteps/NewLegalInfo';
import ProcessorAndPolicies from '../../../../components/contractBeforeLogin/HourlyContractType/HourlyContract/AdditionalSteps/NewProceducers&Policies';
import SoftWareLicenses from '../../../../components/contractBeforeLogin/HourlyContractType/HourlyContract/AdditionalSteps/NewSoftwareLicenses';
import AddTechnicalSkills from '../../../../components/contractBeforeLogin/HourlyContractType/HourlyContract/AdditionalSteps/NewTechnicalSkills';
import OverviewContract from '../../../../components/contractBeforeLogin/HourlyContractType/HourlyContract/StepsComonents/NewOverviewContract';
import { ADD_CONTRACT_STEPS } from '../../../../shared/enums';
import AddExpense from '../../../../components/contractBeforeLogin/HourlyContractType/HourlyContract/StepsComonents/AddNewExpense';
import EligibilityQuestions from '../../../../components/contractBeforeLogin/HourlyContractType/HourlyContract/AdditionalSteps/NewEligibilityQuestion';
import AddDuration from '../../../../components/contractBeforeLogin/HourlyContractType/HourlyContract/StepsComonents/AddNewDuration';
import AddCustomerDetails from '../../../../components/contractBeforeLogin/HourlyContractType/HourlyContract/StepsComonents/AddNewCustomerDetails';
import { getMasterTypes } from '../../../../feature/auth/signup/api';
import { MASTER_TYPE } from '../../../../utils/enums/masterTypes.enum';
import { IOption } from '../../../../Interfaces';
import WorkLocation from '../../../../components/contractBeforeLogin/HourlyContractType/HourlyContract/AdditionalSteps/WorkLocation';

const AddHourlyContract: FC = () => {
  const dispatch = useDispatch();
  const addContract = useAppSelector((state: RootState) => state.addContract);
  const { activeStep } = addContract;

  useEffect(() => {
    const contract = storage.getSavedContract();
    contract.started = true;
    dispatch(initContract(contract));

    loadDropDowns();
  }, []);

  const loadDropDowns = async () => {
    // Occupation list
    const response = await getMasterTypes(MASTER_TYPE.OCCUPATIONS);
    const mappedOccupations = response.data?.map<IOption>((x) => ({
      label: x.name,
      value: x._id,
    }));

    dispatch(setOccupationsList(mappedOccupations));

    // TODO: Add all api calls
  };

  const renderActiveStep = () => {
    switch (activeStep) {
      case ADD_CONTRACT_STEPS.CHOOSE_OCCUPATION:
        return <ChooseOccupations />;
      case ADD_CONTRACT_STEPS.ADD_DESCRIPTION:
        return <AddWorkDescription />;
      case ADD_CONTRACT_STEPS.ADD_BASIC_INFO:
        return <AddLegalInfo />;
      case ADD_CONTRACT_STEPS.ADD_LOCATION:
        return <AddLocation />;
      case ADD_CONTRACT_STEPS.ADD_RATES:
        return <AddRates />;
      case ADD_CONTRACT_STEPS.ADD_KEY_INFORMATION:
        return <AddKeyInformation />;
      case ADD_CONTRACT_STEPS.ADD_TRAVEL_DATA:
        return <AddTravel />;
      case ADD_CONTRACT_STEPS.ADD_SOFWARE_LICENSES:
        return <SoftWareLicenses />;
      case ADD_CONTRACT_STEPS.ADD_MANUFACTURER:
        return <AddTechnicalSkills />;
      case ADD_CONTRACT_STEPS.ADD_TIMINGS:
        return <AddDuration />;
      case ADD_CONTRACT_STEPS.ADD_EXPENSES:
        return <AddExpense />;
      case ADD_CONTRACT_STEPS.ADD_MORE_EXPENSES:
        return <AddMoreExpense />;
      case ADD_CONTRACT_STEPS.ADD_CUSTOMER_CONTACT:
        return <AddCustomerDetails />;
      case ADD_CONTRACT_STEPS.ADD_ELIGIBILITY_QUESTION:
        return <EligibilityQuestions />;
      case ADD_CONTRACT_STEPS.ADD_INTERVIEW_QUESTIONS:
        return <AddInterViewQuestion />;
      case ADD_CONTRACT_STEPS.OVERVIEW_FORM:
        return <OverviewContract />;
      case ADD_CONTRACT_STEPS.PROCEDUCERS_POLICIES:
        return <ProcessorAndPolicies />;
      case ADD_CONTRACT_STEPS.ADD_SECURITY_REQUIREMENT:
        return <DrugTest />;
      case ADD_CONTRACT_STEPS.BACKGROUND_CHECK:
        return <BackGroundCheck />;
      case ADD_CONTRACT_STEPS.ADD_WORK_LOCATION:
        return <WorkLocation />;
      default:
        return <ChooseOccupations />;
    }
  };
  return renderActiveStep();
};

export default AddHourlyContract;
