import React, { FC, useState } from "react";
import Swal from 'sweetalert2';
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../redux/store";
import { WORK_STATUS_TYPE } from "../../../../../../utils/enums/status";
import storage from "../../../../../../utils/storage";
import { useNavigate } from "react-router-dom";
import SaveAsDraftAuthErrorDialog from "./SaveAsDraftAuthErrorDialog";
import { APIResponseError } from "../../../../interface";
import { AddDirectJobRootState } from "../../../../../../models";
import { saveDirectjob } from "../../../../api";
import { APP_ROUTES } from "../../../../../../utils/routes/AppRoutes";


const DirectJobDraft: FC = () => {
    const { authentication } = useSelector((state: RootState) => state);
    const directJob = useSelector((state: RootState) => state.directJob);
    const { isAuthenticated } = authentication;
    const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
    const token = storage.getToken()
    const navigate = useNavigate()
    const jobStatus = WORK_STATUS_TYPE.DRAFT

    const handleSubmitContract = async () => {
        if (!isAuthenticated && !token) {
            setShowErrorDialog(true);
            return;
        }
        await saveContractAsDraft();
    };

    const saveContractAsDraft = async () => {
        const payload = {
            ...new AddDirectJobRootState({
                ...directJob,
                jobStatus,
            }).serialize(),
        };

        try {
            await saveDirectjob(payload);

            if (jobStatus === WORK_STATUS_TYPE.DRAFT) {
                Swal.fire({
                    title: 'Direct Job saved as Draft',
                    text: 'Your Job has been saved as a draft successfully!',
                    icon: 'success',
                    confirmButtonText: 'View Dashboard',

                    customClass: {
                        confirmButton: 'swal-custom-button', // Custom class for confirm button
                        // Custom class for cancel button
                    },

                }).then((result) => {
                    if (result.isConfirmed) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'Your work has been saved',
                            showConfirmButton: false,
                            timer: 1500
                        });
                        navigate(APP_ROUTES.HOME);
                    }
                });
            }
        } catch (error) {
            const errorMessage = error as APIResponseError;
            console.log(errorMessage, 'errorMessage');

            Swal.fire({
                title: 'Error',
                text: errorMessage?.data?.data?.message || 'An error occurred.',
                icon: 'error',
                confirmButtonText: 'OK',
            });
        }
    };

    const onClick = () => {
        Swal.fire({
            title: "Do you want to save the changes as a Draft?",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Save",
            denyButtonText: `Don't save`,
        }).then((result) => {
            // Run handleSubmitContract only when the "Save" button is clicked
            if (result.isConfirmed) {
                handleSubmitContract();
            } else if (result.isDenied) {
                Swal.fire("Changes are not saved", "", "info");
            }
            // No need to do anything on cancel, as the modal will just close
        });
    };


    return (
        <>
            <div className="col-12 text-end my-3 btn-hover-effect">
                <SaveAsDraftAuthErrorDialog
                    show={showErrorDialog}
                    onHide={() => setShowErrorDialog(false)}
                />
                <h6
                    onClick={onClick}
                    className="btn arrowyellow border-grey border-1 position-relative"
                >
                    <span className="px-xl-4 px-2 d-block text-12 fw-semibold py-1">
                        Save as Draft
                    </span>
                    {/* <i className="bg-yellow rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
            <img src={whitearrow} alt="" />
          </i> */}
                </h6>
            </div>
        </>
    );
};

export default DirectJobDraft;
