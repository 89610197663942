import React from "react";
import styles from "../../../styles/style.module.css";
import choosetype from "../../../../../assets/images/choosetype.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { RootState } from "../../../../../redux/store";
import {
  setActiveStep,
  setExpenseData,
} from "../../../../../redux/reducers/addContract";
import { ADD_CONTRACT_STEPS } from "../../../../../shared/enums";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import Toltip from "../../../../common/toltip/page";
import tip from "../../../../../assets/images/tip.png";
import { SaveAsDraftButton } from "../../../../work/contractSteps/Components";
import { CONSTANT_MANAGER } from "../../../../../utils/constans/constants";

const AddMoreExpense: React.FC = () => {
  const dispatch = useDispatch();
  const { expenseData } = useSelector((state: RootState) => state.addContract);
  const { fuel, rentalCarAllowance, airFareAllowance, mileage } = expenseData;

  const formIk: any = useFormik({
    initialValues: { fuel, rentalCarAllowance, airFareAllowance, mileage },

    onSubmit: () => {
      dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_CUSTOMER_CONTACT));
    },
  });

  const handleGoBack = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_EXPENSES));
  };
  // const handleGoToNextStep = () => {
  //   dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_CUSTOMER_CONTACT));
  // };

  const handleSkip = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_CUSTOMER_CONTACT));
  };

  const onChange = (value: string | boolean | number, key: string) => {
    formIk.setFieldValue(key, value);
    dispatch(
      setExpenseData({
        ...expenseData,
        [key]: value,
      })
    );
  };

  return (
    <>
      <HeaderContent />
          <div className="heightautoscroll contractradio">
            <form onSubmit={formIk.handleSubmit} className="h-100">
              <div className="container h-100">
                <div className="row h-100 align-items-center">
                  <div className="col-lg-6">
                    <div className={styles.registerLeft}>
                      <div className="col-lg-11 col-12 mx-auto text-center">
                        <figure>
                          <img
                            src={choosetype}
                            alt=""
                            className="img-fluid w-100"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <h4 className="text-white text-38 fw-semibold">
                        Add More Details About <br />
                        Expences
                      </h4>
                      <p className="text-16 text-white opacity-75">
                        You can provide all the information here regarding the
                        contract; you just need to fill in all the details
                        here.
                      </p>
                    <div className={styles.ScrollDiv}>
                    <div className={styles.signUPContainer}>
                      <div className={styles.registerRight}>
                        <div className={styles.form}>
                          <div
                            className={styles.fromGroup}
                            style={{ marginBottom: "30px" }}
                          >
                            {" "}
                            <div className="topTipHover">
                              <label className={styles.starlabel}>Rental Car Allowance?</label>{" "}
                              <i>
                                <img
                                  className="customeTolImg"
                                  src={tip}
                                  alt=""
                                />
                              </i>
                              <Toltip
                                slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                labelId={
                                  CONSTANT_MANAGER.ADD_CONTRACT
                                    .RENTAL_CAR_ALLOWANCE
                                }
                              />
                            </div>
                            <input
                              type="number"
                              name="rentalCarAllowance"
                              className="form-control rounded-3 h-48"
                              placeholder="Enter rental car allowance"
                              value={rentalCarAllowance}
                              onChange={(e) =>
                                onChange(
                                  Number(e.target.value),
                                  "rentalCarAllowance"
                                )
                              }
                              onBlur={formIk.handleBlur}
                            />
                            {formIk.touched.rentalCarAllowance &&
                              formIk.errors.rentalCarAllowance ? (
                              <div className={styles.error}>
                                {formIk.errors.rentalCarAllowance}
                              </div>
                            ) : null}
                          </div>
                          <div
                            className={styles.fromGroup}
                            style={{ marginBottom: "30px" }}
                          >
                            {" "}
                            <div className="topTipHover">
                              <label className={styles.starlabel}>Fuel?</label>{" "}
                              <i>
                                <img
                                  className="customeTolImg"
                                  src={tip}
                                  alt=""
                                />
                              </i>
                              <Toltip
                                slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                labelId={CONSTANT_MANAGER.ADD_CONTRACT.FUEL}
                              />
                            </div>
                            <input
                              type="number"
                              name="fuel"
                              placeholder="Enter fuel"
                              className="form-control rounded-3 h-48"
                              value={fuel}
                              onChange={(e) =>
                                onChange(Number(e.target.value), "fuel")
                              }
                              onBlur={formIk.handleBlur}
                            />
                            {formIk.touched.fuel && formIk.errors.fuel ? (
                              <div className={styles.error}>
                                {formIk.errors.fuel}
                              </div>
                            ) : null}
                          </div>
                          <div
                            className={styles.fromGroup}
                            style={{ marginBottom: "30px" }}
                          >
                            {" "}
                            <div className="topTipHover">
                              <label className={styles.starlabel}>Airfare Allowance?</label>{" "}
                              <i>
                                <img
                                  className="customeTolImg"
                                  src={tip}
                                  alt=""
                                />
                              </i>
                              <Toltip
                                slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                labelId={
                                  CONSTANT_MANAGER.ADD_CONTRACT
                                    .AIRFAREALLOWANCE
                                }
                              />
                            </div>
                            <input
                              type="number"
                              name="airFareAllowance"
                              placeholder="Enter airfare allowance"
                              className="form-control rounded-3 h-48"
                              value={airFareAllowance}
                              onChange={(e) =>
                                onChange(
                                  Number(e.target.value),
                                  "airFareAllowance"
                                )
                              }
                              onBlur={formIk.handleBlur}
                            />
                            {formIk.touched.airFareAllowance &&
                              formIk.errors.airFareAllowance ? (
                              <div className={styles.error}>
                                {formIk.errors.airFareAllowance}
                              </div>
                            ) : null}
                          </div>
                          <div
                            className={styles.fromGroup}
                            style={{ marginBottom: "30px" }}
                          >
                            {" "}
                            <div className="topTipHover">
                              <label className={styles.starlabel}>Milleage?</label>{" "}
                              <i>
                                <img
                                  className="customeTolImg"
                                  src={tip}
                                  alt=""
                                />
                              </i>
                              <Toltip
                                slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                labelId={
                                  CONSTANT_MANAGER.ADD_CONTRACT.MILEAGE
                                }
                              />
                            </div>
                            <input
                              type="number"
                              name="mileage"
                              className="form-control rounded-3 h-48"
                              placeholder="Enter Mileage"
                              value={mileage}
                              onChange={(e) =>
                                onChange(Number(e.target.value), "mileage")
                              }
                              onBlur={formIk.handleBlur}
                            />
                            {formIk.touched.mileage &&
                              formIk.errors.mileage ? (
                              <div className={styles.error}>
                                {formIk.errors.mileage}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                    <div className="row align-items-center justify-content-between mx-0">
                      <div className="col-lg-3 col-md-4 col-6 text-start my-3 btn-hover-effect">
                        <h6
                          onClick={() => handleGoBack()}
                          className="btn arrowyellow border-grey border-1 position-relative ps-5 backbutton"
                        >
                          <span className="px-lg-4 px-2 text-12 fw-semibold d-inline-block">
                            Back
                          </span>
                          <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                            <img src={blackarrow} alt="" />
                          </i>
                        </h6>
                      </div>

                      <div className="col-lg-3  col-md-4 col-6">
                        <SaveAsDraftButton />
                      </div>
                      <div className="col-lg-3 col-md-4 col-12 text-end my-3 btn-hover-effect">
                        <button
                          className="btn arrowyellow border-grey border-1 position-relative"
                          onClick={handleSkip}
                        >
                          <span className="px-lg-4 px-2 fw-semibold text-14">
                            Skip
                          </span>
                        </button>
                      </div>
                      <div className="col-lg-3 col-12 text-end my-3 btn-hover-effect">
                        <button
                          type="submit"
                          className="btn arrowblue border-grey border-1 position-relative pe-5"
                        >
                          <span className="px-lg-4 px-2 text-12 fw-semibold d-inline-block">
                            Next
                          </span>
                          <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                            <img src={whitearrow} alt="" />
                          </i>
                        </button>
                      </div>
                      {/* <button type="submit">next</button> */}
                    </div>
                  </div>
                </div>
            </div>
          </form>
      </div>
    </>
  );
};
export default AddMoreExpense;
