import React, { useEffect, useState } from 'react'
import Header from '../../../header/routes'
import styles from "../../css/styles/style.module.css"
import share from "../../../../assets/images/share.svg";
import loc from "../../../../assets/images/loc.png";
import rdinfo from "../../../../assets/images/rdinfo.png";
import cross from "../../../../assets/images/reject.svg";
import hire from "../../../../assets/images/hire.svg";
import { changeWorkStatus, getFilteredContractsByStatus, getWorkDetail } from '../OpenContracts/api';
import { useNavigate, useParams } from 'react-router-dom';
import { ContractDetails } from '../OpenContracts/interface';
import moment from "moment";
import { APP_ROUTES } from '../../../../utils/routes/AppRoutes';
import { COUNT_TYPE, WORK_APPLY_TYPE } from '../../../../utils/enums/types';
import InterviewScheduleModal from '../../modals/ScheduleInterviewModal/InterviewScheduleModal';
import Confirm from '../OpenContracts/modals/Confirm';
import ViewInterViewQuestions from './viewInterviewQuestions';
import { toast } from 'react-toastify';

const ViewApplication = () => {
    const {workId,userId}=useParams()
    const navigate=useNavigate()
    const [interviewScheduled, setInterviewScheduled] = useState<boolean>();
    const [professionalId, setProfessionalId] = useState<string>("")
    const [openContractDetails, setOpenContractDetails] = useState<any>("");


    const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [selectedContractForInterviewQuestion, setselectedContractForInterviewQuestion] = useState<ContractDetails | null>(null);
  const [selectedId, setSelectedId] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const closeModal = () => setIsModalOpen(false);
  const handleClose = () => { 
       openContractDetailsList();
    setConfirmModal(false); };
    useEffect(() => { openContractDetailsList(); }, []);

    const openContractDetailsList = async () => {
      const response: any = await getWorkDetail(workId, COUNT_TYPE.WORK_APPLY);
      setOpenContractDetails(response?.data);
    };

    const changeStatus = () => {
      toast.promise(
        changeWorkStatus(selectedId, selectedStatus, openContractDetails?._id as string),
        {
          pending: {
            render() {
              return "Changing Status";
            },
          },
          success: {
            render({ }) {
              refresh();
              handleClose();
              return "Status Changed Successfully";
            },
          },
          error: {
            render(error) {
              return "Status Change Failed";
            },
          },
        }
      );
    };


  const handleShowInterviewQus = (value: ContractDetails) => { setselectedContractForInterviewQuestion(value); };

    const [contract,setContract]=useState<ContractDetails>()
    const handleViewUserProfile = (value: ContractDetails) => { navigate(`${APP_ROUTES.USER_PROFILE}/${value.userId}`); };
    const handleCallback = (data: boolean) => {
      setInterviewScheduled(data);
      setIsModalOpen(false);
      closeModal();
    };

    useEffect(() => {
      refresh()
    }, [])
    const refresh=()=>{
      getFilteredContractsByStatus(workId,"received").then((res)=>{
        const contract=res.data.filter((cont)=>{
            return userId===cont._id
        })
        setContract(contract[0]);
        console.log(contract[0],"testDsta")


    })
    }
    const handleStatusChange = (id: string, status: string) => {
      setSelectedId(id);
      setSelectedStatus(status);
      // changeWorkStatus(id, status,openContractDetails?._id).then((res) => {
      //     refresh(activeSelectedContractType
      // })
      setConfirmModal(true);
    };
    
  return (
    <>
      <Header />
      <div className='container'>
        <div className="mb-3">
          <h4 className="fw-semibold text-24 mb-0 pt-5">
            {" "}
            Applications
          </h4>
        </div>
        <div className={`bg-white mb-5 overflow-hidden d-block`}>
          <div className={`px-3 py-2 ${styles.ContarctHead}`}>
            <div className={styles.leftContract}>
              <h5 className="fw-semibold text-14">
                ID: #{contract?.worksInfo?.workNumber}
              </h5>
            </div>
            <div className={styles.leftContract}>
            
              <button className="py-2 bg-blue text-white rounded-pill d-flex align-items-center border-blue text-12 fw-medium">
                <i className="me-2">
                  <img src={share} alt="" />
                </i>
                Share Profile
              </button>
            </div>
          </div>
          <div className={styles.outerNameDetail}>
            <div className="row align-items-center pb-3 border-bottom">
              <div className="mb-4">
                <h4 className="fw-semibold text-18 mb-0">
                  {" "}
                  Professional Details
                </h4>
              </div>
              <div className="col-md-6 col-12">
                      
              <div className="row align-items-center">
                <div className="col-md-3 col-12 pe-0">
                <div className={styles.leftName}>
                  <div className={styles.PrileImg}>
                    <span className="text-center w-100">
                      <i>
                        <img
                          src={contract?.user?.profilePicture}
                          alt=""
                          style={{ width: "100px", minHeight: "70px" }}
                          className=" img-fluid rounded-circle"
                        />
                      </i>
                      {<h4 onClick={()=>{handleViewUserProfile(contract as ContractDetails)
}} className="mt-2 mb-0 fw-semibold px-3 py-2 text-10 text-capitalize bg-lightblue text-blue rounded-pill d-inline-block">
                        view profile
                      </h4>}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-6">
                <div className={styles.leftName}>
                  <div className={styles.PrileImg}>
                    <div className="">
                      <h5>{contract?.user?.name}</h5>
                      <h6 className="text-14">{contract?.user?.occupation?.name}</h6>
                      <p>
                        <img src={loc} alt="" />{contract?.user?.country?.name}
                      </p>
                      <h6>
                      Reviews : X
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-6">
              <div className={`ps-3 ${styles.rightName}`}>
                  <p>Start Date:{moment(contract?.worksInfo?.times.startDate).format("MMM DD, YYYY")}</p>
                  <p>End Date: {moment(contract?.worksInfo?.times.endDate).format("MMM DD, YYYY")}</p>
                  <p>Rate Per Hour : ${contract?.hourlyPayment?.paymentTerm}</p>
                  <p>Expense Terms : ${contract?.expensePayment?.paymentTerm}</p>
                </div>
              </div>
                </div>
              </div>
            
            </div>
          </div>
          <div className={styles.outerNDetail}>
            <div className="row align-items-center pb-3 p-3 mb-3 border-bottom mx-0">
              <div className="col-12">
                <div className="mb-2">
                  <h4 className="fw-semibold text-18 mb-4">
                    {" "}
                    Eligibility Question
                  </h4>
                </div>
              </div>
              <div className='col-12'>
                <ul>
                 {contract?.worksInfo.eligibilityQuestions.map((ques,index)=>(<li key={index}>
                    <div className='mb-3'>
                      <h4 className='text-14 fw-medium mb-2 font-poppins'>{`Question ${index+1} : ${ques.question} `}</h4>
                      <label className='text-16 fw-semibold'>
                        Ans : {ques.required.toString()}
                      </label>
                    </div>
                  </li>))}
               
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.outerNDetail}>
            <div className="row align-items-center pb-3 p-3 mb-3 border-bottom mx-0">
              <div className="col-12">
                <div className="mb-2">
                  <h4 className="fw-semibold text-18 mb-4">
                    {" "}
                    Interview Question
                  </h4>
                </div>
              </div>
              <div className='col-12'>
                <ul>
                 { contract?.worksInfo.interviewQuestions.map((ques,index)=>(<li key={index}>
                    <div className='mb-4'>
                      <h4 className='text-14 fw-medium mb-2 font-poppins'>{`Question ${index+1} : ${ques.question}`}</h4>
                      <label className='text-16 fw-semibold'>
                      Answer: {ques.required.toString()}
                      </label>
                    </div>
                  </li>))}
                </ul>
              </div>
            </div>
          </div>
          {contract?.status ===
                                    WORK_APPLY_TYPE.APPLIED && (
                                    <div className="col-12">
                                      <ul className="row align-items-center justify-content-between mx-3 g-2">
                                        {/* <button><i className="far fa-times-circle"></i> Cancel Contract</button> */}
                                        {/* <button onClick={() => handleStatusChange(contract._id, WORK_APPLY_TYPE.REJECTED)} className=''><i className="far fa-times-circle"></i> View Profile </button>
                                                                <button onClick={() => handleStatusChange(contract._id, WORK_APPLY_TYPE.SELECTED)} className={styles.videBtn}><i className="far fa-check-circle"></i> View Interview Questions</button>
                                                                <button className={styles.videBtn}><i className="fas fa-video"></i>Shortlist</button> */}
                                        <li className="col-2">
                                        <button
                                        className="rounded-2 p-2 py-3 text-black fw-semibold text-14 d-flex align-items-center w-100 justify-content-center"
                                        style={{background: "#d6d6d6"}}
                                          onClick={() =>
                                            handleStatusChange(
                                              contract._id,
                                              WORK_APPLY_TYPE.REJECTED
                                            )
                                          }
                                        >
                                          <i className="me-2"><img src={cross} alt="" /></i>Reject
                                        </button>
                                        </li>
                                        <li className="col-3"><button
                                        className="bg-blue rounded-2 p-2 py-3 fw-semibold text-white text-14 d-flex align-items-center justify-content-center w-100 text-center"
                                          onClick={() =>
                                            handleStatusChange(
                                              contract._id,
                                              WORK_APPLY_TYPE.SHORTLISTED
                                            )
                                          }
                                        >
                                          {" "}
                                          <i className="me-2"><img src={hire} alt="" /></i>Shortlist
                                        </button></li>
                                        <li className="col-2">
                                        <button
                                         className="bg-blue rounded-2 p-2 py-3 fw-semibold text-white text-14 d-flex align-items-center justify-content-center w-100 text-center"
                                          onClick={() =>
                                            handleStatusChange(
                                              contract._id,
                                              WORK_APPLY_TYPE.SELECTED
                                            )
                                          }
                                        >
                                          <i className="me-2"><img src={hire} alt="" /></i>Hire{" "}
                                        </button>
                                        </li>
                                        <li className="col-5">
                                          <button
                                          className="bg-blue rounded-2 p-2 py-3 fw-semibold text-white text-14 d-flex align-items-center justify-content-center w-100 text-center"
                                          onClick={() =>
                                            handleShowInterviewQus(contract)
                                          }
                                          type="button"
                                        >
                                          {" "}
                                          Request for Interview
                                        </button>
                                        </li>
                                      </ul>
                                    </div>
                                  )}
                                  {contract?.status ===
                                    WORK_APPLY_TYPE.SHORTLISTED && (
                                      <div className="col-12">
                                      <ul className="row align-items-center justify-content-between mx-3 g-2">
                                        {/* <button><i className="far fa-times-circle"></i> Cancel Contract</button> */}
                                        {/* <button onClick={() => handleStatusChange(contract._id, WORK_APPLY_TYPE.REJECTED)} className=''><i className="far fa-times-circle"></i> View Profile </button>
                                                                <button onClick={() => handleStatusChange(contract._id, WORK_APPLY_TYPE.SELECTED)} className={styles.videBtn}><i className="far fa-check-circle"></i> View Interview Questions</button>
                                                                <button className={styles.videBtn}><i className="fas fa-video"></i>Shortlist</button> */}
                                        <li className="col-2">
                                        <button
                                        className="rounded-2 p-2 py-3 text-black fw-semibold text-14 d-flex align-items-center w-100 justify-content-center"
                                        style={{background: "#d6d6d6"}}
                                          onClick={() =>
                                            handleStatusChange(
                                              contract._id,
                                              WORK_APPLY_TYPE.REJECTED
                                            )
                                          }
                                        >
                                          <i className="me-2"><img src={cross} alt="" /></i>Reject
                                        </button>
                                        </li>
                                      
                                        <li className="col-2">
                                        <button
                                         className="bg-blue rounded-2 p-2 py-3 fw-semibold text-white text-14 d-flex align-items-center justify-content-center w-100 text-center"
                                          onClick={() =>
                                            handleStatusChange(
                                              contract._id,
                                              WORK_APPLY_TYPE.SELECTED
                                            )
                                          }
                                        >
                                          <i className="me-2"><img src={hire} alt="" /></i>Hire{" "}
                                        </button>
                                        </li>
                                        <li className="col-5">
                                          <button
                                          className="bg-blue rounded-2 p-2 py-3 fw-semibold text-white text-14 d-flex align-items-center justify-content-center w-100 text-center"
                                          onClick={() =>
                                            handleShowInterviewQus(contract)
                                          }
                                          type="button"
                                        >
                                          {" "}
                                          Request for Interview
                                        </button>
                                        </li>
                                      </ul>
                                    </div>
                                  )}
                                  {contract?.status ===
                                    WORK_APPLY_TYPE.SELECTED && (
                                    <>
                                      <div className={styles.VideoCallBtn}>
                                        <button
                                          onClick={() =>
                                            handleViewUserProfile(contract)
                                          }
                                        >
                                          View Full Profile{" "}
                                        </button>
                                        <button
                                          onClick={() =>
                                            handleStatusChange(
                                              contract._id,
                                              WORK_APPLY_TYPE.REJECTED
                                            )
                                          }
                                        >
                                          <i className="far fa-times-circle"></i>{" "}
                                          Reject
                                        </button>
                                      </div>
                                    </>
                                  )}
                                  {contract?.status ===
                                    WORK_APPLY_TYPE.REJECTED && (
                                    <div className={styles.VideoCallBtn}>
                                      <button className="videBtn">
                                        Remove
                                      </button>
                                      <button
                                        onClick={() =>
                                          handleStatusChange(
                                            contract._id,
                                            WORK_APPLY_TYPE.APPLIED
                                          )
                                        }
                                        className={styles.videBtn}
                                      >
                                        <i className="fas fa-undo"></i> Unreject{" "}
                                      </button>
                                    </div>
                                  )}
          <div className={styles.Infor}>
            <p className="text-14 mb-3 d-flex align-items-center">
              <i className='me-2'><img src={rdinfo} alt="" /> </i>If you cancel
              a project that you committed to, your
              account will be subject to cancelation.
            </p>
          </div>
         
          </div>      
      </div>
      {/* <InterviewScheduleModal
        isOpen={isModalOpen}
        onClose={closeModal}
        professional_id={professionalId}
        work_id={contract?._id as string}
        occupation_name={contract.}
        professional_name={professionalName}
        interviewScheduled={handleCallback} /> */}
      {selectedContractForInterviewQuestion && (
        <ViewInterViewQuestions
          userId={selectedContractForInterviewQuestion.userId}
          workId={selectedContractForInterviewQuestion.workId}
          onClose={() => setselectedContractForInterviewQuestion(null)}
        />
      )}
      <Confirm
          action="Select"
          handleClose={handleClose}
          showModal={confirmModal}
          id={selectedId}
          status={selectedStatus}
          workStatus={contract?._id}
          changeStatus={changeStatus}
        />
    </>
  )
}

export default ViewApplication