import React, { useState } from "react";
import styles from "../../../styles/style.module.css";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import storage from "../../../../../utils/storage";
import { RootState } from "../../../../../redux/store";
import {
  setActiveStep,
  setBackgroundCheck,
} from "../../../../../redux/reducers/addContract";
import { ADD_CONTRACT_STEPS } from "../../../../../shared/enums";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import choosetype from "../../../../../assets/images/choosetype.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import { Dropdown, FormControl } from "react-bootstrap";

const BackGroundCheck: React.FC = () => {
  const dispatch = useDispatch();
  const token = storage.getToken();
  const { backgroundCheck } = useSelector(
    (state: RootState) => state.addContract
  );
  const { backgroundRegecy, isBackgroundCheckRequired } = backgroundCheck;
  const [selectedOption, setSelectedOption] = useState<any>(backgroundCheck.backgroundRegecy);
  console.log(selectedOption, "selectedOption");
  
  const options = [
    "In the last week",
    "Month",
    "Three Months",
    "Six Months",
    "One Year",
  ];


  const formIk = useFormik({
    initialValues: {
      backgroundRegecy,
      isBackgroundCheckRequired,
    },

    onSubmit: () => {
      // dispatch(setActiveStep(ADD_CONTRACT_STEPS.PROCEDUCERS_POLICIES));
    },
  });

  const onValueChange = (value: string | boolean, key: string): void => {
    formIk.setFieldValue(key, value);
    const updatedData = {
      ...backgroundCheck,
      [key]: value,
    };
    dispatch(setBackgroundCheck(updatedData));
    setSelectedOption(value);
  };

  const handleGoBack = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_SECURITY_REQUIREMENT));
  };

  const handleSkip = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_WORK_LOCATION));
  };
  const handleGoToNextStep = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_WORK_LOCATION));
  };

  return (
    <>
      <HeaderContent />
      <div className="heightautoscroll contractradio">
        <form onSubmit={formIk.handleSubmit} className="h-100">
          <div className="container h-100">
            <div className="row h-100 align-items-center">
                      <div className="col-lg-6">
                        <div className={styles.registerLeft}>

                        
                        <div className="col-lg-11 col-12 text-center mx-auto">
                          {/* <p>Add a Legal information of the work that you need</p> */}
                          <figure>
                            <img
                              src={choosetype}
                              alt=""
                              className="img-fluid w-100"
                            />
                          </figure>
                        </div>
                      </div>
</div>
                      <div className="col-md-6">
                      <h4 className="text-white text-32 fw-semibold">
                            Will the applicants have to take a background check?
                          </h4>
                        <div className={styles.signUPContainers}>
                          <div className={styles.registerRight}>
                            <>
                              <div className={styles.fromGroup}>
                                <label className={styles.starlabel}>Background check Required</label>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name={`isBackgroundCheckRequired$`}
                                        id={`isBackgroundCheckRequired1$`}
                                        checked={isBackgroundCheckRequired}
                                        onChange={() =>
                                          onValueChange(
                                            true,
                                            "isBackgroundCheckRequired"
                                          )
                                        }
                                      />
                                      <label className="form-check-label text-white ms-2">
                                        Yes
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name={`isBackgroundCheckRequired$`}
                                        checked={!isBackgroundCheckRequired}
                                        onChange={() =>
                                          onValueChange(
                                            false,
                                            "isBackgroundCheckRequired"
                                          )
                                        }
                                      />
                                      <label className="form-check-label text-white ms-2">
                                        No
                                      </label>
                                    </div>
                              </div>

                              {isBackgroundCheckRequired && (
                                <div className={styles.fromGroup}>
                                  <label className={styles.starlabel}>
                                    Background check Recency
                                  </label>
                                    <Dropdown>
                                        <Dropdown.Toggle
                                          id="dropdown-basic"
                                          name="travelTip"
                                        >
                                          {selectedOption ||
                                            "How many days old can the Check be?"}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <div
                                            style={{
                                              maxHeight: "300px",
                                              overflowY: "scroll",
                                            }}
                                          >
                                            {options.map((item) => (
                                              <Dropdown.Item
                                                key={item}
                                                onClick={() =>
                                                  onValueChange(
                                                    item,
                                                   "backgroundRegecy"
                                                  )
                                                }
                                              >
                                                {item}
                                              </Dropdown.Item>
                                            ))}
                                          </div>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                </div>
                              )}
                            </>
                          </div>
                        </div>
                        <div className="row align-items-center justify-content-between">
                          <div className="col-xl-4 col-6 text-start my-3 btn-hover-effect">
                            <h6
                              onClick={() => handleGoBack()}
                              className="btn arrowyellow border-grey border-1 position-relative ps-5 backbutton"
                            >
                              <span className="px-lg-4 px-2 text-12 fw-semibold">
                                Back
                              </span>
                              <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                                <img src={blackarrow} alt="" />
                              </i>
                            </h6>
                          </div>
                            <div className="col-xl-4 col-6 text-end my-3 btn-hover-effect text-center">
                              <button
                                onClick={handleSkip}
                                className="btn arrowyellow border-grey border-1 position-relative"
                              >
                                <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
                                  Skip
                                </span>
                              </button>
                            </div>
                            <div className="col-xl-4 col-6 text-end my-3 btn-hover-effect">
                              <h6
                                onClick={handleGoToNextStep}
                                className="btn arrowblue border-grey border-1 position-relative pe-5"
                              >
                                <span className="px-lg-4 px-2 text-12 fw-semibold">
                                  Next
                                </span>
                                <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                                  <img src={whitearrow} alt="" />
                                </i>
                              </h6>
                            </div>
                          </div>
                        </div>
              </div>
            </div>
            </form>
          </div>
    </>
  );
};

export default BackGroundCheck;
