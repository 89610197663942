import React from "react";
import { Link } from "react-router-dom";
import styles from "../../../css/styles/style.module.css";
import info from "../../../../../assets/images/info.png";
import pro from "../../../../../assets/images/pro.png";

import grbag from "../../../../../assets/images/grbag.png";
import loc from "../../../../../assets/images/loc.png";
import rdinfo from "../../../../../assets/images/rdinfo.png";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../../../../utils/routes/AppRoutes";
import { CONSTANT_MANAGER } from "../../../../../utils/constans/constants";
import tip from "../../../../../assets/images/info_icon.svg";
import Toltip from "../../../toltip/page";
function Shedulecontract() {
  var contract = {
    dots: false,
    speed: 500,
    slidesToShow: 2,
    centerPadding: "60px",
    slidesToScroll: 2,
    infinite: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.02,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const navigate = useNavigate();

  return (
    <div className={`bg-white p-4 shadow rounded-3 mt-3`}>
      <div className="Contracts">
        <div className={`mb-3 ${styles.AllContracta}`}>
          <div className="row align-items-center justify-content-between mx-0 g-0">
            <div className="col-lg-7 col-12">
              <div className="topTipHover">
                <div className="d-flex align-items-center">
                  <h4 className="form-check-label text-20 fw-bold  font-inter mb-0">
                    Scheduled Contracts
                  </h4>
                  <i>
                    <img className="customeTolImg" src={tip} alt="" />
                  </i>
                  <Toltip
                    slug={CONSTANT_MANAGER.SLUG_HOME.HOME}
                    labelId={CONSTANT_MANAGER.HOME_DATA.SCHEDULED_CONTRACTS}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-12 text-end">
              <Link to="#" className="text-black text-decoration-none">
                View All{" "}
                <span>
                  {" "}
                  <i className="fas fa-chevron-right"></i>{" "}
                </span>{" "}
              </Link>
            </div>
          </div>
        </div>
        <div className={styles.OuterSlides}>
          <Slider {...contract}>
            <div className={styles.ContarctOuter}>
              <div className={styles.ContarctHead}>
                <div className="row align-item-center justify-content-between mx-0 w-100">
                  <div className="col-md-12 col-12 mb-2">
                    <div className={styles.leftContract}>
                      <h5>Contract No.: #457</h5>
                      <span>Start Date : 12 Jan 2024</span>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-12 col-12">
                    <div className={styles.leftContract}>
                      <div className={styles.Local}>
                        <div
                          className={`justify-content-start ${styles.adrssLoc}`}
                        >
                          <span
                            className={`py-2 rounded-2 me-2 mb-0 border-green bg-lightgreen`}
                          >
                            <img src={grbag} alt="" />
                            White Glove Contract
                          </span>
                          <span
                            className={`py-3 mb-0 rounded-2 bg-white text-blue border-blue`}
                          >
                            Remote Contract
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-5 col-lg-12 col-12">
                    <div
                      className={styles.leftContract}
                      style={{ textAlign: "end" }}
                    >
                      <button className={`border-blue bg-blue text-white`}>
                        <i className="far fa-copy"></i> Copy
                      </button>
                      <button className={`bg-white ${styles.shedule}`}>
                        Scheduled
                      </button>{" "}
                    </div>
                  </div>
                  {/* <div className="col-md-12 col-12">
                  <div
                    className={styles.leftContract}
                    style={{ textAlign: "end" }}
                  >
                    <button className={styles.shedule}>Scheduled</button>
                  </div>
                </div> */}
                </div>
              </div>
              <div className={styles.outerNameDetail}>
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <div className={styles.outerNDetail}>
                      <div className="row align-items-center">
                        <div className="col-6">
                          <div className={styles.PrileImg}>
                            <h4 className="fw-semibold text-20">
                              Contractor Details
                            </h4>
                          </div>
                        </div>
                        {/* <div className="col-6">
                          <div className={styles.rightName}>
                            <h6>Standrad Contract</h6>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 col-12 pe-0">
                    <div className={styles.leftName}>
                      <div className={styles.PrileImg}>
                      <span className="text-center w-100">
                          <i>
                            <img
                              src={pro}
                              alt=""
                              height={70}
                              width={70}
                            />
                          </i>
                          <h4 className="mt-2 mb-0 fw-semibold px-1 py-2 text-10 text-capitalize bg-lightblue text-blue rounded-pill">
                            view profile
                          </h4>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 col-6">
                    <div className={styles.leftName}>
                      <div className={styles.PrileImg}>
                        <div className="ps-3">
                          <h5>Bradely Innes</h5>
                          <h6>Pia Automation . inc</h6>
                          <p>
                            <img src={loc} alt="" /> Evansville. IN
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 col-6">
                    <div className={`ps-3 ${styles.rightName}`}>
                      <p>Start Date: 10/18/2023</p>
                      <p>End Date: 12/18/2023</p>
                      <p>Rate Per Hour : $70.84(net 15)</p>
                      <p>Expense Terms : Net 15</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.VideoCallBtn}>
                <button className="bg-white text-blue border-blue">
                  <i className="far fa-times-circle"></i> Cancel Contract
                </button>
                <button
                  className="videBtn"
                  onClick={() =>
                    navigate(APP_ROUTES.VIDEO_CALL.INTERVIEW_CABIN)
                  }
                >
                  <i className="fas fa-video"></i> Join Interview
                </button>
              </div>
              <div className={styles.Infor}>
                <p>
                  <img src={rdinfo} alt="" /> If you cancel a project that you
                  committed to, your account will be subject to cancelation.
                </p>
              </div>
            </div>
            <div className={styles.ContarctOuter}>
              <div className={styles.ContarctHead}>
                <div className="row align-item-center justify-content-between mx-0 w-100">
                  <div className="col-md-12 col-12 mb-2">
                    <div className={styles.leftContract}>
                      <h5>Contract No.: #457</h5>
                      <span>Start Date : 12 Jan 2024</span>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-12 col-12">
                    <div className={styles.leftContract}>
                      <div className={styles.Local}>
                        <div
                          className={`justify-content-start ${styles.adrssLoc}`}
                        >
                          <span
                            className={`py-2 rounded-2 me-2 mb-0 border-green bg-lightgreen`}
                          >
                            <img src={grbag} alt="" />
                            White Glove Contract
                          </span>
                          <span
                            className={`py-3 mb-0 rounded-2 bg-white text-blue border-blue`}
                          >
                            Remote Contract
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-5 col-lg-12 col-12">
                    <div
                      className={styles.leftContract}
                      style={{ textAlign: "end" }}
                    >
                      <button className={`border-blue bg-blue text-white`}>
                        <i className="far fa-copy"></i> Copy
                      </button>
                      <button className={`bg-white ${styles.shedule}`}>
                        Scheduled
                      </button>{" "}
                    </div>
                  </div>
                  {/* <div className="col-md-12 col-12">
                  <div
                    className={styles.leftContract}
                    style={{ textAlign: "end" }}
                  >
                    <button className={styles.shedule}>Scheduled</button>
                  </div>
                </div> */}
                </div>
              </div>
              <div className={styles.outerNameDetail}>
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <div className={styles.outerNDetail}>
                      <div className="row align-items-center">
                        <div className="col-6">
                          <div className={styles.PrileImg}>
                            <h4 className="fw-semibold text-20">
                              Contractor Details
                            </h4>
                          </div>
                        </div>
                        {/* <div className="col-6">
                          <div className={styles.rightName}>
                            <h6>Standrad Contract</h6>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 col-12 pe-0">
                    <div className={styles.leftName}>
                      <div className={styles.PrileImg}>
                      <span className="text-center w-100">
                          <i>
                            <img
                              src={pro}
                              alt=""
                              height={70}
                              width={70}
                            />
                          </i>
                          <h4 className="mt-2 mb-0 fw-semibold px-1 py-2 text-10 text-capitalize bg-lightblue text-blue rounded-pill">
                            view profile
                          </h4>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 col-6">
                    <div className={styles.leftName}>
                      <div className={styles.PrileImg}>
                        <div className="ps-3">
                          <h5>Bradely Innes</h5>
                          <h6>Pia Automation . inc</h6>
                          <p>
                            <img src={loc} alt="" /> Evansville. IN
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 col-6">
                    <div className={`ps-3 ${styles.rightName}`}>
                      <p>Start Date: 10/18/2023</p>
                      <p>End Date: 12/18/2023</p>
                      <p>Rate Per Hour : $70.84(net 15)</p>
                      <p>Expense Terms : Net 15</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.VideoCallBtn}>
                <button className="bg-white text-blue border-blue">
                  <i className="far fa-times-circle"></i> Cancel Contract
                </button>
                <button
                  className="videBtn"
                  onClick={() =>
                    navigate(APP_ROUTES.VIDEO_CALL.INTERVIEW_CABIN)
                  }
                >
                  <i className="fas fa-video"></i> Join Interview
                </button>
              </div>
              <div className={styles.Infor}>
                <p>
                  <img src={rdinfo} alt="" /> If you cancel a project that you
                  committed to, your account will be subject to cancelation.
                </p>
              </div>
            </div>
            <div className={styles.ContarctOuter}>
              <div className={styles.ContarctHead}>
                <div className="row align-item-center justify-content-between mx-0 w-100">
                  <div className="col-md-12 col-12 mb-2">
                    <div className={styles.leftContract}>
                      <h5>Contract No.: #457</h5>
                      <span>Start Date : 12 Jan 2024</span>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-12 col-12">
                    <div className={styles.leftContract}>
                      <div className={styles.Local}>
                        <div
                          className={`justify-content-start ${styles.adrssLoc}`}
                        >
                          <span
                            className={`py-2 rounded-2 me-2 mb-0 border-green bg-lightgreen`}
                          >
                            <img src={grbag} alt="" />
                            White Glove Contract
                          </span>
                          <span
                            className={`py-3 mb-0 rounded-2 bg-white text-blue border-blue`}
                          >
                            Remote Contract
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-5 col-lg-12 col-12">
                    <div
                      className={styles.leftContract}
                      style={{ textAlign: "end" }}
                    >
                      <button className={`border-blue bg-blue text-white`}>
                        <i className="far fa-copy"></i> Copy
                      </button>
                      <button className={`bg-white ${styles.shedule}`}>
                        Scheduled
                      </button>{" "}
                    </div>
                  </div>
                  {/* <div className="col-md-12 col-12">
                  <div
                    className={styles.leftContract}
                    style={{ textAlign: "end" }}
                  >
                    <button className={styles.shedule}>Scheduled</button>
                  </div>
                </div> */}
                </div>
              </div>
              <div className={styles.outerNameDetail}>
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <div className={styles.outerNDetail}>
                      <div className="row align-items-center">
                        <div className="col-6">
                          <div className={styles.PrileImg}>
                            <h4 className="fw-semibold text-20">
                              Contractor Details
                            </h4>
                          </div>
                        </div>
                        {/* <div className="col-6">
                          <div className={styles.rightName}>
                            <h6>Standrad Contract</h6>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 col-12 pe-0">
                    <div className={styles.leftName}>
                      <div className={styles.PrileImg}>
                      <span className="text-center w-100">
                          <i>
                            <img
                              src={pro}
                              alt=""
                              height={70}
                              width={70}
                            />
                          </i>
                          <h4 className="mt-2 mb-0 fw-semibold px-1 py-2 text-10 text-capitalize bg-lightblue text-blue rounded-pill">
                            view profile
                          </h4>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 col-6">
                    <div className={styles.leftName}>
                      <div className={styles.PrileImg}>
                        <div className="ps-3">
                          <h5>Bradely Innes</h5>
                          <h6>Pia Automation . inc</h6>
                          <p>
                            <img src={loc} alt="" /> Evansville. IN
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 col-6">
                    <div className={`ps-3 ${styles.rightName}`}>
                      <p>Start Date: 10/18/2023</p>
                      <p>End Date: 12/18/2023</p>
                      <p>Rate Per Hour : $70.84(net 15)</p>
                      <p>Expense Terms : Net 15</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.VideoCallBtn}>
                <button className="bg-white text-blue border-blue">
                  <i className="far fa-times-circle"></i> Cancel Contract
                </button>
                <button
                  className="videBtn"
                  onClick={() =>
                    navigate(APP_ROUTES.VIDEO_CALL.INTERVIEW_CABIN)
                  }
                >
                  <i className="fas fa-video"></i> Join Interview
                </button>
              </div>
              <div className={styles.Infor}>
                <p>
                  <img src={rdinfo} alt="" /> If you cancel a project that you
                  committed to, your account will be subject to cancelation.
                </p>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default Shedulecontract;
