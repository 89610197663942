import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../css/styles/style.module.css";
import cal from "../../../../assets/images/calender_icon.svg";
import loc from "../../../../assets/images/icon_location.svg";
import skill from "../../../../assets/images/top_skill.svg";
import grbag from "../../../../assets/images/bag_orange.svg";
import chatic from "../../../../assets/images/chatic.png";
import Slider from "react-slick";
import moment from "moment";
import { ContractResponse } from "../../Contracts/interface";
import { getContractList } from "../../Contracts/api";
import { TYPE, WORK_STATUS_TYPE } from "../../../../utils/enums/status";
import { CONTRACT_Type } from "../../../../utils/enums/types";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { WORK_TABS } from "../../../../utils/enums/workTabs.enum";
import { APP_ROUTES } from "../../../../utils/routes/AppRoutes";
import ChatModule from "../../../ChatModule/routes";
import Toltip from "../../toltip/page";
import { CONSTANT_MANAGER } from "../../../../utils/constans/constants";
import tip from "../../../../../src/assets/images/info_icon.svg";
import DateTime from "../../../Datetimefilter/routes";
import SortFilter from "../../../Sortfilter/routes";

const PendingAdminJob = () => {
  const navigate = useNavigate();
  const dashboardData = useSelector((state: RootState) => state.dashboard);
  const { activeTab } = dashboardData;
  const [isExpanded, setIsExpanded] = useState(false);
  const [pendingContarct, setPendingContract] = useState<ContractResponse[]>(
    []
  );
  const [showChatModal, setShowChatmodal] = useState<boolean>(false);
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };
  const [loading, setLoading] = useState<boolean>(false);

  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 2.4,
    centerPadding: "60px",
    slidesToScroll: 2,
    infinite: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.02,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // useEffect(() => {
  //   if (activeTab === WORK_TABS.DONEFORME) {
  //     getPendingContractsForMe();
  //     return;
  //   } else if (activeTab === WORK_TABS.DONEBYME) {
  //     getPendingContractsByMe();
  //     return;
  //   }
  // }, [activeTab]);

  // const getPendingContractsForMe = async () => {
  //   const response: any = await getContractList(
  //     CONTRACT_Type.HOURLY_CONTRACT,
  //     TYPE.DONEFORME,
  //     WORK_STATUS_TYPE.PENDING
  //   );
  //   setPendinContract(response?.data);
  //   setLoading(false);
  // };

  // const getPendingContractsByMe = async () => {
  //   const response: any = await getContractList(
  //     CONTRACT_Type.HOURLY_CONTRACT,
  //     TYPE.DONEBYME,
  //     WORK_STATUS_TYPE.PENDING
  //   );
  //   setPendinContract(response?.data);
  //   setLoading(false);
  // };

  useEffect(() => {
    const fetchContracts = async () => {
      setLoading(true);
      try {
        let response: any;
        if (activeTab === WORK_TABS.DONEFORME) {
          response = await getContractList(
            CONTRACT_Type.HOURLY_CONTRACT,
            TYPE.DONEFORME,
            WORK_STATUS_TYPE.PENDING
          );
        } else if (activeTab === WORK_TABS.DONEBYME) {
          response = await getContractList(
            CONTRACT_Type.HOURLY_CONTRACT,
            TYPE.DONEBYME,
            WORK_STATUS_TYPE.PENDING
          );
        } else {
          response = { data: [] };
        }
        setPendingContract(response.data);
      } catch (error) {
        console.error("Error fetching contracts:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchContracts();
  }, [activeTab]);

  const handleOpenAdminChat = () => {
    setShowChatmodal(true);
  };
  return (
    <div className={`bg-white p-4 shadow rounded-3 mt-3`}>
      {pendingContarct?.length > 0 && (
        <div className={`mb-3 ${styles.AllContracta}`}>
          <div className="row align-items-center justify-content-between mx-0 g-0">
            <div className="col-lg-9 col-12">
              <div className="topTipHover">
                <div className="d-flex align-items-center">
                  <h4 className="form-check-label text-20 fw-bold  font-inter mb-0">
                    Direct In-House Jobs Pending Admin Approval
                  </h4>
                  <i>
                    <img className="customeTolImg" src={tip} alt="" />
                  </i>
                  <Toltip
                    slug={CONSTANT_MANAGER.SLUG_HOME.HOME}
                    labelId={CONSTANT_MANAGER.HOME_DATA.MY_TEAMS}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {loading ? (
        <div className="spinner-grow text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        
              <div className={` ${styles.OuterSlides}`}>
                <div className="col-12">
                  <div className="custom-ongoing">
                    <Slider {...settings}>
                      {pendingContarct?.length > 0
                        ? pendingContarct?.map((data, idx) => {
                            return (
                              <>
                                <div key={idx} className={styles.ContarctOuter}>
                                  <div className={styles.ContarctHead}>
                                    <div className="row align-item-center justify-content-between mx-0 w-100 position-relative">
                                      <div className="col-xl-5 col-lg-12 col-md-5 col-12 mb-2">
                                        <div className={styles.leftContract}>
                                          <h5>
                                          PHP Developer
                                          </h5>

                                          <span>
                                            Start Date:{" "}
                                            {moment(
                                              data.times.startDate
                                            ).format("MM-DD-YYYY")}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="col-xl-7 col-lg-12 col-md-7 col-12 mb-2">
                                        <div
                                          className={styles.leftContract}
                                          style={{ textAlign: "end" }}
                                        >
                                         <button
                                            className={`position-absolute top-0 bg-lightorange text-lightorange ${styles.draftstatusdetails}`}
                                          >
                                             Draft direct in-house jobs
                                          </button>
                                        </div>
                                      </div>
                                      <div className="col-xl-6 col-lg-6 col-12">
                                        <div className={styles.leftContract}>
                                          <div className={styles.Local}>
                                            <div
                                              className={`justify-content-start ${styles.adrssLoc}`}
                                            >
                                              <span
                                                className={`py-2 rounded-2 me-2 mb-0 border-lightorange text-lightorange bg-lightorange`}
                                              >
                                                <img src={grbag} alt="" />
                                               Standrad job
                                              </span>
                                              <span
                                                className={`py-3 mb-0 rounded-2 bg-white text-blue border-blue`}
                                              >
                                                Remote Job
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-xl-6 col-lg-6 col-12 mt-lg-0 mt-2">
                                        <div
                                          className={styles.leftContract}
                                          style={{ textAlign: "end" }}
                                        >
                                          <button
                                            className={`border-blue bg-blue text-white`}
                                          >
                                            <i className="far fa-copy"></i> Copy
                                          </button>
                                          <button
                                            className={`bg-white text-danger border-danger ${styles.deletecontracts}`}
                                          >
                                            Delete
                                          </button>
                                         </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={styles.OuterPadd}>
                                    <div className={styles.PendingCOnatctList}>
                                      <div className={styles.Local}>
                                        <div className={styles.adrssLoc}>
                                          <p className="mb-1 text-13">
                                            <img src={loc} alt="" />{" "}
                                            {data.address},{data.street}
                                          </p>
                                        </div>
                                        <p className="text-13">
                                          <img src={cal} alt="" /> Initiated
                                          {moment(data.times.startDate).format(
                                            "MM-DD-YYYY"
                                          )}
                                        </p>
                                      </div>
                                      <div className="fw-semibold clamp clamp2 mb-4 mt-2">
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              isExpanded && data.description
                                                ? data?.description
                                                : data?.description?.slice(
                                                    0,
                                                    90
                                                  ),
                                          }}
                                        />
                                        {/* <span
                                          onClick={toggleReadMore}
                                          className={`fw-medium ${styles.readMore}`}
                                        >
                                          {isExpanded
                                            ? "Show Less"
                                            : "Read More"}
                                        </span> */}
                                      </div>

                                      {data?.technicalSkills?.length && (
                                        <div className={styles.PenigSkils}>
                                             <ul className="row align-items-start justify-content-start gap-0">
                                            <li className="col-4 pe-0">
                                              <div className="row align-items-start mb-3">
                                                <div className="col-3 text-center ps-0">
                                                  <i>
                                                    <img
                                                      src={skill}
                                                      alt=""
                                                      className="img-fluid w-100"
                                                      width={15}
                                                      height={15}
                                                    />
                                                  </i>
                                                </div>
                                                <div className="col-9 ps-0">
                                                  <h5 className="mb-0 fw-bold text-14 text-uppercase">Top Skill</h5>
                                                    <p className="text-14 mb-0">Autocad</p>
                                                </div>
                                              </div>
                                            </li>
                                            <li className="col-4 pe-0">
                                              <div className="row align-items-start mb-3">
                                                <div className="col-3 text-center px-0">
                                                  <i>
                                                    <img
                                                      src={skill}
                                                      alt=""
                                                      className="img-fluid w-75"
                                                      width={15}
                                                      height={15}
                                                    />
                                                  </i>
                                                </div>
                                                <div className="col-9 ps-0">
                                                  <h5 className="mb-0 fw-bold text-14 text-uppercase">Second skill</h5>
                                                    <p className="text-14 mb-0">
                                                    Programming
                                                    </p>
                                                </div>
                                              </div>
                                            </li>
                                      
                                          </ul>
                                          {/* <ul>
                                            {data.technicalSkills?.map(
                                              (skill: any) => {
                                                return (
                                                  <>
                                                    <li>
                                                      <h5>Top Skills</h5>
                                                      <p>
                                                        {
                                                          skill
                                                            .manufacturerDetails
                                                            ?.name
                                                        }
                                                      </p>
                                                    </li>
                                                    <li>
                                                      <h5>Second Skills</h5>
                                                      <p>
                                                        {
                                                          skill
                                                            .manufacturerModelDetails
                                                            ?.name
                                                        }
                                                      </p>
                                                    </li>
                                                  </>
                                                );
                                              }
                                            )}
                                          </ul> */}
                                        </div>
                                      )}

                                      <div
                                        className={`pt-0 mb-4 ${styles.PenigSkilRate}`}
                                      >
                                        <h5 className="mb-3">Pay Rates</h5>
                                        <ul>
                                          <li>
                                            <span>Base</span>
                                            <label>
                                              ${data?.rates?.baseRate}
                                            </label>
                                          </li>
                                          <li>
                                            <span>OverTime</span>
                                            <label>
                                              ${data?.rates?.overTimeRate}
                                            </label>
                                          </li>
                                          <li>
                                            <span>Double Time</span>
                                            <label>
                                              ${data?.rates?.doubleTimeRate}
                                            </label>
                                          </li>
                                        </ul>
                                      </div>
                                      <div
                                        className={`row align-items-center justify-content-between mt-3 ${styles.chatAdmin}`}
                                        // onClick={handleOpenAdminChat}
                                      >
                                        <div className="col-12">
                                          <a
                                            href=""
                                            className="rounded-3 bg-blue text-white w-100 text-center fw-semibold text-capitalize"
                                          >
                                           Boost this job
                                          </a>
                                        </div>

                                        {/* <a href="#">
                                          <img src={chatic} alt="" />
                                          Chat with Admin
                                        </a> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })
                        : ""}
                    </Slider>
                  </div>
                </div>
              </div>
      )}
      {showChatModal && <ChatModule onClose={() => setShowChatmodal(false)} />}
    </div>
  );
};

export default PendingAdminJob;
