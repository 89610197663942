import React, { useEffect, useState } from "react";
import styles from "../styles/sigup.module.css";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import left from "../../../../assets/images/left.png";
import emchat from "../../../../assets/images/emchat.png";
import mschat from "../../../../assets/images/mschat.png";
import pencil from "../../../../assets/images/edit.png";
import { useFormik } from "formik";
import { verifyOTPSchema } from "../validations";
import { useDispatch, useSelector } from "react-redux";
import { setOtp } from "../../../../redux/reducers/auth";
import { AUTH_ROUTES } from "../../../../utils/routes/AuthRoutes";
import { toast } from "react-toastify";
import { sendOTP, verifyOTP } from "../api";
import { MESSAGES } from "../../../../utils/messages";
import { verifyOTPResponse } from "../interface";
import { RootState } from "../../../../redux/store";
import whitearrow from "../../../../assets/images/whitearrow.svg";
import HeaderContent from "../../../../shared/Components/HeaderContent/headerContent";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import { APIResponseError } from "../../login/interface";
import phn from '../.././../../assets/images/icon-call.png'
import sms from '../../../../assets/images/icon-msg.png'
import email from '../../../../assets/images/icon-email.png'
import checkblue from '../../../../assets/images/check_blue.svg'
import { setOtpTypeMethod } from "../../../../redux/reducers/auth";

const OtpVerify: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isTimerActive, setIsTimerActive] = useState<boolean>(true);
  const [timer, setTimer] = useState<number>(30);
  const userDetail = useSelector((state: RootState) => state.userData);
  // const otpType=useSelector((state:RootState)=>state.userData.otpMethod)
  console.log(userDetail,"get to kno typw of otp")
  const [isEditing, setIsEditing] = useState(false);
  const [editedPhoneNumber, setEditedPhoneNumber] = useState(userDetail?.contact?.phoneNum || "");
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(userDetail ? userDetail?.contact?.phoneNum : "");
  const [savedFullNumber, setSavedFullNumber] = useState(editedPhoneNumber);
  const [otpMethod, setOtpMethod] = useState<'sms' | 'call'>('sms');

  // Initialize Formik
  const verifyPhoneFormik = useFormik({
    initialValues: {
      userName: userDetail?.contact?.phoneNum || "", // Ensure default value
      verificationCode: "", // Initially empty for OTP input
    },
    validationSchema: verifyOTPSchema,
    onSubmit: async (values) => {
      const payload = {
        userName: userDetail?.contact?.phoneNum.toString(),
        verificationCode: values.verificationCode.toString(),
      };
      try {
        const response = await verifyOTP(payload);
        const otpResponse = response as verifyOTPResponse;
        verifyPhoneFormik.resetForm();
        const OTPobj = {
          verificationCode: values.verificationCode,
          isVerified: otpResponse?.data?.isVerified,
        };
        dispatch(setOtp(OTPobj));
        navigate(AUTH_ROUTES.PASSWORD);
        toast.success(MESSAGES.SENDOTP.VERIFIED_OTP);
      } catch (error) {
        const errorMesage = error as APIResponseError;
        toast.error(errorMesage?.data?.message);
      }
    },
  });

  const handlePhoneChange = (value: any) => {
    if (value) {
      const countryCodeMatch = value.match(/^\+\d+/);
      const extractedCountryCode = countryCodeMatch ? countryCodeMatch[0] : "";
      // Remove country code from the value to get the phone number
      const parsedNumber = parsePhoneNumber(value);
      const fullPhoneNumber = `+${parsedNumber?.countryCallingCode}${parsedNumber?.nationalNumber}`;
      setSavedFullNumber(fullPhoneNumber);
      if (parsedNumber) {
        setCountryCode(parsedNumber?.countryCallingCode);
        setPhoneNumber(parsedNumber?.nationalNumber);
      }
    } else {
      setCountryCode("");
      setPhoneNumber("");
    }
  };

  const handleResendotp = () => {
    const payload = {
      countryCode: `+${countryCode}`,
      phoneNumber: phoneNumber,
      type:otpMethod
    };
    toast.promise(sendOTP(payload), {
      pending: {
        render() {
          return MESSAGES.SENDOTP.VERIFY;
        },
      },
      success: {
        render() {
          setIsTimerActive(true);
          setTimer(30);
          navigate(AUTH_ROUTES.OTP_VERIFY);
          return MESSAGES.SENDOTP.SUCCESS;
        },
      },
      error: {
        render(error) {
          let errorMessage = error as APIResponseError;
          return errorMessage?.data?.data?.message;
        },
      },
    });
  };

  useEffect(() => {
    if (isTimerActive) {
      if (timer > 0) {
        const interval = setInterval(() => {
          setTimer((prev) => prev - 1);
        }, 1000);

        return () => clearInterval(interval);
      } else {
        setIsTimerActive(false);
      }
    }
  }, [isTimerActive, timer]);


  const handleEditClick = () => {
    setIsEditing((prev) => !prev);
  };

  const handleInputBlur = () => {
    setIsEditing(false);
    if (
      editedPhoneNumber !== userDetail?.contact?.phoneNum ||
      editedPhoneNumber === userDetail?.contact?.phoneNum
    ) {
      if (
        editedPhoneNumber !== userDetail?.contact?.phoneNum ||
        editedPhoneNumber === userDetail?.contact?.phoneNum
      ) {
        //handleResendotp();
      }
    }
  };
  const handleOtpMethodChange = (method: 'sms' | 'call') => {
    setOtpMethod(method);
    dispatch(setOtpTypeMethod(method));
  };

  return (
    <>
      <HeaderContent />
      <div className="py-4 heightautoscroll">
        <div className={styles.signuppage}>
          <div className={styles.signUPContainer} style={{ padding: "15px" }}>
            <form onSubmit={verifyPhoneFormik.handleSubmit}>
              <h5>OTP verification</h5>
              <span className={styles.Leftntm}>
                <a href="#" onClick={() => navigate(-1)}>
                  <img src={left} alt="Back" />
                </a>
              </span>
              <p>An OTP will be sent to the entered number for verification</p>
              <div className="col-lg-4 col-6 mx-auto mb-4 otpverification">
                <div className="d-flex align-items-center justify-content-center">
                  {isEditing ? (
                    <PhoneInput
                      value={countryCode + phoneNumber} 
                      onChange={handlePhoneChange}
                      defaultCountry="US"
                      international
                      countrySelectProps={{ unicodeFlags: true }}
                      onBlur={handleInputBlur}
                      style={{
                        border: "1px solid #0759cf",
                        padding: "8px 15px",
                        borderRadius: "50px",
                      }}
                    />
                  ) : (
                    <p className="text-blue fw-semibold mb-0">
                      {savedFullNumber}
                    </p>
                  )}
                  <span className="ms-2">
                    <img src={pencil} alt="" onClick={handleEditClick} />
                  </span>
                </div>
              </div>

              <div className={styles.formGroup} style={{ textAlign: "center" }}>
                <label htmlFor="otp">Enter OTP</label>
                <div className={styles.otpFields}>
                  <OtpInput
                    value={verifyPhoneFormik.values.verificationCode}
                    onChange={(otp) =>
                      verifyPhoneFormik.setFieldValue("verificationCode", otp)
                    }
                    numInputs={6}
                    renderInput={(props) => <input {...props} id="otp" />}
                  />
                  {verifyPhoneFormik.touched.verificationCode &&
                    verifyPhoneFormik.errors.verificationCode && (
                      <div className={styles.error}>
                        {verifyPhoneFormik.errors.verificationCode}
                      </div>
                    )}
                </div>
                <h6 >
                  Didn’t get the OTP?{" "}
                  {isTimerActive ? (
                    <p className="mb-2">Resend available in {timer} seconds</p>
                  ) : (
                    <p
                      onClick={handleResendotp}
                      className="mb-2 text-blue cursor-pointer"
                      style={{ cursor: "pointer" }}
                    >
                      Resend OTP
                    </p>
                  )}
                  <div className="d-flex align-items-center justify-content-center gap-3 otp_icon">
                  <i className={otpMethod === 'call' ? 'active' : ''}
                      onClick={() => handleOtpMethodChange('call')}>
                      <img src={phn} alt="" style={{cursor:'pointer'}}/>
                      </i>
                      <i
                      className={otpMethod === 'sms' ? 'active' : ''}
                      onClick={() => handleOtpMethodChange('sms')}
                      style={{ cursor: 'pointer' }}
                    >
                      <img src={sms} alt="" style={{cursor:'pointer'}}/>
                      </i>
                    <div className="select-check position-absolute">
                      <span><img src={checkblue} alt="" style={{cursor:'pointer'}}/></span>
                    </div>
                  </div>
                </h6>
              </div>
              <p className="text-blue mt-3 cursor-pointer" style={{textDecoration:'underline'}}>Receive a Phone Call?</p>

              <div className="col-lg-3 col-6 mx-auto">
                <div className="text-center my-3 btn-hover-effect border rounded-3">
                  <button
                    type="submit"
                    className="btn bg-white arrowblue border-white border-1 position-relative w-100 pe-5 text-center"
                  >
                    <span className="px-lg-4 px-2 text-12 fw-semibold w-100">
                      Next
                    </span>
                    <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                      <img src={whitearrow} alt="" />
                    </i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtpVerify;
