import React from "react";
import styles from "../styles/style.module.css";
import info from "../../../assets/images/info_icon.svg";
import DateTime from "../../Datetimefilter/routes";
import SortFilter from "../../Sortfilter/routes";
import map from "../../../assets/images/map.jpg";
import Toltip from "../../common/toltip/page";
import tip from "../../../assets/images/tip.png";
import { CONSTANT_MANAGER } from "../../../utils/constans/constants";
const SearchOnMap = () => {
  return (
    <div className={styles.maping}>
      <div className="container">
        <div className={styles.new_feeds}>
          <div className="">
            <div className="">
              <div className="row align-items-center justify-content-between mb-3 g-3">
                <div className="col-lg-6 col-12">
                  <div className="newheadingstyle">
                    <div className="topTipHover">
                      <div className="d-flex align-items-center">
                        <h4 className="form-check-label text-20 fw-bold  font-inter">
                          Search on maps
                        </h4>
                        <i>
                          <img className="customeTolImg" src={tip} alt="" />
                        </i>
                        <Toltip
                          slug={CONSTANT_MANAGER.SLUG_FEED.FEED}
                          labelId={
                            CONSTANT_MANAGER.FEED_DATA
                              .SEARCH_ON_MAPS
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="filter_sort">
                    <div className="ms-auto">
                      <div className="row align-items-center justify-content-end">
                        <div className="col-lg-5">
                          <DateTime />
                        </div>

                        {/* <div className="col-lg-5">
                          <SortFilter />
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="search-map">
                <figure className="mb-0">
                  <img src={map} alt="" className="img-fluid" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchOnMap;
