export enum ADD_CONTRACT_STEPS {
  POST_TYPE = "POST_TYPE",
  ADD_BASIC_INFO = "ADD_BASIC_INFO",
  CHOOSE_OCCUPATION = "CHOOSE_OCCUPATION",
  CHOOSE_APPLICATION = "CHOOSE_APPLICATION",
  ADD_LOCATION = "ADD_LOCATION",
  ADD_MANUFACTURER = "ADD_MANUFACTURER",
  ADD_SKILLS = "ADD_SKILLS",
  ADD_SOFWARE_LICENSES = "ADD_SOFWARE_LICENSES",
  ADD_DESCRIPTION = "ADD_DESCRIPTION",
  ADD_RATES = "ADD_RATES",
  ADD_EXPENSES = "ADD_EXPENSES",
  ADD_MORE_EXPENSES = "ADD_MORE_EXPENSES",
  ADD_CUSTOMER_CONTACT = "ADD_CUSTOMER_CONTACT",
  ADD_KEY_INFORMATION = "ADD_KEY_INFORMATION",
  ADD_TRAVEL_DATA = "ADD_TRAVEL_DATA",
  ADD_TIMINGS = "ADD_TIMINGS",
  ADD_ELIGIBILITY_QUESTION = "ADD_ELIGIBILITY_QUESTION",
  ADD_INTERVIEW_QUESTIONS = "ADD_INTERVIEW_QUESTIONS",
  ADD_SECURITY_REQUIREMENT = "ADD_SECURITY_REQUIREMENT",
  ADD_WORK_LOCATION = "ADD_WORK_LOCATION",
  BACKGROUND_CHECK="BACKGROUND_CHECK",
  PROCEDUCERS_POLICIES = "PROCEDUCERS_POLICIES",
  OVERVIEW_FORM = "OVERVIEW_FORM",
  COMPANY_INFORMATION="Company_information",
}
export enum OVERVIEW_STEPS{
  POST_TYPE = "POST TYPE",
  ADD_BASIC_INFO = "BASIC INFO",
  CHOOSE_OCCUPATION = "OCCUPATION",
  ADD_LOCATION = "LOCATION",
  ADD_MANUFACTURER = "TECHNICAL SKILLS",
  ADD_SOFWARE_LICENSES = "SOFWARE LICENSES",
  ADD_DESCRIPTION = "DESCRIPTION",
  ADD_RATES = "RATES",
  ADD_EXPENSES = "EXPENSES",
  ADD_MORE_EXPENSES = "MORE EXPENSES",
  ADD_CUSTOMER_CONTACT = "CUSTOMER DETAILS",
  ADD_KEY_INFORMATION = "KEY INFORMATION",
  ADD_TRAVEL_DATA = "TRAVEL EXPENSES",
  ADD_TIMINGS = "DURATION",
  ADD_ELIGIBILITY_QUESTION = "ELIGIBILITY QUESTION",
  ADD_INTERVIEW_QUESTIONS = "INTERVIEW QUESTIONS",
  ADD_SECURITY_REQUIREMENT = "DRUG TEST INFO",
  BACKGROUND_CHECK="BACKGROUND CHECK",
  ADD_WORK_LOCATION = "ADD_WORK_LOCATION",
  PROCEDUCERS_POLICIES = "PROCEDUCERS POLICIES",
  OVERVIEW_FORM = "OVERVIEW FORM",
  COMPANY_INFORMATION="Company information",
}