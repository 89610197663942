import React, { useState } from "react";
import { Dropdown, FormControl, ModalBody, ModalFooter } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import blackarrow from "../../../assets/images/blackarrow.svg";
import whitearrow from "../../../assets/images/whitearrow.svg";
import upload from "../../../assets/images/upload.svg";
import styles from "../styles/style.module.css";
import { updateUserProfile } from "../../home/api";

interface ModalProps {
  showModal: boolean;
  closeModal: () => void;
  title: string;
  refresh: () => void;
}

const EditCompanyInfoModal: React.FC<ModalProps> = ({
  showModal,
  closeModal,
  title,
  refresh
}) => {
  const [name, setName] = useState<string>("")
  const [city, setCity] = useState<string>("")
  const [phoneNumber, setPhoneNumber] = useState<string>("")
  const [email, setEmail] = useState<string>("")


  const handleUpdateInfo = async () => {
    if (name && city && phoneNumber && email) {
      const payload = {
        companyContact: {
          name: name,
          city: city,
          phoneNumber: phoneNumber,
          email: email,
        },
      };
      try {
        await updateUserProfile(payload);
        refresh();
        closeModal()
      } catch (error) {
        console.log("Error while updating the bank details", error);
      }
    } else {
      alert("Please enter full details")
    }
  };
  return (
    <Modal show={showModal} onHide={closeModal} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <form>
        <ModalBody>
          <div className="addModalPro">

            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>Company Name.................</label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Company Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>

                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>Company Phone</label>
                  <div>
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="Enter telephone"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>

                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>Company Email</label>
                  <div>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter email here"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>Address</label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Address"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </div>


                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="btn-hover-effect">
          <Button
            type="button"
            variant="secondary"
            onClick={closeModal}
            className="btn bg-white arrowyellow border border-1 rounded-3 position-relative py-lg-3 py-2 ps-5 backbutton w-auto"
          >
            <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
              Back
            </span>
            <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
              <img
                src={blackarrow}
                alt=""
                width={15}
                height={15}
                style={{ transform: "rotate(-180deg)" }}
              />
            </i>
          </Button>
          <Button
            type="button"
            variant="secondary"
            onClick={handleUpdateInfo}
            className="btn bg-white arrowblue border border-1 rounded-3 position-relative py-lg-3 py-2 pe-5 w-auto"
          >
            <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
              Save
            </span>
            <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
              <img src={whitearrow} alt="" width={15} height={15} />
            </i>
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default EditCompanyInfoModal;
