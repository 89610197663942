import { IOption } from "../Interfaces";
import { ISaveDirectJobApiRequestBody } from "../Interfaces/saveDirectJobApiRequestBody";
import { DIRECT_JOB_STEPS } from "../components/contractBeforeLogin/DirectJobType/AddDirectJob/Enums";
import { WORK_STATUS_TYPE } from "../utils/enums/status";


export interface ChooseOccupationforDirectJob {
  primaryOccupation?: IOption;
  secondaryOccupations: IOption[];
}

export interface KeyInformations {
  noOfPeople: number;
  startDate: Date;
  shift: string;
  plannedHoursPerDay: number;
  plannedDaysPerWeek: number;
}
export interface Locations {
  selectedCountry: IOption;
  selectedStreet: IOption;
  selectedState: IOption;
  selectedCity: IOption;
  selectedZipcode: IOption;
  isFilled: boolean | undefined;

  // for UI only
  countries: IOption[];
  states: IOption[];
  cities: IOption[];
  streets: IOption[];
  zipcode: IOption[];
}
export interface Rate {
  baseRate: number;
  otRate: number;
  dtRate: number;
}

export interface TechnicalSkillsDataDirectJob {
  skillTypes: IOption[];
  skills: TechnicalSkillDirectJob[];
}
export interface TechnicalSkillDirectJob {
  category: IOption;
  subCategory: IOption[];
  isRequired: boolean;
  subCategoriesList: IOption[];
}
export interface EligibilityAnswersDirectJob {
  Answers: boolean;
  questionId: string;
}
export interface InterviewAnswersDirectJob {
  Answers: string;
  questionId: string;
}
export interface Additionfiels {
  workEnvironment: string[];
  isSameCitizenship: boolean,
  isHealthInsurance: boolean,
  isVacationAndTimeOff: boolean,
}

export class AddDirectJobRootState {
  started: boolean = false;
  jobStatus: WORK_STATUS_TYPE = WORK_STATUS_TYPE.DRAFT;
  activeStep: DIRECT_JOB_STEPS = DIRECT_JOB_STEPS.ADD_OCCUPATION;
  contractType: string = "direct_job";
  occupationsList: IOption[] = [];
  occupation: ChooseOccupationforDirectJob = {
    secondaryOccupations: [],
  };
  workDescription: string = "";

  keyInformations: KeyInformations = {
    noOfPeople: 0,
    startDate: new Date(),
    shift: "",
    plannedHoursPerDay: 0,
    plannedDaysPerWeek: 0,
  };
  location: Locations = {
    selectedCountry: { label: "", value: "" },
    selectedCity: { label: "", value: "" },
    selectedState: { label: "", value: "" },
    selectedStreet: { label: "", value: "" },
    selectedZipcode: { label: '', value: '' },
    isFilled: false,
    cities: [],
    countries: [],
    states: [],
    streets: [],
    zipcode: [],
  };
  rates: Rate = {
    baseRate: 0,
    dtRate: 0,
    otRate: 0,

  };
  additionfiels: Additionfiels = {
    workEnvironment: [],
    isSameCitizenship: false,
    isHealthInsurance: false,
    isVacationAndTimeOff: false
  };

  technicalSkillsData: TechnicalSkillsDataDirectJob = {
    skillTypes: [],
    skills: [
      {
        subCategory: [],
        isRequired: false,
        category: { label: "", value: "" },
        subCategoriesList: [],
      },
    ],
  };
  eligibilityQuestions: string[] = [""];
  interviewQuestions: string[] = [""];
  constructor(props?: Partial<AddDirectJobRootState>) {
    Object.assign(this, props);
  }
  public serialize(): ISaveDirectJobApiRequestBody {
    return {
      workType: this.contractType,
      description: this.workDescription,
      workStatus: this.jobStatus,
      countryId: this.location.selectedCountry?.value,
      state: this.location.selectedState?.label,
      city: this.location.selectedCity?.label,
      occupationIds: this.occupation.primaryOccupation
        ? [this.occupation.primaryOccupation.value]
        : [],
      secondaryOccupationIds: this.occupation.secondaryOccupations?.map(
        (x) => x.value
      ),
      technicalSkills: this.technicalSkillsData.skills?.map((x) => ({
        manufacturerIds: x.category.value,
        isGoodToHave: !x.isRequired,
        isMandatory: x.isRequired,
        manufacturerModelIds:
          x.subCategoriesList.length > 0 ? x.subCategoriesList[0].value : "",
      })),
      eligibilityQuestions: this.eligibilityQuestions?.map((x) => ({
        question: x,
      })),
      interviewQuestions: this.interviewQuestions?.map((x) => ({
        question: x,
      })),
      times: {
        noOfPepole: this.keyInformations.noOfPeople,
        startDate: this.keyInformations.startDate
          ? new Date(this.keyInformations.startDate).toISOString()
          : "",
        plannedDaysPerWeek: this.keyInformations.plannedDaysPerWeek,
        plannedHoursPerDay: this.keyInformations.plannedHoursPerDay,
        shift: this.keyInformations.shift,
      },
      rates: {
        baseRate: this.rates.baseRate,
        overTimeRate: this.rates.otRate,
        doubleTimeRate: this.rates.dtRate,
      },

      isHealthInsurance: Boolean(this.additionfiels.isHealthInsurance),
      isSameCitizenship: Boolean(this.additionfiels.isSameCitizenship),
      isVacationAndTimeOff: Boolean(this.additionfiels.isVacationAndTimeOff),
      workEnvironment: this.additionfiels.workEnvironment instanceof Array
        ? this.additionfiels.workEnvironment
        : [this.additionfiels.workEnvironment],

      location: {
        type: "Point",
        coordinates: [0, 0],
      },

    }
  }

}
