import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter } from "react-bootstrap";
import congratscheck from "../../../assets/images/congra_check.png";

interface CongratsModalProps {
  closeModal: () => void;
  showCongratsModal: boolean;
}

export const CongModal: React.FC<CongratsModalProps> = ({
  showCongratsModal,
  closeModal,
}) => {
  const navigate = useNavigate();

  const handleOk = () => {
    // closeModal();
    navigate("/findOpportunities");
  };

  const gotoDashboard = () => {
    navigate("/findOpportunities");
  };

  return (
    <Modal
      show={showCongratsModal}
      onHide={closeModal}
      centered
      size="lg"
      backdrop="static"
    >
      {/* <Modal.Header closeButton>
        <Modal.Title>Congratulations</Modal.Title>
      </Modal.Header> */}
      <ModalBody>
        <div className="modal_catch d-flex flex-column justify-content-center text-center rounded-0 pt-4">
          <div className="text-center">
            <figure>
              <img
                src={congratscheck}
                alt=""
                className="img-fluid mx-auto my-4"
              />
            </figure>
          </div>
          <h1 className="text-24 fw-bold">Congratulations!</h1>
          <p className="text-16 mb-0">
            Thank you for applying to this contract, we appreciate your
            interest! <br></br>
            The Manager for this contract will review your application and
            Automate America profile shortly. <br></br>
            You will be notified when there are updates to your application.
          </p>
        </div>
      </ModalBody>

      <ModalFooter className="btn-hover-effect justify-content-center border-top-0 mb-3">
        {/* <Button
          type="button"
          variant="secondary"
          onClick={handleOk}
          className="btn bg-white arrowyellow border border-1 rounded-3 position-relative py-2 backbutton w-auto mb-3"
        >
          <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
            Ok
          </span>
        </Button> */}
        <Button
          type="button"
          variant="secondary"
          onClick={gotoDashboard}
          className="btn bg-blue arrowblue border border-1 rounded-3 position-relative py-2 w-auto mb-3"
        >
          <span className="px-lg-4 px-2 text-12 fw-semibold text-white">
            Back to Feed
          </span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};
