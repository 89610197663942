import React, { FC, useEffect, useState } from "react";
import styles from "../../../../../components/contractBeforeLogin/styles/style.module.css"
import style from "./style/style.module.css";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import info_ic from "../../../../../assets/images/info.png";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { postAdData } from "../../../../../components/Posts/api";
import { POST_TYPE } from "../../../../../utils/enums/types";
import { loadStripe } from "@stripe/stripe-js";
import { BOOST_TYPE } from "../../../../../utils/enums/types";
import { postPaidAd } from "../api";
import storage from "../../../../../utils/storage";
import { CONSTANT_MANAGER } from "../../../../../utils/constans/constants";
import Toltip from "../../../../../components/common/toltip/page";
import tip from "../../../../../assets/images/tip.png";
import applepy from "../../../../../assets/images/applepy.png";
import visacard from "../../../../../assets/images/visacard.png";
import paypal from "../../../../../assets/images/paypal.png";
import defaulImg from "../../../../../assets/images/bannergirl.jpg";

const stripePromise = loadStripe(
  `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`
);

const BoostAds: FC = () => {
  const dispatch = useDispatch();
  const { adDescription, uploadedImages, planId, planPrice } = useSelector(
    (state: RootState) => state.paiAds
  );
  const [imgURL, setImgURL] = useState<string[]>([`${uploadedImages}`]);
  const [runUntilPaused, setRunUntilPaused] = useState<boolean>(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRunUntilPaused(event.target.checked);
  };

  const handleChechout = async () => {
    if (planId) {
      let payload: any = {
        boostType: BOOST_TYPE.POST,
        boostPlanId: planId,
      };
      const payload2 = {
        type: "advertisement",
        files: imgURL,
        description: adDescription,
        durationTime: 1,
      };
      try {
        const postResp = await postAdData(POST_TYPE.ADVERTISEMENT, payload2);
        payload.postId = postResp.data._id;

        const response = await postPaidAd(payload);
        const sessionId = response.data.sessionId;
        storage.setSessionId(sessionId);
        const stripe = await stripePromise;
        if (stripe) {
          const { error } = await stripe.redirectToCheckout({
            sessionId: sessionId,
          });

          if (error) {
            console.error("Stripe Checkout error:", error);
          }
        }
      } catch (err) {
        console.log("Error redirecting to checkout:", err);
      }
    }
  };

  return (
    <>
      <HeaderContent />
      <div className="bgbluegradient py-4 ">
        <div className={styles.signuppage}>
          {/* <ProgressBar now={20} /> */}
          <div className="contractpages">
            <form>
              <div
                className={styles.registerOuter}
                style={{ position: "relative" }}
              >
                <div className="container py-5 ">
                  <div className="topTipHover">
                    <div className="d-flex align-items-center">
                      <h4 className="form-check-label text-20 fw-bold text-white font-inter">
                        Boost Ads
                      </h4>
                      <i>
                        <img className="customeTolImg" src={tip} alt="" />
                      </i>
                      <Toltip
                        slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                        labelId={
                          CONSTANT_MANAGER.ADD_CONTRACT.PAID_AD.DATA.BOOST_ADD
                        }
                      />
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-5">
                      {/* {uploadedImages.map((img, ind) => (
                        <div key={ind} className={styles.img_boost}>
                        <img src={img} alt="" className="img-fluid" />
                      </div>
                      ))
                      } */}
                      <div className={styles.img_boost}>
                        <img
                          src={uploadedImages ?  `${uploadedImages}` : defaulImg}
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className={styles.text_boost}>
                        <div className="topTipHover">
                          <div className="d-flex align-items-center">
                            <h4 className="form-check-label text-20 fw-bold text-white font-inter">
                              Descriptions
                            </h4>
                            <i>
                              <img className="customeTolImg" src={tip} alt="" />
                            </i>
                            <Toltip
                              slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                              labelId={
                                CONSTANT_MANAGER.ADD_CONTRACT.PAID_AD.DATA
                                  .BOOST_ADD_DESCRIPTION
                              }
                            />
                          </div>
                        </div>
                        <p>{adDescription}</p>
                      </div>
                      <div className={styles.text_week}>
                        <div className={styles.flex_week}>
                          <div className={styles.text_inner}>
                            <h5>$ {planPrice} / Week</h5>
                            <p>
                              {runUntilPaused
                                ? "Runs this ad until I paused it"
                                : "Runs for a week"}
                            </p>
                          </div>
                          <div className={styles.text_inner}>
                            <h5>500 - 2000</h5>
                            <p>Estimated Daily Reach</p>
                          </div>
                        </div>
                        <div></div>
                        <div className={styles.text_reds}>
                          You are over the spending limit set in your payment
                          settings. Please adjust your limit so this ad can be
                          delivered.
                          <img src={info_ic} alt="" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-lg-6">
                      <div className={styles.text_boost}>
                        <div className={styles.raio_custom}>
                          <label>
                            <div className="topTipHover">
                              <div className="d-flex align-items-center">
                                <h4 className="form-check-label text-20 fw-bold text-white font-inter">
                                  Run this ad until I pause it
                                </h4>
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                                <Toltip
                                  slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                  labelId={
                                    CONSTANT_MANAGER.ADD_CONTRACT.PAID_AD.DATA
                                      .RUN_UNTIL_IT_PAUSE
                                  }
                                />
                              </div>
                            </div>
                            <input
                              name="runUntil"
                              type="checkbox"
                              checked={runUntilPaused}
                              onChange={handleCheckboxChange}
                            />
                          </label>
                        </div>
                        <div className={styles.formGroup}>
                          <h5 className="text-white">Available Payment Methods</h5>
                          <div className={style.selctCard}>
                            <h6>
                              <img src={applepy} alt="" />
                              Apple Pay
                            </h6>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="exampleRadios"
                                id="exampleRadios1"
                                value="option1"
                                disabled
                              />
                            </div>
                          </div>
                          <div className={style.selctCard}>
                            <h6>
                              <img src={visacard} alt="" />
                              Credit / Debit Card{" "}
                              <label>Visa, Mastercard, AMEX, CUP, JCB</label>
                            </h6>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="exampleRadios"
                                id="exampleRadios1"
                                value="option1"
                                defaultChecked
                              />
                            </div>
                          </div>
                          <div className={style.selctCard}>
                            <h6>
                              <img src={paypal} alt="" />
                              Paypal
                            </h6>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="exampleRadios"
                                id="exampleRadios1"
                                value="option1"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>  
                    <div className="col-md-6">
                      <div className={style.paymentForm}>
                        <h5 className="text-white">Payment Summery</h5>
                        <div className={style.paymentBox}>
                          {/* <h6>Duration</h6>
                  <label>{planPrice}</label> */}
                          <ul>
                            <li>
                              Subtotal <span>${planPrice}</span>
                            </li>
                            {/* <li>
                      Discounts <span>-$3.00</span>
                    </li> */}
                            <li>
                              Total <span>${planPrice}</span>
                            </li>
                          </ul>
                        </div>
                        <button
                          type="button"
                          className={style.pay_now}
                          onClick={handleChechout}
                        >
                          Checkout Summary
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default BoostAds;
