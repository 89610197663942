import React, { useState } from 'react'
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ModalBody, ModalFooter, Spinner } from 'react-bootstrap';
import blackarrow from '../../../assets/images/blackarrow.svg'
import whitearrow from '../../../assets/images/whitearrow.svg'
interface BusinessModalProps{
    showModal: boolean;
    closeModal: () => void;
}

const EditBusinessModal : React.FC<BusinessModalProps>= ({showModal, closeModal}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
  return (
   <>
       <Modal show={showModal} onHide={closeModal} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Edit Business</Modal.Title>
      </Modal.Header>

      <ModalBody>
        <div className="addModalPro">
          <div className="row">
            <div className="col-lg-6 col-12">
              <div className="formGroup">
                <label>Name of Facility</label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Name"
                />
                
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="formGroup">
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Email Address"
                />
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="formGroup">
                <label>Phone</label>
                <input
                  type="tel"
                  name="phoneNumber"
                  className="form-control"
                  placeholder="Phone Number"
                />
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="formGroup">
                <label>City</label>
                <input
                  type="text"
                  name="city"
                  className="form-control"
                  placeholder="City"
                />
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="formGroup">
                <label>Street</label>
                <input
                  type="text"
                  name="street"
                  className="form-control"
                  placeholder="Street"
                />
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="formGroup">
                <label>Country</label>
                <input
                  type="text"
                  name="country"
                  className="form-control"
                  placeholder="Country"
                />
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="formGroup">
                <label>Contact Name</label>
                <input
                  type="text"
                  name="contact name"
                  className="form-control"
                  placeholder="Contact Name"
                />
              </div>
            </div>
          </div>
        </div>
      </ModalBody>

      <ModalFooter className="btn-hover-effect">
        <Button
          type="button"
          variant="secondary"
          onClick={closeModal}
          className="btn bg-white arrowyellow border border-1 rounded-3 position-relative py-lg-3 py-2 ps-5 backbutton w-auto"
        >
          <span className="px-lg-4 px-2 text-12 fw-semibold text-black">Back</span>
          <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
            <img
              src={blackarrow}
              alt=""
              width={15}
              height={15}
              style={{ transform: "rotate(-180deg)" }}
            />
          </i>
        </Button>

        <Button
          type="button"
          variant="secondary"
          className="btn bg-white arrowblue border border-1 rounded-3 position-relative py-lg-3 py-2 pe-5 w-auto"
        >
          <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
            {isSubmitting ? (
              <Spinner animation="border" size="sm" className="me-2" />
            ) : (
              "Save"
            )}
          </span>
          <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
            <img src={whitearrow} alt="" width={15} height={15} />
          </i>
        </Button>
      </ModalFooter>
    </Modal>
   </>
  )
}

export default EditBusinessModal