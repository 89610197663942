import React from "react";
import styles from "../styles/style.module.css";
import arrowwithoutcircle_orange from "../../../assets/images/arrow_withoutcircle_orange.svg";
import findjobs from "../../../assets/images/find_jobs.svg";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../../utils/routes/AppRoutes";

const FindJob = () => {
  const navigate = useNavigate();
  return (
    <>
      <li className={styles.findjobs}>
        <div className="row align-items-center">
          <div className="col-3 px-0">
            <i>
              <img src={findjobs} alt="" className="img-fluid" />
            </i>
          </div>
          <div className="col-9">
            <div className="newheadingstyle">
              <h3 className="mb-0 text-white">Find a Job</h3>
              <p className=" text-white">
                Explore Our Comprehensive Contract Repository for Seamless
                Business Solutions.
              </p>
              <button className="btn btn-white text-16  d-flex align-items-center" onClick={() => navigate(APP_ROUTES.FINDOPPORTUNITIES)}>
                Find Job Now
                <i className="ms-2">
                  <img src={arrowwithoutcircle_orange} alt="" />
                </i>
              </button>
            </div>
          </div>
        </div>
      </li>
    </>
  );
};

export default FindJob;