import React, { useEffect, useState } from "react";
import styles from "./styles/style.module.css";
import Header from "../../header/routes";
import script from "../../../assets/images/script.png";
import infogr from "../../../assets/images/tip.png";
import print from "../../../assets/images/print.png";
import dnlod from "../../../assets/images/dnlod.png";
import pnedit from "../../../assets/images/pnedit.png"
import dlt from "../../../assets/images/dlt.png"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { getexpenseById } from "./api";
import blackarrow from "../../../assets/images/blackarrow.svg";
import { getMondayOfCurrentWeek, getWeekDaysMap } from "../../../utils/daysMap";
import WhiteGloveContractDetails from "../Contracts/whiteGloveHourlyContract/whileGloveHourlyContract";
import { APP_ROUTES } from "../../../utils/routes/AppRoutes";
import Toltip from "../toltip/page";
import { transform } from "typescript";

const ExpenseDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const ongoingContractsDetails = location?.state?.currentOngoingData;
  const [currentOngoingData, setCurrentOngoingData] = useState<any>([]);
  const [expenseDetails, setExpenseDetails] = useState<any>();
  const [work, setWork] = useState<string>("");
  const navigate = useNavigate();

  const approvedExpense = async () => {
    const response: any = await getexpenseById(id);
    setExpenseDetails(response?.data);
    setWork(response?.data?.work?._id);
  };
  const isInvoiceDetailRoute = new RegExp(`^${APP_ROUTES.INVOICE_MAIN}/[a-fA-F0-9]{24}$`).test(location.pathname);

  useEffect(() => {
    approvedExpense();
    setCurrentOngoingData(ongoingContractsDetails);
    
  }, []);

  return (
    <>
{!isInvoiceDetailRoute &&      <Header />}
      <div className={styles.pageOuter}>
        <div className="container">
          <div className="">
            <h4 className="fw-bold text-26 mb-4">Expense Details</h4>
            <div className={`rounded-4 ${styles.baseInfo} ${styles.noMarg}`}>
           { !isInvoiceDetailRoute &&   <WhiteGloveContractDetails
           expenseDetails={expenseDetails}

                ongoingContractDetails={currentOngoingData}
              />}
              <div
                className={`rounded-3 col-12 ${styles.timeSheetdetils} ${styles.noMarg}`}
              >
                <div className="row align-items-center mb-3">
                  <div className="col-4 topTipHover">
                    <div className="w-100 d-flex align-items-center">
                      <h4 className="form-check-label text-20 fw-bold mb-0 font-inter">
                      Expenses Details
                      </h4>
                      <i>
                        <img className="customeTolImg" src={infogr} alt="" />
                      </i>
                      <Toltip />
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="d-flex align-items-center justify-content-end g-2">
                      <div className="px-1">
                        {" "}
                        <button className="border text-14 d-flex align-items-center py-2 px-3 rounded-pill font-inter fw-semibold">
                          <i className="me-2">
                            <img src={print} alt="" />
                          </i>{" "}
                          Print
                        </button>
                      </div>
                      <div className="px-1">
                        {" "}
                        <button className="border text-14 d-flex align-items-center py-2 px-3 rounded-pill font-inter fw-semibold">
                          <i className="me-2">
                            <img src={dnlod} alt="" />
                          </i>{" "}
                          Export Data
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={`table-responsive ${styles.tabletimesheet}`}>
                  <table className="table align-middle">
                    <tr>
                      <td>
                        <span className="fw-semibold">Engineers :</span>
                      </td>
                      <td>
                        <span className="fw-normal">
                          {" "}
                          {expenseDetails?.user?.name}
                        </span>
                      </td>
                      <td colSpan={2} className=" text-center">
                        <span className="fw-semibold">
                          Monday / Week Start Date
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="fw-semibold">
                          Customer Company Name :
                        </span>
                      </td>
                      <td>
                        <span className="fw-normal">FGSFSF hfhfbfbh infor</span>
                      </td>
                      <td rowSpan={2} colSpan={2} className=" text-center">
                        <span className="fw-normal">
                          {moment(expenseDetails?.work?.times.startDate).format(
                            "MM-DD-YYYY"
                          )}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="fw-semibold">Business Name :</span>
                      </td>
                      <td>
                        <span className="fw-normal">47110</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="fw-semibold">Business Contact :</span>
                      </td>
                      <td>
                        <span className="fw-normal">
                          {expenseDetails?.work?.user?.name}
                        </span>
                      </td>
                      <td>
                        <span className="fw-semibold">Business Email : </span>
                      </td>
                      <td>
                        <span className="fw-normal">
                          {" "}
                          {expenseDetails?.work?.user?.email}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="fw-semibold">Contract Number :</span>
                      </td>
                      <td colSpan={4}>
                        <span className="fw-normal">
                          {" "}
                          #{expenseDetails?.work?.workNumber}{" "}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span className="fw-semibold">End Customer :</span>
                      </td>
                      <td colSpan={4}>
                        <span className="fw-normal">Jerry Vemia</span>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className={`table-responsive ${styles.tableexpense} ${styles.tabletimesheet}`}>
                  <table className="table" cellSpacing={10}>
                    <thead className="bg-light align-middle">
                      <tr>
                        <th>Expense</th>
                        <th>Allowance</th>
                        <th>
                          Monday
                          <br />
                          <small>07-12-2024</small>
                        </th>
                        <th>
                          Tuesday
                          <br />
                          <small>07-12-2024</small>
                        </th>
                        <th>
                          Wednesday
                          <br />
                          <small>07-12-2024</small>
                        </th>
                        <th>
                          Thursday
                          <br />
                          <small>07-12-2024</small>
                        </th>
                        <th>
                          Friday
                          <br />
                          <small>07-12-2024</small>
                        </th>
                        <th>
                          Saturday
                          <br />
                          <small>07-12-2024</small>
                        </th>
                        <th>
                          Sunday
                          <br />
                          <small>07-12-2024</small>
                        </th>
                        <th>Weekly <br/> Total</th>
                        <th>Receipt</th>
                        <th>Uploaded Receipt</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Per Diem</td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>$300.00</td>
                        <td>Receipt not Required</td>
                        <td>
                          <a href="#" className="link">
                            No Receipt Uploaded
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>Per Diem</td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>$300.00</td>
                        <td>Receipt not Required</td>
                        <td>
                          <a href="#" className="link">
                            No Receipt Uploaded
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>Per Diem</td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>$300.00</td>
                        <td>Receipt not Required</td>
                        <td>
                          <a href="#" className="link">
                            No Receipt Uploaded
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>Per Diem</td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>$300.00</td>
                        <td>Receipt not Required</td>
                        <td>
                          <a href="#" className="link">
                            No Receipt Uploaded
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>Per Diem</td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>
                          <input type="text" value="$50.99" />
                        </td>
                        <td>$300.00</td>
                        <td>Receipt not Required</td>
                        <td>
                          <a href="#" className="link">
                            No Receipt Uploaded
                          </a>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <td>Daily Total</td>
                        <td>
                        $280.98
                        </td>
                        <td>
                        $280.98
                        </td>
                        <td>
                        $280.98
                        </td>
                        <td>
                        $280.98
                        </td>
                        <td>
                        $280.98
                        </td>
                        <td>
                        $280.98
                        </td>
                        <td>
                        $280.98
                        </td>
                        <td>
                        $280.98
                        </td>
                        <td>$1200.98</td>
                        <td></td>
                        <td>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div className="">
                  <h4 className="text-20 fw-semibold text-capitalized">Comment</h4>
                  <div className="">
                    <textarea className="form-control text-14" style={{height: '150px'}} placeholder="No Comments here..">

                    </textarea>
                  </div>
                </div>

                <div className="row align-items-center mt-4 justify-content-end pt-4">
                  <div className="col-6">
                    <h3 className="text-18 fw-bold mb-0">Uploaded Receipt</h3>
                    <p className="text-12 fw-normal mb-0">*AA converts the pdf into Image</p>
                  </div>
                 <div className="col-6 text-end">
                    <button className="border text-14 d-flex align-items-center py-2 px-3 rounded-pill font-inter fw-semibold float-end">
                      <i className="me-2" style={{transform: "rotate(180deg)"}}>
                        <img src={dnlod} alt="" className="img-fluid"/>
                      </i>{" "}
                     Upload Receipt
                    </button>
                 </div>
                </div>
                <div className={`table-responsive ${styles.uploadtimetable} ${styles.tabletimesheet}`}>
                  <table className="table rounded-3 overflow-hidden" cellSpacing={10}>
                    <thead className="align-middle bg-primary">
                      <tr>
                        <th className="ps-3">Expense</th>                        
                        <th className="ps-3">Total</th>
                        <th className="ps-3">Receipt</th>
                        <th className="ps-3 text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="ps-3 fw-semibold">Per Diem</td>
                        <td className="ps-3 fw-semibold">$300.00</td>
                        <td className="ps-3">
                          <div className="col-12">
                            <ul className="d-inline-block mb-0">
                              <li className="border rounded-3 d-inline-block me-3">
                                <figure className="mb-0">
                                  <img src={script} alt="" className="img-fluid" />
                                </figure>
                              </li>                             
                              <li className="border rounded-3 d-inline-block">
                                <figure className="mb-0">
                                  <img src={script} alt="" className="img-fluid" />
                                </figure>
                              </li>     
                            </ul>
                          </div>
                        </td>
                        <td>
                           <ul className="d-inline-block mx-auto text-center w-100 mb-0">
                              <li className="d-inline-block me-2">
                                <i className="d-block p-2 rounded-circle bg-blue"><img src={pnedit} alt="" height="20px" width="20px" className="" style={{filter: "brightness(20.5)"}}/></i>
                              </li>
                              <li className="d-inline-block me-2">
                                <i className="d-block p-2 rounded-circle bg-blue"><img src={dnlod} alt="" height="20px" width="20px" style={{filter: "brightness(20.5)"}} /></i>
                              </li>
                              <li className="d-inline-block me-2">
                                <i className="d-block p-2 rounded-circle bg-blue"><img src={dlt} alt="" height="20px" width="20px"  style={{filter: "brightness(20.5) invert(1)"}}/></i>
                              </li>
                           </ul>
                        </td>
                      </tr>
                      
                    </tbody>
                    
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className={styles.pageOuter}>
        <div className="container">
          <div className={styles.rightContainer}>
            <h2>Expense Details</h2> */}
        {/* <button
              className={`btn outline-primary d-flex align-items-center w-auto px-3`}
              onClick={() => navigate(`${APP_ROUTES.EXPENSE_TABLE}/${work}`)}
            >
              <i className="me-2">
                <img
                  src={blackarrow}
                  alt=""
                  style={{ transform: "rotate(180deg)" }}
                />
              </i>{" "}
              Back to Expenses
            </button> */}
        {/* <div className={`${styles.baseInfo} ${styles.noMarg}`}>
              <div className="ExpenseReportBord">
                <div className="expenseLogo">
                  <div className={styles.personalDetials}>
                    <div className={styles.topBrd}>
                      <div className={styles.innerbrd}>
                        <b>Engineer :</b>
                      </div>
                      <div className={styles.innerbrd}>
                        {expenseDetails?.user?.name}
                      </div>
                      <div className={styles.innerbrd}>
                        <b>Monday / Week Start Date</b>
                      </div>
                    </div>
                    <div className={styles.topBrd}>
                      <div className={styles.innerbrd}>
                        <b>Business Name</b>
                      </div>
                      <div className={styles.innerbrd}>...</div>
                      <div className={styles.innerbrd}>
                        {moment(expenseDetails?.work?.times.startDate).format(
                          "MMM DD, YYYY"
                        )}
                      </div>
                    </div>
                    <div className={styles.topBrd}>
                      <div className={styles.innerbrd}>
                        <b>Business# :</b>
                      </div>
                      <div className={styles.innerbrd}>47110</div>
                    </div>
                    <div className={styles.topBrd}>
                      <div className={styles.innerbrd}>
                        <b>Business Contact : </b>
                      </div>
                      <div className={styles.innerbrd}>
                        {expenseDetails?.work?.user?.name}
                      </div>
                      <div className={styles.innerbrd}>
                        <b>Business Email : </b>
                      </div>
                      <div className={styles.innerbrd}>
                        {expenseDetails?.work?.user?.email}
                      </div>
                    </div>
                    <div className={styles.topBrd}>
                      <div className={styles.innerbrd}>
                        <b>Contract Number : </b>
                      </div>
                      <div className={styles.innerbrd}>
                        #{expenseDetails?.work?.workNumber}
                      </div>
                    </div>
                    <div className={styles.topBrd}>
                      <div className={styles.innerbrd}>
                        <b>End Customer : </b>
                      </div>
                      <div className={styles.innerbrd}>#10179800</div>
                    </div>
                  </div>

                  <div
                    className={`${styles.personalDetials} ${styles.expenseTable}`}
                  >
                    <div className={styles.topBrd}>
                      <div className={styles.innerbrd}></div>
                      <div className={styles.innerbrd}></div>
                      <div className={styles.innerbrd}>
                        <b>Monday</b>
                      </div>
                      <div className={styles.innerbrd}>
                        <b>Tuesday</b>
                      </div>
                      <div className={styles.innerbrd}>
                        <b>Webnesday</b>
                      </div>
                      <div className={styles.innerbrd}>
                        <b>Thursday</b>
                      </div>
                      <div className={styles.innerbrd}>
                        <b>Friday</b>
                      </div>
                      <div className={styles.innerbrd}>
                        <b>Saturday</b>
                      </div>
                      <div className={styles.innerbrd}>
                        <b>Sunday</b>
                      </div>
                      <div className={styles.innerbrd}>
                        <b>Weekly Total</b>
                      </div>
                    </div>
                    <div className={styles.topBrd}>
                      <div className={styles.innerbrd}>
                        <b>Expense </b>
                      </div>
                      <div className={styles.innerbrd}>
                        <b>Allownce </b>
                      </div>
                      <div className={styles.innerbrd}>10/5/2023</div>
                      <div className={styles.innerbrd}>10/5/2023</div>
                      <div className={styles.innerbrd}>10/5/2023</div>
                      <div className={styles.innerbrd}>10/5/2023</div>
                      <div className={styles.innerbrd}>10/5/2023</div>
                      <div className={styles.innerbrd}>10/5/2023</div>
                      <div className={styles.innerbrd}>10/5/2023</div>
                      <div className={styles.innerbrd}>
                        <b>10/5/2023</b>
                      </div>
                    </div>

                    {
                      <div className={styles.topBrd}>
                        <div className={styles.innerbrd}>Per Diem</div>
                        <div className={styles.innerbrd}>$10</div>
                        <div className={styles.innerbrd}>$10</div>
                        <div className={styles.innerbrd}>$10</div>
                        <div className={styles.innerbrd}>$10</div>
                        <div className={styles.innerbrd}>$10</div>
                        <div className={styles.innerbrd}>$10</div>
                        <div className={styles.innerbrd}>$10</div>
                        <div className={styles.innerbrd}>$10</div>
                        <div className={styles.innerbrd}>
                          <b>10/5/2023</b>
                        </div>
                      </div>
                    }

                    {/* <div className={styles.topBrd}>
                      <div className={styles.innerbrd}>Pre Dim Foood</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}><b>10/5/2023</b></div>
                    </div>
                    <div className={styles.topBrd}>
                      <div className={styles.innerbrd}>Pre Dim Foood</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}><b>10/5/2023</b></div>
                    </div> */}
      </div>
      {/* <div className='AprovdDate'>
                    <p className='mb-1'><b>Approved By:</b> Toood Viller</p>
                    <p className='mb-1'><b>IP Address:</b> 12345</p>
                    <p className='mb-1'><b>Approved Date:</b> 10/4/45</p>
                    <p className='mt-5'><b>Date:</b> </p>


                  </div> 
               </div>
              </div> 
         </div>
        </div>
      </div>*/}
    </>
  );
};

export default ExpenseDetails;
