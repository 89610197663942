import React, { FC, ReactElement } from "react";
import {
  AddEligibilityQusForDirectJob,
  AddKeyInfoDirectJob,
  AddLocationForDirectJob,
  AddOccupationForDirectJob,
  AddRatesForDirectJob,
  AddWorkDescriptionForDirectJob,
  InterviewQuestionForDirectJob,
} from "./Components";
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";
import { DIRECT_JOB_STEPS } from "./Enums";
import OverViewDirectjob from "./Components/OverviewFormForDirectJob";
import AddAditionalInfo from "./Components/AddAditionalFieldsDirectjob";
import AddTechnicalSkills from "./Components/AddtechnicalSkills";
import AddTimingDirectJob from "./Components/AddTimingDirectJob";

const AddDirectJob: FC = () => {
  const { activeStep } = useSelector((state: RootState) => state.directJob);

  const renderActiveStep = (): ReactElement => {
    switch (activeStep) {
      case DIRECT_JOB_STEPS.ADD_OCCUPATION:
        return <AddOccupationForDirectJob />;
      case DIRECT_JOB_STEPS.ADD_DESCIPTION:
        return <AddWorkDescriptionForDirectJob />;
      case DIRECT_JOB_STEPS.ADD_TIMINGS:
        return <AddTimingDirectJob />;
      case DIRECT_JOB_STEPS.ADD_KEYINFO:
        return <AddKeyInfoDirectJob />;
      case DIRECT_JOB_STEPS.ADD_LOCATIONS:
        return <AddLocationForDirectJob />;
      case DIRECT_JOB_STEPS.ADD_RATES:
        return <AddRatesForDirectJob />;
      case DIRECT_JOB_STEPS.ADD_ELIGIBILITYQUESTIONS:
        return <AddEligibilityQusForDirectJob />;
      case DIRECT_JOB_STEPS.ADD_INTERVIEWQUESTIONS:
        return <InterviewQuestionForDirectJob />;
      case DIRECT_JOB_STEPS.ADD_TECHNICAL_SKILLS:
        return <AddTechnicalSkills />;
      case DIRECT_JOB_STEPS.ADD_ADDITIONALS:
        return <AddAditionalInfo />;
      case DIRECT_JOB_STEPS.OVERVIEW_FORM:
        return <OverViewDirectjob />;
      default:
        return <AddOccupationForDirectJob />;
    }
  };

  return renderActiveStep();
};

export default AddDirectJob;
