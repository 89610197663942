import React, { FC, useEffect, useState } from "react";
import styles from "../../../../../components/contractBeforeLogin/styles/style.module.css"
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import Occupations from "../../../../../assets/images/occupations.png";
import folder from "../../../../../assets/images/folder.png";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveStep,
  setUploadedFiles,
} from "../../../../../redux/reducers/addPaidAds";
import { ADD_PAID_ADS } from "../../../../../shared/enums";
import { RootState } from "../../../../../redux/store";
import { uploadFileImg } from "../../../../../components/Posts/api";
import { postSocialPostData } from "../api";
import { POST_TYPE } from "../../../../../utils/enums/types";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../../../../utils/routes/AppRoutes";
import tip from "../../../../../assets/images/tip.png";
import Toltip from "../../../../../components/common/toltip/page";
import { CONSTANT_MANAGER } from "../../../../../utils/constans/constants";
const UploadImages: FC = () => {
  const dispatch = useDispatch();
  const { uploadedImages, socialPostDesc } = useSelector(
    (state: RootState) => state.paiAds
  );

  const [img, setImg] = useState<any>([]);

  useEffect(() => {}, [img]);

  const [imgUrl, setImgUrl] = useState<string[]>([`${uploadedImages}`]);
  const navigate = useNavigate();

  const handleClickBack = () => {
    dispatch(setActiveStep(ADD_PAID_ADS.AD_DESCRIPTION));
  };

  const handleFileUpload = async (files: FileList | null) => {
    if (files) {
      const formData = new FormData();
      Array.from(files).forEach((file) => {
        formData.append("files", file);
      });
      try {
        const response = await uploadFileImg(formData);
        dispatch(setUploadedFiles(response.data.files[0]));
        setImg(response.data.files[0]);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const removeUploadedFiles = (fileIndex: number): void => {
    if (fileIndex >= 0 && fileIndex <= uploadedImages.length - 1) {
      const updatedFiles = [...uploadedImages];
      updatedFiles.splice(fileIndex, 1);
      dispatch(setUploadedFiles([...updatedFiles]));
    }
  };

  const handlePost = async () => {
    if (uploadedImages && socialPostDesc) {
      const payload = {
        type: POST_TYPE.FREE,
        files: [img],
        description: socialPostDesc,
      };
      try {
        await postSocialPostData(POST_TYPE.FREE, payload);
        navigate(APP_ROUTES.FEEDS);
      } catch (err) {
        console.log("Occuring error while posting social post for feeds");
      }
    }else{
      alert("Please upload image first")
    }
  };

  return (
    <>
      <HeaderContent />
      <div className="py-4 heightautoscroll">
        <div className={styles.signuppage}>
          {/* <ProgressBar now={20} /> */}
          <div className="contractpages">
            <form>
              <div
                className={styles.registerOuter}
                style={{ position: "relative" }}
              >
                <div className="container h-100">
                  <div className=" h-100">
                    <div className={styles.registerBord}>
                      <div className="row pt-5 h-100 align-items-center">
                        <div className="col-md-6">
                          <div
                            className={styles.registerLeft}
                            style={{
                              position: "absolute",
                              left: "0",
                              width: "50%",
                              top: "50%",
                              transform: "translateY(-50%)",
                            }}
                          >
                            <figure>
                              <img
                                src={Occupations}
                                alt=""
                                className="img-fluid"
                              />
                            </figure>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className={styles.registerBord}>
                            <div className={styles.signUPContainer}>
                              <div className={styles.registerLeft}>
                                <div className="topTipHover">
                                  <div className="d-flex align-items-center">
                                    <h4 className="form-check-label text-20 fw-bold text-white font-inter">
                                      Upload Image
                                    </h4>
                                    <i>
                                      <img
                                        className="customeTolImg"
                                        src={tip}
                                        alt=""
                                      />
                                    </i>
                                    <Toltip
                                      slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                      labelId={
                                        CONSTANT_MANAGER.ADD_CONTRACT
                                          .SOCIAL_POST_FOR_FEED.DATA
                                          .UPLOAD_IMAGE_SOCIAL_POST
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className={styles.registerRight}>
                                <div className={styles.uploadSection}>
                                  <div className={styles.uploadbox}>
                                    <input
                                      type="file"
                                      multiple={true}
                                      accept="image/*"
                                      className={styles.filePos}
                                      // onChange={({ target }) =>
                                      //   onFilesChange(target.files)
                                      // }
                                      onChange={({ target }) =>
                                        handleFileUpload(target.files)
                                      }
                                    />
                                    <div className={styles.uploadcontent}>
                                      <img src={folder} alt="" />
                                      <h6 className="text-white text-14 ">
                                        Drag & Drop or choose Image to upload
                                      </h6>
                                      <p className=" ">Select Image</p>
                                    </div>
                                  </div>
                                </div>

                                <ul className={`nav ${styles.uploadnav}`}>
                                  {img && (
                                    <li>
                                      <div className={`position-relative ${styles.uploadImages}`}>
                                        <img
                                          src={img}
                                          alt=""
                                          className="img-fluid"
                                        />
                                        <i
                                          className="fas fa-times position-absolute top-0 end-0"
                                          onClick={() =>
                                            removeUploadedFiles(img[0])
                                          }
                                        ></i>
                                      </div>
                                    </li>
                                  )}
                                </ul>
                              </div>
                              <div className="row align-items-center justify-content-between">
                                <div
                                  className="col-4 text-start my-3 btn-hover-effect"
                                  onClick={handleClickBack}
                                >
                                  <h6 className="btn arrowyellow border-grey border-1 position-relative ps-5 backbutton">
                                    <span className="px-lg-4 px-2 text-12 fw-semibold">
                                      Back
                                    </span>
                                    <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                                      <img src={blackarrow} alt="" />
                                    </i>
                                  </h6>
                                </div>
                                <div className="col-8">
                                  <div
                                    className="d-flex align-items-center jusify-content-center"
                                    style={{ gap: "10px" }}
                                  >
                                    <div
                                      className="col-12 text-end my-3 btn-hover-effect"
                                      onClick={handlePost}
                                    >
                                      <h6 className="btn arrowblue border-grey border-1 position-relative pe-5">
                                        <span className="px-lg-4 px-2 text-12 fw-semibold">
                                          Post
                                        </span>
                                        <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                                          <img src={whitearrow} alt="" />
                                        </i>
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadImages;
