import React from "react";
import styles from "../styles/style.module.css";
import whatsnew from "../../../assets/images/whats_new.png";
import arrowwithoutcircle_blue from "../../../assets/images/arrow_withoutcircle_blue.svg";

const WhatsNew = () => {
  return (
    <div className={styles.whatsnew}>
      <div className="container">
        <div className={styles.whatsnewblock}>
          <div className="row align-items-center g-4 justify-content-between mt-3 mx-0 flex-row-reverse py-lg-5 py-3">
            <div className="col-lg-6 d-lg-block d-none text-center">
              <figure>
                <img src={whatsnew} alt="" className="img-fluid" />
              </figure>
            </div>

            <div className="col-lg-5 offset-lg-1 col-12">
              <div className="newheadingstyle">
                <h2 className="text-white fw-bold text-46">Whats New</h2>
                <p className="text-16 text-white">
                  Strategies for Boosting Engagement
                  <br />
                  Unlock the Power of Engagement with These
                  <br /> Proven Techniques.
                </p>
                <button className="btn btn-white text-blue text-20 d-flex align-items-center justify-content-center">
                  View More{" "}
                  <i className="ps-2">
                    <img
                      src={arrowwithoutcircle_blue}
                      alt=""
                      width={15}
                      height={15}
                    />
                  </i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatsNew;
