import React from "react";
import { Link } from "react-router-dom";
import styles from "../styles/style.module.css";
import Slider from 'react-slick';
import info from "../../../../assets/images/info.png";
import grdpost from "../../../../assets/images/grdpost.png";
import share from "../../../../assets/images/share.png";
import tip from "../../../../../src/assets/images/tip.png";
import Toltip from "../../toltip/page";
import { CONSTANT_MANAGER } from "../../../../utils/constans/constants";
function PendingApplication() {

  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 3,
    centerPadding: '60px',
    slidesToScroll: 2,
    infinite: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2.8,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2.05,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.02,
          slidesToScroll: 1,
        },
      },
    ],
  };


  return (
    <div className={`bg-white p-4 shadow rounded-3 mt-3`}>
      <div className="">
      <div>
        
        <div className="topTipHover">
          <div className="d-flex align-items-center">
            <h4 className="form-check-label text-20 fw-bold  font-inter">
            My Posts
            </h4>
            <i>
              <img className="customeTolImg" src={tip} alt="" />
            </i>
            <Toltip
              slug={CONSTANT_MANAGER.SLUG_HOME.HOME}
              labelId={CONSTANT_MANAGER.HOME_DATA.MY_POSTS}
            />
          </div>
        </div>
        <div className="">
        <ul className={styles.PendingLinks}>
          <li className={styles.active}>
            <span>Active Posts (10)</span>
          </li>
          <li>
            <span>Inactive Posts ( 20)</span>
          </li>
          <li>
            <span>Open Apprenticeships</span>
          </li>
          <li>
            <span>Closed Apprenticeships</span>
          </li>
        </ul>
        </div>
      </div>
      </div>
      <div>
      <div className={`p-0 shadow-0 bg-transparent ${styles.ContractTabs}`}>
        
        <div className={styles.postads}>
          <div className="row g-3 align-items-center justify-content-start">
            <Slider {...settings}>
            <div className="">
              <div className={styles.PendinfInner}>
                <div className={styles.PendigImag}>
                  <img className={styles.mainImg} src={grdpost} alt="" />
                  <a href="#">
                    {" "}
                    <img src={share} alt="" />
                  </a>
                  <span>Active</span>
                </div>
                <div className={styles.PendingData}>
                  <h5 className="fw-semibold">
                    Ultimate GPT-4 Masttery Course
                  </h5>
                  <span>Gumrod</span>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    luctus, augue eget scelerisque efficitur.Lorem ipsum dolor
                    sit amet, consectetur adipiscing elit. Sed luctus, augue
                    eget scelerisque efficitur.Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit. Sed luctus, augue eget
                    scelerisque efficitur.
                  </p>
                  <div className={styles.ditBtns}>
                    <button>Remove</button>
                    <button>Boost</button>
                    <button>Edit</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className={styles.PendinfInner}>
                <div className={styles.PendigImag}>
                  <img className={styles.mainImg} src={grdpost} alt="" />
                  <a href="#">
                    {" "}
                    <img src={share} alt="" />
                  </a>
                  <span>Active</span>
                </div>
                <div className={styles.PendingData}>
                  <h5 className="fw-semibold">
                    Ultimate GPT-4 Masttery Course
                  </h5>
                  <span>Gumrod</span>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    luctus, augue eget scelerisque efficitur.Lorem ipsum dolor
                    sit amet, consectetur adipiscing elit. Sed luctus, augue
                    eget scelerisque efficitur.Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit. Sed luctus, augue eget
                    scelerisque efficitur.
                  </p>
                  <div className={styles.ditBtns}>
                    <button>Remove</button>
                    <button>Boost</button>
                    <button>Edit</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className={styles.PendinfInner}>
                <div className={styles.PendigImag}>
                  <img className={styles.mainImg} src={grdpost} alt="" />
                  <a href="#">
                    {" "}
                    <img src={share} alt="" />
                  </a>
                  <span>Active</span>
                </div>
                <div className={styles.PendingData}>
                  <h5 className="fw-semibold">
                    Ultimate GPT-4 Masttery Course
                  </h5>
                  <span>Gumrod</span>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    luctus, augue eget scelerisque efficitur.Lorem ipsum dolor
                    sit amet, consectetur adipiscing elit. Sed luctus, augue
                    eget scelerisque efficitur.Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit. Sed luctus, augue eget
                    scelerisque efficitur.
                  </p>
                  <div className={styles.ditBtns}>
                    <button>Remove</button>
                    <button>Boost</button>
                    <button>Edit</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className={styles.PendinfInner}>
                <div className={styles.PendigImag}>
                  <img className={styles.mainImg} src={grdpost} alt="" />
                  <a href="#">
                    {" "}
                    <img src={share} alt="" />
                  </a>
                  <span>Active</span>
                </div>
                <div className={styles.PendingData}>
                  <h5 className="fw-semibold">
                    Ultimate GPT-4 Masttery Course
                  </h5>
                  <span>Gumrod</span>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    luctus, augue eget scelerisque efficitur.Lorem ipsum dolor
                    sit amet, consectetur adipiscing elit. Sed luctus, augue
                    eget scelerisque efficitur.Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit. Sed luctus, augue eget
                    scelerisque efficitur.
                  </p>
                  <div className={styles.ditBtns}>
                    <button>Remove</button>
                    <button>Boost</button>
                    <button>Edit</button>
                  </div>
                </div>
              </div>
            </div>
            </Slider>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default PendingApplication;
