import React, { useEffect, useState } from "react";
import styles from "../styles/style.module.css";
import Header from "../../header/routes";
import pro from "../../../assets/images/pro.png";
import rdinfo from "../../../assets/images/rdinfo.png";
import applepy from "../../../assets/images/applepy.png";
import visacard from "../../../assets/images/visacard.png";
import paypal from "../../../assets/images/paypal.png";
import { getBoostPlan, postBoostProfile } from "../api";
import { PLAN_RESPONSE } from "../interface";
import { BOOST_TYPE } from "../../../utils/enums/types";
import { loadStripe } from "@stripe/stripe-js";
import storage from "../../../utils/storage";
import { useLocation } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

const stripePromise = loadStripe(
  `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`
);

function BoostProfile() {
  const location = useLocation();
  const profileDetails = location.state.profileDetetails;
  const [planData, setPlanData] = useState<PLAN_RESPONSE[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<PLAN_RESPONSE | null>(null);
  const [isUntilPaused, setIsUntilPaused] = useState<boolean>(false);
  const [planID, setPlanID] = useState("");

  const fetchPlan = async () => {
    try {
      const data = await getBoostPlan();
      console.log("Boost Plans: ", data.data);
      setPlanData(data.data);
      if (data?.data?.length > 0) {
        setSelectedPlan(data.data[0]);
        setPlanID(data.data[0]._id);
      }
    } catch (err) {
      console.log(err, "Error while fetching plans");
    }
  };

  useEffect(() => {
    fetchPlan();
  }, []);

  // const handlePlanChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   const selectedValue = event.target.value;
  //   const plan = planData.find(
  //     (p) => `${p.duration} ${p.durationType} ${p.price}$` === selectedValue
  //   );
  //   setSelectedPlan(plan || null);
  //   setPlanID(plan ? plan._id : "");
  // };

  const handlePlanChange = (plan: PLAN_RESPONSE) => {
    setSelectedPlan(plan); // Update selected plan with the clicked plan
    setPlanID(plan._id); // Update the plan ID
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsUntilPaused(event.target.checked);
  };

  const handleCheckOut = async () => {
    if (planData) {
      const payload = {
        boostType: BOOST_TYPE.PROFILE,
        boostPlanId: planID,
      };
      try {
        const response = await postBoostProfile(payload);
        const sessionId = response.data.sessionId;
        console.log(sessionId, "SessionId");
        storage.setSessionId(sessionId);
        const stripe = await stripePromise;
        if (stripe) {
          const { error } = await stripe.redirectToCheckout({
            sessionId: sessionId,
          });
          if (error) {
            console.error("Stripe Checkout error:", error);
          }
        }
      } catch (err) {
        console.log("Error redirecting to checkout:", err);
      }
    }
  };

  return (
    <>
      <Header />
      <div className={styles.boostProfile}>
        <div className="container">
          <div className={styles.boostBk}>
            <h3>Boost Profile</h3>
            <p>
              Boost this profile to increase your reach. We'll automatically
              format it for different placements across Automate America and run
              it wherever it's likely to perform best.
            </p>
            <div className={styles.bostBox}>
              <div className="row">
                <div className="col-lg-4">
                  <div className={styles.bosstLeft}>
                    <img
                      src={profileDetails?.data?.profilePicture}
                      alt=""
                      className="rounded-circle"
                    />
                    <div>
                      <h5>{profileDetails?.data?.name}</h5>
                      <span className="text-12">
                        {profileDetails?.data?.occupationDetails?.map(
                          (data: any, index: number) => (
                            <span className="text-12" key={index}>
                              {data.name}
                            </span>
                          )
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 pl-lg-5 pl-0">
                  <div className="row align-items-start">
                    <div className="col-6">
                      <div className={styles.bostDate}>
                        <h5>
                          {" "}
                          {selectedPlan
                            ? `$ ${selectedPlan.price} /${selectedPlan.duration} ${selectedPlan.durationType}`
                            : "$ 50 /per Week"}
                        </h5>

                        {isUntilPaused && <span>Runs until paused</span>}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className={styles.bostDate}>
                        <h5>500 - 2000</h5>
                        <span className="opacity-75">
                          Estimated Daily Reach
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={styles.bostalert}>
                    <p>
                      <img src={rdinfo} alt="" />
                      You're over the spending limit set in your payment
                      settings. Please adjust your limit so this ad can be
                      delivered.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.boastPayment}>
              <div className="row">
                <div className="col-lg-6 col-12">
                  <div className={styles.bostpaymentleft}>
                    <div className={styles.formGroup}>
                      <h5>Set Duration</h5>
                      {/* <select
                        onChange={handlePlanChange}
                        value={
                          selectedPlan
                            ? `${selectedPlan.duration} ${selectedPlan.durationType} ${selectedPlan.price}$`
                            : ""
                        }
                      >
                        {planData && planData?.length > 0 ? (
                          planData?.map((plan, ind) => (
                            <option
                              key={ind}
                              value={`${plan.duration} ${plan.durationType} ${plan.price}$`}
                            >
                              {plan.duration} {plan.durationType} {plan.price}$
                            </option>
                          ))
                        ) : (
                          <option disabled>No plans available</option>
                        )}
                      </select> */}
                      <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic" name="travelTip">
                          {selectedPlan
                            ? `${selectedPlan.duration} ${selectedPlan.durationType} ${selectedPlan.price}$`
                            : "Select a plan"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <div
                            style={{
                              maxHeight: "300px",
                              overflowY: "scroll",
                            }}
                          >
                            {planData && planData.length > 0 ? (
                              planData.map((plan, index) => (
                                <Dropdown.Item
                                  key={index}
                                  onClick={() => handlePlanChange(plan)}
                                >
                                  {plan.duration} {plan.durationType}{" "}
                                  {plan.price}$
                                </Dropdown.Item>
                              ))
                            ) : (
                              <Dropdown.Item disabled>
                                No plans available
                              </Dropdown.Item>
                            )}
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="row align-items-center justify-content-between mt-3">
                      <div className="col-9">
                        <p className="text-14 fw-medium">
                          Run this ad until I pause it
                        </p>
                      </div>
                      <div className="col-3 text-end">
                        <div className={styles.checkboxwrap}>
                          <label>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="runUntilPaused"
                              id="runUntilPaused"
                              checked={isUntilPaused}
                              onChange={handleRadioChange}
                            />
                          </label>
                        </div>
                      </div>

                      {/* <div className="form-check"> */}
                      {/* <input
                          className="form-check-input"
                          type="checkbox"
                          name="runUntilPaused"
                          id="runUntilPaused"
                          checked={isUntilPaused}
                          onChange={handleRadioChange}
                        /> */}
                      {/* </div> */}
                    </div>
                    <div className={styles.formGroup}>
                      <h5>Payment Methods</h5>
                      <div className={styles.selctCard}>
                        <h6>
                          <img src={applepy} alt="" />
                          Apple Pay
                        </h6>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios"
                            id="exampleRadios1"
                            value="option1"
                            disabled
                          />
                        </div>
                      </div>
                      <div className={styles.selctCard}>
                        <h6>
                          <img src={visacard} alt="" />
                          Credit / Debit Card{" "}
                          <label>Visa, Mastercard, AMEX, CUP, JCB</label>
                        </h6>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios"
                            id="exampleRadios1"
                            value="option1"
                            defaultChecked
                          />
                        </div>
                      </div>
                      <div className={styles.selctCard}>
                        <h6>
                          <img src={paypal} alt="" />
                          Paypal
                        </h6>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios"
                            id="exampleRadios1"
                            value="option1"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-12">
                  <div className={styles.paymentForm}>
                    <h5>Payment Summery</h5>
                    <div className={styles.paymentBox}>
                      <h6>Duration</h6>
                      <label>
                        {selectedPlan?.duration} {selectedPlan?.durationType}
                      </label>
                      <ul>
                        <li>
                          Subtotal <span>${selectedPlan?.price}</span>
                        </li>
                        {/* <li>
                          Discounts <span>-$3.00</span>
                        </li> */}
                        <li>
                          Total <span>${selectedPlan?.price}</span>
                        </li>
                      </ul>
                    </div>
                    <button onClick={handleCheckOut}>Checkout</button>
                  </div>
                </div>
                <div className="mt-5">
                  <h4 className="text-16 fw-semibold">Available Features</h4>
                  <div className={styles.avaiablefeatures}>
                    <ol className="mt-3">
                      <li>
                        Skill-Based Matching: Implement algorithms that match
                        candidates' skills with job requirements more precisely.
                      </li>
                      <li>
                        Real-Time Matching: Use real-time data to suggest jobs
                        to candidates and candidates to employers as soon as
                        they become available.
                      </li>
                      <li>
                        Detailed Profiles: Allow freelancers to showcase their
                        skills, experience, and portfolio in a more detailed
                        manner.
                      </li>
                      <li>
                        Integrated Messaging: Provide a robust messaging system
                        that allows real-time communication between employers
                        and freelancers.
                      </li>
                      <li>
                        Push Notifications: Enable push notifications for new
                        job postings, messages, and payment updates.
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BoostProfile;
