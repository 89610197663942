import React from "react";

import Header from "../../header/routes";
import { useNavigate } from "react-router-dom";
import styles from "../styles/style.module.css";

interface Props {
  sortValue: string;
  setSortValue: (value: string) => void;
}

const SortFilter: React.FC<Props> = ({sortValue, setSortValue}) => {
  return (
    <>
      <div className="d-flex align-items-center justify-content-end">
        <span className="me-2">Sort:</span>
        <div className="dropdown">
          <button
            className="dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {sortValue}
          </button>
          <ul className="dropdown-menu w-auto">
            <li>
              <a className="dropdown-item" onClick={() => setSortValue("day")}>
                Today
              </a>
            </li>
            <li>
              <a className="dropdown-item" onClick={() => setSortValue("week")}>
                Last Week
              </a>
            </li>
            <li>
              <a className="dropdown-item" onClick={() => setSortValue("month")}>
                Month
              </a>
            </li>
            <li>
              <a className="dropdown-item" onClick={() => setSortValue("all")}>
                All
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default SortFilter;
