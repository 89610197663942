import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
// import congratsCheck from '../../../../../assets/images/congratulations.png'
import crossicon from '../../../../../assets/images/cross.svg'
import review from '../../../../../assets/images/review.png'

interface DocumentReviewModal {
  onClose: () => void;
  onNext: () => void;
  showModal:boolean
}

const DocumentReviewModal: React.FC<DocumentReviewModal> = ({ onClose,onNext,showModal }) => (
<Modal onHide={onClose} show={showModal} centered className="modal-md">
    {/* <ModalHeader closeButton></ModalHeader> */}
      <ModalBody>
      <div className="modal_catch d-flex flex-column justify-content-center text-center rounded-0 pt-4 position-relative">
      <div className="position-absolute" style={{top: "10px", right: "10px"}}>
          <i>
            <img src={crossicon} onClick={onClose} alt="" width="25px" height="25px" />
          </i>
        </div>
        <div className="col-lg-9 col-12 mx-auto">
            <div className="text-center">
              <figure>
                <img src={review} alt="" className="img-fluid mx-auto my-4" />
              </figure>
            </div>
            <h1 className="text-24 fw-bold">Your Document has been submitted <br/> for review!</h1>
            <p className="text-16 mb-0">
            An admnistrator will review your document shortly. You will be notified once the review process is complete.</p>
        </div>
        </div>
            
      </ModalBody>
      
      <ModalFooter className="btn-hover-effect justify-content-center border-top-0 mb-3">
      <div className="col-md-5 col-12 mx-auto">
          <Button
          onClick={onNext}
            type="button"
            variant="secondary"
            className="btn border border-1 rounded-2 position-relative py-2 backbutton w-100 mb-0"
            style={{backgroundColor: "#1EC20F"}}
          >
            <span className="px-lg-4 px-2 text-12 fw-semibold text-white">
            Continue
            </span>
          </Button>
          </div>
        </ModalFooter>
    </Modal>
  );

export default DocumentReviewModal;
