import React, { useState } from "react";
import styles from "../styles/style.module.css";
import info from "../../../assets/images/info_icon.svg";
import Plus from "../../../assets/images/plus.png";
import DateTime from "../../Datetimefilter/routes";
import SortFilter from "../../Sortfilter/routes";
import Search from "../../search/routes";
import Myteams from "../../Myteams/routes";
import { CONSTANT_MANAGER } from "../../../utils/constans/constants";
import Toltip from "../../common/toltip/page";
import tip from "../../../assets/images/tip.png";
const MyTeamsComp = () => {
  const [filterValue, setFilterValue] = useState<string>("");

  return (
    <div className={styles.myfriends}>
      <div className="container">
        <div className={styles.new_feeds}>
          <div className="">
            <div className="row align-items-center justify-content-between mb-3">
              <div className="col-6">
                <div className="newheadingstyle">
                  <div className="topTipHover">
                    <div className="d-flex align-items-center">
                      <h4 className="form-check-label text-20 fw-bold  font-inter">
                        My Teams
                      </h4>
                      <i>
                        <img className="customeTolImg" src={tip} alt="" />
                      </i>
                      <Toltip
                        slug={CONSTANT_MANAGER.SLUG_FEED.FEED}
                        labelId={CONSTANT_MANAGER.FEED_DATA.MY_TEAMS}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 ">
                {/* <div className="text-end">
              <div className="col-xl-5 col-11 ms-auto">
                <button className="btn btn-primary h-40 text-14">
                  <i className="me-2">
                    <img src={Plus} alt="" width={10} height={10} />
                  </i>
                  Invite Friends
                </button>
              </div>
            </div> */}
              </div>
            </div>
            <div className="row align-items-center justify-content-between mt-3 mb-3">
              <div className="col-lg-3 col-12">
              <Search filterValue={filterValue} setFilterValue={setFilterValue} />
              </div>

              <div className="col-lg-9 col-12">
                <div className="filter_sort mt-md-0 mt-3">
                  <div className="ms-auto">
                    <div className="row align-items-center justify-content-center g-3">
                      
                      <div className="col-lg-4 col-6">
                        <DateTime />
                      </div>

                      {/* <div className="col-lg-4 col-6">
                        <SortFilter />
                      </div> */}
                      <div className="col-md-4 col-12">
                        <div className="text-end">
                          <div className="col-xl-7 col-md-11 col-6 ms-auto">
                            <button className="btn btn-primary h-40 text-14 d-flex align-items-center justify-content-center">
                              <i className="me-2">
                                <img src={Plus} alt="" width={10} height={10} />
                              </i>
                              Invite Friends
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Myteams />
        </div>
      </div>
    </div>
  );
};

export default MyTeamsComp;
