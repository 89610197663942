import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WORK_TABS } from "../../utils/enums/workTabs.enum";
import { CONTRACT_STATUS } from "../../utils/enums/status";

export interface DashboardState {
  activeTab: WORK_TABS;
  activeContract: CONTRACT_STATUS;
  eligibilityAnswers: EligibilityAnswers[];
  interviewAnswers: InterviewAnswers[];
  liability_insurance?: liability_insurance;
}
export interface EligibilityAnswers {
  Answers: boolean;
  questionId: string;
}
export interface InterviewAnswers {
  Answers: string;
  questionId: string;
}

export interface liability_insurance {
    fileName: String,
    fileUrl: String,
}

const initialState: DashboardState = {
  activeTab: WORK_TABS.DONEBYME,
  activeContract: CONTRACT_STATUS.ONGOING,
  eligibilityAnswers: [],
  interviewAnswers: [],
};

const workDataSlice = createSlice({
  name: "dashboradData",
  initialState,
  reducers: {
    changeTab: (state, action: PayloadAction<WORK_TABS>) => {
      state.activeTab = action.payload;
    },
    changeContract: (state, action: PayloadAction<CONTRACT_STATUS>) => {
      state.activeContract = action.payload;
    },
    setAnswers: (state, action: PayloadAction<EligibilityAnswers[]>) => {
      state.eligibilityAnswers = [...action.payload];
    },
    setInterviewAns: (state, action: PayloadAction<InterviewAnswers[]>) => {
      state.interviewAnswers = [...action.payload];
    },
    setLiabilityInsurance: (state, action: PayloadAction<liability_insurance>) => {
      state.liability_insurance = action.payload;
    }
  },

});

export const { changeTab, changeContract, setAnswers, setInterviewAns, setLiabilityInsurance } = workDataSlice.actions;
export default workDataSlice.reducer;
