import React, { useState } from "react";
import Slider from "react-slick";
import styles from "../../css/styles/style.module.css";
import { ContractResponse } from "../interface";
import moment from "moment";
import bags from "../../../../assets/images/bags2.png";
import loc from "../../../../assets/images/loc.png";
import checkmark from "../../../../assets/images/checkmark.svg";
import rejectedapp from "../../../../assets/images/rejectedapp.svg";
import offeraccepted from "../../../../assets/images/offeraccepted.svg";

interface ModalProps {
    onGoingContracts: ContractResponse[];
}

const OngoingStandard:React.FC<ModalProps> = ({onGoingContracts}) => {
//   const [onGoingContracts, setOngoingContracts] = useState<ContractResponse[]>([]);

  const [loading, setLoading] = useState<boolean>(true);

  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 2,
    centerPadding: "60px",
    slidesToScroll: 2,
    infinite: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1.4,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.02,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div
      className="tab-pane fade"
      id="nav-profile"
      role="tabpanel"
      aria-labelledby="nav-profile-tab"
    >
      <div className={styles.OuterSlides}>
        <Slider {...settings}>
          {onGoingContracts?.length > 0
            ? onGoingContracts?.map((data: any, idx) => {
                return (
                  <div key={idx} className={`mt-0 ${styles.ContarctOuter}`}>
                    <div className={styles.ContarctHead}>
                      <div className="row align-item-center justify-content-between mx-0 w-100">
                        <div className="col-xl-5 col-lg-12 col-md-5 col-12">
                          <div className={styles.leftContract}>
                            <h5>Contract No.: ----#{data?.workNumber}</h5>
                            <span>
                              Start Date :
                              {moment(data.times.startDate).format(
                                "MM-DD-YYYY"
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="col-xl-7 col-lg-12 col-md-7 col-12">
                          <div
                            className={styles.leftContract}
                            style={{ textAlign: "end" }}
                          >
                            <button className={styles.CoptText}>
                              <i className="far fa-copy"></i> Copy
                            </button>
                            {/* <button>{data?.workStatus === WORK_STATUS_TYPE.ONGOING ? "Ongoing" : data.workStatus === WORK_STATUS_TYPE.APPROVED ? "Approved" : data.workStatus === WORK_STATUS_TYPE.OPEN ? "Open" : ""}</button> */}
                            <button>Ongoing</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.outerNameDetail}>
                      <div className="row align-items-center">
                        <div className="col-md-12">
                          <div className={styles.outerNDetail}>
                            <div className="row align-items-center">
                              <div className="col-6">
                                <div className={styles.PrileImgs}>
                                  <h4 className="fw-semibold text-20">
                                    {" "}
                                    {data?.ownWork
                                      ? "Contractor Details"
                                      : "Professional Details"}
                                  </h4>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className={styles.rightName}>
                                  {data?.ownWork &&
                                  data.planType === "whiteglove" ? (
                                    <h6>Working: {data?.professional?.name}</h6>
                                  ) : (
                                    <span className={styles.contarctBag}>
                                      <img
                                        src={bags}
                                        alt="White Glove Contract"
                                      />
                                      {data.planType === "whiteglove"
                                        ? "White Glove Contract"
                                        : data.planType === "standard"
                                        ? "Standard Contract"
                                        : ""}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 col-12">
                          <div className={styles.leftName}>
                            <div className={styles.PrileImg}>
                              <span className="text-center w-100">
                                <img
                                  src={
                                    data?.contractor?.profilePicture
                                      ? data?.contractor?.profilePicture
                                      : data?.userInfo?.profilePicture
                                  }
                                  alt=""
                                  height={70}
                                  width={70}
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-5 col-6">
                          <div className={styles.leftName}>
                            <div className={styles.PrileImg}>
                              <div className="">
                                {data?.ownWork ? (
                                  <h5>{data?.contractor?.name}</h5>
                                ) : (
                                  <h5>{data?.userInfo?.name}</h5>
                                )}

                                {data?.ownWork ? (
                                  <h6>{data?.contractor?.occupation?.name}</h6>
                                ) : (
                                  <h6>{data?.userInfo?.occupation?.name}</h6>
                                )}
                                {data?.ownWork ? (
                                  <p>
                                    <img src={loc} alt="" /> {data?.address}
                                  </p>
                                ) : (
                                  <p>
                                    {" "}
                                    <img src={loc} alt="" />{" "}
                                    {data?.userInfo?.address}{" "}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-5 col-6">
                          <div className={styles.rightName}>
                            <p>
                              Start Date:{" "}
                              {moment(data.times.startDate).format(
                                "MM-DD-YYYY"
                              )}
                            </p>
                            {/* <p>End Date: {moment(data.times.startDate).format("MMM DD, YYYY")}</p> */}
                            <p>
                              Rate Per Hour : Net
                              {data?.userInfo?.hourlyPayment?.paymentTerm}
                            </p>
                            <p>
                              Expense Terms : Net{" "}
                              {data?.userInfo?.expensePayment?.paymentTerm}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row p-3">
                      <div className="col-lg-4 col-6">
                        <div
                          className={`d-flex align-items-start bg-lightblue border-0 ${styles.timesheetData}`}
                        >
                          <div className="col-2 mt-1">
                            <i>
                              <img
                                src={checkmark}
                                alt=""
                                className="img-fluid"
                              />
                            </i>
                          </div>
                          <div className="col-10">
                            <h6 className="fw-medium mb-1">
                              Applications Received
                            </h6>
                            <h3 className="mb-0">20</h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-6">
                        <div
                          className={`d-flex align-items-start bg-lightblue border-0 ${styles.timesheetData}`}
                        >
                          <div className="col-2 mt-1">
                            <i>
                              <img
                                src={checkmark}
                                alt=""
                                className="img-fluid"
                              />
                            </i>
                          </div>
                          <div className="col-10">
                            <h6 className="fw-medium mb-1">
                              Shortlisted Applicants
                            </h6>
                            <h3 className="mb-0">20</h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-6">
                        <div
                          className={`d-flex align-items-start bg-lightblue border-0 ${styles.timesheetData}`}
                        >
                          <div className="col-2 mt-1">
                            <i>
                              <img
                                src={checkmark}
                                alt=""
                                className="img-fluid"
                              />
                            </i>
                          </div>
                          <div className="col-10">
                            <h6 className="fw-medium mb-1">Offers Pending</h6>
                            <h3 className="mb-0">20</h3>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-6">
                        <div
                          className={`d-flex align-items-start bg-lightblue border-0 ${styles.timesheetData}`}
                        >
                          <div className="col-2 mt-1">
                            <i>
                              <img
                                src={offeraccepted}
                                alt=""
                                className="img-fluid"
                              />
                            </i>
                          </div>
                          <div className="col-10">
                            <h6 className="fw-medium mb-1">Offers Accepted</h6>
                            <h3 className="mb-0">20</h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-6">
                        <div
                          className={`d-flex align-items-start bg-lightred border-0 ${styles.timesheetData}`}
                        >
                          <div className="col-2 mt-1">
                            <i>
                              <img
                                src={rejectedapp}
                                alt=""
                                className="img-fluid"
                              />
                            </i>
                          </div>
                          <div className="col-10">
                            <h6 className="fw-medium mb-1">
                              Rejected Applicants
                            </h6>
                            <h3 className="mb-0">20</h3>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* {data.planType === "whiteglove" &&
                            <div className={styles.timeSheets}>
                             
                              {data?.ownWork ? (
                                <>
                                  <div className="text-center py-3">
                                    <button
                                      onClick={() =>
                                        navigate(
                                          `${APP_ROUTES.SSAAGGREMENT}/${data?._id}`
                                        )
                                      }
                                      className="btn btn-primary w-50 mx-auto"
                                    >
                                      View SSA
                                    </button>
                                  </div>

                                </>
                              ) : (
                                <div className='row'>
                                <div className='col-6'>
                                  <div className="text-center py-3">
                                    <button
                                      onClick={() =>
                                        navigate(
                                          `${APP_ROUTES.MSAAGGREMENT}/${data._id}/${data.userInfo._id}`
                                        )
                                      }
                                      className="btn btn-primary w-100 mx-auto"
                                    >
                                      View MSA
                                    </button>
                                  </div>
                                  </div>
                                  <div className='col-6'>
                                  <div className="text-center py-3">
                                    <button
                                      onClick={() =>
                                        navigate(
                                          `${APP_ROUTES.ONGOING_CONTRACT_DETAIL}/${data?._id}`,
                                          {
                                            state: { professionalFlag: true,data },
                                          }
                                        )
                                      }
                                      className="btn btn-primary w-100 mx-auto"
                                    >
                                      Track Progress
                                    </button>
                                  </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          } */}
                  </div>
                );
              })
            : ""}
        </Slider>
      </div>
    </div>
  );
};

export default OngoingStandard;
