import React, { useEffect, useState } from "react";
import styles from "../../jobs/styles/style.module.css";
import Slider from "react-slick";
import loc from "../../../../assets/images/loc.png";
import info from "../../../../assets/images/info.png";
import orbag from "../../../../assets/images/orbag.png";
import slider from "../../../../assets/images/slider.png";
import { Link, useNavigate } from "react-router-dom";
import tip from "../../../../../src/assets/images/tip.png";
import Toltip from "../../toltip/page";
import { CONSTANT_MANAGER } from "../../../../utils/constans/constants";
import { getWorkDetail } from "../../Contracts/OpenContracts/api";
import { COUNT_TYPE } from "../../../../utils/enums/types";
import { getEventDetail } from "../api";
import { eventDetailRes } from "../interface";
import { WorkResponse } from "../../Contracts/OpenContracts/interface";
import { WorkData } from "../../Contracts/OpenContracts/interface";
import { APP_ROUTES } from "../../../../utils/routes/AppRoutes";
import storage from "../../../../utils/storage";
import moment from "moment";

const CustomerRequest = () => {
  const navigate = useNavigate();
  const [openContractDetails, setOpenContractDetails] = useState<WorkData[]>([]);
  // const [eventData, setEventData] = useState<eventDetailRes[]>([]);

  const openContractDetailsList = async () => {
    const data = await getEventDetail();
    const wordData: Array<any> = [];
    // setEventData(data?.data);
    if (data?.data) storage.setInterviewData(data?.data);

    const startDay = moment().startOf('day').format('YYYY-MM-DD');
    const endDay = moment().endOf('day').format('YYYY-MM-DD');

    if (data?.data?.length !== openContractDetails?.length) {
      for await (let key of data?.data) {
        let FetchInterviewDate = moment(key.eventDate).format('YYYY-MM-DD');
        if (FetchInterviewDate < startDay || FetchInterviewDate > endDay) continue;

        const response: any = await getWorkDetail(key.workId, COUNT_TYPE.BY_ME);
        console.log(data?.data);
        wordData.push(response?.data);
      }
      setOpenContractDetails(wordData);
    }
  };
  console.log(openContractDetails, "jhiojj", Array.isArray(openContractDetails));

  useEffect(() => {
    openContractDetailsList();
  }, []);

  var Jobslide = {
    dots: false,
    speed: 500,
    slidesToShow: 2,
    centerPadding: "60px",
    slidesToScroll: 2,
    infinite: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.02,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="mt-4">
        <div className={styles.Jobs}>
          <div>
            <div className="topTipHover">
              <div className="d-flex align-items-center">
                <h4 className="form-check-label text-20 fw-bold  font-inter">
                  Requests from Customers
                </h4>
                <i>
                  <img className="customeTolImg" src={tip} alt="" />
                </i>
                <Toltip
                  slug={CONSTANT_MANAGER.SLUG_HOME.HOME}
                  labelId={
                    CONSTANT_MANAGER.HOME_DATA.REQUESTS_FROM_CUSTOMERS.DATA
                      .REQUESTS_FROM_CUSTOMERS
                  }
                />
              </div>
            </div>
            <div className={styles.ContractTabs}>
              <div className={styles.AllContract}>
                <div className="topTipHover">
                  <div className="d-flex align-items-center">
                    <h4 className="form-check-label text-20 fw-bold  font-inter">
                      Requests
                    </h4>
                    <i>
                      <img className="customeTolImg" src={tip} alt="" />
                    </i>
                    <Toltip
                      slug={CONSTANT_MANAGER.SLUG_HOME.HOME}
                      labelId={
                        CONSTANT_MANAGER.HOME_DATA.REQUESTS_FROM_CUSTOMERS.DATA
                          .REQUESTS
                      }
                    />
                  </div>
                </div>

                <Link to="#">
                  View All{" "}
                  <span>
                    {" "}
                    <i className="fas fa-chevron-right"></i>{" "}
                  </span>{" "}
                </Link>
              </div>
              <Slider {...Jobslide}>
                {/* {openContractDetails.length ? openContractDetails.map((item, index) =>
                (<>
                  <div key={index} className={styles.customerContarctSlides}>
                    <div className={styles.CustomerSlideHEad}>
                      <h5>Request for John Simone Services</h5>
                      <span>Date : 12 Jan 2024</span>
                    </div>
                    <div className={styles.DateRane}>
                      <p>
                        Start Date: 10/18/202
                        <br />
                        Estimated Duration: {item.times.duration}
                      </p>
                      <h6>Rates : $70.84(net 15)</h6>
                    </div>
                    <div className={styles.Mainpara}>
                      <p>
                        {item.description} <Link to="#">Learn More</Link>
                      </p>
                    </div>
                    <div className={styles.VideoCallBtn}>
                      <button>
                        <i className="far fa-times-circle"></i> Cancel{" "}
                      </button>
                      <button className={styles.videBtn} onClick={() => navigate(APP_ROUTES.VIDEO_CALL.AUDIENCE)}>
                        <i className="fas fa-video"></i> Accept
                      </button>
                    </div>
                    <div className={styles.Meetbtn}>
                      <button className={styles.videBtnmt}>
                        <i className="fas fa-video"></i> Request a Video Meeting
                      </button>
                    </div>
                  </div>
                </>)
                ) : <p>data...</p>} */}


                <div className={styles.customerContarctSlides}>
                  <div className={styles.CustomerSlideHEad}>
                    <h5>Request for John Simone Services</h5>
                    <span>Date : 12 Jan 2024</span>
                  </div>
                  <div className={styles.DateRane}>
                    <p>
                      Start Date: 10/18/202
                      <br />
                      Estimated Duration: One Month
                    </p>
                    <h6>Rates : $70.84(net 15)</h6>
                  </div>
                  <div className={styles.Mainpara}>
                    <p>
                      Need: Healthyaa, a leading health and wellness clinic, is
                      seeking an experienced Mobile App Developer to create an
                      engaging and intuitive mobile application for both Android
                      and iOS platforms. This application will focus on
                      presenting health reports graphically, allowing our
                      clients to visualize their health and wellness data in a
                      meaningful way. The ideal candidate will possess a strong
                      background in mobile development with a proven track
                      record of creating data visualization apps or similar
                      functionalities. <Link to="#">Learn More</Link>
                    </p>
                  </div>
                  <div className={styles.VideoCallBtn}>
                    <button>
                      <i className="far fa-times-circle"></i> Cancel{" "}
                    </button>
                    <button className={styles.videBtn}>
                      <i className="fas fa-video"></i> Accept
                    </button>
                  </div>
                  <div className={styles.Meetbtn}>
                    <button className={styles.videBtnmt}>
                      <i className="fas fa-video"></i> Request a Video Meeting
                    </button>
                  </div>
                </div>
                <div className={styles.customerContarctSlides}>
                  <div className={styles.CustomerSlideHEad}>
                    <h5>Request for John Simone Services</h5>
                    <span>Date : 12 Jan 2024</span>
                  </div>
                  <div className={styles.DateRane}>
                    <p>
                      Start Date: 10/18/202
                      <br />
                      Estimated Duration: One Month
                    </p>
                    <h6>Rates : $70.84(net 15)</h6>
                  </div>
                  <div className={styles.Mainpara}>
                    <p>
                      Need: Healthyaa, a leading health and wellness clinic, is
                      seeking an experienced Mobile App Developer to create an
                      engaging and intuitive mobile application for both Android
                      and iOS platforms. This application will focus on
                      presenting health reports graphically, allowing our
                      clients to visualize their health and wellness data in a
                      meaningful way. The ideal candidate will possess a strong
                      background in mobile development with a proven track
                      record of creating data visualization apps or similar
                      functionalities. <Link to="#">Learn More</Link>
                    </p>
                  </div>
                  <div className={styles.VideoCallBtn}>
                    <button>
                      <i className="far fa-times-circle"></i> Cancel{" "}
                    </button>
                    <button className={styles.videBtn}>
                      <i className="fas fa-video"></i> Accept
                    </button>
                  </div>
                  <div className={styles.Meetbtn}>
                    <button className={styles.videBtnmt}>
                      <i className="fas fa-video"></i> Request a Video Meeting
                    </button>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerRequest;
