// import React, { FC } from "react";
// import { useDispatch } from "react-redux";
// import { useAppSelector } from "../../../../redux/hooks";
// import { RootState } from "../../../../redux/store";
// import { Signup_steps } from "../../../../shared/enums";
// import PersonalInfo from "./PersonalInfo";

// const AddHourlyContract: FC = () => {
//   const dispatch = useDispatch();
//   const userData = useAppSelector((state: RootState) => state.userData);
//   const { } = userData;


//   const renderActiveStep = () => {
//     switch (activeStep) {
//       case Signup_steps.PERSONAL_INFO:
//         return <PersonalInfo />;
//       case Signup_steps.SEND_OTP:
//         return <PersonalInfo />;
//       case Signup_steps.PHONE_VERIFY:
//         return <PersonalInfo />;
//       case Signup_steps.OTP_VERIFY:
//         return <PersonalInfo />;
//       case Signup_steps.EMAIL_VERIFY:
//         return <PersonalInfo />;
//       case Signup_steps.PASSWORD:
//         return <PersonalInfo />;
//       case Signup_steps.OCCUPATION:
//         return <PersonalInfo />;
//       case Signup_steps.CITIZENSHIP:
//         return <PersonalInfo />;
//       case Signup_steps.ABOUT_US:
//         return <PersonalInfo />;

//       default:
//         return <PersonalInfo />;
//     }
//   };
//   return renderActiveStep();
// };

// export default AddHourlyContract;

import React from "react";
import PersonalInfo from "./PersonalInfo";
import EmailVerify from "./EmailVerification";

const SignUp = () => {
  return (
    <>
      <PersonalInfo />
      <EmailVerify />
    </>
  );
};

export default SignUp;
