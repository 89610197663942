import { configureStore } from '@reduxjs/toolkit';
import userDataReducer from '../reducers/auth';
import {
  dashboardReducer,
  signupDataReducer,
  contractReducer,
  addContractReducer,
  authenticatedReducer,
  paidAdsReducer,
  addDirectJobReducer,
  videoJoinConfigReducer,
  workTypeReducer,
} from '../reducers';
import storage from '../../utils/storage';
import businessDetails from '../reducers/businessDetails';
import BusinessEmployeeSlice from '../reducers/BusinessEmployeeSlice';
import businessGroupData from '../reducers/businessGroupData';

export const store = configureStore({
  reducer: {
    userData: userDataReducer,
    signupResponse: signupDataReducer,
    dashboard: dashboardReducer,
    contractPost: contractReducer,
    addContract: addContractReducer,
    authentication: authenticatedReducer,
    paiAds: paidAdsReducer,
    directJob: addDirectJobReducer,
    videoJoinConfig: videoJoinConfigReducer,
    workType: workTypeReducer,
    business:businessDetails,
    employee:BusinessEmployeeSlice,
    businessGroup:businessGroupData
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

store.subscribe(() => {
  const state = store.getState().addContract;

  if (state.started) {
    storage.saveContract(state);
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
