import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../css/styles/style.module.css";
import info from "../../../../assets/images/info_icon.svg";
import bag from "../../../../assets/images/grbag.png";
import rejectedapp from "../../../../assets/images/rejectedapp.svg";
import offeraccepted from "../../../../assets/images/offeraccepted.svg";
import loc from "../../../../assets/images/loc.png";
import checkmark from "../../../../assets/images/checkmark.svg";
import star from "../../../../assets/images/star.svg";
import Slider from "react-slick";
import { APP_ROUTES } from "../../../../utils/routes/AppRoutes";
import { CONTRACT_Type } from "../../../../utils/enums/types";
import moment from "moment";
import { TYPE, WORK_STATUS_TYPE } from "../../../../utils/enums/status";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { WORK_TABS } from "../../../../utils/enums/workTabs.enum";
import { getContractList } from "../api";
import { ContractResponse } from "../interface";
import Toltip from "../../toltip/page";
import OngoingStandard from "../OngoingContracts/OngoingStandard";

const OnGoingContractsProfessional = () => {
  const navigate = useNavigate();
  const dashboardData = useSelector((state: RootState) => state.dashboard);
  const { activeTab } = dashboardData;
  console.log(dashboardData,"dashboard data for professional ")
  const [onGoingContracts, setOngoingContracts] = useState<ContractResponse[]>(
    []
  );

  const [loading, setLoading] = useState<boolean>(true);

  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 2,
    centerPadding: "60px",
    slidesToScroll: 2,
    infinite: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1.4,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.02,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchOngoingContracts = async () => {
      setLoading(true);
      try {
        let response: ContractResponse | any;
        if (activeTab === WORK_TABS.DONEFORME) {
          response = await getContractList(
            CONTRACT_Type.HOURLY_CONTRACT,
            TYPE.DONEFORME,
            WORK_STATUS_TYPE.ONGOING
          );
          console.log(response, "res");
        } else if (activeTab === WORK_TABS.DONEBYME) {
          response = await getContractList(
            CONTRACT_Type.HOURLY_CONTRACT,
            TYPE.DONEBYME,
            WORK_STATUS_TYPE.ONGOING
          );
          console.log(response, "res");
        } else {
          response = { data: [] };
        }
        setOngoingContracts(response.data);
      } catch (error) {
        console.error("Error fetching ongoing contracts:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchOngoingContracts();
  }, [activeTab]);

  const handleViewTimesheet = (workdata: ContractResponse) => {
    if (workdata.ownWork) {
      navigate(`${APP_ROUTES.TIMESHEETDETAIL_TABLE}/${workdata?._id}`, {
        state: { workdata },
      });
    } else {
      navigate(`${APP_ROUTES.TIMESHEET_TABLE}/${workdata?._id}`, {
        state: { workdata },
      });
    }
  };

  const handleViewExpense = (workdata: ContractResponse) => {
    if (workdata.ownWork) {
      navigate(`${APP_ROUTES.EXPENSE_STATUS_TABLE}/${workdata?._id}`);
    } else {
      navigate(`${APP_ROUTES.EXPENSE_TABLE}/${workdata?._id}`);
    }
  };

  const handleViewInvoice = (workdata: ContractResponse) => {
    if (workdata.ownWork) {
      navigate(
        `${APP_ROUTES.INVOICE_TABLE_PROFESSIONAL}/${workdata?._id}?isBusiness=${true}`
      );
    } else {
      navigate(`${APP_ROUTES.INVOICE_TABLE_PROFESSIONAL}/${workdata?._id}`);
    }
  };

  console.log("Ongoing data", onGoingContracts);

  return (
    <div className={`bg-white p-4 shadow rounded-3 mt-3`}>
      {onGoingContracts?.length > 0 && (
        <div className="Contracts">
          <div className={styles.ContractTabs}>
            <div className={`justify-content-start mb-3 ${styles.AllContract}`}>
              <div className="d-flex align-items-center mb-2">
                <h4 className="form-check-label text-20 fw-bold mb-0 font-inter">
                  Ongoing Contracts
                </h4>
                <i>
                  <img className="customeTolImg" src={info} alt="" />
                </i>
                <Toltip />
              </div>
            </div>

            {loading ? (
              <div className="spinner-grow text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <div className={`${styles.tabingnav}`}>
                <nav className="bg-lightblue navtabing">
                  <div
                    className="nav nav-tabs px-2"
                    id="nav-tab"
                    role="tablist"
                  >
                    <button
                      className={`nav-link active`}
                      id="nav-home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-home"
                      type="button"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                    >
                      White Glove Contracts
                    </button>
                    <button
                      className={`nav-link`}
                      id="nav-profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-profile"
                      type="button"
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected="false"
                    >
                      Standard Contracts
                    </button>
                  </div>
                </nav>
                <div
                  className="tab-content py-3 px-2 bg-light"
                  id="nav-tabContent"
                >
                  <div
                    className={`tab-pane fade show active`}
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                  >
                    {" "}
                    <div className={styles.OuterSlides}>
                      <Slider {...settings}>
                        {onGoingContracts?.length > 0
                          ? onGoingContracts?.map((data: ContractResponse, idx) => {
                              return (
                                <div
                                  key={idx}
                                  className={`mt-0 ${styles.ContarctOuter}`}
                                >
                                  <div className={styles.ContarctHead}>
                                    <div className="row align-item-center justify-content-between mx-0 w-100 position-relative">
                                      <div className="col-xl-5 col-lg-12 col-md-5 col-12 mb-2">
                                        <div className={styles.leftContract}>
                                          <h5>
                                            Contract No.: #{data?.workNumber}
                                          </h5>
                                          <span>
                                            Start Date:{" "}
                                            {moment(
                                              data.times.startDate
                                            ).format("MM-DD-YYYY")}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="col-xl-7 col-lg-12 col-md-7 col-12 mb-2">
                                        <div
                                          className={styles.leftContract}
                                          style={{ textAlign: "end" }}
                                        >
                                          <button
                                            className={`position-absolute top-0 ${styles.statusdetails}`}
                                          >
                                            Ongoing
                                          </button>
                                        </div>
                                      </div>
                                      <div className="col-xl-7 col-lg-12 col-12">
                                        <div className={styles.leftContract}>
                                          <div className={styles.Local}>
                                            <div
                                              className={`justify-content-start ${styles.adrssLoc}`}
                                            >
                                              <span
                                                className={`py-2 rounded-2 me-2 mb-0 border-green bg-lightgreen ${styles.contarctBag}`}
                                              >
                                                <img
                                                  src={bag}
                                                  alt="White Glove Contract"
                                                />
                                                {data.planType === "whiteglove"
                                                  ? "White Glove Contract"
                                                  : data.planType === "standard"
                                                  ? "Standard Contract"
                                                  : ""}
                                              </span>
                                              {data?.workLocation ? (
                                                <span
                                                  className={`py-3 mb-0 rounded-2 bg-white text-blue border-blue`}
                                                >
                                                  {data?.workLocation ===
                                                  "Remote"
                                                    ? "Remote Contract"
                                                    : data?.workLocation ===
                                                      "Onsite"
                                                    ? "Onsite Contract"
                                                    : data?.workLocation ===
                                                      "Hybrid"
                                                    ? "Hybrid Contract"
                                                    : "Unknown Location"}
                                                </span>
                                              ) : (
                                                <span className="text-gray">
                                                  No Work Location
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-xl-5 col-lg-12 col-12">
                                        <div
                                          className={styles.leftContract}
                                          style={{ textAlign: "end" }}
                                        >
                                          <button
                                            className={`border-blue bg-blue text-white`}
                                          >
                                            <i className="far fa-copy"></i> Copy
                                          </button>
                                          <button
                                            className={`bg-white ${styles.endcontracts}`}
                                          >
                                            End Contract
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={styles.outerNameDetail}>
                                    <div className="row align-items-center">
                                      <div className="col-md-12">
                                        <div className={styles.outerNDetail}>
                                          <div className="row align-items-center">
                                            <div className="col-6">
                                              <div className={styles.PrileImgs}>
                                                <h4 className="fw-medium text-20">
                                                  {" "}
                                                  {data?.ownWork
                                                    ? "Contractor Details"
                                                    : "Professional Details"}
                                                </h4>
                                              </div>
                                            </div>
                                            <div className="col-6">
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-2 col-12 pe-0">
                                        <div className={styles.leftName}>
                                          <div className={styles.PrileImg}>
                                            <span className="text-center w-100">
                                              <i>
                                                <img
                                                  src={
                                                    data?.contractor
                                                      ?.profilePicture
                                                      ? data?.contractor
                                                          ?.profilePicture
                                                      : data?.userInfo
                                                          ?.profilePicture
                                                  }
                                                  alt=""
                                                  height={70}
                                                  width={70}
                                                />
                                              </i>
                                              <h4 className="mt-2 mb-0 fw-semibold px-1 py-2 text-10 text-capitalize bg-lightblue text-blue rounded-pill">
                                                view profile
                                              </h4>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-5 col-6">
                                        <div className={styles.leftName}>
                                          <div className={styles.PrileImg}>
                                            <div className="">
                                              {data?.ownWork ? (
                                                <h5>
                                                  {data?.contractor?.name}
                                                </h5>
                                              ) : (
                                                <h5>{data?.userInfo?.name}</h5>
                                              )}

                                              {data?.ownWork ? (
                                                <h6>
                                                  {
                                                    data?.contractor?.occupation
                                                      ?.name
                                                  }
                                                </h6>
                                              ) : (
                                                <h6>
                                                  {
                                                    data?.userInfo?.occupation
                                                      ?.name
                                                  }
                                                </h6>
                                              )}
                                              {data?.ownWork ? (
                                                <p>
                                                  <img src={loc} alt="" />{" "}
                                                  {data?.address}
                                                </p>
                                              ) : (
                                                <p>
                                                  {" "}
                                                  <img src={loc} alt="" />{" "}
                                                  {data?.userInfo?.address}{" "}
                                                </p>
                                              )}
                                              <h6>
                                                Reviews: 20 | 4.5{" "}
                                                <i>
                                                  <img src={star} alt="" />
                                                </i>
                                              </h6>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-5 col-6">
                                        <div
                                          className={`ps-2 ${styles.rightName}`}
                                        >
                                          <p>Top Skill : {data?.technicalSkills?.map((item, ind) => (
                                            <p key={ind}>{item?.manufacturerDetails?.name}</p>
                                          ))}</p>
                                          <p>Exp : 2.5 Years</p>
                                          <p>Member Since : 2018</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {data.planType === "whiteglove" && (
                                    <div className={styles.timeSheets}>
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div
                                            className={styles.timesheetData}
                                            onClick={() => {
                                              handleViewTimesheet(data);
                                            }}
                                          >
                                            <h4 className="fw-bold">
                                              Timesheet
                                            </h4>
                                            <ul>
                                              <li>
                                                <span>Current Timesheet</span>{" "}
                                                <label>
                                                  {data?.timesheet?.current}
                                                </label>
                                              </li>
                                              <li className={styles.red}>
                                                <span>Due Timesheet</span>{" "}
                                                <label>
                                                  {data?.timesheet?.due}
                                                </label>
                                              </li>
                                              <li className={styles.yeloo}>
                                                <span>Pending</span>{" "}
                                                <label>
                                                  {data?.timesheet?.pending}
                                                </label>
                                              </li>
                                              <li className={styles.gren}>
                                                <span>Approved</span>{" "}
                                                <label>
                                                  {data?.timesheet?.approved}
                                                </label>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div
                                            className={styles.timesheetData}
                                            onClick={() => {
                                              handleViewExpense(data);
                                            }}
                                          >
                                            <h4 className="fw-bold">
                                              Expense Report
                                            </h4>
                                            <ul>
                                              <li>
                                                <span>Current Expense</span>{" "}
                                                <label>
                                                  {data?.expense?.current}
                                                </label>
                                              </li>
                                              <li className={styles.red}>
                                                <span>Due Expense</span>{" "}
                                                <label>
                                                  {data?.expense?.due}
                                                </label>
                                              </li>
                                              <li className={styles.yeloo}>
                                                <span>Pending</span>{" "}
                                                <label>
                                                  {data?.expense?.pending}
                                                </label>
                                              </li>
                                              <li className={styles.gren}>
                                                <span>Approved</span>{" "}
                                                <label>
                                                  {data?.expense?.approved}
                                                </label>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div
                                            className={styles.timesheetData}
                                            onClick={() => {
                                              handleViewInvoice(data);
                                            }}
                                          >
                                            <h4 className="fw-bold">
                                              Invoices
                                            </h4>
                                            <ul>
                                              <li className={styles.red}>
                                                <span>Due </span>{" "}
                                                <label>
                                                  {data?.invoice?.due}
                                                </label>
                                              </li>
                                              <li className={styles.yeloo}>
                                                <span>Past Due</span>{" "}
                                                <label>
                                                  {data?.invoice?.pastDue}
                                                </label>
                                              </li>
                                              <li className={styles.gren}>
                                                <span>Paid</span>{" "}
                                                <label>
                                                  {data?.invoice?.isPaid}
                                                </label>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className={styles.timesheetData}>
                                            <h4 className="fw-bold">
                                              Purchase Orders
                                            </h4>
                                            <ul>
                                              <li>
                                                <span>Current Order</span>{" "}
                                                <label>
                                                  {data?.invoice?.pastDue}
                                                </label>
                                              </li>
                                              <li className={styles.red}>
                                                <span>Due Order</span>{" "}
                                                <label>
                                                  {data?.invoice?.pastDue}
                                                </label>
                                              </li>
                                              <li className={styles.yeloo}>
                                                <span>Pending</span>{" "}
                                                <label>
                                                  {data?.invoice?.pastDue}
                                                </label>
                                              </li>
                                              <li className={styles.gren}>
                                                <span>Approved</span>{" "}
                                                <label>
                                                  {data?.invoice?.pastDue}
                                                </label>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        <div className="col-lg-4 col-6">
                                          <div
                                            className={`d-flex align-items-start bg-lightblue border-0 ${styles.timesheetData}`}
                                          >
                                            <div className="col-2 mt-1">
                                              <i>
                                                <img
                                                  src={checkmark}
                                                  alt=""
                                                  className="img-fluid"
                                                />
                                              </i>
                                            </div>
                                            <div className="col-10">
                                              <h6 className="fw-medium mb-1">
                                                Applications Received
                                              </h6>
                                              <h3 className="mb-0">20</h3>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-lg-4 col-6">
                                          <div
                                            className={`d-flex align-items-start bg-lightblue border-0 ${styles.timesheetData}`}
                                          >
                                            <div className="col-2 mt-1">
                                              <i>
                                                <img
                                                  src={checkmark}
                                                  alt=""
                                                  className="img-fluid"
                                                />
                                              </i>
                                            </div>
                                            <div className="col-10">
                                              <h6 className="fw-medium mb-1">
                                                Shortlisted Applicants
                                              </h6>
                                              <h3 className="mb-0">20</h3>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-lg-4 col-6">
                                          <div
                                            className={`d-flex align-items-start bg-lightblue border-0 ${styles.timesheetData}`}
                                          >
                                            <div className="col-2 mt-1">
                                              <i>
                                                <img
                                                  src={checkmark}
                                                  alt=""
                                                  className="img-fluid"
                                                />
                                              </i>
                                            </div>
                                            <div className="col-10">
                                              <h6 className="fw-medium mb-1">
                                                Offers Pending
                                              </h6>
                                              <h3 className="mb-0">20</h3>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-lg-4 col-6">
                                          <div
                                            className={`d-flex align-items-start bg-lightblue border-0 ${styles.timesheetData}`}
                                          >
                                            <div className="col-2 mt-1">
                                              <i>
                                                <img
                                                  src={offeraccepted}
                                                  alt=""
                                                  className="img-fluid"
                                                />
                                              </i>
                                            </div>
                                            <div className="col-10">
                                              <h6 className="fw-medium mb-1">
                                                Offers Accepted
                                              </h6>
                                              <h3 className="mb-0">20</h3>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-lg-4 col-6">
                                          <div
                                            className={`d-flex align-items-start bg-lightred border-0 ${styles.timesheetData}`}
                                          >
                                            <div className="col-2 mt-1">
                                              <i>
                                                <img
                                                  src={rejectedapp}
                                                  alt=""
                                                  className="img-fluid"
                                                />
                                              </i>
                                            </div>
                                            <div className="col-10">
                                              <h6 className="fw-medium mb-1">
                                                Rejected Applicants
                                              </h6>
                                              <h3 className="mb-0">20</h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {data.planType === "whiteglove" && (
                                    <div className={styles.timeSheets}>
                                      {data?.ownWork ? (
                                        <>
                                          <div className="text-center py-3">
                                            <button
                                              onClick={() =>
                                                navigate(
                                                  `${APP_ROUTES.SSAAGGREMENT}/${data?._id}`
                                                )
                                              }
                                              className="btn btn-primary w-50 mx-auto"
                                            >
                                              View SSA
                                            </button>
                                          </div>
                                        </>
                                      ) : (
                                        <div className="row">
                                          <div className="col-6">
                                            <div className="text-center py-3">
                                              <button
                                                onClick={() =>
                                                  navigate(
                                                    `${APP_ROUTES.MSAAGGREMENT}/${data._id}/${data.userInfo._id}`
                                                  )
                                                }
                                                className="btn btn-primary w-100 mx-auto"
                                              >
                                                View MSA
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              );
                            })
                          : ""}
                      </Slider>
                    </div>
                  </div>
                  {/* ongoing standar here */}
                  <OngoingStandard onGoingContracts={onGoingContracts}/>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default OnGoingContractsProfessional;
