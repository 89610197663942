import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../css/styles/style.module.css";
import info from "../../../../assets/images/info.png";
import grbag from "../../../../assets/images/bag_orange.svg";
import bags from "../../../../assets/images/bags.png";
import skill from "../../../../assets/images/top_skill.svg";
import tip from "../../../../../src/assets/images/info_icon.svg";
import timeling from "../../../../assets/images/timeiing.png";
import loc from "../../../../assets/images/icon_location.svg";
import Slider from "react-slick";
import moment from "moment";
// import { getContractList } from "../api";
import { TYPE, WORK_STATUS_TYPE } from "../../../../utils/enums/status";
import { CONTRACT_Type } from "../../../../utils/enums/types";
import { APP_ROUTES } from "../../../../utils/routes/AppRoutes";
import { ContractResponse } from "../../Contracts/interface";
import { getContractList, getOpenContractList } from "../../Contracts/api";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { WORK_TABS } from "../../../../utils/enums/workTabs.enum";
import DateTime from "../../../Datetimefilter/routes";
import SortFilter from "../../../Sortfilter/routes";
import Toltip from "../../toltip/page";

const ClosedDirectJob = () => {
  const navigate = useNavigate();
  const dashboardData = useSelector((state: RootState) => state.dashboard);
  const { activeTab } = dashboardData;
  const [closedContract, setClosedContract] = useState<ContractResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isExpanded, setIsExpanded] = useState(false);
  console.log(closedContract, "closedContract");
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    const fetchOngoingContracts = async () => {
      setLoading(true);
      try {
        let response: ContractResponse | any;
        if (activeTab === WORK_TABS.DONEFORME) {
          response = await getOpenContractList(
            CONTRACT_Type.HOURLY_CONTRACT,
            TYPE.DONEFORME,
            WORK_STATUS_TYPE.CLOSED
          );
        } else if (activeTab === WORK_TABS.DONEBYME) {
          response = await getOpenContractList(
            CONTRACT_Type.HOURLY_CONTRACT,
            TYPE.DONEBYME,
            WORK_STATUS_TYPE.CLOSED
          );
        } else {
          response = { data: [] };
        }
        setClosedContract(response.data);
      } catch (error) {
        console.error("Error fetching ongoing contracts:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchOngoingContracts();
  }, [activeTab]);

  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 2.4,
    centerPadding: "60px",
    slidesToScroll: 2,
    infinite: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.02,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className={`bg-white p-3 shadow rounded-3 mt-3`}>
      {closedContract?.length > 0 && (
        <div className="custom-ongoing">
          <div className={styles.OuterSlides}>
            <div className={`mb-3 ${styles.AllContracta}`}>
              <div className="row align-items-center justify-content-between mx-0 g-0">
                <div className="col-12">
                  <div className="topTipHover">
                    <div className="d-flex align-items-center">
                      <h4 className="form-check-label text-20 fw-bold  font-inter mb-0">
                        Completed / Closed Direct In-House Jobs
                      </h4>
                      <i>
                        <img className="customeTolImg" src={tip} alt="" />
                      </i>
                      <Toltip />
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-7 col-12">
                <div className="filter_sort">
                  <div className="ms-auto">
                    <div className="row align-items-center justify-content-end">
                      <div className="col-md-3">
                        <DateTime />
                      </div>
                      <div className={`col-md-4 ${styles.RightFilyter}`}>
                        <button
                          onClick={() => navigate("/add-contract")}
                          className="btn btn-primary"
                        >
                          +Post New Contract
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              </div>
            </div>

            {loading ? (
              <div className="spinner-grow text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <Slider {...settings}>
                {closedContract?.map((item: any, ind: any) => (
                  <div key={ind} className={styles.ContarctOuter}>
                    <div className={styles.ContarctHead}>
                      <div className={`w-100 ${styles.leftContract}`}>
                        <div className="row align-item-center justify-content-between mx-0 w-100 position-relative">
                          <div className="col-xl-5 col-lg-12 col-md-5 mb-2">
                            <div className={styles.leftContract}>
                              <h5>{item?.occupation?.name}</h5>
                              <span>
                                End Date :{" "}
                                {moment(item?.times?.endDate).format(
                                  "MM-DD-YYYY"
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="col-xl-7 col-lg-12 col-md-7 col-12 mb-2">
                            <div
                              className={styles.leftContract}
                              style={{ textAlign: "end" }}
                            >
                              <button
                                className={`position-absolute top-0 ${styles.statusdetails}`}
                              >
                                Completed
                              </button>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-12">
                            <div className={`w-100 ${styles.leftContract}`}>
                              <div className={styles.Local}>
                                <div
                                  className={`justify-content-start ${styles.adrssLoc}`}
                                >
                                  <span
                                    className={`py-2 rounded-2 me-2 mb-0 border-lightorange text-lightorange bg-lightorange`}
                                  >
                                    <img src={grbag} alt="" />
                                    Standrad job
                                  </span>

                                  <span
                                    className={`py-3 mb-0 rounded-2 bg-white text-blue border-blue`}
                                  >
                                    Remote Job
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-12 mt-lg-0 mt-2">
                            <div style={{ textAlign: "end" }}>
                              <button
                                className={`border-blue bg-blue text-white`}
                              >
                                <i className="far fa-copy"></i> Copy
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={styles.draftCity}>
                      <div className={styles.gratCtyLoc}>
                        <div>
                          <p>
                            <img src={loc} alt="" /> {item?.address}
                          </p>
                          <p>
                            <img src={timeling} alt="" /> Start Date :{" "}
                            {moment(item?.times?.startDate).format(
                              "MM-DD-YYYY"
                            )}{" "}
                          </p>
                        </div>
                        {/* <span>
          <img src={bags} alt="White Glove Contract" />
          City, State
      </span> */}
                      </div>
                      <div className="fw-semibold clamp clamp2 mb-4 mt-2">
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              isExpanded && item.description
                                ? item?.description
                                : item?.description?.slice(0, 90),
                          }}
                        />
                        {/* <span
                                          onClick={toggleReadMore}
                                          className={`fw-medium ${styles.readMore}`}
                                        >
                                          {isExpanded
                                            ? "Show Less"
                                            : "Read More"}
                                        </span> */}
                      </div>
                      {item?.technicalSkills?.length && (
                        <div className={styles.PenigSkils}>
                          <ul className="row align-items-start justify-content-start gap-0">
                            <li className="col-4 pe-0">
                              <div className="row align-items-start mb-3">
                                <div className="col-3 text-center ps-0">
                                  <i>
                                    <img
                                      src={skill}
                                      alt=""
                                      className="img-fluid w-100"
                                      width={15}
                                      height={15}
                                    />
                                  </i>
                                </div>
                                <div className="col-9 ps-0">
                                  <h5 className="mb-0 fw-bold text-14 text-uppercase">
                                    Top Skill
                                  </h5>
                                  <p className="text-14 mb-0">Autocad</p>
                                </div>
                              </div>
                            </li>
                            <li className="col-4 pe-0">
                              <div className="row align-items-start mb-3">
                                <div className="col-3 text-center px-0">
                                  <i>
                                    <img
                                      src={skill}
                                      alt=""
                                      className="img-fluid w-75"
                                      width={15}
                                      height={15}
                                    />
                                  </i>
                                </div>
                                <div className="col-9 ps-0">
                                  <h5 className="mb-0 fw-bold text-14 text-uppercase">
                                    Second skill
                                  </h5>
                                  <p className="text-14 mb-0">Programming</p>
                                </div>
                              </div>
                            </li>
                          </ul>
                          {/* <ul>
                                            {data.technicalSkills?.map(
                                              (skill: any) => {
                                                return (
                                                  <>
                                                    <li>
                                                      <h5>Top Skills</h5>
                                                      <p>
                                                        {
                                                          skill
                                                            .manufacturerDetails
                                                            ?.name
                                                        }
                                                      </p>
                                                    </li>
                                                    <li>
                                                      <h5>Second Skills</h5>
                                                      <p>
                                                        {
                                                          skill
                                                            .manufacturerModelDetails
                                                            ?.name
                                                        }
                                                      </p>
                                                    </li>
                                                  </>
                                                );
                                              }
                                            )}
                                          </ul> */}
                        </div>
                      )}

                      <div className={`pt-0 mb-4 ${styles.PenigSkilRate}`}>
                        <h5 className="mb-3">Pay Rates</h5>
                        <ul>
                          <li>
                            <span>Base</span>
                            <label>${item?.rates?.baseRate}</label>
                          </li>
                          <li>
                            <span>OverTime</span>
                            <label>${item?.rates?.overTimeRate}</label>
                          </li>
                          <li>
                            <span>Double Time</span>
                            <label>${item?.rates?.doubleTimeRate}</label>
                          </li>
                        </ul>
                      </div>

                      <div className={styles.invoiceBox}>
                        <div className={styles.invoiceBoxInner}>
                          <h4>Invoices</h4>
                          <h5>20</h5>
                        </div>
                        <div className={styles.invoiceBoxInner}>
                          <h4>Purchase Orders</h4>
                          <h5>20</h5>
                        </div>
                        <div className={styles.invoiceBoxInner}>
                          <h4>Time Sheets</h4>
                          <h5>20</h5>
                        </div>
                        <div className={styles.invoiceBoxInner}>
                          <h4>Expense Reports</h4>
                          <h5>20</h5>
                        </div>
                        <div className={styles.invoiceBoxInner}>
                          <h4>Contrctors Hired</h4>
                          <h5>20</h5>
                        </div>
                      </div>

                      
                    </div>
                    <div className={`bg-light p-4 ${styles.draftCity}`}>
                    <div className='bg-light text-center'>
                        <h4 className="text-24 text-blue fw-semibold">You have hired 6 People for<br/> this Job</h4>
                        <div
                            className={`row align-items-center justify-content-between mt-3 ${styles.chatAdmin}`}
                          >
                            <div className="col-8 mx-auto">
                              <a
                                href=""
                                className="rounded-3 bg-blue text-white w-100 text-center fw-semibold text-capitalize"
                              >
                                View all professionals
                              </a>
                            </div>
                          </div>
                      </div>
                      </div>
                  </div>
                ))}
              </Slider>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ClosedDirectJob;
