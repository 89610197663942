
import * as Yup from "yup";

const fullName = Yup.object().shape({
  firstName: Yup.string()
    .required("Please enter your first name")
    .matches(/^[A-Za-z\s]+$/, "First name contains only alphabetic characters")
    .max(25)
    .test(
      "no-whitespace",
      "First name should not start or end with whitespace",
      (value) => {
        if (value) {
          return value === value.trim();
        }
        return true;
      }
    ),

  lastName: Yup.string()
    .required("Please enter your last name")
    .matches(/^[A-Za-z\s]+$/, "Last name contains only alphabetic characters")
    .max(25)
    .test(
      "no-whitespace",
      "Last name should not start or end with whitespace",
      (value) => {
        if (value) {
          return value === value.trim();
        }
        return true;
      }
    ),
});

const Contactschema = Yup.object().shape({
  phoneNumber: Yup.string()
    .required("Phone number is required!")
    .test(
      "len",
      "Phone number must be exactly 10 digits",
      (val) => (val ? val.length === 10 : false)
    )
});



const verifyOTPSchema = Yup.object().shape({
  verificationCode: Yup.string().required("Required"),
});

const EmailSchema = Yup.object().shape({
  email: Yup.string()
    .required("Please enter your email address")
    .max(50, "Email must be 50 characters or less")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      "Please enter a valid email address with a top-level domain"
    ),
});


const PasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Please confirm your password"),
});

const userName = Yup.object().shape({
  userName: Yup.string().required("Please enter email or phone number"),
});

const passwordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
      "Password must contain at least 1 number, 1 uppercase letter, and 1 special character"
    )
    .required("Password is required"),
});
const occupationDropdownSchema = Yup.object({
  selectedOccupation: Yup.array()
    .min(1, 'Please select at least one occupation')
    .required('Occupation is required'),
  selectedOccupationName: Yup.string()
    .required('Primary Occupation is required')
});

const dropdownValidationSchema = Yup.object({
  selectedCitizenship: Yup.array().min(1, 'Please select at least one option').required('Please select at least one option'),
  selectedCitizenshipName: Yup.string().required('Citizenship name is required')
});
export {
  fullName,
  Contactschema,
  verifyOTPSchema,
  userName,
  EmailSchema,
  PasswordSchema,
  occupationDropdownSchema,
  dropdownValidationSchema,
  passwordSchema,
};
