import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ProgressBar,
} from "react-bootstrap";
import crossicon from "../../../../../assets/images/cross.svg";
import productoffered from "../../../../../assets/images/product_offered.jpg";
import { uploadFile } from "../../../../../common/api";
import { useDispatch } from "react-redux";
import { setLiabilityInsurance } from "../../../../../redux/reducers/dashboard";

interface ApplyDocumentsModal {
  onClose: () => void;
  onNext: () => void;
  showModal: boolean;
}

const ApplyDocumentsModal: React.FC<ApplyDocumentsModal> = ({
  onClose,
  onNext,
  showModal,
}) => {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0); // Track progress
  const [isUploadComplete, setIsUploadComplete] = useState<boolean>(false); // Track completion
  const [file, setFile] = useState<any>("");
  const [fileName, setFileName] = useState<string>('');
  const [errors, setErrors] = useState<{ name?: string; file?: string }>({});

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    setSelectedFile(file);
    if (file) {
      setIsLoading(true);
      simulateUploadProgress();
      handleFileUpload(file as any);
    }
  };

  console.log("file", file);

  const simulateUploadProgress = () => {
    const interval = setInterval(() => {
      setUploadProgress((prevProgress) => {
        if (prevProgress < 100) {
          return prevProgress + 10;
        } else {
          clearInterval(interval);
          setIsUploadComplete(true);
          setIsLoading(false);
          return 100;
        }
      });
    }, 500);
  };

  const handleSubmit = () => {
    if (!selectedFile) {
      alert("Please upload a document before submitting!");
      return;
    }
    onNext();
  };

  const handleFileUpload = async (files: any) => {
    if (files) {
      const formData = new FormData();
      formData.append("file", files);
      try {
        const response = await uploadFile(formData);
        const newFileUrl = response.data.files;
        setFile(newFileUrl);
        dispatch(setLiabilityInsurance({ fileName: files.name, fileUrl: newFileUrl }));
        setErrors((prevErrors) => ({ ...prevErrors, file: "" })); // Clear file error
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false); // End loading when upload is done or fails
      }
    }
  };

  const fileUploadStyles: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: "2px dashed #eee",
    padding: "14px 3px",
    borderRadius: "5px",
    width: "100%",
    marginBottom: "20px",
  };

  const fileUploadTextStyles: React.CSSProperties = {
    cursor: "pointer",
    fontSize: "16px",
    color: "#171717",
    fontWeight: 400,
    textAlign: "start" as "start",
  };

  return (
    <Modal onHide={onClose} show={showModal} centered className="modal-md">
      {/* <ModalHeader closeButton></ModalHeader> */}
      <ModalBody>
        <div className="modal_catch d-flex flex-column justify-content-center text-center rounded-0 pt-4 position-relative">
          <div
            className="position-absolute"
            style={{ top: "10px", right: "10px" }}
          >
            <i>
              <img
                src={crossicon}
                onClick={onClose}
                alt=""
                width="25px"
                height="25px"
              />
            </i>
          </div>
          <div className="col-lg-10 col-12 mx-auto mt-4">
            <div className="text-center">
              <div className="">
                <p>Document should be .pdf type!</p>
              </div>
              <figure>
                <div style={fileUploadStyles}>
                  <input
                    type="file"
                    className="file-upload-input"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    accept=".pdf"
                  />
                  <div className="col-12 row align-items-center">
                    <div className="rounded-2 col-2">
                      <i>
                        <img src={productoffered} alt="" />
                      </i>
                    </div>
                    <div className="col-8 text-start">
                      <div
                        style={fileUploadTextStyles}
                        onClick={() =>
                          (
                            document.querySelector(
                              'input[type="file"]'
                            ) as HTMLInputElement
                          )?.click()
                        }
                      >
                        {selectedFile
                          ? selectedFile.name
                          : "Click to Upload Document"}
                      </div>
                      {isLoading && !file && (
                        <div style={{ width: "100%", marginTop: "10px" }}>
                          <ProgressBar
                            now={uploadProgress}
                            label={`${uploadProgress}%`}
                          />
                        </div>
                      )}
                      {file && (
                        <div style={{ marginTop: "2px", color: "green" }}>
                          <p className="mb-0 fw-semibold">Upload Complete!</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </figure>
            </div>
            <div className="text-start">
              <h1 className="text-16 fw-semibold">Expiration Date</h1>
              <div>
                <div className="formGroup">
                  <div>
                    <input
                      type="date"
                      className="form-control w-100"
                      placeholder="02/25/2024"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>

      <ModalFooter className="btn-hover-effect justify-content-center border-top-0 mb-3">
        <div className="col-md-5 col-12 mx-auto">
          <Button
            onClick={handleSubmit}
            type="button"
            variant="secondary"
            className="btn border border-1 rounded-2 position-relative py-2 backbutton w-100 mb-0"
            style={{ backgroundColor: "#1EC20F" }}
          >
            <span className="px-lg-4 px-2 text-12 fw-medium text-white">
              Submit
            </span>
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ApplyDocumentsModal;
