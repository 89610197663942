import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import blackarrow from "../../../assets/images/blackarrow.svg";
import whitearrow from "../../../assets/images/whitearrow.svg";
import { updateUserProfile } from "../../home/api";
import { CompanyAdmin } from "../interface";
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

interface EditCompanyAdminContModalProps {
  showModal: boolean;
  closeModal: () => void;
  title: string;
  refresh: () => void;
  editData: CompanyAdmin | null;
  index: number | null;  
  companyAdminData:any 
}

const EditCompanyAdminContModal: React.FC<EditCompanyAdminContModalProps> = ({
  showModal,
  closeModal,
  title,
  refresh,
  editData,
index,
companyAdminData

}) => {
  const [formValues, setFormValues] = useState({
    name: "",
    city: "",
    phoneNumber: "",
    email: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({
    name: "",
    city: "",
    phoneNumber: "",
    email: "",
  });
  useEffect(() => {
    if (editData) {
      setFormValues({
        name: editData.name || "",
        city: editData.city || "",
        phoneNumber: editData.phoneNumber || "",
        email: editData.email || "",
      });
    } else {
      // Reset form fields to empty when adding new entry
      setFormValues({
        name: "",
        city: "",
        phoneNumber: "",
        email: "",
      });
    }
  }, [editData]); 

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const validate = () => {
    let formErrors = { name: "", city: "", phoneNumber: "", email: "" };
    let isValid = true;

    if (!formValues.name) {
      formErrors.name = "Name is required";
      isValid = false;
    }

    if (!formValues.city) {
      formErrors.city = "City is required";
      isValid = false;
    }

    if (!formValues.phoneNumber || !/^[0-9]+$/.test(formValues.phoneNumber) || formValues.phoneNumber.length < 10) {
      formErrors.phoneNumber = "Phone number must be at least 10 digits and only contain numbers";
      isValid = false;
    }

    if (!formValues.email || !/\S+@\S+\.\S+/.test(formValues.email)) {
      formErrors.email = "Valid email is required";
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const handleUpdateInfo = async () => {
    if (!validate()) return;
    setIsSubmitting(true);
    const newCompany={
      name:formValues.name,
      city:formValues.city,
      phoneNumber: formValues.phoneNumber,
      email: formValues.email
    }
    try{
      let updatedCompany: any = {};
      let successMessage = "";
      if (index !== null && index !== undefined && index >= 0){
        let companyData=companyAdminData?.map((data:any,ind:any)=>{
          if (index===ind){
            return{
              name:newCompany.name,
              city:newCompany.city,
              phoneNumber:newCompany.phoneNumber,
              email:newCompany.email
            }
          }else{
            return{
              name:data.name,
              city:data.city,
              phoneNumber:data.phoneNumber,
              email:data.email
            }
          }
        });
        updatedCompany={
          companyAdministrationContacts: [...companyData]
        }
        successMessage = "Updated successfully!";
      } else{
        let existingData: any = [];
        if (companyAdminData?.length) {
          existingData = companyAdminData.map((data:any, ind:any) => {
            return {
              name:data.name,
              city:data.city,
              phoneNumber:data.phoneNumber,
              email:data.email
            };
          });
        }
        updatedCompany = {
          companyAdministrationContacts: [...existingData, newCompany],
        };
        successMessage = "Added successfully!";
      }
      await updateUserProfile(updatedCompany);
      refresh();
      setIsSubmitting(false);
      toast.success(successMessage);
    }catch(err){
      console.log("Error while updating user information", err);
      toast.error("Error while updating education details.");
    }

  };

  return (
    <Modal show={showModal} onHide={closeModal} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <form onSubmit={(e) => e.preventDefault()}>
        <ModalBody>
          <div className="addModalPro">
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>Name</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    value={formValues.name}
                    onChange={handleChange}
                    placeholder="Name of Facility"
                  />
                  {errors.name && <div className="text-danger">{errors.name}</div>}
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>City</label>
                  <input
                    type="text"
                    name="city"
                    className="form-control"
                    value={formValues.city}
                    onChange={handleChange}
                    placeholder="City"
                  />
                  {errors.city && <div className="text-danger">{errors.city}</div>}
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>Phone Number*</label>
                  <input
                    type="tel"
                    name="phoneNumber"
                    className="form-control"
                    value={formValues.phoneNumber}
                    onChange={handleChange}
                    placeholder="Phone Number*"
                  />
                  {errors.phoneNumber && <div className="text-danger">{errors.phoneNumber}</div>}
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="formGroup">
                  <label>Email*</label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    value={formValues.email}
                    onChange={handleChange}
                    placeholder="Email address*"
                  />
                  {errors.email && <div className="text-danger">{errors.email}</div>}
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="btn-hover-effect">
          <Button
            type="button"
            variant="secondary"
            onClick={closeModal}
            className="btn bg-white arrowyellow border border-1 rounded-3 position-relative py-lg-3 py-2 ps-5 backbutton w-auto"
          >
            <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
              Back
            </span>
            <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
              <img
                src={blackarrow}
                alt=""
                width={15}
                height={15}
                style={{ transform: "rotate(-180deg)" }}
              />
            </i>
          </Button>
          <Button
            type="button"
            variant="secondary"
            onClick={handleUpdateInfo}
            className="btn bg-white arrowblue border border-1 rounded-3 position-relative py-lg-3 py-2 pe-5 w-auto"
          >
            <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
            {isSubmitting ? (
              <Spinner animation="border" size="sm" className="me-2" />
            ) : (
              "Save"
            )}
          </span>
            <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
              <img src={whitearrow} alt="" width={15} height={15} />
            </i>
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default EditCompanyAdminContModal;
