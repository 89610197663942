import React, { useEffect, useState } from "react";
import styles from "../../expense/styles/style.module.css"
import infogr from "../../../../assets/images/infogr.png"
import crtick from "../../../../assets/images/crtick.png"
import pnedit from "../../../../assets/images/pnedit.png"
import dlt from "../../../../assets/images/dlt.png"
import print from "../../../../assets/images/print.png"
import dnlod from "../../../../assets/images/dnlod.png"
import Header from "../../../header/routes";
import { useLocation, useParams } from "react-router-dom";
import { getTimesheetById } from "../api";
import { GetTimesheetByIdResponse } from "../interface/getTimesheetById";
import {
  getMondayOfCurrentWeek,
  getWeekDaysMap,
} from "../../../../utils/daysMap";
import moment from "moment";
import WhiteGloveContractDetails from "../../Contracts/whiteGloveHourlyContract/whileGloveHourlyContract";
import Toltip from "../../toltip/page";
import { APP_ROUTES } from "../../../../utils/routes/AppRoutes";
import ExpenseDetails from "../../expense/expenseDetails";


const TimesheetDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const ongoingContractsDetails = location?.state?.currentOngoingData
  const [currentOngoingData, setCurrentOngoingData] = useState<any>([])
  const [timeSheetStatusData, setTimesheetStatusData] = useState<GetTimesheetByIdResponse>();

  const approvedTimesheets = async () => {
    const response: any = await getTimesheetById(id);
    setTimesheetStatusData(response?.data);
  };

  useEffect(() => {
    approvedTimesheets();
    setCurrentOngoingData(ongoingContractsDetails)
  }, []);



  const mondayDate = getMondayOfCurrentWeek();
  const daysMap = getWeekDaysMap();
  const isInvoiceDetailRoute = new RegExp(`^${APP_ROUTES.INVOICE_MAIN}/[a-fA-F0-9]{24}$`).test(location.pathname);

  return (
    <>
       {!isInvoiceDetailRoute && <Header />}
      <div className={styles.pageOuter}>
        <div className="container">
          <div className="">
            <h4 className="fw-bold text-30 mb-4">
              Timesheet Details
            </h4>
            <div className={`rounded-4 ${styles.baseInfo} ${styles.noMarg}`}>
{     !isInvoiceDetailRoute &&         <WhiteGloveContractDetails expenseDetails={timeSheetStatusData} ongoingContractDetails={currentOngoingData} />
}              <div className={`rounded-3 col-12 ${styles.timeSheetdetils} ${styles.noMarg}`}>
                <div className="row align-items-center mb-3">
                  <div className="col-4 topTipHover">
                    <div className="w-100 d-flex align-items-center">
                      <h4 className="form-check-label text-20 fw-bold mb-0 font-inter">
                        Timesheet Details
                      </h4>
                      <i>
                          <img className="customeTolImg" src={infogr} alt="" />
                      </i>
                      <Toltip
                      />
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="d-flex align-items-center justify-content-end g-2">
                        <div className="px-1"><button className='border text-14 d-flex align-items-center py-2 px-3 rounded-pill font-inter fw-semibold'>View Signed Timesheet </button></div>
                        <div className="px-1"><button className='border text-14 d-flex align-items-center py-2 px-3 rounded-pill font-inter fw-semibold'><i className="me-2"><img src={dlt} alt="" /></i> Delete</button></div>
                        <div className="px-1"><button className='border text-14 d-flex align-items-center py-2 px-3 rounded-pill font-inter fw-semibold'><i className="me-2"><img src={crtick} alt="" /></i> Notify</button></div>
                        <div className="px-1"> <button className='border text-14 d-flex align-items-center py-2 px-3 rounded-pill font-inter fw-semibold'><i className="me-2"><img src={pnedit} alt="" /></i> Edit</button></div>
                        <div className="px-1"> <button className='border text-14 d-flex align-items-center py-2 px-3 rounded-pill font-inter fw-semibold'><i className="me-2"><img src={print} alt="" /></i> Print</button></div>
                        <div className="px-1"> <button className='border text-14 d-flex align-items-center py-2 px-3 rounded-pill font-inter fw-semibold'><i className="me-2"><img src={dnlod} alt="" /></i> Export Data</button></div>
                    </div>
                  </div>
                </div>
                {/* <div className={styles.timeSheetdetilshead}>
                  <h4>
                    Timesheet Details<img src={infogr} alt="" />
                  </h4>
                </div> */}

                <div className={`table-responsive ${styles.tabletimesheet}`}>

                  <table className="table align-middle">
                      <tr>
                          <td><span className="fw-semibold">Engineerss:</span></td>
                          <td><span className="fw-normal">{timeSheetStatusData?.user?.name}</span></td>
                          <td colSpan={2} className=" text-center"><span className="fw-semibold">Monday / Week Start Date</span></td>
                          
                      </tr>
                      <tr>
                          <td><span className="fw-semibold">Customer Company Name :</span></td>
                          <td><span className="fw-normal">FGSFSF hfhfbfbh infor</span></td>
                          <td rowSpan={2} colSpan={2} className=" text-center"><span className="fw-normal">{mondayDate}</span></td>
                      </tr>
                      <tr>
                          <td><span className="fw-semibold">Business Number :</span></td>
                          <td><span className="fw-normal">47110</span></td>
                      </tr>
                      <tr>
                          <td><span className="fw-semibold">Business Contact :</span></td>
                          <td><span className="fw-normal">SHane yaniuo</span></td>
                          <td><span className="fw-semibold">Business Email : </span></td>
                          <td><span className="fw-normal">Shaneyaniuo@gmail.com</span></td>
                      </tr>
                      <tr>
                          <td><span className="fw-semibold">Customer Contact :</span></td>
                          <td colSpan={4}><span className="fw-normal"> {timeSheetStatusData?.work?.user?.name}</span></td>
                        
                      </tr>
                      
                      <tr>
                        <td><span className="fw-semibold">End Customer :</span></td>
                        <td colSpan={4}><span className="fw-normal">Jerry Vemia</span></td>
                      </tr>
                    
                  </table>

                </div>

                <div className={`table-responsive table-striped ${styles.tabletimesheet}`}>

                  <table className="table align-middle mb-0">
                    <thead>
                      <th>
                        <span className="fw-normal">Day</span>
                      </th>
                      <th>
                        <span className="fw-normal">Date</span>
                      </th>
                      <th>
                        <span className="fw-normal">Hours Worked Per Day</span>
                      </th>
                      <th>
                      <span className="fw-normal">Holiday</span>
                      </th>
                      <th>
                      <span className="fw-normal">Travel Time Hours</span>
                      </th>
                      <th>
                      <span className="fw-normal"> Total</span>
                      </th>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-center"><span className="fw-semibold">Monday Work Report</span></td>
                        <td><span className="fw-semibold">11-25-2018</span></td>
                        <td>1</td>
                        <td> 
                          <div className="checkboxes__item">
                            <label className="checkbox style-c">
                              <input type="checkbox"/>
                              <div className="checkbox__checkmark"></div>
                            </label>
                          </div>
                        </td>
                        <td></td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td colSpan={12}>1</td>
                      </tr>
                      <tr>
                        <td className="text-center"><span className="fw-semibold">Monday Work Report</span></td>
                        <td><span className="fw-semibold">10-25-2024</span></td>
                        <td>1</td>
                        <td>  
                          <div className="checkboxes__item">
                            <label className="checkbox style-c">
                              <input type="checkbox"/>
                              <div className="checkbox__checkmark"></div>
                            </label>
                          </div>
                        </td>
                        <td></td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td colSpan={12}>1</td>
                      </tr>
                      <tr>
                        <td className="text-center"><span className="fw-semibold">Monday Work Report</span></td>
                        <td><span className="fw-semibold">10-25-2024</span></td>
                        <td>1</td>
                        <td> 
                          <div className="checkboxes__item">
                            <label className="checkbox style-c">
                              <input type="checkbox"/>
                              <div className="checkbox__checkmark"></div>
                            </label>
                          </div>
                        </td>
                        <td></td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td colSpan={12}>1</td>
                      </tr>
                      <tr>
                        <td className="text-center"><span className="fw-semibold">Monday Work Report</span></td>
                        <td><span className="fw-semibold">10-25-2024</span></td>
                        <td>1</td>
                        <td>
                          <div className="checkboxes__item">
                            <label className="checkbox style-c">
                              <input type="checkbox"/>
                              <div className="checkbox__checkmark"></div>
                            </label>
                          </div>
                        </td>
                        <td></td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td colSpan={12}>1</td>
                      </tr>
                    </tbody>
                      
                    
                  </table>

                </div>
                <div className="row">
                  <div className="col-6">
                    <div className={`table-responsive ${styles.tabletimesheet}`}>
                      <table className="table align-middle">
                          <tr>
                              <td><span className="fw-medium">Total Straight Time</span></td>
                              <td className="text-end"><span className="fw-normal">{timeSheetStatusData?.totalStraightTime}</span></td>                          
                          </tr>
                          <tr>
                              <td><span className="fw-medium">Total Over Time</span></td>
                              <td className="text-end"><span className="fw-normal">{timeSheetStatusData?.overTime}</span></td>
                          </tr>
                          <tr>
                              <td><span className="fw-medium">Sunday/Holiday</span></td>
                              <td className="text-end"><span className="fw-normal">-----</span></td>
                          </tr>
                          <tr>
                              <td><span className="fw-medium">Total Travel TIme</span></td>
                              <td className="text-end"><span className="fw-normal">{timeSheetStatusData?.totalTravelTime}</span></td>
                          </tr>
                          <tr>
                              <td><span className="fw-medium">Total Hours</span></td>
                              <td className="text-end"><span className="fw-normal">{timeSheetStatusData?.totalHours}</span></td>                        
                          </tr>
                      </table>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className={`table-responsive ${styles.tabletimesheet}`}>
                      <table className="table align-middle">
                          <tr>
                              <td><span className="fw-semibold">Bill Rates</span></td>
                              <td className="text-end"><span className="fw-normal">{timeSheetStatusData?.ssaRates?.baseRate}</span></td>                          
                          </tr>
                          <tr>
                              <td><span className="fw-medium">Base Rate (per hour)</span></td>
                              <td className="text-end"><span className="fw-normal"> {timeSheetStatusData?.ssaRates?.baseRate}</span></td>
                          </tr>
                          <tr>
                              <td><span className="fw-medium">OT Rate (per hour)</span></td>
                              <td className="text-end"><span className="fw-normal">{timeSheetStatusData?.ssaRates?.overTimeRate}</span></td>
                          </tr>
                          <tr>
                              <td><span className="fw-medium">Sunday/Holiday</span></td>
                              <td className="text-end"><span className="fw-normal">-----</span></td>
                          </tr>
                          <tr>
                              <td><span className="fw-medium">Travel Time Rate</span></td>
                              <td className="text-end"><span className="fw-normal"> {timeSheetStatusData?.totalTravelTime}</span></td>                        
                          </tr>
                          <tr>
                              <td><span className="fw-medium">Travel Trips Allowed (per month)</span></td>
                              <td className="text-end"><span className="fw-normal">{timeSheetStatusData?.ssaRates?.travelTimeAllowed}</span>
                              </td>                        
                          </tr>
                          <tr>
                              <td><span className="fw-medium">Travel Trips Allowed (per month)</span></td>
                              <td className="text-end"><span className="fw-normal">-----</span></td>                        
                          </tr>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="">
                  <h4 className="text-20 fw-semibold text-capitalized">Comment</h4>
                  <div className="">
                    <textarea className="form-control" style={{height: '150px'}}>

                    </textarea>
                  </div>
                </div>
                <div className="mt-3 row g-2">
                  <div className="col-3">
                    <button className="bg-blue text-white p-2 rounded-2 text-14 px-4 w-100">
                      Upload Signed Timesheet
                    </button>
                  </div>
                  <div className="col-3">
                    <button className="bg-blue text-white p-2 rounded-2 text-14 px-4 w-100">
                      Print
                    </button>
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TimesheetDetails;
