import React from "react";
import styles from "../styles/style.module.css";
import arrowwithoutcircle_blue from "../../../assets/images/arrow_withoutcircle_blue.svg";

const AutomateToHomeScreen = () => {
  return (
    <div className={styles.automateamerica}>
      <div className="container">
        <div className={styles.automateamericablock}>
          <div className="row align-items-center g-4 justify-content-between mt-3 mx-0 flex-row-reverse py-lg-5 py-3">
            <div className="col-lg-8 col-12 mx-auto">
              <div className="newheadingstyle text-center">
                <h2 className="text-53">
                  Add <strong>Automate America</strong> to your{" "}
                  <strong>home</strong> screen
                </h2>
                <p className="text-18 text-center text-white">
                  Enhance your experience by adding Automate America to your
                  home screen! Access our platform with just a tap for quick and
                  convenient usage.
                </p>
                <button className="btn btn-white text-blue text-20 mt-3 d-flex align-items-center justify-content-center">
                  Add to home screen{" "}
                  <i className="ps-2">
                    <img
                      src={arrowwithoutcircle_blue}
                      alt=""
                      width={15}
                      height={15}
                    />
                  </i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutomateToHomeScreen;
