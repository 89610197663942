import React, { useState } from "react";
import styles from "../styles/style.module.css";
import penbl from "../../../assets/images/penbl.png";
import tip from "../../../assets/images/tip.png";
import downloadbalck from "../../../assets/images/download_black.svg";
import printblack from "../../../assets/images/print_black.svg";
import check from "../../../assets/images/check_green.svg";
import EditResumeModal from "../modals/EditResumeModal";
import { ResumeProps } from "../interface";

  const Resume: React.FC<ResumeProps> = ({refresh, docFile}) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const openModal = () => {
      setShowModal(true);
    }
    const closeModal = () => { setShowModal(false)};
  return (
    <div className={styles.AboutEdit}>
      <div className="row align-items-center justify-content-center">
        <div className="col-6">
          <div className="topTipHover">
            <div className="d-flex align-items-center">
              <h4 className="text-20 fw-bold  font-inter mb-0">Resume</h4>
              <i>
                <img className="customeTolImg" src={tip} alt="" />
              </i>
            </div>
          </div>
        </div>
        <div className="col-6">
          <ul
            className="d-flex align-items-center justify-content-end mb-0"
            style={{ gap: "15px" }}
          >
            <li className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2">
              <i className="me-1">
                <img src={printblack} alt="" width={12} height={12} />
              </i>
              <span className="text-10">Print</span>
            </li>
            <li className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2">
              <i className="me-1">
                <img src={downloadbalck} alt="" width={12} height={12} />
              </i>
              <span className="text-10">Download</span>
            </li>
            <li>
              <div className="text-end">
                <i>
                  <img src={penbl} className="cursor-pointer" alt="" onClick={openModal}/>
                </i>
              </div>
            </li>
          </ul>
        </div>
        <div className="text-start mt-2">
          <h4 className="justify-content-start text-black fw-semibold text-14">
            <i className="me-2">
              <img src={check} alt="" width={15} />
            </i>
            {docFile?.name}.pdf
          </h4>
        </div>
      </div>
      <EditResumeModal 
      showModal={showModal} 
      closeModal={closeModal} 
      title="Edit Resume" 
      refresh={refresh}
      editData={docFile}/>
    </div>
  );
};

export default Resume;
