// employeeSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface EmployeeDetails {
  firstName: string;
  lastName: string;
  selectedOccupation: string;
}

const initialState: EmployeeDetails = {
  firstName: "",
  lastName: "",
  selectedOccupation: "",
};

const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    setEmployeeDetails(state, action: PayloadAction<EmployeeDetails>) {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.selectedOccupation = action.payload.selectedOccupation;
    },
  },
});

export const { setEmployeeDetails } = employeeSlice.actions;

export default employeeSlice.reducer;
