import React, { FC, useState } from "react";
import businessgroup from "../../assets/images/businessgroup.png";
import whitearrow from "../../assets/images/whitearrow.svg";
import styles from '../../../src/components/contractBeforeLogin/styles/style.module.css';
import blackarrow from "../../assets/images/blackarrow.svg";
import tip from '../../assets/images/tip.png';
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import HeaderContent from "../../shared/Components/HeaderContent/headerContent";
import { useNavigate } from "react-router-dom";
import { addBusinessGroup } from "./api";
import { RootState } from "../../redux/store";
import { setGroupName } from "../../redux/reducers/businessDetails";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateNewBusiness: FC = () => {
  const businessNameRedux = useSelector((state: RootState) => state.business.businessName);
  const businessIDFromRedux = useSelector((state: RootState) => state.business._id);
  console.log(businessIDFromRedux,"businessID fromm the redux")
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [businessID,setBusinessId]=useState("")
  const [id,setId]=useState('')

  const formik = useFormik({
    initialValues: {
      businessID: businessNameRedux || "", 
      groupName: "",
    },
    enableReinitialize: true, 
    validationSchema: Yup.object({
      businessID: Yup.string().required("Business Name is required"),
      groupName: Yup.string()
        .max(20, "Must be 20 characters or less")
        .required("Group Name is required"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const payload = {
          name: values.groupName,
          businessId: businessIDFromRedux,
        };
        dispatch(setGroupName(values.groupName));
        const response = await addBusinessGroup(payload);
        console.log("API Response:", response);
        if (response) {
          setBusinessId(response.data?.businessId)
          setId(response.data?._id)
          toast.success("Business group created successfully!");
          setTimeout(() => {
            navigate('/newBusiness/businessGroup', { state: { businessID: response.data?.businessId,_id:response.data?._id } });
          }, 1500); 
        } else {
          toast.error("Unexpected response from server.");
        }
      } catch (error:any) {
        console.error("Error creating business group:", error);
    
        toast.error(error?.data?.message);
      } finally {
        setLoading(false);
      }
    }
    
  });

  return (
    <>
      <HeaderContent />
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
      <div className="py-4 heightautoscroll">
        <div className={styles.signuppage}>
          <div className="contractpages">
            <form onSubmit={formik.handleSubmit}>
              <div style={{ position: "relative" }}>
                <div className="container">
                  <div className={styles.registerBord}>
                    <div className="row align-items-start">
                      <div className="col-lg-6">
                        <div className={styles.registerLeft}>
                          
                          <div className="col-lg-11 col-12">
                            <figure>
                              <img src={businessgroup} alt="" className="img-fluid" />
                            </figure>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className={styles.registerBord}>
                          <div className={styles.signUPContainer}>
                            <div className="col-lg-11 col-12 mx-auto">
                              <div className={styles.registerRight}>
                                <div>
                                <h4 className="text-white text-32 fw-semibold">
                                Create a business group
                                </h4>
                                <p className="text-16 text-white opacity-75 ls-base">
                                Create a dynamic business group to strengthen<br/> teamwork, enhance connectivity, and ensure all team<br/> members stay informed and engaged in one unified<br/> space.
                                </p>
                                </div>
                                <div className={styles.fromGroup}>
                                  <div className="topTipHover">
                                    <label className={styles.starlabel}>
                                      Business Name
                                    </label>
                                    <i>
                                      <img className="customeTolImg" src={tip} alt="" />
                                    </i>
                                  </div>
                                  <input
                                    type="text"
                                    name="businessID"
                                    placeholder="Business Name"
                                    value={formik.values.businessID}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className={
                                      formik.touched.businessID && formik.errors.businessID
                                        ? "input-error"
                                        : ""
                                    }
                                  />
                                  {formik.touched.businessID && formik.errors.businessID && (
                                    <div className="error-text text-danger text-14 pt-2">{formik.errors.businessID}</div>
                                  )}
                                </div>

                                <div className={styles.fromGroup} style={{ marginBottom: "30px" }}>
                                  <div className="topTipHover">
                                    <label>Group Name</label>
                                    <i>
                                      <img className="customeTolImg" src={tip} alt="" />
                                    </i>
                                  </div>
                                  <input
                                    type="text"
                                    name="groupName"
                                    placeholder="Group Name"
                                    maxLength={20}
                                    value={formik.values.groupName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className={
                                      formik.touched.groupName && formik.errors.groupName
                                        ? "input-error"
                                        : ""
                                    }
                                  />
                                  {formik.touched.groupName && formik.errors.groupName && (
                                    <div className="error-text text-danger text-14 pt-2">{formik.errors.groupName}</div>
                                  )}
                                </div>
                              </div>
                        
                      

                          <div className="row align-items-center justify-content-between">
                            <div className="col-md-4 col-6 text-start my-3 btn-hover-effect">
                              <h6
                                className="btn arrowyellow border-grey border-1 position-relative ps-5 backbutton mb-0"
                                onClick={() => navigate(-1)}
                              >
                                <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                                  <img src={blackarrow} alt="" />
                                </i>
                                <span className="px-lg-4 px-2 ps-3 text-12 fw-semibold d-block">
                                  Back
                                </span>
                              </h6>
                            </div>

                            <div className="col-md-4 col-12 text-end my-3 btn-hover-effect">
                              <button
                                type="submit"
                                className="btn arrowblue border-grey border-1 position-relative pe-5 mb-0"
                                disabled={loading}
                              >
                                {loading ? (
                                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                ) : (
                                  <>
                                    <span className="px-lg-4 px-2 pe-lg-4 text-12 fw-semibold d-block">
                                        Next
                                    </span>
                                    <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                                        <img src={whitearrow} alt="" />                                                        
                                    </i>
                                    </>
                                )}
                              </button>
                            </div>
                          </div>
                      </div>
                      </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateNewBusiness;
