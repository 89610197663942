export enum MASTER_TYPE {
  OCCUPATIONS = "occupations",
  INDUSTRIES = "industries",
  MANUFACTURERS = "manufacturers",
  SOFTWARE_LICENCES = "softwarelicences",
  APPLICATIONS = "applications",
  REFERRALS = "referrals",
  COMPANIES = "companies",
  DEGREE = "degree",
  SCHOOL = "school",
  SPECIALIZATIONS = "specializations",
  SKILLED_TRADES = "skilledtrades",
  MANUFACTURERS_MODELS = "manufacturersmodels",
  ABOUT="heard_type"
}

export enum MASTER_ID_TYPE {
  occupations = "occupationIds",
  industries = "industriesIds",
  manufacturers = "manufacturerIds",
  softwarelicences = "softwareLicenceIds",
  applications = "applicationIds",
  referrals = "referralIds",
  companies = "companyIds",
  degree = "degreeIds",
  school = "schoolIds",
  specializations = "specializationIds",
  skilledtrades = "skilledTradeIds",
}

export enum MASTER_STATUS {
  PENDING = "pending",
  APPROVED = "approved",
  REJECTED = "rejected",
}

export enum SKILL_TYPE {
  TOP = "top",
  SECOND = "second",
  OTHER = "other",
}
