import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "react-bootstrap";
import { CongratulationComp } from "./CongratulationComp";
import confirmrole from "../../../assets/images/confirmrole.png";


interface ThanksModalProps {
  showModal: boolean;
  closeModal: () => void;
}

export const ConfirmationComp: React.FC<ThanksModalProps> = ({
  showModal,
  closeModal,
}) => {
  const [showCongratsModal, setShowCongratsModal] = useState(false);


  const onYes = () => {
    setShowCongratsModal(true);
    closeModal()

  };

  const onNo = () => {
    closeModal();
  };

  const closeCongratsModal = () => {
    setShowCongratsModal(false);
    closeModal()
  };

  return (
    <>
      <Modal show={showModal} onHide={closeModal} centered  >
        <Modal.Header closeButton style={{ background: "#DBE3EC" }}>
          <Modal.Title className="d-none">Confirm Role</Modal.Title>
        </Modal.Header>
        <ModalBody style={{ background: "#DBE3EC" }} className="">
          <div className="modal_catch d-flex flex-column justify-content-center text-center rounded-0">
            <div className="text-center">
              <figure>
                <img src={confirmrole} alt="" className="img-fluid mx-auto my-4" />
              </figure>
            </div>
            <div className="col-lg-8 col-12 mx-auto">
              <h1 className="text-24 fw-bold">Confirm Role</h1>
              <p className="text-12 mb-0">
                Do you want to assign this professional as the <strong>Team Manager</strong> for
                this business group? Assigning them will grant them manager-level
                responsibilities and permissions.
              </p>
            </div>

          </div>
        </ModalBody>

        <ModalFooter className="btn-hover-effect justify-content-center border-top-0" style={{ background: "#DBE3EC" }}>
          <Button
            type="button"
            variant="secondary"
            onClick={onNo}
            className="btn bg-white arrowyellow border border-1 rounded-3 position-relative py-2 backbutton w-auto mb-3"
          >
            <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
              No
            </span>
          </Button>
          <Button
            type="button"
            variant="secondary"
            onClick={onYes}
            className="btn bg-blue arrowblue border border-1 rounded-3 position-relative py-2 w-auto mb-3"
          >
            <span className="px-lg-4 px-2 text-12 fw-semibold text-white">
              Yes
            </span>
          </Button>
        </ModalFooter>
      </Modal>

      {showCongratsModal && (
        <CongratulationComp
          closeModal={closeCongratsModal}
          showCongratsModal={showCongratsModal}
        />
      )}
    </>
  );
};
