import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "react-bootstrap";
import congratsCheck from "../../../../../assets/images/enhance-profile.png";
import crossicon from "../../../../../assets/images/cross.svg";

interface EnhanceProfileModalProps {
  onClose: () => void;
  onNext: () => void;
  showModal: boolean;
  onEdit: () => void;
  profileImg?: string;
}

const EnhanceProfileModal: React.FC<EnhanceProfileModalProps> = ({
  onClose,
  onNext,
  showModal,
  onEdit,
  profileImg,
}) => (
  <Modal onHide={onClose} show={showModal} centered className="modal-md">
    {/* <ModalHeader closeButton></ModalHeader> */}
    <ModalBody>
      <div className="modal_catch d-flex flex-column justify-content-center text-center rounded-0 pt-4 px-4 position-relative">
        <div
          className="position-absolute"
          style={{ top: "10px", right: "10px" }}
        >
          <i>
            <img
              src={crossicon}
              onClick={onClose}
              alt=""
              width="25px"
              height="25px"
            />
          </i>
        </div>
        <div className="text-center">
          <figure>
            {profileImg ? (
              <img style={{border:`1px solid grey`, borderRadius: 50}}
                src={profileImg}
                alt=""
                className="img-fluid mx-auto my-4"
                height={100}
                width={100}
              />
            ) : (
              <img
                src={congratsCheck}
                alt=""
                className="img-fluid mx-auto my-4"
                height={50}
                width={50}
              />
            )}
          </figure>
        </div>
        <h1 className="text-20 fw-bold mb-3">
          Enhance Your Profile for Better
          <br /> Oppurtunities!
        </h1>
        <p className="text-14 fw-normal mb-3">
          Is your profile fully detailed and up to date? Client will only view
          your profile not your resume.Generally the most comprehensive and
          plished profiles attracts ther most projects!{" "}
        </p>
      </div>
    </ModalBody>

    <ModalFooter className="btn-hover-effect justify-content-center border-top-0 mb-3">
      <div className="col-lg-10 col-12 mx-auto row align-items-center justify-content-center">
        <div className="col-4">
          <Button 
          onClick={onClose}
            type="button"
            variant="secondary"
            className="btn bg-white arrowyellow border border-1 rounded-3 position-relative py-2 backbutton w-100 mb-0"
          >
            <span className="px-lg-4 px-2 text-14 fw-medium text-black text-capitalize">
              Cancel
            </span>
          </Button>
        </div>
        <div className="col-4">
          <Button
            onClick={onEdit}
            type="button"
            variant="secondary"
            className="btn bg-blue arrowblue border border-1 rounded-3 position-relative py-2 w-auto"
          >
            <span className="px-lg-4 px-2 text-12 fw-semibold text-white">
              Edit Profile
            </span>
          </Button>
        </div>
        <div className="col-4">
          <Button
            onClick={onNext}
            type="button"
            variant="secondary"
            className="btn arrowyellow border border-1 rounded-2 position-relative py-2 backbutton w-100 mb-0"
            style={{ backgroundColor: "#1EC20F" }}
          >
            <span className="text-12 fw-medium text-white">Continue</span>
          </Button>
        </div>
      </div>
    </ModalFooter>
  </Modal>
);

export default EnhanceProfileModal;
