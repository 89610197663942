import React, { FC, useState, useEffect } from "react";
import Occupations from "../../assets/images/addnewemployee.png";
// import styles from '../../../src/components/work/contractSteps/styles/styles.module.css';
import styles from '../../components/contractBeforeLogin/styles/style.module.css'
import whitearrow from "../../assets/images/whitearrow.svg";
import blackarrow from "../../assets/images/blackarrow.svg";
import tip from '../../assets/images/tip.png';
import { useDispatch, useSelector } from "react-redux";
import HeaderContent from "../../shared/Components/HeaderContent/headerContent";
import ThanksComp from "./modals/ThanksComp";
import { getMasterTypes } from "../../../src/feature/auth/signup/api";
import { postMasterType } from "../work/api";
import { MASTER_TYPE } from "../../../src/utils/enums/masterTypes.enum";
import CreatableSelect from "react-select/creatable";
import { MESSAGES } from "../../../src/utils/messages";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { setEmployeeDetails } from "../../redux/reducers/BusinessEmployeeSlice";
import { addEmployee } from "./api";
import { RootState } from "../../redux/store";
import { Dropdown, FormControl } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";


interface OptionType {
  value: string;
  label: string;
}

const AddNewEmployee: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { businessID, groupID } = location.state || {};
  const GroupData = useSelector((state: RootState) => state.businessGroup.businessGroupData);

  const [occupationList, setOccupationList] = useState<OptionType[]>([]);
  const [businessId, setBusinessId] = useState<string>("");
  const [businessGroupId, setBusinessGroupId] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  useEffect(() => {
    // if (Array.isArray(GroupData) && GroupData.length > 0) {
    //   const group = GroupData.find(item => item._id && item.businessId);
    //   if (group) {
        setBusinessId(businessID);
        setBusinessGroupId(groupID);
        // console.log("Extracted IDs:", { businessId: group.businessId, businessGroupId: group._id });
    //   }
    // }
  }, []);
  const types = ["Manager", "Employee"];

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      selectedOccupation: [] as OptionType[],
      type: "", 
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      email: Yup.string().email("Invalid email format").required("Email is required"),
      selectedOccupation: Yup.array().min(1, "Select at least one occupation").required("Occupation is required"),
      type: Yup.string().required("Role Type is required"),

    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const payload = {
          email: values.email,
          businessId:businessId ,
          businessGroupId: businessGroupId,
          type: values.type,
          firstName: values.firstName,
          lastName: values.lastName,  
          primaryOccupation: values.selectedOccupation.map((occupation) => ({
            label: occupation.label,
            value: occupation.value,
          }))[0], 
          // primaryOccupation: values.selectedOccupation[0]?.label || "", 
        };
        const response = await addEmployee(payload);

        if (response) {
          setShowModal(true)                                                                                                                                                                                                                              
          setTimeout(() => {
          
          }, 1500);
        } else {

          toast.error("Unexpected response from server.");
        }
      } catch (error:any) {
      

        toast.error(error.data?.message);
      } finally {
        setLoading(false)
      }
    }
  });
  const closeModal = () => { setShowModal(false) };

  const getOccupationData = async () => {
    try {
      const response = await getMasterTypes(MASTER_TYPE.OCCUPATIONS);
      const formattedOccupations: OptionType[] = response.data.map((occupation: any) => ({
        value: occupation._id,
        label: occupation.name,
      }));
      setOccupationList(formattedOccupations);
    } catch (error) {
      console.error("Error fetching occupation data:", error);
    }
  };

  useEffect(() => {
    getOccupationData();
  }, []);

  const handleCreateOccupation = async (newOccupationName: string) => {
    const payload = {
      type: MASTER_TYPE.OCCUPATIONS,
      name: newOccupationName,
      alias: newOccupationName,
    };

    toast.promise(postMasterType(payload), {
      pending: {
        render() {
          return MESSAGES.POSTING_CONTRACTS.VERIFYING;
        },
      },
      success: {
        render(newOccupation) {
          const newId = newOccupation?.data?.data?._id || newOccupationName;
          const newOccupationObj: OptionType = { value: newId, label: newOccupationName };

          setOccupationList((prev) => [...prev, newOccupationObj]);

          formik.setFieldValue("selectedOccupation", [newOccupationObj]);

          return MESSAGES.POSTING_CONTRACTS.ADDING;
        },
      },
      error: {
        render(error) {
          console.log(error, "error");
          return "Error adding occupation.";
        },
      },
    });
  };

  return (
    <>
      <HeaderContent />
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
      <div className="py-4 heightautoscroll">
        <div className={styles.signuppage}>
          <div className="contractpages">
              <form onSubmit={formik.handleSubmit}>
                <div style={{ position: "relative" }}>
                  <div className="container">
                    <div className={styles.registerBord}>
                      <div className="row align-items-start">
                        <div className="col-lg-6">
                          <div className={styles.registerLeft}>
                            
                            <div className="col-lg-11 col-12">
                              <figure>
                                <img
                                  src={Occupations}
                                  alt=""
                                  className="img-fluid"
                                />
                              </figure>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-12">
                          <div className={styles.registerBord}>
                            <div className={styles.signUPContainer}>
                              <div className={styles.registerRight}>
                                <>
                                <h4 className="text-white text-32 fw-semibold">
                              Add New Employee
                            </h4>
                            <p className="text-16 text-white opacity-75">
                            Bring Fresh Energy to Your Workforce – Add New Talent Effortlessly
                            </p>
                            </>
                                <div className={styles.fromGroup}>
                                  <div className="topTipHover">
                                    <label className={styles.starlabel}>
                                      First Name
                                    </label>
                                    <i className="">
                                      <img
                                        className="customeTolImg"
                                        src={tip}
                                        alt=""
                                      />
                                    </i>
                                  </div>
                                  <div className={styles.fromGroup}>
                                    <input
                                      type="text"
                                      placeholder="First Name"
                                      maxLength={20}
                                      name="firstName"
                                      value={formik.values.firstName}
                                      onChange={formik.handleChange}
                                    />
                                      {formik.touched.firstName && formik.errors.firstName && (
                                    <div className={styles.error}>{formik.errors.firstName}</div>
                                  )}
                                  </div>
                                
                                </div>
                                <div className={styles.fromGroup}>
                                  <div className="topTipHover">
                                    <label>Last Name</label>
                                    <i>
                                      <img
                                        className="customeTolImg"
                                        src={tip}
                                        alt=""
                                      />
                                    </i>
                                  </div>
                                  <div className={styles.fromGroup}>
                                    <input
                                      type="text"
                                      placeholder="Last name"
                                      maxLength={20}
                                      name="lastName"
                                      value={formik.values.lastName}
                                      onChange={formik.handleChange}
                                    />
                                      {formik.touched.lastName && formik.errors.lastName && (
                                    <div className={styles.error}>{formik.errors.lastName}</div>
                                  )}
                                  </div>
                                
                                </div>
                                <div className={styles.fromGroup}>
                                  <div className="topTipHover">
                                    <label>Email</label>
                                    <i>
                                      <img
                                        className="customeTolImg"
                                        src={tip}
                                        alt=""
                                      />
                                    </i>
                                  </div>
                                  <div className={styles.fromGroup}>
                                    <input
                                      type="text"
                                      placeholder="Email"
                                      name="email"
                                      value={formik.values.email}
                                      onChange={formik.handleChange}
                                    />
                                      {formik.touched.email && formik.errors.email && (
                                    <div className={styles.error}>{formik.errors.email}</div>
                                  )}
                                  </div>
                                
                                </div>
                                <div className={styles.fromGroup}>
                                  <div className="topTipHover">
                                    <label>Primary Occupation</label>
                                    <i>
                                      <img
                                        className="customeTolImg"
                                        src={tip}
                                        alt=""
                                      />
                                    </i>
                                  </div>
                                  <div className={styles.fromGroup}>
                                    <CreatableSelect<OptionType>
                                      name="selectedOccupation"
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                      onCreateOption={handleCreateOccupation}
                                      options={occupationList}
                                      onChange={(option) => {
                                        const selectedOption = option ? [option] : [];
                                        formik.setFieldValue("selectedOccupation", selectedOption);
                                      }}
                                      value={formik.values.selectedOccupation[0] || null}
                                      styles={{
                                        menu: (base) => ({
                                          ...base,
                                          zIndex: 9999,
                                        }),
                                      }}
                                    />
                                      {formik.touched.selectedOccupation && formik.errors.selectedOccupation && (
                                    <div className={styles.error}>
                                   Please select occpation
                                    </div>
                                  )}
                                  </div>
                                
                                </div>
                                <div className={styles.fromGroup}>
                              <label>Role</label>
                              <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                  {formik.values.type || "Select Type"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {types.map((typeData) => (
                                    <Dropdown.Item
                                      key={typeData}
                                      onClick={() => formik.setFieldValue("type", typeData)}
                                    >
                                      {typeData}
                                    </Dropdown.Item>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                              {formik.touched.type && formik.errors.type && (
                                <div className={styles.error}>{formik.errors.type}</div>
                              )}
                            </div>
                              </div>
                            </div>
                          </div>
                          <div className="row align-items-center justify-content-end">
                          <div className="col-md-6 col-12 text-end my-3 btn-hover-effect">
                          <button
                            type="submit"
                            className="btn arrowblue border-grey border-1 position-relative pe-5 mb-0"
                            >
                               {loading ? (
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              ) : (
                                <>
                                <span className="px-lg-4 px-2 pe-lg-5 text-12 fw-semibold d-block">
                                Send Request
                                </span>
                                <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                                    <img src={whitearrow} alt="" />                                                        
                                </i>
                                </>
                              )}
                            </button>
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
          </div>
        </div>
      </div>
      <ThanksComp
      showModal={showModal}
      closeModal={closeModal}/>
    </>
  );
};

export default AddNewEmployee;
