import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../styles/style.module.css";
import whitearrow from "../../../assets/images/whitearrow.svg";
import thumb from "../../../assets/images/thumb.svg";
import cross from "../../../assets/images/cross.svg";


const Congratulation = () => {
  const navigate = useNavigate();
  return (
    <>

      <div className={styles.Congratulation}>
        <div className="container">
          <div className={styles.innerCongra}  style={{background: "#DBE3EC", position: "relative"}}>
            <img src={cross} alt="" style={{position: "absolute", top: "15px", right: "15px"}}/>
            <img src={thumb} alt="" className="img-fluid" width={200} height={200}/>
            <h4 className="fw-semibold mb-4">Congratulations!</h4>
            <p className="d-block">Your contract has successfully started.</p>
            <p>
              Your contract has been successfully submitted to the admin for
              approval.&apos;Once approved, you&apos;ll receive a notification
              from our automated system. Please be patient while we process your
              request.
            </p>
            {/* <div className="">
              <div className="col-lg-4 col-6">
                <button
                  className="btn border btn-primary rounded-2 border primaryBtn cancelBtn m-0 my-2"
                  type="button"
                  onClick={() => navigate("/home")}
                >
                  <span>View Dashbaord</span>
                </button>
              </div>
            </div> */}
            <div className=" my-4">
            <div className="col-6 mx-auto text-center btn-hover-effect">
              <h6
                onClick={() => navigate("/home")}
                className="btn bg-white arrowyellow border border-1 rounded-pill position-relative py-lg-3 py-2 pe-5 mb-0"
              >
                <span className="px-lg-4 px-2 pe-lg-3 text-12 fw-semibold d-block">
                View Dashbaord
                </span>
                <i className="bg-yellow rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                  <img src={whitearrow} alt="" />
                </i>
              </h6>
            </div>
            </div>
            
            {/* <div className={styles.congBtn}>
              <Link to="/feeds">Back to Feed</Link>
              <button onClick={() => navigate("/home")}>View Dashbaord</button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Congratulation;
