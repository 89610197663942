import React, { useEffect, useState } from "react";
import styles from '../../components/contractBeforeLogin/styles/style.module.css'
import { Dropdown, FormControl } from "react-bootstrap";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import tip from '../../../src/assets/images/tip.png'
import whitearrow from '../../../src/assets/images/whitearrow.svg'
import blackarrow from '../../../src/assets/images/blackarrow.svg'
import createnewbusiness from '../../../src/assets/images/createnewbusiness.png'
import storage from "../../utils/storage";
import { RootState } from "../../redux/store";
import { locationSchema } from "../work/validations";
import { getCitizenshipList } from "../../feature/auth/signup/api";
import { setLocation } from "../../redux/reducers/addContract";
import HeaderContent from "../../shared/Components/HeaderContent/headerContent";
import Toltip from "../common/toltip/page";
import { CONSTANT_MANAGER } from "../../utils/constans/constants";
import { ToastContainer, toast } from "react-toastify";
import { SaveAsDraftButton } from "../work/contractSteps/Components";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";
import { addBusiness } from "./api";
const libraries: any = ["places"];
const AddBusiness: React.FC = () => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_APIKEY as string,
        libraries,
    });
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const token = storage.getToken();
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [fullAddress, setFullAddress] = useState<any>('');
    const [autocomplete, setAutocomplete] =
        useState<google.maps.places.Autocomplete | null>(null);
    const [addressDetails, setAddressDetails] = useState({
        city: "",
        state: "",
        country: "",
        zipcode: "",
        streets: "",
    });
    const [loading, setLoading] = useState(false);
    const { location } = useSelector((state: RootState) => state.addContract);
    const {
        selectedCity,
        selectedCountry,
        selectedState,
        selectedStreet,
        selectedZipcode,
        isFilled,
    } = location;

    const formik = useFormik({
        initialValues: {
            selectedCountry,
            selectedStreet,
            selectedState,
            selectedCity,
            selectedZipcode,
            isFilled,
            businessName: ""
        },
        // validationSchema: locationSchema,
        onSubmit:async (values) => {
            setLoading(true);

            try{
                const payload = {
                    businessName: values?.businessName,
                    country: addressDetails?.country,
                    state: addressDetails?.state,
                    city: addressDetails?.city,
                };
                
                const response = await addBusiness(payload);
                if(response){
                    toast.success("Business created successfully!");
                       
          setTimeout(() => {
            navigate('/home');
          }, 1500); 
                }else{
                    toast.error("Unexpected response from server.");
                }
            }
            catch (error:any) {
                console.error("Error creating business group:", error);
            
                toast.error(error.data?.message);
              } finally {
                setLoading(false);
              }
        },
    });
    const onLoad = (autocomplete: any) => {
        setAutocomplete(autocomplete);
    };
    const onPlaceChanged = () => {
        if (autocomplete) {
            const place = autocomplete.getPlace();
            const addressComponents: any = place.address_components;
            const result = {
                city: "",
                state: "",
                country: "",
                zipcode: "",
                streets: "",
            };
            const streetComponents: string[] = [];

            addressComponents.forEach(
                (component: google.maps.GeocoderAddressComponent) => {
                    const types = component.types;
                    if (types.includes("locality")) {
                        result.city = component.long_name;
                    } else if (types.includes("administrative_area_level_1")) {
                        result.state = component.long_name;
                    } else if (types.includes("country")) {
                        result.country = component.long_name;
                    } else if (types.includes("postal_code")) {
                        result.zipcode = component.long_name;
                    } else if (types.includes("premise")) {
                        streetComponents.push(component.long_name);
                    } else if (types.includes("street_number")) {
                        streetComponents.push(component.long_name);
                    } else if (types.includes("route")) {
                        streetComponents.push(component.long_name);
                    } else if (
                        types.includes("sublocality") ||
                        types.includes("sublocality_level_1") ||
                        types.includes("sublocality_level_2")
                    ) {
                        streetComponents.push(component.long_name);
                    }   }   );
            result.streets = streetComponents.join(", ");
            formik.setFieldValue("selectedCity", result.city);
            formik.setFieldValue("selectedState", result.state);
            formik.setFieldValue("selectedCountry", result.country);
            formik.setFieldValue("selectedZipcode", result.zipcode);
            formik.setFieldValue("selectedStreet", result.streets);
            dispatch(
                setLocation({
                    ...location,
                    selectedCity: { label: result.city, value: result.city },
                    selectedState: { label: result.state, value: result.state },
                    selectedCountry: { label: result.country, value: result.country },
                    selectedZipcode: { label: result.zipcode, value: result.zipcode },
                    selectedStreet: { label: result.streets, value: result.streets },
                    isFilled: true,
                })
            );
            setAddressDetails(result);
        }
    };
    useEffect(() => {
        const getCitizenshipData = async () => {
            const response = await getCitizenshipList();
            response.data.forEach((x) => {
                x.label = x.name;
                x.value = x._id;
            });

            if (response.data.length > 0) {
                const usCountry = response.data.find(
                    (x) => x.code.toLowerCase() === "us"
                );  }     };
        getCitizenshipData();
    }, []);
    const handleGoToNextStep = () => {
        if (isFilled === false) {
            toast.error("Please fill your Address");
            return;
        }
        else {
        }
    };
    const onInputChange = (value: any, key: string) => {

        formik.setFieldValue(key, value.value);
        if (value.label === "") {
            return;
        }
        dispatch(setLocation({ ...location, [key]: value }));
    };

    return (
        <>
            <HeaderContent />
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
            <div className="py-4 heightautoscroll">
                <div className={styles.signuppage}>
                    <div className={styles.registerOuter}>
                        <div className="container">
                            <div className={styles.registerBord}>
                                <div className="row pt-2 h-100 align-items-start">
                                    <div className="col-lg-6">
                                        <div className={styles.registerLeft}>
                                            <div className="">
                                                <h4 className="text-white text-32 fw-semibold">Create New Business</h4>
                                                <p className="text-white text-14 opacity-75">Begin Your Path to Success – Launch Your Business with Ease</p>
                                            </div>
                                            <div className="col-lg-11 col-12">
                                                <figure>
                                                    <img src={createnewbusiness} alt="" />
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-12">
                                        <div className={styles.signUPContainer}>
                                            <div className={styles.registerRight}>
                                                <form onSubmit={formik.handleSubmit}>
                                                    <div className={styles.ScrollDiv}>
                                                       
                                                        {/* .............................................................. */}
                                                        <div className={styles.fromGroup}>
                                                            <div className="topTipHover">
                                                                <label className={styles.starlabel}>
                                                                    Business Name
                                                                </label>
                                                                <i>
                                                                    <img className="customeTolImg" src={tip} alt="" />
                                                                </i>
                                                            </div>
                                                            <input
                                                                type="text"
                                                                name="businessName"
                                                                placeholder="Business Name"
                                                                value={formik.values.businessName}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                className={
                                                                    formik.touched.businessName && formik.errors.businessName
                                                                        ? "input-error"
                                                                        : ""
                                                                }
                                                            />
                                                            {formik.touched.businessName && formik.errors.businessName && (
                                                                <div className="error-text text-danger text-14 pt-2">{formik.errors.businessName}</div>
                                                            )}
                                                        </div>
                                                        {/* .................................. ..................................*/}

                                                        <div
                                                            className={styles.fromGroup}
                                                            style={{ marginBottom: "30px" }}
                                                        >
                                                            {" "}
                                                            <div className="topTipHover">
                                                                <label className={styles.starlabel}>
                                                                    Start typing your address
                                                                </label>
                                                                <i>
                                                                    <img
                                                                        className="customeTolImg"
                                                                        src={tip}
                                                                        alt=""
                                                                    />
                                                                </i>
                                                                <Toltip
                                                                    slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                                                    labelId={CONSTANT_MANAGER.ADD_CONTRACT.STREET}
                                                                />
                                                            </div>
                                                            {isLoaded && (
                                                                <Autocomplete
                                                                    onLoad={onLoad}
                                                                    onPlaceChanged={onPlaceChanged}
                                                                >
                                                                    <input
                                                                        type="text"
                                                                        name="address"
                                                                        placeholder="Start typing your address"
                                                                    />
                                                                </Autocomplete>
                                                            )}
                                                        </div>

                                                        <div
                                                            className={styles.fromGroup}
                                                            style={{ marginBottom: "30px" }}
                                                        >
                                                            {" "}
                                                            <div className="topTipHover">
                                                                <label className={styles.starlabel}>
                                                                    Street
                                                                </label>
                                                            </div>
                                                            <input
                                                                type="text"
                                                                disabled
                                                                value={selectedStreet.label}
                                                            />
                                                        </div>
                                                        <div
                                                            className={styles.fromGroup}
                                                            style={{ marginBottom: "30px" }}
                                                        >
                                                            {" "}
                                                            <div className="topTipHover">
                                                                <label className={styles.starlabel}>
                                                                    Country
                                                                </label>
                                                            </div>
                                                            <input
                                                                type="text"
                                                                disabled
                                                                value={selectedCountry.label}
                                                            />
                                                        </div>

                                                        <div
                                                            className={styles.fromGroup}
                                                            style={{ marginBottom: "30px" }}
                                                        >
                                                            {" "}
                                                            <div className="topTipHover">
                                                                <label className={styles.starlabel}>
                                                                    State
                                                                </label>
                                                            </div>
                                                            <input
                                                                type="text"
                                                                disabled
                                                                value={selectedState.label}
                                                            />
                                                        </div>
                                                        <div
                                                            className={styles.fromGroup}
                                                            style={{ marginBottom: "30px" }}
                                                        >
                                                            {" "}
                                                            <div className="topTipHover">
                                                                <label className={styles.starlabel}>City</label>
                                                            </div>
                                                            <input
                                                                type="text"
                                                                disabled
                                                                value={selectedCity.value}
                                                            />
                                                        </div>

                                                        <div
                                                            className={styles.fromGroup}
                                                            style={{ marginBottom: "0px" }}
                                                        >{" "}
                                                            <div className="topTipHover">
                                                                <label className={styles.starlabel}>
                                                                    Zipcode
                                                                </label>
                                                            </div>
                                                            <input
                                                                type="text"
                                                                disabled
                                                                value={selectedZipcode.label} />
                                                        </div>
                                                    </div>
                                                    <div className="row align-items-center justify-content-end">
                                            <div className="col-md-4 col-12 text-end my-3 btn-hover-effect">
                                            <button
                                                type="submit"
                                                className="btn arrowblue border-grey border-1 position-relative pe-5 mb-0"
                                                disabled={loading}
                                                >
                                                {loading ? (
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                ) : (
                                                    <>
                                                    <span className="px-lg-4 px-2 pe-lg-5 text-12 fw-semibold d-block">
                                                        Continue
                                                    </span>
                                                    <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                                                        <img src={whitearrow} alt="" />                                                        
                                                    </i>
                                                    </>
                                                )}
                                                </button>
                                            </div>
                                        </div>
                                                </form>
                                            </div>
                                            
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddBusiness;
