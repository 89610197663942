import React, { useEffect, useState } from "react";
import styles from "../expense/styles/style.module.css";
import dlt from "../../../assets/images/dlt.png";
import infogr from "../../../assets/images/infogr.png";
import crtick from "../../../assets/images/crtick.png";
import pnedit from "../../../assets/images/pnedit.png";
import print from "../../../assets/images/print.png";
import dnlod from "../../../assets/images/dnlod.png";
import info from "../../../assets/images/info_icon.svg";
import logo from "../../../assets/images/logo.png";
import Header from "../../header/routes";
import { getexpenseById } from "./api";
import { useParams } from "react-router-dom";

import { getMondayOfCurrentWeek } from "../../../utils/daysMap";
import WhiteGloveContractDetails from "../Contracts/whiteGloveHourlyContract/whileGloveHourlyContract";
import Toltip from "../toltip/page";


const Expensereport = () => {
  const { id } = useParams();
  const [expenseStatusData, setExpenseStatusData] = useState<any>();

  const expenseDetails = async () => {
    const response: any = await getexpenseById(id);
    setExpenseStatusData(response?.data);
  };
  useEffect(() => {
    expenseDetails();
  }, []);
  const mondayDate = getMondayOfCurrentWeek();
  return (
    <>
      <Header />
      <div className={styles.pageOuter}>
        <div className="container">
          <div className={styles.rightContainer}>
            <h2 className="fw-bold text-26 mb-4">Expense Reports</h2>
            <div className={styles.baseInfo}>
              {/* <WhiteGloveContractDetails /> */}
              <div className={styles.ExpenseReport}>
                <div className={styles.timeSheetdetilshead}>
                <div className="col-4 topTipHover">
                    <div className="w-100 d-flex align-items-center">
                      <h4 className="form-check-label text-20 fw-bold mb-0 font-inter">
                      Expense Report
                      </h4>
                      <i>
                        <img className="customeTolImg" src={infogr} alt="" />
                      </i>
                      <Toltip />
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="d-flex align-items-center justify-content-end g-2">
                      <div className="px-1">
                        {" "}
                        <button className="border text-14 d-flex align-items-center py-2 px-3 rounded-pill font-inter fw-semibold">
                          <i className="me-2">
                            <img src={dlt} alt="" />
                          </i>{" "}
                          Delete
                        </button>
                      </div>
                      <div className="px-1">
                        {" "}
                        <button className="border text-14 d-flex align-items-center py-2 px-3 rounded-pill font-inter fw-semibold">
                          <i className="me-2">
                            <img src={print} alt="" />
                          </i>{" "}
                          Print
                        </button>
                      </div>
                      <div className="px-1">
                        {" "}
                        <button className="border text-14 d-flex align-items-center py-2 px-3 rounded-pill font-inter fw-semibold">
                          <i className="me-2">
                            <img src={dnlod} alt="" />
                          </i>{" "}
                          Export Data
                        </button>
                      </div>
                    </div>
                  </div>
                  
                </div>
                <div className={`table-responsive ${styles.tabletimesheet}`}>
                  <table className="table align-middle">
                    <tr>
                      <td colSpan={4}>
                        <img src={logo} alt="" />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={4}>
                        <h4 className="text-20">Expense Report</h4>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="fw-semibold">Engineers :</span>
                      </td>
                      <td>
                        <span className="fw-normal">
                          {" "}
                          {expenseStatusData?.user?.name}
                        </span>
                      </td>
                      <td colSpan={2} className=" text-center">
                        <span className="fw-semibold">
                          Monday / Week Start Date
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="fw-semibold">
                          Customer Company Name :
                        </span>
                      </td>
                      <td>
                        <span className="fw-normal">FGSFSF hfhfbfbh infor</span>
                      </td>
                      <td rowSpan={2} colSpan={2} className=" text-center">
                        <span className="fw-normal">
                        {mondayDate}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="fw-semibold">Business Name :</span>
                      </td>
                      <td>
                        <span className="fw-normal">47110</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="fw-semibold">Business Contact :</span>
                      </td>
                      <td>
                        <span className="fw-normal">
                          {expenseStatusData?.work?.user?.name}
                        </span>
                      </td>
                      <td>
                        <span className="fw-semibold">Business Email : </span>
                      </td>
                      <td>
                        <span className="fw-normal">
                          {" "}
                          {expenseStatusData?.work?.user?.email}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="fw-semibold">Contract Number :</span>
                      </td>
                      <td colSpan={4}>
                        <span className="fw-normal">
                          {" "}
                          #{expenseStatusData?.work?.workNumber}{" "}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span className="fw-semibold">End Customer :</span>
                      </td>
                      <td colSpan={4}>
                        <span className="fw-normal">Jerry Vemia</span>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="ExpenseReportBord">
                  <div className="expenseLogo">
                    {/* <img src={logo} alt="" />

                    <h3>Expense Report</h3> */}
                    {/* <div className={styles.personalDetials}>
                      <div className={styles.topBrd}>
                        <div className={styles.innerbrd}>
                          <b>Engineers :</b>
                        </div>
                        <div className={styles.innerbrd}>
                          {expenseStatusData?.user?.name}
                        </div>
                        <div className={styles.innerbrd}>
                          <b>Monday / Week Start Date</b>
                        </div>
                      </div>
                      <div className={styles.topBrd}>
                        <div className={styles.innerbrd}>
                          <b>Customer Company Name</b>
                        </div>
                        <div className={styles.innerbrd}>..</div>
                        <div className={styles.innerbrd}>{mondayDate}</div>
                      </div>
                      <div className={styles.topBrd}>
                        <div className={styles.innerbrd}>
                          <b>Customer # :</b>
                        </div>
                        <div className={styles.innerbrd}>47110</div>
                      </div>
                      <div className={styles.topBrd}>
                        <div className={styles.innerbrd}>
                          <b>Customer Contact : </b>
                        </div>
                        <div className={styles.innerbrd}>
                          {expenseStatusData?.work?.user?.name}
                        </div>
                        <div className={styles.innerbrd}>
                          <b>Customer Email : </b>
                        </div>
                        <div className={styles.innerbrd}>
                          {expenseStatusData?.work?.user?.email}
                        </div>
                      </div>
                      <div className={styles.topBrd}>
                        <div className={styles.innerbrd}>
                          <b>Work Number : </b>
                        </div>
                        <div className={styles.innerbrd}>
                          #{expenseStatusData?.work?.workNumber}
                        </div>
                      </div>
                      <div className={styles.topBrd}>
                        <div className={styles.innerbrd}>
                          <b>End Customer : </b>
                        </div>
                        <div className={styles.innerbrd}>#10179800</div>
                      </div>
                    </div> */}

                    <div
                      className={`${styles.personalDetials} ${styles.expenseTable}`}
                    >
                      <div className={styles.topBrd}>
                        <div className={styles.innerbrd}></div>
                        <div className={styles.innerbrd}></div>
                        <div className={styles.innerbrd}>
                          <b>Monday</b>
                        </div>
                        <div className={styles.innerbrd}>
                          <b>Tuesday</b>
                        </div>
                        <div className={styles.innerbrd}>
                          <b>Webnesday</b>
                        </div>
                        <div className={styles.innerbrd}>
                          <b>Thursday</b>
                        </div>
                        <div className={styles.innerbrd}>
                          <b>Friday</b>
                        </div>
                        <div className={styles.innerbrd}>
                          <b>Saturday</b>
                        </div>
                        <div className={styles.innerbrd}>
                          <b>Sunday</b>
                        </div>
                        <div className={styles.innerbrd}>
                          <b>Weekly Total</b>
                        </div>
                      </div>
                      <div className={styles.topBrd}>
                        <div className={styles.innerbrd}>
                          <b>Expense :</b>
                        </div>
                        <div className={styles.innerbrd}>
                          <b>Allownce :</b>
                        </div>
                        <div className={styles.innerbrd}>10/5/2023</div>
                        <div className={styles.innerbrd}>10/5/2023</div>
                        <div className={styles.innerbrd}>10/5/2023</div>
                        <div className={styles.innerbrd}>10/5/2023</div>
                        <div className={styles.innerbrd}>10/5/2023</div>
                        <div className={styles.innerbrd}>10/5/2023</div>
                        <div className={styles.innerbrd}>10/5/2023</div>
                        <div className={styles.innerbrd}>
                          <b>10/5/2023</b>
                        </div>
                      </div>
                      <div className={styles.topBrd}>
                        <div className={styles.innerbrd}>Pre Dim Foood</div>
                        <div className={styles.innerbrd}>$10</div>
                        <div className={styles.innerbrd}>$10</div>
                        <div className={styles.innerbrd}>$10</div>
                        <div className={styles.innerbrd}>$10</div>
                        <div className={styles.innerbrd}>$10</div>
                        <div className={styles.innerbrd}>$10</div>
                        <div className={styles.innerbrd}>$10</div>
                        <div className={styles.innerbrd}>$10</div>
                        <div className={styles.innerbrd}>
                          <b>10/5/2023</b>
                        </div>
                      </div>
                      <div className={styles.topBrd}>
                        <div className={styles.innerbrd}>Pre Dim Foood</div>
                        <div className={styles.innerbrd}>$10</div>
                        <div className={styles.innerbrd}>$10</div>
                        <div className={styles.innerbrd}>$10</div>
                        <div className={styles.innerbrd}>$10</div>
                        <div className={styles.innerbrd}>$10</div>
                        <div className={styles.innerbrd}>$10</div>
                        <div className={styles.innerbrd}>$10</div>
                        <div className={styles.innerbrd}>$10</div>
                        <div className={styles.innerbrd}>
                          <b>10/5/2023</b>
                        </div>
                      </div>
                      <div className={styles.topBrd}>
                        <div className={styles.innerbrd}>Pre Dim Foood</div>
                        <div className={styles.innerbrd}>$10</div>
                        <div className={styles.innerbrd}>$10</div>
                        <div className={styles.innerbrd}>$10</div>
                        <div className={styles.innerbrd}>$10</div>
                        <div className={styles.innerbrd}>$10</div>
                        <div className={styles.innerbrd}>$10</div>
                        <div className={styles.innerbrd}>$10</div>
                        <div className={styles.innerbrd}>$10</div>
                        <div className={styles.innerbrd}>
                          <b>10/5/2023</b>
                        </div>
                      </div>
                    </div>
                    <div className="AprovdDate">
                      <p className="mb-1">
                        <b>Approved By:</b> Toood Viller
                      </p>
                      <p className="mb-1">
                        <b>IP Address:</b> 12345
                      </p>
                      <p className="mb-1">
                        <b>Approved Date:</b> 10/4/45
                      </p>
                      <p className="mt-5">
                        <b>Date:</b>{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.Starptimsshetet}>
                <h3>
                  {expenseStatusData?.status === "approved"
                    ? "Approved"
                    : expenseStatusData?.status === "pending"
                      ? "Pending"
                      : expenseStatusData?.status === "draft"
                        ? "Current"
                        : expenseStatusData?.isDue === true
                          ? "Due"
                          : ""}
                </h3>
                <button>
                  {" "}
                  {expenseStatusData?.status === "approved"
                    ? "Approved Expense"
                    : expenseStatusData?.status === "pending"
                      ? "Pending Expense"
                      : expenseStatusData?.status === "draft"
                        ? "Current Expense"
                        : expenseStatusData?.isDue === true
                          ? "Due Expense"
                          : ""}{" "}
                </button>
              </div>
              <div className={`${styles.timeSheetdetils} ${styles.noMarg}`}>
                <div className={styles.timeSheetdetilshead}>
                  <h4>
                    Expense Details <img src={infogr} alt="" />
                  </h4>
                  <div className={styles.editBtns}>
                    <button>View Signed Timesheet </button>
                    <button>
                      <img src={dlt} alt="" /> Delete
                    </button>
                    <button>
                      <img src={crtick} alt="" /> Notify
                    </button>
                    <button>
                      <img src={pnedit} alt="" /> Edit
                    </button>
                    <button>
                      <img src={print} alt="" /> Print
                    </button>
                    <button>
                      <img src={dnlod} alt="" /> Export Data
                    </button>
                  </div>
                </div>
                <div className={styles.personalDetials}>
                  <div className={styles.topBrd}>
                    <div className={styles.innerbrd}>
                      <b>Engineers :</b>
                    </div>
                    <div className={styles.innerbrd}>
                      {expenseStatusData?.user?.name}
                    </div>
                    <div className={styles.innerbrd}>
                      <b>Monday / Week Start Date</b>
                    </div>
                  </div>
                  <div className={styles.topBrd}>
                    <div className={styles.innerbrd}>
                      <b>Customer Company Name</b>
                    </div>
                    <div className={styles.innerbrd}>..</div>
                    <div className={styles.innerbrd}>{mondayDate}</div>
                  </div>
                  <div className={styles.topBrd}>
                    <div className={styles.innerbrd}>
                      <b>Customer # :</b>
                    </div>
                    <div className={styles.innerbrd}>47110</div>
                  </div>
                  <div className={styles.topBrd}>
                    <div className={styles.innerbrd}>
                      <b>Customer Contact : </b>
                    </div>
                    <div className={styles.innerbrd}>
                      {expenseStatusData?.work?.user?.name}
                    </div>
                    <div className={styles.innerbrd}>
                      <b>Customer Email : </b>
                    </div>
                    <div className={styles.innerbrd}>
                      {expenseStatusData?.work?.user?.email}
                    </div>
                  </div>
                  <div className={styles.topBrd}>
                    <div className={styles.innerbrd}>
                      <b>Work Number : </b>
                    </div>
                    <div className={styles.innerbrd}>
                      #{expenseStatusData?.work?.workNumber}
                    </div>
                  </div>
                  <div className={styles.topBrd}>
                    <div className={styles.innerbrd}>
                      <b>End Customer : </b>
                    </div>
                    <div className={styles.innerbrd}>#10179800</div>
                  </div>
                </div>
                <div className={styles.personalDetials}>
                  <div className={`${styles.Morefieldss} ${styles.topBrd}`}>
                    <div className={styles.innerbrd}>
                      <b>Day</b>
                    </div>
                    <div className={styles.innerbrd}>
                      <b>Date</b>
                    </div>
                    <div className={styles.innerbrd}>
                      <b>Per Diem (Food)</b>
                    </div>
                    <div className={styles.innerbrd}>
                      <b>Luggage Fee</b>
                    </div>
                    <div className={styles.innerbrd}>
                      <b>Mileage ($0.67/mi)</b>
                    </div>
                    <div className={styles.innerbrd}>
                      <b>Daily Total</b>
                    </div>
                  </div>
                  <div className={`${styles.topBrd} ${styles.Morefieldss}`}>
                    <div className={styles.innerbrd}>
                      <b>Monday</b>
                    </div>
                    <div className={styles.innerbrd}>
                      <b>10 / 30 / 2023</b>
                    </div>
                    <div className={styles.innerbrd}>
                      <b>8</b>
                    </div>
                    <div className={styles.innerbrd}>
                      <b></b>
                    </div>
                    <div className={styles.innerbrd}>
                      <b></b>
                    </div>
                    <div className={styles.innerbrd}>
                      <b>8.00</b>
                    </div>
                  </div>
                  <div className={`${styles.topBrd} ${styles.brdColor}`}>
                    <div className={styles.innerbrd}>Monday Work Report</div>
                    <div className={styles.innerbrd}>
                      AMKGH Smart fill Project Debuging PLC & HMI Programs
                    </div>
                  </div>
                  <div className={`${styles.topBrd} ${styles.Morefieldss}`}>
                    <div className={styles.innerbrd}>
                      <b>Monday</b>
                    </div>
                    <div className={styles.innerbrd}>
                      <b>10 / 30 / 2023</b>
                    </div>
                    <div className={styles.innerbrd}>
                      <b>8</b>
                    </div>
                    <div className={styles.innerbrd}>
                      <b></b>
                    </div>
                    <div className={styles.innerbrd}>
                      <b></b>
                    </div>
                    <div className={styles.innerbrd}>
                      <b>8.00</b>
                    </div>
                  </div>
                  <div className={`${styles.topBrd} ${styles.brdColor}`}>
                    <div className={styles.innerbrd}>Monday Work Report</div>
                    <div className={styles.innerbrd}>
                      AMKGH Smart fill Project Debuging PLC & HMI Programs
                    </div>
                  </div>
                  <div className={`${styles.topBrd} ${styles.Morefieldss}`}>
                    <div className={styles.innerbrd}>
                      <b>Monday</b>
                    </div>
                    <div className={styles.innerbrd}>
                      <b>10 / 30 / 2023</b>
                    </div>
                    <div className={styles.innerbrd}>
                      <b>8</b>
                    </div>
                    <div className={styles.innerbrd}>
                      <b></b>
                    </div>
                    <div className={styles.innerbrd}>
                      <b></b>
                    </div>
                    <div className={styles.innerbrd}>
                      <b>8.00</b>
                    </div>
                  </div>
                  <div className={`${styles.topBrd} ${styles.brdColor}`}>
                    <div className={styles.innerbrd}>Monday Work Report</div>
                    <div className={styles.innerbrd}>
                      AMKGH Smart fill Project Debuging PLC & HMI Programs
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Expensereport;
