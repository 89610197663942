import React, { useEffect, useState } from "react";
import styles from "../../../styles/style.module.css";
import choosetype from "../../../../../assets/images/choosetype.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import tip from "../../../../../assets/images/tip.png";
import storage from "../../../../../utils/storage";
import { RootState } from "../../../../../redux/store";
import { getuserProfile } from "../../../../home/api";
import {
  setActiveStep,
  setCustomerDetails,
} from "../../../../../redux/reducers/addContract";
import { ADD_CONTRACT_STEPS } from "../../../../../shared/enums";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import {
  SaveAsDraftButton,
} from "../../../../work/contractSteps/Components";
import Toltip from "../../../../common/toltip/page";
import { CONSTANT_MANAGER } from "../../../../../utils/constans/constants";
import CustomModal from "../../../../../shared/CustomModal/customModal";
import ConfirmModal from "../../../../../shared/CustomModal/confirmationModal";
import { onSiteCustomervalidationSchema } from "../../../../work/validations";

const AddCustomerDetails: React.FC = () => {
  const dispatch = useDispatch();
  const token = storage.getToken();
  const { authentication } = useSelector((state: RootState) => state);
  const { isAuthenticated } = authentication;
  const [key, setKey] = useState(Math.random());
  const { customerDetails } = useSelector(
    (state: RootState) => state.addContract
  );
  const [userData, setUserData] = useState<any>();
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const [isCustomer, setIsCustomer] = useState<any>(false);

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
  };

  const getuserData = () => {
    if (token) {
      getuserProfile().then((res) => {
        setUserData(res.data);
      });
    }
  };

  useEffect(() => {
    getuserData();
  }, []);

  const {
    sameAsCustomer,
    primaryName,
    primaryEmail,
    primaryPhoneNumber,
    secondaryName,
    secondaryEmail,
    secondaryPhoneNumber,
  } = customerDetails;

  const formIk = useFormik({
    initialValues: {
      sameAsCustomer,
      primaryName,
      primaryEmail,
      primaryPhoneNumber,
      secondaryName,
      secondaryEmail,
      secondaryPhoneNumber,
      // isCustomer,
    },
    validationSchema: onSiteCustomervalidationSchema,
    // validationSchema: onSiteCustomervalidationSchema,
    // validateOnBlur: true,
    // validateOnChange: true,
    onSubmit: () => {
      dispatch(setActiveStep(ADD_CONTRACT_STEPS.OVERVIEW_FORM));

      // if(isCustomer!=false){
      // }
    },
  });

  const handleGoBack = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_TRAVEL_DATA));
  };

  const handleAddMoreDetails = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_BASIC_INFO));
  };
  const onDetailChange = async (
    value: string | boolean | number,
    key: string
  ) => {
    if (value === true) {
      setIsCustomer(true);
    }
    else if (value === false) {
      setIsCustomer(false)
    }
    formIk.setFieldValue(key, value);
    await formIk.validateField(key);

    if (key === "sameAsCustomer") {
      if (!isAuthenticated && !token) {
        return;
      }
      if (value) {
        dispatch(
          setCustomerDetails({
            ...customerDetails,
            sameAsCustomer: true,
            primaryName: userData?.name || "", // Use optional chaining to prevent potential undefined errors
            primaryEmail: userData?.email || "", // Provide default values if userData[0] is undefined
            primaryPhoneNumber: String(userData?.phoneNumber || ""), // Ensure phoneNumber is always a string
          })
        );
      } else {
        dispatch(
          setCustomerDetails({
            ...customerDetails,
            sameAsCustomer: false,
            primaryName: "",
            primaryEmail: "",
            primaryPhoneNumber: "",
          })
        );
      }
      return;
    }

    dispatch(setCustomerDetails({ ...customerDetails, [key]: value }));

  };

  console.log("Is Customer", isCustomer);


  return (
    <>
      <HeaderContent />
      <div className="heightautoscroll contractradio">
        <form onSubmit={formIk.handleSubmit} className="h-100">
          <div className="container h-100">
            <div className="row h-100 align-items-center">
                    <div className="col-md-6">
                      <div className={styles.registerLeft}>
                        <div className="col-lg-11 col-12 mx-auto text-center">
                          <figure>
                            <img
                              src={choosetype}
                              alt=""
                              className="img-fluid w-100"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                    <h4 className="text-white text-32 fw-semibold">
                          ON-SITE Customer Contact
                        </h4>
                        <p className="text-16 text-white opacity-75">
                          Add a basic information of the Customer
                        </p>
                        <div className={styles.ScrollDiv}>
                      <div className={styles.signUPContainer}>
                        <div
                          className={`${styles.registerRight} ${styles.editors}`}
                        >
                         {token &&
                            <div
                              className={styles.fromGroup}
                              style={{ marginBottom: "30px" }}
                            >
                              <div className={styles.ratedRadio}>
                                {" "}
                                <div className="topTipHover">
                                  <label className={styles.starlabel}>
                                    {" "}
                                    I am the onsite customer?
                                  </label>
                                  <i>
                                    <img
                                      className="customeTolImg"
                                      src={tip}
                                      alt=""
                                    />
                                  </i>

                                  <Toltip
                                    slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                    labelId={
                                      CONSTANT_MANAGER.ADD_CONTRACT
                                        .ONSITE_CUSTOMER
                                    }
                                  />
                                </div>
                                <div className={styles.rateradio}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="sameAsCustomer"
                                      id="sameAsCustomer"
                                      checked={sameAsCustomer}
                                      onChange={() =>
                                        onDetailChange(true, "sameAsCustomer")
                                      }
                                    />
                                    <label className="form-check-label">
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="sameAsCustomer1"
                                      id="sameAsCustomer1"
                                      checked={!sameAsCustomer}
                                      onChange={() =>
                                        onDetailChange(false, "sameAsCustomer")
                                      }
                                    />
                                    <label className="form-check-label">
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            }
                            <div
                              className={styles.fromGroup}
                              style={{ marginBottom: "30px" }}
                            >
                              {" "}
                              <div className="topTipHover">
                                <label className={styles.starlabel}>
                                  Primary Name{" "}
                                </label>{" "}
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                                <Toltip
                                  slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                  labelId={
                                    CONSTANT_MANAGER.ADD_CONTRACT.PRIMARY_NAME
                                  }
                                />
                              </div>
                              <input
                                type="text"
                                name="primaryName"
                                className="form-control rounded-3 h-48"
                                placeholder="Primary Name "
                                value={primaryName}
                                onChange={(e) =>
                                  onDetailChange(e.target.value, "primaryName")
                                }
                                onBlur={formIk.handleBlur}
                              />
                              {isCustomer == false ? (formIk.touched.primaryName &&
                                formIk.errors.primaryName ? (
                                <div className={styles.error}>
                                  {formIk.errors.primaryName}
                                </div>
                              ) : null) : null}
                            </div>
                            <div
                              className={styles.fromGroup}
                              style={{ marginBottom: "30px" }}
                            >
                              {" "}
                              <div className="topTipHover">
                                <label className={styles.starlabel}>
                                  Primary Phone
                                </label>{" "}
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                                <Toltip
                                  slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                  labelId={
                                    CONSTANT_MANAGER.ADD_CONTRACT.PRIMARY_PHONE
                                  }
                                />
                              </div>
                              <input
                                type="text" // Changed type to text to handle phone numbers as strings
                                name="primaryPhoneNumber"
                                className="form-control rounded-3 h-48"
                                placeholder="Primary Phone"
                                value={primaryPhoneNumber}
                                onChange={
                                  (e) =>
                                    onDetailChange(
                                      e.target.value,
                                      "primaryPhoneNumber"
                                    ) // Handle as a string
                                }
                              />
                              {isCustomer == false ? (formIk.touched.primaryPhoneNumber &&
                                formIk.errors.primaryPhoneNumber ? (
                                <div className={styles.error}>
                                  {formIk.errors.primaryPhoneNumber}
                                </div>
                              ) : null) : null}
                            </div>

                            <div
                              className={styles.fromGroup}
                              style={{ marginBottom: "30px" }}
                            >
                              {" "}
                              <div className="topTipHover">
                                <label className={styles.starlabel}>
                                  Primary Email
                                </label>{" "}
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                                <Toltip
                                  slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                  labelId={
                                    CONSTANT_MANAGER.ADD_CONTRACT.PRIMARY_EMAIL
                                  }
                                />
                              </div>
                              <input
                                type="email"
                                name="primaryEmail"
                                className="form-control rounded-3 h-48"
                                placeholder="Primary Email"
                                value={primaryEmail}
                                onChange={(e) =>
                                  onDetailChange(e.target.value, "primaryEmail")
                                }
                                onBlur={formIk.handleBlur}
                              />
                              {isCustomer == false ? (formIk.touched.primaryEmail &&
                                formIk.errors.primaryEmail ? (
                                <div className={styles.error}>
                                  {formIk.errors.primaryEmail}
                                </div>
                              ) : null) : null}
                            </div>

                            {!sameAsCustomer && (
                              <>
                                <div className={styles.fromGroup}>
                                  {" "}
                                  <div className="topTipHover">
                                    <label className={styles.starlabel}>Secondary Name</label>{" "}
                                    <i>
                                      <img
                                        className="customeTolImg"
                                        src={tip}
                                        alt=""
                                      />
                                    </i>
                                    <Toltip
                                      slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                      labelId={
                                        CONSTANT_MANAGER.ADD_CONTRACT
                                          .SECONDARY_NAME
                                      }
                                    />
                                  </div>
                                  <input
                                    type="text"
                                    name="secondaryName"
                                    className="form-control rounded-3 h-48"
                                    placeholder="Secondary Name"
                                    value={secondaryName}
                                    onChange={(e) =>
                                      onDetailChange(
                                        e.target.value,
                                        "secondaryName"
                                      )
                                    }
                                  />
                                </div>
                                <div className={styles.fromGroup}>
                                  {" "}
                                  <div className="topTipHover">
                                    <label className={styles.starlabel}>Secondary Phone Number </label>{" "}
                                    <i>
                                      <img
                                        className="customeTolImg"
                                        src={tip}
                                        alt=""
                                      />
                                    </i>
                                    <Toltip
                                      slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                      labelId={
                                        CONSTANT_MANAGER.ADD_CONTRACT
                                          .SECONDARY_PHONE_NUMBER
                                      }
                                    />
                                  </div>
                                  <input
                                    type="number"
                                    name="secondaryPhoneNumber"
                                    className="form-control rounded-3 h-48"
                                    placeholder="Secondary Phone Number "
                                    value={secondaryPhoneNumber}
                                    onChange={(e) =>
                                      onDetailChange(
                                        Number(e.target.value),
                                        "secondaryPhoneNumber"
                                      )
                                    }
                                  />
                                </div>

                                <div className={styles.fromGroup}>
                                  {" "}
                                  <div className="topTipHover">
                                    <label className={styles.starlabel}>Secondary Email</label>{" "}
                                    <i>
                                      <img
                                        className="customeTolImg"
                                        src={tip}
                                        alt=""
                                      />
                                    </i>
                                    <Toltip
                                      slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                      labelId={
                                        CONSTANT_MANAGER.ADD_CONTRACT
                                          .SECONDARY_EMAIL
                                      }
                                    />
                                  </div>
                                  <input
                                    type="email"
                                    name="secondaryEmail"
                                    className="form-control rounded-3 h-48"
                                    placeholder="Secondary Email"
                                    value={secondaryEmail}
                                    onChange={(e) =>
                                      onDetailChange(
                                        e.target.value,
                                        "secondaryEmail"
                                      )
                                    }
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row align-items-center justify-content-end mx-0">
                        <div className="col-xl-4 col-lg-6 col-6 text-start my-3 btn-hover-effect">
                          <h6
                            onClick={() => handleGoBack()}
                            className="btn arrowyellow border-grey border-1 position-relative ps-5 backbutton"
                          >
                            <span className="px-lg-3 px-2 text-12 fw-semibold d-inline-block">
                              Back
                            </span>
                            <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                              <img src={blackarrow} alt="" />
                            </i>
                          </h6>
                        </div>
                        

                        <div className="col-xl-4 col-md-6 col-6">
                          <SaveAsDraftButton />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-6 text-end my-3 btn-hover-effect">
                          <button
                            type="submit"
                            className="btn arrowblue border-white border-1 position-relative pe-5"
                          >
                            <span className="px-2 text-10 fw-semibold d-inline-block">
                              Submit for Approval
                            </span>
                            <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                              <img src={whitearrow} alt="" />
                            </i>
                          </button>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-6 text-end my-3 btn-hover-effect saveasdraft">
                          <h6
                            onClick={handleAddMoreDetails}
                            className="btn arrowblue border-grey border-1 rounded-3 position-relative"
                          >
                            <span className="py-1 text-12 fw-semibold d-inline-block">
                              + Add More Details
                            </span>
                          </h6>
                        </div>
                        
                      </div>
                    </div>
            </div>
          </div>
          </form>
          {modalDetail.show && (
            <CustomModal
              key={key}
              show={modalDetail.show}
              backdrop="static"
              showCloseBtn={true}
              isRightSideModal={false}
              mediumWidth={false}
              className={modalDetail.flag === "addMore" ? "addMore" : ""}
              child={
                modalDetail.flag === "addMore" ? (
                  <ConfirmModal onCloseModal={() => handleOnCloseModal()} />
                ) : (
                  ""
                )
              }
              onCloseModal={() => handleOnCloseModal()}
            />
          )}
      </div>
    </>
  );
};
export default AddCustomerDetails;
