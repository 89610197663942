import React, { useState } from "react";
import styles from "../styles/style.module.css";
import tip from "../../../assets/images/tip.png";
import penbl from "../../../assets/images/penbl.png";
import { EmergencyCont } from "../interface";
import EmergencyContModal from "../modals/EmergencyContModal";

const EmergencyContact: React.FC<EmergencyCont> = ({ contact, refresh }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [editData, setEditData] = useState<string | null>(null);
  const [indexNumber, setIndexNumber] = useState<number | null>(null);

  const openModal = (data?: string, index?: number) => {
    setEditData(data || null); // Set data to edit or null for new entry
    setIsModalOpen(true);
    console.log("index---", index);
    if (index !== null && index !== undefined && index >= 0) {
      console.log("index-if", index);
      setIndexNumber(index);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditData(null); // Clear data when closing modal
  };
  return (
    <div className={styles.AboutEdit}>
      <div className="row align-items-center justify-content-between mb-3">
        <div className="col-10">
          <div className="topTipHover">
            <div className="d-flex align-items-center">
              <h4 className="text-20 fw-bold  font-inter mb-0">
                Emergency Contact
              </h4>
              <i>
                <img className="customeTolImg" src={tip} alt="" />
              </i>
            </div>
          </div>
        </div>
        <div className="col-2 text-end">
          <span>
            <img
              src={penbl}
              className="cursor-pointer ms-auto"
              alt=""
              onClick={() => openModal()}
            />
          </span>
        </div>
      </div>
      <div className={styles.personalinfo}>
        <ul className="row align-items-center">
          <li className="col-xl-4 col-6">
            <span className="">Name</span>
            <label className="clamp clamp1">
              {contact?.name ? contact?.name : "You have not added yet!"}
            </label>
          </li>
          <li className="col-xl-4 col-6">
            <span className="">Phone Number</span>
            <label className="clamp clamp1">
              {contact?.phoneNumber
                ? contact?.phoneNumber
                : "You have not added yet!"}
            </label>
          </li>
          <li className="col-xl-4 col-6">
            <span>Relationship</span>
            <label className="clamp clamp1">
              {contact?.relationship
                ? contact?.relationship
                : "You have not added yet!"}
            </label>
          </li>
        </ul>
      </div>
      <EmergencyContModal
        isOpen={isModalOpen}
        onClose={closeModal}
        title="Edit Emergency Contact"
        contact={contact}
        refresh={refresh}
      />
    </div>
  );
};

export default EmergencyContact;
