import React from "react";
import styles from "../styles/style.module.css";
import { interviewQuestionsData } from "../interface";

interface InterviewQuesProps {
  interviewQues: interviewQuestionsData[];
  refresh: () => void;
}

const InterviewQuestion: React.FC<InterviewQuesProps> = ({ refresh, interviewQues }) => {
  return (
    <div className={styles.AboutEdit}>
      <h4 className="text-20 fw-bold font-inter mb-0">Interview Questions</h4>

      {interviewQues.length === 0 ? (
        <p>No interview questions available.</p>
      ) : (
        <ul className="row align-items-center mt-3">
          {interviewQues.map((item, index) => (
            <li key={index} className="col-md-6 col-12 d-flex align-items-center mb-3">
              <div className="ps-3">
                <h6 className="text-14 mb-0">{item.question}</h6>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default InterviewQuestion;
