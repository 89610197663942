import React, { FC, useEffect, useRef, useState } from "react";
import styles from "../../../styles/style.module.css";
import choosetype from "../../../../../assets/images/choosetype.png";
import calender from "../../../../../assets/images/calender_icon.svg";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { durationSchema } from "../../../../work/validations";
import { setActiveStep, setDurations } from "../../../../../redux/reducers/addContract";
import { ADD_CONTRACT_STEPS } from "../../../../../shared/enums";
import moment from "moment";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import Toltip from "../../../../common/toltip/page";
import { SaveAsDraftButton } from "../../../../work/contractSteps/Components";
import tip from "../../../../../assets/images/tip.png";


const AddDuration: FC = () => {
  const dispatch = useDispatch();
  const datePickerRef = useRef<boolean | any>(null);
  const { durations } = useSelector((state: RootState) => state.addContract);
  const { startDate, endDate, duration } = durations;

  const formik: any = useFormik({
    initialValues: {
      startDate,
      endDate,
      duration,
    },
    validationSchema: durationSchema,
    onSubmit: (value) => {
      console.log(value);
      dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_KEY_INFORMATION));
    },
  });
  const handleIconClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  const onChange = (value: Date | string | number | null, key: string) => {
    formik.setFieldValue(key, value);

    if (key === "startDate" || key === "endDate") {
      const start =
        key === "startDate" ? moment.utc(value) : moment.utc(durations.startDate);
      const end =
        key === "endDate" ? moment.utc(value) : moment.utc(durations.endDate);
      let diffInDays = end.diff(start, "days");

      // Ensure duration is not negative
      if (diffInDays < 0) {
        diffInDays = 0;
      }

      console.log(diffInDays, "diffInDays");
      formik.setFieldValue("duration", diffInDays);
      dispatch(
        setDurations({
          ...durations,
          startDate: durations.startDate,
          endDate: durations.endDate,
          duration: diffInDays,
          [key]: value,
        })
      );
    } else if (key === "duration") {
      const parsedDuration = parseInt(String(value), 10);
      const durationValue = isNaN(parsedDuration) || parsedDuration < 0 ? 0 : parsedDuration; // Ensure duration is not negative

      if (durationValue >= 0 && durations.startDate) {
        const newEndDate = moment
          .utc(durations.startDate)
          .add(durationValue, "days")
          .toDate();
        formik.setFieldValue("endDate", newEndDate);
        dispatch(
          setDurations({
            ...durations,
            endDate: newEndDate,
            [key]: durationValue,
          })
        );
      }
    } else {
      dispatch(
        setDurations({
          ...durations,
          [key]: value,
        })
      );
    }
  };


  useEffect(() => {
    const start = moment.utc(formik.values.startDate);
    const end = moment.utc(formik.values.endDate);
    const diffInDays = end.diff(start, "days");
    formik.setFieldValue("duration", diffInDays);
  }, [formik.values.startDate, formik.values.endDate]);

  const handleBack = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_DESCRIPTION));
  };


  return (
    <>
      <HeaderContent />
      <div className="heightautoscroll contractradio">
        <form onSubmit={formik.handleSubmit} className="h-100">
          <div className="container h-100">
            <div className="row h-100 align-items-center">
              <div className="col-lg-6 d-lg-block d-none">
                <div
                  className={styles.registerLeft}
                >
                  <div className="col-lg-11 col-12 mx-auto text-center">
                    <figure>
                      <img src={choosetype} alt="" className="img-fluid w-100" />
                    </figure>
                  </div>

                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="row align-items-center justify-content-between">
                  <div
                    className={styles.fromGroup}
                    style={{ marginBottom: "30px" }}
                  >
                    {" "}
                    <div className="topTipHover">
                      <label className={styles.starlabel}>
                        Start Date
                      </label>{" "}
                      <i>
                        <img className="customeTolImg" src={tip} alt="" />
                      </i>
                      <Toltip />
                    </div>
                    <div className="position-relative">
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => onChange(date, "startDate")}
                        onBlur={formik.handleBlur}
                        className="form-control rounded-3 h-48"
                        placeholderText="StartDate"
                        minDate={new Date()}
                        dateFormat="MM-dd-yyyy"
                        ref={datePickerRef}
                      />
                      <span
                        className="position-absolute"
                        style={{ right: "15px", top: "50%", transform: "translateY(-50%)", cursor: "pointer" }}
                        onClick={handleIconClick}
                      >
                        <i>
                          <img src={calender} alt="calendar" />
                        </i>
                      </span>

                    </div>

                    {formik.touched.startDate &&
                      formik.errors.startDate ? (
                      <div className={styles.error}>
                        {formik.errors.startDate}
                      </div>
                    ) : null}
                  </div>

                  <div
                    className={styles.fromGroup}
                    style={{ marginBottom: "30px" }}
                  >
                    {" "}
                    <div className="topTipHover">
                      <label className={styles.starlabel}>
                        Estimated End Date
                      </label>{" "}
                      <i>
                        <img className="customeTolImg" src={tip} alt="" />
                      </i>
                      <Toltip />
                    </div>
                    <div className="position-relative">
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => onChange(date, "endDate")}
                        onBlur={formik.handleBlur}
                        className="form-control rounded-3 h-48"
                        placeholderText="EndDate"
                        minDate={startDate}
                        dateFormat="MM-dd-yyyy"
                        ref={datePickerRef}

                      />
                      <span
                        className="position-absolute"
                        style={{ right: "15px", top: "50%", transform: "translateY(-50%)", cursor: "pointer" }}
                        onClick={handleIconClick}
                      >
                        <i>
                          <img src={calender} alt="calendar" />
                        </i>
                      </span>
                    </div>
                    {formik.touched.endDate && formik.errors.endDate ? (
                      <div className={styles.error}>
                        {formik.errors.endDate}
                      </div>
                    ) : null}
                  </div>
                  <p className="fw-bold text-white">OR</p>

                  <div
                    className={styles.fromGroup}
                    style={{ marginBottom: "30px" }}
                  >
                    {" "}
                    <div className="topTipHover">
                      <label className={styles.starlabel}>
                        Duration in (Days)
                      </label>{" "}
                      <i>
                        <img className="customeTolImg" src={tip} alt="" />
                      </i>
                      <Toltip />
                    </div>
                    <input
                      type="number"
                      name="duration"
                      className="form-control rounded-3 h-48"
                      placeholder="duration"
                      value={duration}
                      maxLength={5} // This restricts the input to 5 digits
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.length <= 5) {
                          // Ensure the length does not exceed 5 digits
                          onChange(Number(value), "duration");
                        }
                      }}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.duration && formik.errors.duration ? (
                      <div className={styles.error}>
                        {formik.errors.duration}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="row align-items-center justify-content-between">
                  <div className="col-md-4 col-6 text-start my-3 btn-hover-effect">
                    <h6
                      onClick={() => handleBack()}
                      className="btn arrowyellow border-grey border-1 position-relative ps-5 backbutton mb-0"
                    >
                      <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                        <img src={blackarrow} alt="" />
                      </i>
                      <span className="px-lg-4 px-2 ps-3 text-12 fw-semibold d-block">
                        Back
                      </span>
                    </h6>
                  </div>

                  <div className="col-md-4 col-6">
                    <SaveAsDraftButton />
                  </div>
                  <div className="col-md-4 col-12 text-end my-3 btn-hover-effect">
                    <button
                      type="submit"
                      // onClick={handleGoToNextStep}
                      className="btn arrowblue border-grey border-1 position-relative pe-5 mb-0"
                    >
                      <span className="px-lg-4 px-2 pe-lg-3 text-12 fw-semibold d-block">
                        Next
                      </span>
                      <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                        <img src={whitearrow} alt="" />
                      </i>
                    </button>
                  </div>

                  {/* <button  > Next</button> */}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddDuration;
