import React, { useCallback, useEffect, useMemo, useState } from "react";
// import GoogleMapReact from "google-map-react";
// import styles from "../../common/css/styles/style.module.css";
import styles from "../../common/css/styles/style.module.css";
import { Modal, Button } from "react-bootstrap";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  InfoWindow,
} from "@react-google-maps/api";
import { JobResponse } from "../interface";
import moment from "moment";
import loc from "../../../assets/images/loc.png";
import skill from "../../../assets/images/top_skill.svg";
import bags2 from "../../../assets/images/bags.png";

const containerStyle = {
  width: "100%",
  height: "100vh",
};
const center = {
  lat: 37.7749,
  lng: -122.4194,
};

const googleAPIKey = process.env.REACT_APP_APIKEY as string;
interface GoogleMapsProps {
  users: JobResponse[];
  selectedUser: JobResponse | null; // Selected user prop
  onSelectUser: (user: JobResponse) => void;
}
const GoogleMaps = ({ users, onSelectUser, selectedUser }: GoogleMapsProps) => {
  // const googleMapKey = process.env.GOOGLE_MAPS_API_KEY;
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleAPIKey, // Replace with your API key
  });

  const [selectedMarker, setSelectedMarker] = useState<any | null>(null); // Store the clicked user

  const onLoad = useCallback(
    (map: any) => {
      const bounds = new window.google.maps.LatLngBounds();
      let hasValidCoordinates = false;
      console.log(`Total number of users: ${users.length}`); // Log number of users

      users.forEach((user) => {
        if (user.location && user.location.coordinates) {
          const [lng, lat] = user.location.coordinates;
          console.log(
            `User: ${user._id}, Coordinates: Latitude: ${lat}, Longitude: ${lng}`
          );
          bounds.extend({ lat, lng });
          hasValidCoordinates = true;
        } else {
          console.log(`User: ${user._id} does not have valid coordinates.`);
        }
      });

      if (hasValidCoordinates) {
        map.fitBounds(bounds); // Fit map bounds to show all markers
      } else {
        map.setCenter(center); // Fallback to default center
      }
      // if (users.length > 0 && users[0].location && users[0].location.coordinates) {
      //   setSelectedMarker(users[0]); // Highlight first marker
      // }
    },
    [users]
  );
  useEffect(() => {
    if (
      selectedUser &&
      selectedUser.location &&
      selectedUser.location.coordinates
    ) {
      const [lng, lat] = selectedUser.location.coordinates;
      // setSelectedMarker(selectedUser);
      // Optionally: you can center the map on the selected marker using `map.panTo({ lat, lng })`
    }
  }, [selectedUser]);
  const handleMarkerClick = (user: any) => {
    // alert("jsdflksfkl")
    console.log(`Selected user ID: ${user._id}`);
    setSelectedMarker(user); // Set the selected user to open the info window
    // onSelectUser(user); // Set the selected user
  };

  const handleCloseInfoWindow = () => {
    setSelectedMarker(null); // Clear the selected user when the info window is closed
  };

  if (!isLoaded) {
    return <div>Loading...</div>; // Show loading while the map is loading
  }

  return (
    <div className="col-lg-8">
      <div className={styles.RightOpportunity}>
        <div className={styles.ContarctOuter}>
          <div style={{ height: "100vh", width: "100%" }}>
            <GoogleMap
              mapContainerStyle={containerStyle}
              onLoad={onLoad}
              center={center}
              zoom={10}
            >
              {/* Place markers for each user */}
              {users?.map((user) => {
                const coordinates = user?.location?.coordinates;
                if (coordinates && coordinates.length === 2) {
                  const [lng, lat] = coordinates;
                  return (
                    <Marker
                      key={user._id}
                      position={{ lat, lng }}
                      onClick={() => handleMarkerClick(user)}
                      icon={
                        selectedUser?._id === user._id
                          ? "http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
                          : undefined
                      } // Highlight selected user marker
                    />
                  );
                }
                return null;
              })}

              {selectedMarker && (
                <InfoWindow
                  position={{
                    lat: selectedMarker?.location?.coordinates[1], // Latitude
                    lng: selectedMarker?.location?.coordinates[0], // Longitude
                  }}
                  onCloseClick={handleCloseInfoWindow}
                >
                
                    {/* <h6>{selectedMarker.workType}</h6>
                  <p><strong>Latitude:</strong> {selectedMarker.location.coordinates[1]}</p>
                  <p><strong>Longitude:</strong> {selectedMarker.location.coordinates[0]}</p>
                  <p><strong>Description:</strong> {selectedMarker.description}</p>
                  <p><strong>Address</strong> {selectedMarker.address}</p> */}
                    <div
                      className={styles.ContarctOuter}
                      style={{ cursor: "pointer" }}
                    >
                      <div className={styles.opprBox} style={{width: "350px"}}>
                        <div
                          className={`${styles.PendingCOnatctList} ${styles.active}`}
                          style={{padding: "20px 9px"}}
                        >
                          <h5 className="fw-400 text-14">#4567777</h5>
                          <h6>
                            {selectedMarker?.occupation?.name}{" "}
                            <span className={styles.boosted}>boosted</span>
                            <span className={styles.worktype}>
                              {selectedMarker?.workType}
                            </span>
                          </h6>
                          <div className="mt-3">
                            <p className={styles.Locate}>
                              <img src={loc} alt="" /> Company Name
                            </p>
                          </div>
                          <div className={styles.Loca}>
                            <p className={styles.Locate}>
                              <img src={loc} alt="" /> {selectedMarker?.address}
                            </p>
                          </div>
                          <p>
                            <span
                              className={`${styles.Hrrate}`}
                              style={{ marginBottom: "0" }}
                            >
                              {" "}
                              <img src={bags2} alt="" />
                              {selectedMarker?.planType}
                            </span>
                          </p>
                          <div className={styles.PenigSkil}>
                            <ul className="row align-items-center justify-content-center">
                              <li className="col-4 me-0 mb-4">
                                <h6 className="text-12 fw-400">Start Date</h6>
                                <p className="text-10 fw-400">
                                  {moment(
                                    selectedMarker?.times?.startDate
                                  ).format("MMM DD, YYYY")}
                                </p>
                              </li>
                              <li className="col-4 me-0 mb-4">
                                <h6 className="text-12 fw-400">Duration</h6>
                                <p className="text-10 fw-400">
                                  {moment(
                                    selectedMarker?.times?.plannedHoursPerDay
                                  ).format("MMM DD, YYYY")}
                                </p>
                              </li>
                              <li className="col-4 me-0 mb-4">
                                <h6 className="text-12 fw-400">
                                  Estimated End Date
                                </h6>
                                <p className="text-10 fw-400">
                                  {moment(
                                    selectedMarker?.times?.endDate
                                  ).format("MMM DD, YYYY")}
                                </p>
                              </li>
                              <li className="col-3 me-0">
                                <h6 className="text-12 fw-400"># of People</h6>
                                <p className="text-10 fw-400">10</p>
                              </li>

                              <li className="col-4 me-0">
                                <h6 className="text-12 fw-400">Shift</h6>
                                <p className="text-10 fw-400">Day</p>
                              </li>
                              <li className="col-5 me-0">
                                <h6 className="text-12 fw-400">
                                  Planned Hour Per Day
                                </h6>
                                <p className="text-10 fw-400">8 Hours</p>
                              </li>
                            </ul>
                          </div>
                          <div
                            className={`row align-items-center mb-3 ${styles.PenigSkilRate}`}
                          >
                            <div className="col-2 text-center">
                              <i>
                                <img
                                  src={skill}
                                  alt=""
                                  className="img-fluid w-100"
                                />
                              </i>
                            </div>
                            <div className="col-10 ps-0">
                              <h5 className="mb-0">Top Skill</h5>
                              <p className="text-14 mb-0">Autocad</p>
                            </div>
                          </div>
                          <div className={styles.PenigSkilRate}>
                            <h5>Pay Rates</h5>
                            <ul>
                              <li>
                                <span>Base</span>
                                <label>{selectedMarker?.rates?.baseRate}</label>
                              </li>
                              <li>
                                <span>Overtime</span>
                                <label>
                                  {selectedMarker?.rates?.overTimeRate}
                                </label>
                              </li>
                              <li>
                                <span>Sun/Holidays</span>
                                <label>
                                  {selectedMarker?.rates?.doubleTimeRate}
                                </label>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-12">
                  <div className={styles.bordeerBottom}></div>
                  <div className="row align-items-center">
                    <div className="col-6">
                    <button
                      type="button"
                      className={`btn w-100 px-3 ${styles.commonbtn} ${styles.applybtn}`}
                      // onClick={() => handleShowModal(user)}
                    >
                      View Contract
                    </button>
                    </div>
                    <div className="col-6">
                    <button
                      type="button"
                      className={`btn w-100 px-3 ${styles.commonbtn} ${styles.sharebtn}`}
                      // onClick={onViewFullContract}
                    >
                     Share
                    </button>
                  </div>
                  </div>
                </div>
                        </div>
                      </div>
                    </div>
                
                </InfoWindow>
              )}
            </GoogleMap>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleMaps;
