import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const workTypeSlice = createSlice({
    name: "workTypeData",
    initialState: {workType: ''},
    reducers: {
        setWorkType: (state, action: PayloadAction<any>) => {
            state.workType = action.payload
        }
    }
});

export const {setWorkType} = workTypeSlice.actions;
export default workTypeSlice.reducer;