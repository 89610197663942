import React from 'react';
import group from "../../assets/images/congratulations.png";
import whitearrow from "../../assets/images/whitearrow.svg";
import style from "../css/congratulationsModal.module.css";

const LoginSucessModal = () => {
    return (
        <div className="py-3" style={{background: "#DBE3EC"}}>
            <div className="text-center">
                <img src={group} alt="" />
                <h5 className="text-28 fw-semibold" >
                    Congratulations on your successful Sign Up!
                </h5>
                <div className={style.modal}>
                    <p className='text-18'>Welcome to Automate America!</p>
                </div>
            </div>
            <div className="col-6 text-end my-3 btn-hover-effect">
            <button
                // onClick={() => goToNextStep()}
                className="btn bg-white arrowblue border-white border-1 rounded-pill position-relative py-lg-3 py-2 pe-5"
            >
                <span className="px-lg-4 px-2 text-12 fw-semibold">
                Go to Feed
                </span>
                <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                <img src={whitearrow} alt="" />
                </i>
            </button>
            </div>
            {/* <div className={`${style.viewDashButtonStyle} row mt-4`}>
                <div className={`${style.viewDashButtonStyle}  text-center`}>
                    <button
                        className={`${style.viewDashButton} btn border btn-light rounded-pill primaryBtn submitBtn m-0`}
                        type="button"
                    onClick={handleGoToMoreDetails}
                    >
                        <span> View Dashboard </span>
                    </button>
                </div>
            </div> */}
        </div>
    )
}

export default LoginSucessModal;