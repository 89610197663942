import React, { useEffect, useState } from 'react'
import { validateJoinMember } from '../api'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { APP_ROUTES } from '../../../utils/routes/AppRoutes'
import { useDispatch } from 'react-redux'
import { setEmail, setOccupation, setUserData, setUserType } from '../../../redux/reducers/auth'

const index = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search); // location.search contains the query part of the URL
    const tokenFromUrl = queryParams.get('token');
        const [loading,setLoading]=useState(true);
        const dispatch=useDispatch()
    const navigate=useNavigate()
    useEffect(() => {
validateJoinMember(tokenFromUrl||"").then((res)=>{
    dispatch(setUserType({userType:res.data.userType,businessId:res.data.businessId,businessGroupId:res.data.businessGroupId}));
    dispatch(setUserData({firstName:res.data.firstName,lastName:res.data.lastName}))
    dispatch(setEmail({email:res.data.email}));
    //@ts-ignore
    dispatch(setOccupation({occupationName:res.data.primaryOccupation.label,occupation:[{name:res.data.primaryOccupation.label,_id:res.data.primaryOccupation.value}]}));
    navigate(`/${APP_ROUTES.SIGNUP}`);
}).catch(()=>{
    setLoading(false)
    
})
    }, [])
    
  return (<>
    {
        loading?<div className="spinner-grow text-primary" role="status">
        <span className="sr-only">Loading...</span>
        </div>: 
       <div style={{textAlign:"center"}} className="section">
       <h1 className="error">Error</h1>
       <div className="page">Ooops!!! The link is expired</div>
       <a className="back-home" onClick={()=>{navigate(`/${APP_ROUTES.SIGNUP}`)}} href="#!">Back to home</a>
     </div>
    }
    </>
  )
}

export default index