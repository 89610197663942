import React, { useEffect, useState } from 'react'
import Header from '../../../header/routes'
import info from "../../../../assets/images/tip.png"
import exportt from "../../../../assets/images/exportt.png"
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import styles from "../styles/styles/style.module.css"
import { changeTimesheetStatus, getTimesheet } from '../api'
import { TIMESHEET_OR_EXPENSE_TYPE } from '../../../../utils/enums/timesheetOrExpenseStatusTypes'
import moment from 'moment'
import Confirm from '../../Contracts/OpenContracts/modals/Confirm'
import { toast } from 'react-toastify'
import { APP_ROUTES } from '../../../../utils/routes/AppRoutes'
import Toltip from '../../toltip/page'


const TimesheetTable = () => {
    const { id } = useParams();
    const navigate = useNavigate()
    const location = useLocation();
    const ongoingContractsDetails = location?.state?.workdata ? location?.state?.workdata : location?.state?.currentOngoingData
    const [currentOngoingData, setCurrentOngoingData] = useState<any>([])
    const [approvedTimesheetData, setApprovedTimeSheetData] = useState<any>();
    const [pendingTimesheetData, setPendingTimeSheetData] = useState<any>();
    const [loader, setLoader] = useState<boolean>(true);
    const [confirmModal, setConfirmModal] = useState<boolean>(false);
    const [selectedStatus, setSelectedStatus] = useState<string>("");
    const [selectedId, setSelectedId] = useState<string>("");

    const getApprovedData = async () => {
        const response = await getTimesheet(id, TIMESHEET_OR_EXPENSE_TYPE.APPROVED)
        setApprovedTimeSheetData(response?.data)
        setLoader(false);
    };
    const getPendingdata = async () => {
        const response = await getTimesheet(id, TIMESHEET_OR_EXPENSE_TYPE.PENDING)
        setPendingTimeSheetData(response?.data)
        setLoader(false);
    };
    useEffect(() => {
        getApprovedData();
        getPendingdata();
    }, [])

    useEffect(() => {
        setCurrentOngoingData(ongoingContractsDetails)
    }, []);

    const handleViewStatusDetails = (timesheetId: string) => {
        navigate(`${APP_ROUTES.TIMESHEET_DETAILS}/${timesheetId}`, {
            state: { currentOngoingData },
        });
    };

    const handleApproveTimesheet = (status: string, id: string) => {
        setConfirmModal(true);
        setSelectedStatus(status)
        setSelectedId(id)
    }
    const handleRejectTimesheet = (status: string, id: string) => {
        setConfirmModal(true);
        setSelectedStatus(status);
        setSelectedId(id)
    }
    const handleClose = () => {
        setConfirmModal(false);
    };
    const changeStatus = () => {
        const payload = {
            selectedId: selectedId,
            selectedStatus: selectedStatus
        }
        toast.promise(
            changeTimesheetStatus(payload.selectedId, payload.selectedStatus),
            {
                pending: {
                    render() {
                        return "Changing Status";
                    },
                },
                success: {
                    render({ }) {
                        handleClose();
                        getApprovedData();
                        getPendingdata();
                        return "Status Changed Successfully";
                    },
                },
                error: {
                    render(error) {
                        return "Status Change Failed";
                    },
                },
            }
        );
    };
    return (
        <>
            <Header />
            <div className={styles.TimeShettTable}>
                <Confirm
                    action="Select"
                    handleClose={handleClose}
                    showModal={confirmModal}
                    changeStatus={changeStatus}
                    status={selectedStatus}
                />
                <div className='container'>
                    <h3>Timesheet Table</h3>
                    {loader ? <div className="spinner-grow text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div> :
                        <>
                            <div className={styles.TimeShettTableOuter}>
                                <div className={styles.TimeShettTableHead}>
                                    <div className="col-6 topTipHover">
                                        <div className="d-flex align-items-center w-100">
                                            <h4 className="form-check-label text-20 fw-bold mb-0 font-inter">
                                            Timesheet Approved 
                                            </h4>
                                            <i className='me-2'>
                                                <img className="customeTolImg" src={info} alt="" />
                                            </i>
                                            <Toltip
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                    {/* <h4> <img src={info} alt="" /></h4> */}
                                    <button className='btn bg-white text-14 fw-bold font-inter d-flex align-items-center w-auto float-end'><i className='me-2'><img src={exportt} alt="" /></i> Export Data</button>
                                </div>
                                </div>
                                <div className="table-responsive">
                                <table className="table align-middle">
                                    <thead>
                                        <tr>
                                            <th>WR#</th>
                                            <th>PROFESSIONAL NAME</th>
                                            <th>LOCATION</th>
                                            <th>START DATE</th>
                                            <th>END DATE</th>
                                            <th>APPROVED DATE</th>
                                            <th>TOTAL AMOUNT</th>
                                            <th>ACTIONS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            {approvedTimesheetData?.length > 0 ? approvedTimesheetData?.map((data: any, idx: number) => {
                                                return (
                                                    <>
                                                        <td key={idx}>#{data?.work?.workNumber}</td>
                                                        <td>{data?.user?.name}</td>
                                                        <td>{data?.work?.workNumber}</td>
                                                        <td>{moment(data.startDate).format("MMM DD, YYYY")}</td>
                                                        <td >{moment(data.endDate).format("MMM DD, YYYY")}</td>
                                                        <td >{moment(data.approvedDate).format("MMM DD, YYYY")}</td>
                                                        <td>${data?.totalAmount}</td>
                                                        <td>
                                                        <button onClick={() => handleViewStatusDetails(data?._id)} className='btn  bg-lightblue border-blue rounded-pill text-blue text-12 w-auto fw-bold'>View Details</button>
                                                        </td>
                                                
                                                    </>
                                                )
                                            }) : <td colSpan={12} className='text-center w-100 text-12'>No Timesheets Found</td>}

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            </div>
                            <div className={styles.TimeShettTableOuter}>
                                <div className={styles.TimeShettTableHead}>
                                <div className="col-6 topTipHover">
                                <div className=" d-flex align-items-center w-100">
                                        <h4 className="form-check-label text-20 fw-bold mb-0 font-inter">
                                        Timesheet Pending for approval
                                        </h4>
                                        <i>
                                            <img className="customeTolImg" src={info} alt="" />
                                        </i>
                                        <Toltip
                                        />
                                    </div>
                                    </div>
                                    <div className="col-6">
                                    {/* <h4> <img src={info} alt="" /></h4> */}
                                    <button className='btn bg-white text-14 fw-bold font-inter d-flex align-items-center float-end'><i className='me-2'><img src={exportt} alt="" /></i>Export Data</button>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                <table className="table align-middle">
                                    <thead>
                                    <tr>
                                        <th>WR#</th>
                                        <th>PROFESSIONAL NAME</th>
                                        <th>LOCATION</th>
                                        <th>START DATE</th>
                                        <th>END DATE</th>
                                        <th>APPROVED DATE</th>
                                        <th>TOTAL AMOUNT</th>
                                        <th>View Details</th>
                                        <th>ACTIONS</th>
                                    </tr>
</thead>
<tbody>
                                    <tr>
                                        {pendingTimesheetData?.length > 0 ? pendingTimesheetData?.map((data: any, idx: number) => {
                                            return (
                                                <>
                                                    <td key={idx}>#{data?.work?.workNumber}</td>
                                                    <td>{data?.user?.name}</td>
                                                    <td>{data?.work?.workNumber}</td>
                                                    <td>{moment(data.startDate).format("MMM DD, YYYY")}</td>
                                                    <td >{moment(data.endDate).format("MMM DD, YYYY")}</td>

                                                    <td >{moment(data.approvedDate).format("MMM DD, YYYY")}</td>
                                                    <td>${data?.totalAmount}</td>
                                                    <td>
                                                        <button onClick={() => handleViewStatusDetails(data?._id)} className='btn  bg-lightblue border-blue rounded-pill text-blue text-12 w-auto fw-bold'>View Details</button>
                                                    </td>
                                                    <td>
                                                        <button onClick={() => handleApproveTimesheet(TIMESHEET_OR_EXPENSE_TYPE.APPROVED, data._id)}  className='btn bg-blue rounded-pill text-white text-12 w-auto fw-normal me-2'> Approve </button>
                                                        {/* <button onClick={() => handleRejectTimesheet(TIMESHEET_OR_EXPENSE_TYPE.REJECT, data._id)}  className='btn bg-danger rounded-pill text-white text-12 w-auto fw-normal'> Reject </button> */}
                                                    </td>

                                                </>
                                            )
                                        }) : <td colSpan={12} className='text-center w-100 text-12'>No Timesheets Found</td>}
                                    </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </>
                    }

                </div>
            </div>
        </>

    )
}

export default TimesheetTable