import React, { useEffect, useState } from "react";
import PersonalInfo from "../modals/PersonalInfo";
import { deactivateAccount } from "../../home/api";
import Swal from "sweetalert2";
import trash from "../../../assets/images/trash.png";
import eyehidden from "../../../assets/images/eye_hidden.png";
import avatar from "../../../assets/images/Avatardummy.png";
import penbl from "../../../assets/images/penbl.png";
import styles from "../styles/style.module.css";
import { PersonalDetails, PersonalInfoProps } from "../interface";

const PersonalInfoUser: React.FC<PersonalInfoProps> = ({
  refresh,
  firstName,
  languages,
  lastName,
  email,
  phoneNumber,
  address,
  profilePicture,
  workStatus,
  occupationDetails,
  countoryDetails,
}) => {
  const [modal1, setModal1] = useState<boolean>(false);
  const [modal2, setModal2] = useState<boolean>(false);
  const [modal3, setModal3] = useState<boolean>(false);
  const [data] = useState<PersonalDetails>({
    firstName,
    languages,
    lastName,
    workStatus,
    email,
    phoneNumber,
    address,
    profilePicture,
    countoryDetails,
  });
  console.log(data,"data")

  const openModal1 = () => {
    setModal3(false);
    setModal2(false);
    setTimeout(() => {
      setModal1(true);
    }, 0);
  };

  const openModal2 = () => {
    setModal3(false);
    setModal1(false);
    setTimeout(() => {
      setModal2(true);
    }, 0);
  };

  const openModal3 = () => {
    // setModal3(true);
    // console.log(modal3, "madal33331");
    // setModal1(false);
    // setModal2(false);

    setModal3(false);
    setModal1(false);
    setTimeout(() => {
      setModal3(true);
    }, 0);

    console.log(modal3, "madal33332");
  };
  const closeModal1 = () => {
    setModal1(false);
    setModal2(false);
    setModal3(false);
  };

  const closeModal2 = () => {
    setModal3(false);
    setModal2(false);
    setModal1(true);
  };

  const closeModal3 = () => {
    setModal3(false);
    setModal1(false);
    setModal2(true);
  };

  const handleDeactivateAccount = async () => {
    const payload = {
      status: false,
    };
    console.log(payload);

    const initialResult = await Swal.fire({
      title: "Are you sure?",
      text: "Are you sure to deactivate your account?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Deactivate!",
      cancelButtonText: "No, cancel",
    });
    if (initialResult.isConfirmed) {
      try {
        await deactivateAccount(payload);
        await Swal.fire({
          title: "Deactivation Done",
          text: "You can activate your account anytime",
          icon: "success",
          confirmButtonText: "OK",
        });
        refresh();
      } catch (err) {
        console.log("Error occuring while deactivating account", err);
      }
    }
  };

  return (
    <div className={styles.ProfileEdit}>
      <div className="row align-items-center mx-0 mt-4">
        <div className="col-2 text-center pe-0">
          <div className={styles.ProfileEditHead}>
            <img
              src={profilePicture ? profilePicture : avatar}
              className="img-fluid rounded-circle"
              alt=""
              style={{width: "100px", height: "100px"}}
            />
          </div>
        </div>
        <div className="col-9">
          <div className="ps-2">
            <h4 className="text-22 fw-semibold text-capitalize">
              {`${firstName} ${lastName}`}
            </h4>
            <p className="text-14 mb-0">
              Primary Occupations :{" "}
              {occupationDetails?.map((data, ind) => (
                <span key={ind}>{data?.name} </span>
              ))}
              <span className="badge bg-lightgreen text-medium py-1 text-11 px-4 rounded-pill">
                {workStatus == true ? "Available" : "Not Available"}
              </span>
              <span className="text-11 ps-2">For one week</span>
            </p>
          </div>
        </div>
        <div className="col-1 text-end">
          <i>
            <img
              src={penbl}
              alt=""
              className="cursor-pointer ms-auto"
              onClick={() => openModal1()}
            />
          </i>
        </div>
      </div>
      <div className="px-3">
        <h6 className="text-18 fw-semibold">Personal Information</h6>
        <div className={styles.personalinfo}>
          <ul className="row align-items-center">
            <li className="col-xl-4 col-md-6 col-12">
              <span className="">First Name</span>
              <label className="clamp clamp1">{firstName}</label>
            </li>
            <li className="col-xl-4 col-md-6 col-12">
              <span className="">Last Name</span>
              <label className="clamp clamp1">{lastName}</label>
            </li>
            <li className="col-xl-4 col-md-6 col-12">
              <span>Email Address</span>
              <label className="clamp clamp1">{email}</label>
            </li>
            <li className="col-xl-4 col-md-6 col-12">
              <span>Phone Number</span>
              <label className="clamp clamp1">{phoneNumber}</label>
            </li>
            <li className="col-xl-4 col-md-6 col-12">
              <span>Citizenship</span>
              <label className="clamp clamp1">{countoryDetails?.name}</label>
            </li>
            <li className="col-xl-4 col-md-6 col-12">
              <span>Address</span>
              <label className="clamp clamp1">{address}</label>
            </li>
            <li className="col-xl-4 col-md-6 col-12">
              <span>Account Type</span>
              <label className="clamp clamp1">Public</label>
            </li>
            <li className="col-xl-4 col-md-6 col-12">
              <span>Spoken Languages</span>
              <label className="clamp clamp1">English</label>
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.deletAcount}>
        <ul className="col-xl-12 row align-items-center">
          <li className="col-xl-4 col-6">
            <button
              className="border-0 bg-transparent d-flex align-items-center"
              onClick={handleDeactivateAccount}
            >
              <i
                className="me-2 d-flex"
                style={{ verticalAlign: "text-bottom" }}
              >
                <img src={trash} alt="" />
              </i>
              <span className="text-danger text-18">Deactivate Account</span>
            </button>
          </li>
          <li className="col-xl-4 col-6">
            <button className="border-0 bg-transparent d-flex align-items-center">
              <i className="me-2 d-flex">
                <img src={eyehidden} alt="" />
              </i>
              <span className="text-danger text-18">Hide Account</span>
            </button>
          </li>
        </ul>
      </div>

      <PersonalInfo
        openModal2={openModal2}
        openModal3={openModal3}
        closeModal1={closeModal1}
        closeModal2={closeModal2}
        closeModal3={closeModal3}
        refresh={refresh}
        modal1={modal1}
        modal2={modal2}
        modal3={modal3}
        title="Edit Personal Informations"
        data={data}
      />
    </div>
  );
};

export default PersonalInfoUser;
