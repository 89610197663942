import React from "react";
import info from "../../../assets/images/info_icon.svg";
import copy from "../../../assets/images/copy.svg";
import profile_picture from "../../../assets/images/profile_picture.png";
import postthumb from "../../../assets/images/post_thumb.png";
import sendbtn from "../../../assets/images/sendbtn.svg";
import bag from "../../../assets/images/bag.png";
import bluecircle from "../../../assets/images/bluecircle.svg";
import share from "../../../assets/images/share.png";
import pluscircle from "../../../assets/images/plus_circle.svg";
import stras from "../../../assets/images/stras.png";
import pro from "../../../assets/images/pro.png";
import ltarow from "../../../assets/images/ltarow.png";
import loc from "../../../assets/images/loc.png";
import rate from "../../../assets/images/rate.png";
import skshare from "../../../assets/images/skshare.png";
import chatdot from "../../../assets/images/chatdot.png";
import exper from "../../../assets/images/exper.png";
import Header from "../../header/routes";
import styles from "../styles/style.module.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import Toltip from "../../common/toltip/page";

const ViewProfile = () => {
  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 3.2,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3.2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className={`bg-white p-4 shadow rounded-3 mt-3`}>
      {/* <Header /> */}
      <div className={styles.viewProiflePage}>
        <div className="">
          {/* <div className="row">
           <div className="col-md-7">
           
            </div> 
             <div className="col-md-5 mt-4">
              <div className={styles.personalHead}>
                <div className={styles.personalName}>
                  <h4>Personal Information</h4>
                  <div className={styles.editBns}>
                    <span>
                      {" "}
                      <img src={pen} alt="" />
                    </span>
                    <span>
                      {" "}
                      <img src={pls} alt="" />
                    </span>
                  </div>
                </div>
                <p>
                  This will not be seen by anyone other than Automate America
                  Admin.
                </p>
                <div className={styles.personlDetal}>
                  <span>Email</span>
                  <label>Vinta.k@codebrewinnovations.com</label>
                </div>
                <div className={styles.personlDetal}>
                  <span>Phone Number</span>
                  <label>+91 (780) 733-0000</label>
                </div>
              </div>
              <div className={styles.personalHead}>
                <div className={styles.personalName}>
                  <h4>Secure Files</h4>
                  <div className={styles.editBns}>
                    <span>
                      {" "}
                      <img src={pen} alt="" />
                    </span>
                    <span>
                      {" "}
                      <img src={pls} alt="" />
                    </span>
                  </div>
                </div>
                <p>
                  Personal secure files will not be seen by anyone other than
                  Automate America Admin.
                </p>
                <ul className={styles.secureFiles}>
                  <li>
                    <div className={styles.ouetDoc}>
                      <div className={styles.smdoc}>
                        <img src={smdoc} alt="" />
                        <span>Resume</span>
                      </div>

                      <label>(Download sample)</label>
                    </div>
                    <button>
                      <img src={rcupload} alt="" />
                    </button>
                  </li>
                  <li>
                    <div className={styles.ouetDoc}>
                      <div className={styles.smdoc}>
                        <img src={smdoc} alt="" />
                        <span>Resume</span>
                      </div>
                      <label>(Download sample)</label>
                    </div>
                    <button>
                      <img src={rcupload} alt="" />
                    </button>
                  </li>
                  <li>
                    <div className={styles.ouetDoc}>
                      <div className={styles.smdoc}>
                        <img src={smdoc} alt="" />
                        <span>Resume</span>
                      </div>
                      <label>(Download sample)</label>
                    </div>
                    <button>
                      <img src={rcupload} alt="" />
                    </button>
                  </li>
                  <li>
                    <div className={styles.ouetDoc}>
                      <div className={styles.smdoc}>
                        <img src={smdoc} alt="" />
                        <span>Resume</span>
                      </div>
                      <label>(Download sample)</label>
                    </div>
                    <button>
                      <img src={rcupload} alt="" />
                    </button>
                  </li>
                </ul>
              </div>

              <div className={styles.personalHead}>
                <div className={styles.personalName}>
                  <h4>Secure Files</h4>
                  <div className={styles.editBns}>
                    <span>
                      {" "}
                      <img src={pen} alt="" />
                    </span>
                    <span>
                      {" "}
                      <img src={pls} alt="" />
                    </span>
                  </div>
                </div>

                <ul className={styles.bankInformation}>
                  <li>
                    <span>
                      Bank Name : <b>ICICI Bank</b>
                    </span>
                  </li>
                  <li>
                    <span>
                      Routing : <b>0887 28799 682888</b>
                    </span>
                  </li>
                  <li>
                    <span>
                      Account Name : <b>Johns Yami</b>
                    </span>
                  </li>
                </ul>
              </div>
              <div className={styles.personalHead}>
                <div className={styles.personalName}>
                  <h4>Secure Files</h4>
                  <div className={styles.editBns}>
                    <span>
                      {" "}
                      <img src={pen} alt="" />
                    </span>
                    <span>
                      {" "}
                      <img src={pls} alt="" />
                    </span>
                  </div>
                </div>

                <ul className={styles.Emergency}>
                  <li>+91 (780) 733-0000</li>
                </ul>
              </div>

              <div className={styles.personalHead}>
                <div className={styles.personalName}>
                  <h4>Secure Files</h4>
                  <div className={styles.editBns}>
                    <span>
                      {" "}
                      <img src={pen} alt="" />
                    </span>
                    <span>
                      {" "}
                      <img src={pls} alt="" />
                    </span>
                  </div>
                </div>

                <ul className={styles.LanguageFlag}>
                  <li>
                    <img src={flgone} alt="" />
                    Spanish
                  </li>
                  <li>
                    <img src={flgone} alt="" />
                    English
                  </li>
                  <li>
                    <img src={flgone} alt="" />
                    Arabic
                  </li>
                </ul>
              </div>
            </div> 
          </div> */}
          <div className={styles.viewProfileLeft}>
          <div className="d-flex align-items-center mb-2">
                <h4 className="form-check-label text-20 fw-bold mb-0 font-inter">
                Profile
                </h4>
                <i>
                  <img className="customeTolImg" src={info} alt="" />
                </i>
                <Toltip />
              </div>
            {/* <h3>
               <i><img src={info} alt="" />{" "}</i>
            </h3> */}
            <div className={styles.profileHead}>
              {/* <div className={styles.profileCover}>
                <img src={profileCover} alt="" />
                <span>
                  {" "}
                  <img src={pen} alt="" />
                </span>
              </div> */}
              <div className={styles.profileName}>
                <div className={styles.prfoleHead}>
                  <img src={profile_picture} alt="" />
                  <div className="form-check form-switch">
                    <div className={styles.leftContract}>
                      <button className={styles.CoptText}>
                        <i className="far fa-copy"></i> Copy
                      </button>
                      <img src={share} alt="" />
                    </div>
                  </div>
                </div>
                <h4>
                  Nick Kroll <span></span>
                </h4>
                <p className="">
                  UI/UX Designer/ ( Figma Artist || Data Visualization Expert ||
                  Keynote || Mapbox || Management )
                </p>
                <div className={styles.location}>
                  <span className="text-14 opacity-75">
                    <img src={loc} alt="" />
                    New York City, America{" "}
                  </span>
                </div>
                <p className="mb-1">
                  Reviews : 200{" "}
                  <span>
                    <img src={rate} alt="" />
                    4.5
                  </span>
                </p>
                <p className="mb-1">Member Since : 12 Jan 1998</p>

                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  luctus, augue eget scelerisque efficitur.
                </p>

                <div className={styles.PayRate}>
                  <h5>Pay Rates</h5>
                  <ul>
                    <li>
                      <label>Base</label>
                      <span>$85</span>
                    </li>
                    <li>
                      <label>Overtime</label>
                      <span>$150</span>
                    </li>
                    <li>
                      <label>Sun/Holidays</label>
                      <span>$200</span>
                    </li>
                  </ul>
                </div>
                <div className={styles.connectBtns} style={{ display: "flex" }}>
                  <div className="col-8">
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "25px" }}
                    >
                      <button className="btn btn-primary d-block w-auto">
                        + Connect
                      </button>
                      <button className="btn avail-btn">Available</button>
                      <img src={chatdot} alt="" />
                    </div>
                  </div>
                  {/* <div className="col-4">
                    <button className="btn btn-primary d-block w-auto ms-auto">
                      + Add a post
                    </button>
                  </div> */}
                </div>
                {/* <div className={styles.BostProfile}>
                  <button>Boost Profile</button>
                  <ul>
                    <li>
                      <span>Share :</span>
                    </li>
                    <li>
                      <a href="#">
                        <img src={socone} alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src={soctwo} alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src={socthree} alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src={socfour} alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src={socfive} alt="" />
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div className={styles.skillset}>
              <h4 className="text-20 fw-semibold">Top Skill</h4>
              <div className="ProTags">
                <ul>
                  <li>
                    <span>Figma</span>
                  </li>
                  <li>
                    <span>Figma</span>
                  </li>
                  <li>
                    <span>Figma</span>
                  </li>
                </ul>
              </div>
              <div className="selfRating">
                <span>Self Rating :</span>
                <ul>
                  <li className="active">
                    <span></span>
                  </li>
                  <li className="active">
                    <span></span>
                  </li>
                  <li className="active">
                    <span></span>
                  </li>
                  <li>
                    <span></span>
                  </li>
                  <li>
                    <span></span>
                  </li>
                </ul>
              </div>
              <h6>About</h6>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                luctus, augue eget scelerisque efficitur.Lorem ipsum dolor sit
                amet, consectetur adipiscing elit. Sed luctus, augue eget
                scelerisque efficitur.Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. Sed luctus, augue eget scelerisque
                efficitur.Lorem ipsum dolor sit amet, consectetur adipiscing
                elit. Sed luctus, augue eget scelerisque efficitur.Lorem ipsum
                dolor sit amet, consectetur adipiscing elit. Sed luctus, augue
                eget scelerisque efficitur.Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Sed luctus, augue eget scelerisque
                efficitur.
              </p>
            </div>
            <div className={styles.skillset}>
              <h4 className="text-20 fw-semibold">About</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                luctus, augue eget scelerisque efficitur.Lorem ipsum dolor sit
                amet, consectetur adipiscing elit. Sed luctus, augue eget
                scelerisque efficitur.Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. Sed luctus, augue eget scelerisque
                efficitur.Lorem ipsum dolor sit amet, consectetur adipiscing
                elit. Sed luctus, augue eget scelerisque efficitur.Lorem ipsum
                dolor sit amet, consectetur adipiscing elit. Sed luctus, augue
                eget scelerisque efficitur.Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Sed luctus, augue eget scelerisque
                efficitur.
              </p>
            </div>

            <div className={styles.reviewSection}>
              <h3>
                Reviews <img src={info} alt="" />
              </h3>
              {/* <div className={`${styles.reviwSection} ${styles.noborder}`}>
                <div className={styles.revieStar}>
                  <span>
                    <img src={pro} alt="" />
                  </span>
                  <div className="">
                    <h4>Legacy Industries | Robot Programmer - Jimmy G</h4>
                    <img src={stras} alt="" />
                  </div>
                </div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Sed dapibus eros eu vehicula interdum. Cras nec ultricies
                  massa. Curabitur rutrum,{" "}
                </p>
              </div> */}

              {/* <div className={styles.textReview}>
                <span>
                  <img src={pro} alt="" />
                </span>
                <div className={styles.formGroup}>
                  <input type="text" placeholder="Write a reply..." />
                  <label>
                    <img src={send} alt="" />
                  </label>
                </div>
              </div> */}
              <div className={styles.reviwSection}>
                <div className={styles.revieStar}>
                  <span>
                    <img src={pro} alt="" />
                  </span>
                  <div className="">
                    <h4>Legacy Industries | Robot Programmer - Jimmy G</h4>
                    <img src={stras} alt="" />
                  </div>
                  <div className={styles.reply}>
                    <a href="#">
                      <img src={ltarow} alt="" />
                      Reply
                    </a>
                  </div>
                </div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  dapibus eros eu vehicula interdum. Cras nec ultricies massa.
                  Curabitur rutrum,{" "}
                </p>
                <div className="d-flex align-items-center mt-3">
                  {/* <div className="col-1 text-center">
                    
                  </div> */}
                  
                  <div className="w-100 d-flex align-items-center position-relative">
                  <i className="me-2">
                      <img
                        src={profile_picture}
                        alt=""
                        className="rounded-circle"
                        width={30}
                        height={30}
                      />
                    </i>
                    <input
                      type="text"
                      className="form-control w-100 rounded-pill pe-5"
                      placeholder="Write a reply..."
                      style={{ background: "#eee", height: "42px" }}
                    />
                    <div
                      className="position-absolute"
                      style={{
                        transform: "translateY(-50%)",
                        right: "15px",
                        top: "50%",
                      }}
                    >
                      <i>
                        <img
                          src={sendbtn}
                          alt=""
                          width={20}
                          height={20}
                          className=""
                        />
                      </i>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.reviwSection}>
                <div className={styles.revieStar}>
                  <span>
                    <img src={pro} alt="" />
                  </span>
                  <div className="">
                    <h4>Legacy Industries | Robot Programmer - Jimmy G</h4>
                    <img src={stras} alt="" />
                  </div>
                  <div className={styles.reply}>
                    <a href="#">
                      <img src={ltarow} alt="" />
                      Reply
                    </a>
                  </div>
                </div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  dapibus eros eu vehicula interdum. Cras nec ultricies massa.
                  Curabitur rutrum,{" "}
                </p>
              </div>
              <div className={styles.reviwSection}>
                <div className={styles.revieStar}>
                  <span>
                    <img src={pro} alt="" />
                  </span>
                  <div className="">
                    <h4>Legacy Industries | Robot Programmer - Jimmy G</h4>
                    <img src={stras} alt="" />
                  </div>
                  <div className={styles.reply}>
                    <a href="#">
                      <img src={ltarow} alt="" />
                      Reply
                    </a>
                  </div>
                </div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  dapibus eros eu vehicula interdum. Cras nec ultricies massa.
                  Curabitur rutrum,{" "}
                </p>
              </div>
              <div className={styles.reviwSection}>
                <div className={styles.revieStar}>
                  <span>
                    <img src={pro} alt="" />
                  </span>
                  <div className="">
                    <h4>Legacy Industries | Robot Programmer - Jimmy G</h4>
                    <img src={stras} alt="" />
                  </div>
                  <div className={styles.reply}>
                    <a href="#">
                      <img src={ltarow} alt="" />
                      Reply
                    </a>
                  </div>
                </div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  dapibus eros eu vehicula interdum. Cras nec ultricies massa.
                  Curabitur rutrum,{" "}
                </p>
              </div>

              <div className={styles.loadMore}>
                <a href="#">See all reviews</a>
              </div>
            </div>

            <div className={styles.skillset}>
              <h3>
              Endorsements <img src={pluscircle} alt="" />
              </h3>
              <div className={styles.ebdrotabs}>
                <ul>
                  <li className={styles.active}>
                    <span>Received</span>
                  </li>
                  <li>
                    <span>Given</span>
                  </li>
                </ul>
              </div>
              <div className={styles.endroProfile}>
                <span>
                  {" "}
                  <img src={profile_picture} alt="" />
                </span>
                <div>
                  <h5>Nick Kroll</h5>
                  <h6>UI/UX Designer</h6>
                  <span>2 months ago</span>
                </div>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                dapibus eros eu vehicula interdum. Cras nec ultricies massa.
                Curabitur rutrum, diam id consequat consequat. Lorem ipsum dolor
                sit amet, consectetur adipiscing elit. Sed dapibus eros eu
                vehicula interdum. Cras nec ultricies massa. Curabitur rutrum,
                diam id consequat consequat. Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Sed dapibus eros eu vehicula
                interdum. Cras nec ultricies massa. Curabitur rutrum, diam id
                consequat consequat. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. Sed dapibus eros eu vehicula interdum. Cras nec
                ultricies massa. Curabitur rutrum, diam id consequat
                consequat.Lorem ipsum dolor sit amet, consectetur adipiscing
                elit. Sed dapibus eros eu vehicula interdum. Cras nec ultricies
                massa. Curabitur rutrum, diam id consequat consequat. Lorem
                ipsum dolor sit amet, consectetur adipiscing elit. Sed dapibus
                eros eu vehicula interdum. Cras nec ultricies massa. Curabitur
                rutrum, diam id consequat consequat.
              </p>
              <div className={styles.loadMore}>
                <a href="#">See all reviews</a>
              </div>
            </div>
            <div className={styles.skillset}>
              <div className={styles.skilhead}>
                <h3>
                  Technical Skills
                  {/* <img src={info} alt="" /> */}
                </h3>
              </div>
              <ul>
                <li>
                  <span>Lorem Ipsum</span>
                </li>
                <li>
                  <span>Figma</span>
                </li>
                <li>
                  <span>Adobe</span>
                </li>
                <li>
                  <span>Lorem Ipsum</span>
                </li>
                <li>
                  <span>Figma</span>
                </li>
              </ul>
            </div>
          </div>

          <div className={styles.skillset}>
            <div className={styles.skilhead}>
              <h3>
                Occupation
                {/* Professional Experience <img src={info} alt="" /> */}
              </h3>
              {/* <div className={styles.skilladd}>
                <span>
                  {" "}
                  <img src={pen} alt="" />
                </span>
                <span>
                  {" "}
                  <img src={pls} alt="" />
                </span>
                <span>
                  {" "}
                  <img src={skshare} alt="" />
                </span>
              </div> */}
            </div>
            <div className={styles.experset}>
              <span>
                {" "}
                <img src={exper} alt="" />
              </span>
              <div>
                <h4 className="fw-semibold">Self Employed-Freelancer</h4>
                <h5>Lorem ipsum Lorem ipsum</h5>
                <h5>
                  4 Years 2 months
                  <span className="text-blue">(Mar 2020 - Present)</span>
                </h5>
                <p>
                  In this role dedicated to the control and automation of
                  automotive and industrial processes. My responsibilities
                  include integrating new process stations or lines, supporting
                  start-ups, designing, and debugging PLC programs in Allen
                  Bradley, and ensuring the correct placement of auto-parts
                  validated by cameras, sensors, and scanning. I have also
                  worked on safety task logic and collaborated closely with
                  customers in Mexico and the US, including notable names like
                  Plastic Omnium, Volkswagen, Mercedes Benz, Nissan, and
                  Symbotic at a Walmart DC Warehouse, among others.
                </p>
              </div>
            </div>
            <div className={styles.experset}>
              <span>
                {" "}
                <img src={exper} alt="" />
              </span>
              <div>
                <h4>Self Employed-Freelancer</h4>
                <h5>
                  4 Years 2 months
                  <span className="text-blue">(Mar 2020 - Present)</span>
                </h5>
                <p>
                  In this role dedicated to the control and automation of
                  automotive and industrial processes. My responsibilities
                  include integrating new process stations or lines, supporting
                  start-ups, designing, and debugging PLC programs in Allen
                  Bradley, and ensuring the correct placement of auto-parts
                  validated by cameras, sensors, and scanning. I have also
                  worked on safety task logic and collaborated closely with
                  customers in Mexico and the US, including notable names like
                  Plastic Omnium, Volkswagen, Mercedes Benz, Nissan, and
                  Symbotic at a Walmart DC Warehouse, among others.
                </p>
              </div>
            </div>
          </div>

          <div className={styles.skillset}>
            {/* <div className={styles.skilhead}>
              <h3>
                Company Detail <img src={info} alt="" />
              </h3>
              <div className={styles.skilladd}>
                <span>
                  {" "}
                  <img src={pen} alt="" />
                </span>
                <span>
                  {" "}
                  <img src={pls} alt="" />
                </span>
                <span>
                  {" "}
                  <img src={skshare} alt="" />
                </span>
              </div>
            </div> */}
            {/* <div className={styles.experset}>
              <span>
                {" "}
                <img src={exper} alt="" />
              </span>
              <div className={styles.compDetails}>
                <h4>Self Employed-Freelancer</h4>
                <h5>4 Years 2 months (Mar 2020 - Present)</h5>
                <p>
                  In this role dedicated to the control and automation of
                  automotive and industrial processes. My responsibilities
                  include integrating new process stations or lines, supporting
                  start-ups, designing, and debugging PLC programs in Allen
                  Bradley, and ensuring the correct placement of auto-parts
                  validated by cameras, sensors, and scanning. I have also
                  worked on safety task logic and collaborated closely with
                  customers in Mexico and the US, including notable names like
                  Plastic Omnium, Volkswagen, Mercedes Benz, Nissan, and
                  Symbotic at a Walmart DC Warehouse, among others.
                </p>
                <div className={styles.compDeteails}>
                  <div>
                    <p>Address : </p>
                    <p>
                      456 Park Avenue <br />
                      New York NY 10022 <br /> www.companyname.com{" "}
                    </p>
                  </div>

                  <div className={styles.BostProfile}>
                    <ul>
                      <li>
                        <span>Share :</span>
                      </li>
                      <li>
                        <a href="#">
                          <img src={socone} alt="" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img src={soctwo} alt="" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img src={socthree} alt="" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img src={socfour} alt="" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img src={socfive} alt="" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div> */}
            <div className={styles.productOffers}>
              <div className={styles.skilhead}>
                <h4>Posts</h4>
                {/* <div className={styles.skilladd}>
                  <span>
                    {" "}
                    <img src={pen} alt="" />
                  </span>
                  <span>
                    {" "}
                    <img src={pls} alt="" />
                  </span>
                  <span>
                    {" "}
                    <img src={skshare} alt="" />
                  </span>
                </div> */}
              </div>

              <div className="proSlider">
                <Slider {...settings}>
                  <div className="">
                    <div className={styles.proslideOut}>
                      <div className="">
                        <figure>
                          <img
                            src={postthumb}
                            alt=""
                            className="img-fluid w-100"
                          />
                        </figure>
                      </div>
                      <div className={styles.proslideOutdetails}>
                        <div className={styles.proslideOutHead}>
                          <div className={styles.leftProHead}>
                            <span>#2999</span>
                            <h4>Product Name</h4>
                          </div>
                          <div className={styles.leftProHeadShare}>
                            <img src={skshare} alt="" />
                          </div>
                        </div>
                        <div>
                          <p>
                            Our online coaching platform for students provides
                            personalized learning experiences and expert
                            guidance to help them achieve academic success.
                          </p>
                          <Link to="#">View Details</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className={styles.proslideOut}>
                      <div className="">
                        <figure>
                          <img
                            src={postthumb}
                            alt=""
                            className="img-fluid w-100"
                          />
                        </figure>
                      </div>
                      <div className={styles.proslideOutdetails}>
                        <div className={styles.proslideOutHead}>
                          <div className={styles.leftProHead}>
                            <span>#2999</span>
                            <h4>Product Name</h4>
                          </div>
                          <div className={styles.leftProHeadShare}>
                            <img src={skshare} alt="" />
                          </div>
                        </div>
                        <div>
                          <p>
                            Our online coaching platform for students provides
                            personalized learning experiences and expert
                            guidance to help them achieve academic success.
                          </p>
                          <Link to="#">View Details</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className={styles.proslideOut}>
                      <div className="">
                        <figure>
                          <img
                            src={postthumb}
                            alt=""
                            className="img-fluid w-100"
                          />
                        </figure>
                      </div>
                      <div className={styles.proslideOutdetails}>
                        <div className={styles.proslideOutHead}>
                          <div className={styles.leftProHead}>
                            <span>#2999</span>
                            <h4>Product Name</h4>
                          </div>
                          <div className={styles.leftProHeadShare}>
                            <img src={skshare} alt="" />
                          </div>
                        </div>
                        <div>
                          <p>
                            Our online coaching platform for students provides
                            personalized learning experiences and expert
                            guidance to help them achieve academic success.
                          </p>
                          <Link to="#">View Details</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className={styles.proslideOut}>
                      <div className="">
                        <figure>
                          <img
                            src={postthumb}
                            alt=""
                            className="img-fluid w-100"
                          />
                        </figure>
                      </div>
                      <div className={styles.proslideOutdetails}>
                        <div className={styles.proslideOutHead}>
                          <div className={styles.leftProHead}>
                            <span>#2999</span>
                            <h4>Product Name</h4>
                          </div>
                          <div className={styles.leftProHeadShare}>
                            <img src={skshare} alt="" />
                          </div>
                        </div>
                        <div>
                          <p>
                            Our online coaching platform for students provides
                            personalized learning experiences and expert
                            guidance to help them achieve academic success.
                          </p>
                          <Link to="#">View Details</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className={styles.proslideOut}>
                      <div className="">
                        <figure>
                          <img
                            src={postthumb}
                            alt=""
                            className="img-fluid w-100"
                          />
                        </figure>
                      </div>
                      <div className={styles.proslideOutdetails}>
                        <div className={styles.proslideOutHead}>
                          <div className={styles.leftProHead}>
                            <span>#2999</span>
                            <h4>Product Name</h4>
                          </div>
                          <div className={styles.leftProHeadShare}>
                            <img src={skshare} alt="" />
                          </div>
                        </div>
                        <div>
                          <p>
                            Our online coaching platform for students provides
                            personalized learning experiences and expert
                            guidance to help them achieve academic success.
                          </p>
                          <Link to="#">View Details</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>

              {/* <div className="accountPay">
                <ul>
                  <li>
                    <h4>Company Accounts Payable Contact</h4>
                    <p>Name : Johns Yanni rai</p>
                    <p>Email : example@gmail.com</p>
                    <p>Phone : +91 09876543</p>
                  </li>
                  <li>
                    <h4>Company Accounts Payable Contact</h4>
                    <p>Name : Johns Yanni rai</p>
                    <p>Email : example@gmail.com</p>
                    <p>Phone : +91 09876543</p>
                  </li>
                  <li>
                    <h4>Company Accounts Payable Contact</h4>
                    <p>Name : Johns Yanni rai</p>
                    <p>Email : example@gmail.com</p>
                    <p>Phone : +91 09876543</p>
                  </li>
                  <li>
                    <h4>Company Accounts Payable Contact</h4>
                    <p>Name : Johns Yanni rai</p>
                    <p>Email : example@gmail.com</p>
                    <p>Phone : +91 09876543</p>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>

          <div className={styles.skillset}>
            <div className={styles.skilhead}>
              <h3 className="fw-semibold text-24 d-flex align-items-center">
                Applications <i className="ms-2"><img src={info} alt="" /></i>
              </h3>
            </div>

            <div className="row align-items-center">
              
              <div className="col-lg-4 col-md-6 col-12">
                <div className={styles.profileHead}>
                  <div className={styles.profileName}>
                    <h4 className="mt-3 text-20 fw-semibold ">
                      Robot Programming{" "}
                      <span className="text-success fw-semibold text-20">
                        (Open)
                      </span>
                      <span></span>
                    </h4>

                    <div className={` ${styles.locations}`}>
                      <span className="text-14 opacity-75 d-flex align-items-center">
                        <img src={loc} alt="" />
                        New York City, America{" "}
                      </span>
                    </div>
                    <div className="d-inline-block mb-3">
                      <h4 className="text-ornage bg-lightorange px-3 py-2 my-2 d-flex align-items-center justify-content-center">
                        <i className="me-2">
                          <img src={bag} alt="" width={15} height={15} />
                        </i>
                        Direct Job
                      </h4>
                    </div>

                    <h6 className="fw-semibold"></h6>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className={styles.profileHead}>
                  <div className={styles.profileName}>
                    <h4 className="mt-3 text-20 fw-semibold ">
                      Robot Programming{" "}
                      <span className="text-danger fw-semibold text-20">
                        (Closed)
                      </span>
                      <span></span>
                    </h4>

                    <div className={styles.locations}>
                      <span className="text-14 opacity-75 d-flex align-items-center">
                        <img src={loc} alt="" />
                        New York City, America{" "}
                      </span>
                    </div>
                    <div className="d-inline-block mb-3">
                      <h4 className="text-success bg-lightgreen px-3 py-2 my-2  d-flex align-items-center justify-content-center">
                        <i className="me-2">
                          <img src={bag} alt="" width={15} height={15} />
                        </i>
                        Hourly Contract
                      </h4>
                    </div>

                    <h6 className="fw-semibold">Total Applications(5)</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.skillset}>
            <div className={styles.skilhead}>
              <h3 className="d-flex align-items-center">
                Interview Questions
                <i  className="ms-2"><img src={info} alt="" /></i>
              </h3>
            </div>
            <div className={styles.InterviewQues}>
              <ul>
                <li className="d-flex align-items-start">
                  <i>
                    <img src={bluecircle} alt="" width={30} height={30} />
                  </i>
                  <p className="d-block ms-3 mb-0">
                    1. Willing to work more than 60 miles from home ?{" "}
                    <span>Ans : Yes</span>
                  </p>
                </li>
                <li className="d-flex align-items-start">
                  <i>
                    <img src={bluecircle} alt="" width={30} height={30} />
                  </i>
                  <p className="d-block ms-3 mb-0">
                    1. Willing to work more than 60 miles from home ?{" "}
                    <span>Ans : Yes</span>
                  </p>
                </li>
                <li className="d-flex align-items-start">
                  <i>
                    <img src={bluecircle} alt="" width={30} height={30} />
                  </i>
                  <p className="d-block ms-3 mb-0">
                    1. Willing to work more than 60 miles from home ?{" "}
                    <span>Ans : Yes</span>
                  </p>
                </li>
                <li className="d-flex align-items-start">
                  <i>
                    <img src={bluecircle} alt="" width={30} height={30} />
                  </i>
                  <p className="d-block ms-3 mb-0">
                    1. Willing to work more than 60 miles from home ?{" "}
                    <span>Ans : Yes</span>
                  </p>
                </li>
                <li className="d-flex align-items-start">
                  <i>
                    <img src={bluecircle} alt="" width={30} height={30} />
                  </i>
                  <p className="d-block ms-3 mb-0">
                    1. Willing to work more than 60 miles from home ?{" "}
                    <span>Ans : Yes</span>
                  </p>
                </li>
                <li className="d-flex align-items-start">
                  <i>
                    <img src={bluecircle} alt="" width={30} height={30} />
                  </i>
                  <p className="d-block ms-3 mb-0">
                    1. Willing to work more than 60 miles from home ?{" "}
                    <span>Ans : Yes</span>
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.skillset}>
            <div className={styles.skilhead}>
              <h3 className="d-flex align-items-center">
                Educations, Certificates and training
                <i className="ms-2">  <img src={info} alt="" /></i>
              
              </h3>
            </div>
            <div className={styles.InterviewQues}>
              <ul>
                <li className="d-flex align-items-start w-100 mb-3">
                  <i>
                    <img src={bluecircle} alt="" width={30} height={30} />
                  </i>
                  <div className="d-block ms-3">
                    <h4 className="text-16 my-0">Kawasaki</h4>
                    <p className="text-12 mb-0">Graphic Designer</p>
                  </div>
                </li>
                <li className="d-flex align-items-start w-100 mb-3">
                  <i>
                    <img src={bluecircle} alt="" width={30} height={30} />
                  </i>
                  <div className="d-block ms-3">
                    <h4 className="text-16 my-0">Kawasaki</h4>
                    <p className="text-12 mb-0">Graphic Designer</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProfile;
