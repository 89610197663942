import React, { useEffect, useState } from 'react'
import info from "../../../assets/images/info_icon.svg"
import styles from "../styles/style.module.css"
import logo from "../../../assets/images/logo.png"
import Header from '../../header/routes'
import { getMSAAgrement } from '../api'
import { useParams } from 'react-router-dom'
import moment from "moment";
import { toast } from 'react-toastify'

const MSAAggrement = () => {
    const { id, userId } = useParams();
    const [MSA, setMSA] = useState<any>();

    useEffect(() => {
        MSADataList();
    }, []);


    const MSADataList = async () => {
        try {
            const response: any = await getMSAAgrement(id, userId);
            if (response && response.data) {
                setMSA(response.data);
                console.log(MSA, "MSA DATA");
                
            } else {
                throw new Error('No data found');
            }
        } catch (error: any) {
            toast.error(error.data.message);

        }
    };
    return (
        <>
            <Header />
            <div className={styles.aggremtn}>
                <div className='container'>
                    <div className={styles.agreeHead}>
                        <h4>Master Service Agreement  <img src={info} alt="" /></h4>
                        <div className={styles.greBtn}>
                            <button className='cmn-tbn'>Download MSA</button>
                            <button className="cmn-tbn" onClick={() => window.print()}>Print MSA</button>
                        </div>
                    </div>

                    <div className={styles.agrementUper}>
                        <div className={styles.AgreBrd}>
                            <div className={styles.AgreBrdHead}>
                                <img src={logo} alt="" />
                            </div>
                            <div className={styles.AgreBrdname}>
                                <h5>Master Service Agreement</h5>
                            </div>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className={styles.leftAgremnt}>
                                        <span className={styles.maindeHd}> Details</span>
                                        <ul>
                                            <li><span>Contract Number: </span> <label>{MSA?.work?.workNumber}</label></li>
                                            <li><span>Account Manager: </span> <label>{"Jacob Sanchez"}</label></li>
                                            <li><span>Account Manager Phone: </span> <label>{"+1 (586) 884-1116"}</label></li>
                                            <li><span>Account Manager Email: </span> <label>{"jacobs@automateamerica.com"}</label></li>
                                        </ul>
                                    </div>
                                    <div className={styles.leftAgremnt}>
                                        <span className={styles.maindeHd}> Job Site Locations</span>
                                        <ul>
                                            <li><span>State: </span> <label>{MSA?.work?.state}</label></li>
                                            <li><span>City: </span> <label>{MSA?.work?.city}</label></li>
                                            <li><span>Street Address: </span> <label>{MSA?.work?.address}</label></li>
                                            <li><span>Location Notes: </span> <label>10086478</label></li>
                                        </ul>
                                    </div>
                                    <div className={styles.leftAgremnt}>
                                        <span className={styles.maindeHd}> Professional Contact Info</span>
                                        <ul>
                                            <li><span>Professional Name: </span> <label>{MSA?.professional?.name}</label></li>
                                            <li><span>Professional Phone: </span> <label>{MSA?.professional?.phoneNumber}</label></li>
                                            <li><span>Professional Email: </span> <label>{MSA?.professional?.email}</label></li>
                                        </ul>
                                    </div>
                                    <div className={styles.leftAgremnt}>
                                        <span className={styles.maindeHd}> Business Contact Info </span>
                                        <ul>
                                            <li><span>Business Name: </span> <label>{MSA?.Customer?.name}</label></li>
                                            <li><span>Group Name: </span> <label></label></li>
                                            <li><span>Contact Name: </span> <label></label></li>
                                            <li><span>Contact Number: </span> <label></label></li>
                                            <li><span>Contact Email: </span> <label></label></li>
                                            <li><span>Account Payable Name: </span> <label></label></li>
                                            <li><span>Account Payable Phone: </span> <label></label></li>
                                            <li><span>Account Payable Email: </span> <label></label></li>
                                            <li><span>AP Name #2: </span> <label></label></li>
                                            <li><span>AP Phone #2:</span> <label></label></li>
                                            <li><span>AP Email #2:</span> <label></label></li>
                                        </ul>
                                    </div>
                                    <div className={styles.leftAgremnt}>
                                        <span className={styles.maindeHd}> Safety Equipment Required </span>
                                        <p>Standard PPE ( Safety glasses, Shoes, ear protection ) </p>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className={styles.leftAgremnt}>
                                        <span className={styles.maindeHd}> Application Date</span>
                                        <ul>
                                            <li><span>Application Start Date: </span> <label>{moment(MSA?.times?.startDate).format("MMM DD, YYYY")}</label></li>
                                            <li><span>Application End Date: </span> <label>{moment(MSA?.times?.endDate).format("MMM DD, YYYY")}</label></li>
                                            <li><span>Planned Days/week: </span> <label>{MSA?.work?.times?.plannedDaysPerWeek}</label></li>
                                            <li><span>Planned Hours/Day: </span> <label>{MSA?.work?.times?.plannedHoursPerDay}</label></li>
                                        </ul>
                                    </div>
                                    <div className={styles.leftAgremnt}>
                                        <span className={styles.maindeHd}> Business Bill Rates</span>
                                        <ul>
                                            <li><span>Payment Terms: </span> <label>{MSA?.hourlyPayment?.paymentTerm}</label></li>
                                            <li><span>Base Rate: </span> <label>${MSA?.work?.rates?.baseRate}</label></li>
                                            <li><span>Overtime Rate: </span> <label>${MSA?.work?.rates?.overTimeRate}</label></li>
                                            <li><span>Sunday/Holiday Rate: </span> <label>${MSA?.work?.rates?.doubleTimeRate}</label></li>
                                            <li><span>Travel Time Rate: </span> <label>{MSA?.hourlyPayment?.travelTimeRate}</label></li>
                                            <li><span>Base Rate Hours: </span> <label>{MSA?.hourlyPayment?.baseRate}</label></li>
                                            <li><span>OT Hours: </span> <label>{MSA?.hourlyPayment?.overTimeRate}</label></li>
                                            <li><span>Sunday/Holiday Hours: </span> <label>{MSA?.hourlyPayment?.doubleTimeRate}</label></li>
                                        </ul>
                                    </div>
                                    <div className={styles.leftAgremnt}>
                                        <span className={styles.maindeHd}>Expense Paid:</span>
                                        <ul>
                                            <li><span>Daily Per Diem: </span> <label>{MSA?.expensePayment?.perDiem}</label></li>
                                            <li><span>Travel Time Rate: </span> <label>{MSA?.work?.travels?.travelTimeRate}</label></li>
                                            <li><span>Travel Trips Allowed(per month): </span> <label>{MSA?.work?.travels?.tipAllowed}</label></li>
                                            <li><span>Travel Hours Per Round Trip: </span> <label>{MSA?.work?.travels?.perRoundTrip}</label></li>
                                            {/* <li><span>Hotel Allowance/Day: </span> <label>{MSA?.expensePayment?.hotelAllowance}</label></li> */}
                                            <li><span>Rental Car Allowance/Day: </span> <label>{MSA?.expensePayment?.rentalCarAllowance}</label></li>
                                            <li><span>Fuel: </span> <label>{MSA?.expensePayment?.fuel}</label></li>
                                            <li><span>Airfare Allowance: </span> <label>{MSA?.expensePayment?.airfareAllowance}</label></li>
                                            <li><span>Mileage Rate/Mile: </span> <label>{MSA?.expensePayment?.mileage}</label></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {MSA?.htmlText ?
                            <div dangerouslySetInnerHTML={{
                                __html: MSA?.htmlText
                            }} />
                            : null
                            }

                    </div>
                </div>
            </div>
        </>
    )
}

export default MSAAggrement