import React, { useState } from "react";
import styles from "../styles/style.module.css";
import penbl from "../../../assets/images/penbl.png";
import { Link } from "react-router-dom";
import { updateUserProfile } from "../../home/api";
import EditSocialMediaModal from "../modals/EditSocialMediaModal";

interface Props {
  instaLink: string;
  fbLink: string;
  linkedinLink: string;
  twiterLink: string;
  refresh: () => void;
}

const SocialMedia: React.FC<Props> = ({
  instaLink,
  fbLink,
  linkedinLink,
  twiterLink,
  refresh,
}) => {
  const [linkedin, setLinkedin] = useState("");
  const [facebook, setFacebook] = useState("");
  const [insta, setInsta] = useState("");
  const [twiter, setTwiter] = useState("");

  const handleInsta = async () => {
    if (insta) {
      const payload = {
        instagramLink: insta,
      };
      try {
        await updateUserProfile(payload);
        refresh();
      } catch (err) {
        console.log("Error occuring while uploading social media links");
      }
      setInsta("");
    }
  };
  const handleFacebook = async () => {
    if (facebook) {
      const payload = {
        facebookLink: facebook,
      };
      try {
        await updateUserProfile(payload);
        refresh();
      } catch (err) {
        console.log("Error occuring while uploading social media links");
      }
      setFacebook("");
    }
  };
  const handleLinkedin = async () => {
    if (linkedin) {
      const payload = {
        linkedinLink: linkedin,
      };
      try {
        await updateUserProfile(payload);
        refresh();
      } catch (err) {
        console.log("Error occuring while uploading social media links");
      }
      setLinkedin("");
    }
  };
  const handleTwiter = async () => {
    if (twiter) {
      const payload = {
        twitterLink: twiter,
      };
      try {
        await updateUserProfile(payload);
        refresh();
      } catch (err) {
        console.log("Error occuring while uploading social media links");
      }
      setTwiter("");
    }
  };
  const [showModal, setShowModal] = useState<boolean>(false);
  const openModal = () => {
    setShowModal(true);
  }
  const closeModal = () => { setShowModal(false)};
  return (
    <div className={styles.SocialMediEdit}>
      <div className="row align-items-center justify-content-between mb-3">
        <div className="col-6">
          <h5 className="text-20 fw-semibold mb-0"> Social Media </h5>
        </div>
        <div className="col-6 text-end">
          <span>
            <img src={penbl} className="cursor-pointer ms-auto" alt="" onClick={openModal} />
          </span>
        </div>
      </div>
      <div className=" mb-3">
        <div className="row align-items-center border rounded-2 h-44 mx-0">
          <div className="col-8">
            <input
              type="text"
              placeholder="Instagram"
              value={insta}
              className="py-2 w-100 border-0"
              onChange={(e) => setInsta(e.target.value)}
            />
          </div>
          <div className="col-4 text-end">
            <button
              type="button"
              onClick={handleInsta}
              className="btn text-blue fw-medium text-14 w-auto py-2"
            >
              +Add Link
            </button>
          </div>
        </div>
        {instaLink ? (
          <Link to={instaLink}>{instaLink}</Link>
        ) : (
          <p className="text-12">No Link Added yet!</p>
        )}
      </div>
      <div className="mb-3">
        <div className="row align-items-center border rounded-2 h-44 mx-0">
          <div className="col-8">
            <input
              type="text"
              placeholder="LinkedIn"
              value={linkedin}
              className="py-2 w-100 border-0"
              onChange={(e) => setLinkedin(e.target.value)}
            />
          </div>
          <div className="col-4 text-end">
            <button
              type="button"
              onClick={handleLinkedin}
              className="btn text-blue fw-medium text-14 w-auto py-2"
            >
              +Add Link
            </button>
          </div>
        </div>
        {linkedinLink ? (
          <Link to={linkedinLink}>{linkedinLink}</Link>
        ) : (
          <p className="text-12">No Link Added yet!</p>
        )}
      </div>
      <div className="mb-3">
        <div className="row align-items-center border rounded-2 h-44 mx-0">
          <div className="col-8">
            <input
              type="text"
              placeholder="Facebook"
              value={facebook}
              className="py-2 w-100 border-0"
              onChange={(e) => setFacebook(e.target.value)}
            />
          </div>
          <div className="col-4 text-end">
            <button
              type="button"
              onClick={handleFacebook}
              className="btn text-blue fw-medium text-14 w-auto py-2"
            >
              + Add Link
            </button>
          </div>
          </div>
          {fbLink ? (
            <Link to={fbLink}>{fbLink}</Link>
          ) : (
            <p className="text-12">No Link Added yet!</p>
          )}
        
      </div>
      <div className="mb-3">
        <div className="row align-items-center border rounded-2 h-44 mx-0">
          <div className="col-8">
            <input
              type="text"
              placeholder="youtube"
              // value={Youtube}
              className="py-2 w-100 border-0"
              // onChange={(e) => setFacebook(e.target.value)}
            />
          </div>
          <div className="col-4 text-end">
            <button
              type="button"
              onClick={handleFacebook}
              className="btn text-blue fw-medium text-14 w-auto py-2"
            >
              + Add Link
            </button>
          </div>
        </div>
        {fbLink ? (
          <Link to={fbLink}>{fbLink}</Link>
        ) : (
          <p className="text-12">No Link Added yet!</p>
        )}
      </div>
      <div className="mb-3">
        <div className="row align-items-center border rounded-2 h-44 mx-0">
          <div className="col-8">
            <input
              type="text"
              placeholder="Twiter"
              value={twiter}
              className="py-2 w-100 border-0"
              onChange={(e) => setTwiter(e.target.value)}
            />
          </div>
          <div className="col-4 text-end">
            <button
              type="button"
              onClick={handleTwiter}
              className="btn text-blue fw-medium text-14 w-auto py-2"
            >
              + Add Link
            </button>
          </div>
        </div>
        {twiterLink ? (
          <Link to={twiterLink}>{twiterLink}</Link>
        ) : (
          <p className="text-12">No Link Added yet!</p>
        )}
      </div>
      {/* <ul>
        <li>
          <span>Instagram Link</span> <Link to="#">+ Add Links</Link>
        </li>
        <li>
          <span>Linkedin</span> <Link to="#">+ Add Links</Link>
        </li>
        <li>
          <span>Facebook</span> <Link to="#">+ Add Links</Link>
        </li>
        <li>
          <span>Twitter</span> <Link to="#">+ Add Links</Link>
        </li>
      </ul> */}
      <EditSocialMediaModal showModal={showModal} closeModal={closeModal} title="Edit Banking Info"/>
    </div>
  );
};

export default SocialMedia;
