import React, { useEffect, useState } from "react";
import styles from "../styles/style.module.css";
import logo from "../../../assets/images/logo.png";
import noti from "../../../assets/images/noti.png";
import hduser from "../../../assets/images/hduser.png";
import { useLocation, useNavigate } from "react-router-dom";
import storage from "../../../utils/storage";
import { APP_ROUTES } from "../../../utils/routes/AppRoutes";
import { logout } from "../../../feature/auth/login/api";
import { toast } from "react-toastify";
import ViewProfile from "../../profileview/routes/page";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = storage.getToken();
  const [activeTab, setActiveTab] = useState<string>();
  const userImg = storage.getUserProfileImg();

  const handleLogout = () => {
    logOutUser();
  };
  const logOutUser = (): void => {
    toast.promise(logout(), {
      pending: {
        render() {
          return "Logout user";
        },
      },
      success: {
        render() {
          storage.clearToken();
          storage.clearAuthUserId();
          storage.clearSavedContract();
          storage.clearProfieImg();
          // dispatch(logoutUser());
          navigate(APP_ROUTES.DASHBOARD);
          return "User Logged out successfully!";
        },
      },
      error: {
        render({ data }) {
          return (data as any)?.data?.message;
        },
      },
    });
  };

  useEffect(() => {
    setActiveTab(location?.pathname);
  }, [location?.pathname]);

  const _viewProfile = () => {
    navigate(APP_ROUTES.EDITPROFILE);
  };

  return (
    <div className={styles.HeaderMain}>
      <div className="container-fluid">
        <nav className="navbar navbar-expand-lg">
          <div className="row align-items-center justify-content-between w-100 navbarmenutoglr">
            <div className="col-lg-4 col-md-3 col-6">
              <a className="navbar-brand d-flex" href="#">
                <img src={logo} alt="" className="img-fluid" />
              </a>
            </div>
            <div className="col-lg-7 col-6 ms-auto text-end">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav me-0">
                  <li
                    className={
                      activeTab === APP_ROUTES.HOME ? styles.active : ""
                    }
                  >
                    <a
                      onClick={() => setActiveTab("/home")}
                      className="nav-link"
                      href="/home"
                    >
                      Dashboard
                    </a>
                  </li>

                  <li
                    className={
                      activeTab === APP_ROUTES.FEEDS ? styles.active : ""
                    }
                  >
                    <a
                      onClick={() => setActiveTab("/feeds")}
                      className="nav-link"
                      href="/feeds"
                    >
                      Feed
                    </a>
                  </li>

                  <li
                    className={
                      activeTab === APP_ROUTES.FIND_PROFESSIONALS
                        ? styles.active
                        : ""
                    }
                  >
                    <a className="nav-link" href="/findProfessionals">
                      Find Professionals
                    </a>
                  </li>
                  <li
                    className={
                      activeTab === APP_ROUTES.FINDOPPORTUNITIES
                        ? styles.active
                        : ""
                    }
                  >
                    <a className="nav-link" href="/findOpportunities">
                      Find Opportunities
                    </a>
                  </li>
                  <li
                    className={
                      activeTab === APP_ROUTES.ADD_CONTRACT ? styles.active : ""
                    }
                  >
                    <a className="nav-link" href="/add-contract">
                      Post Work
                    </a>
                  </li>

                  <li
                    className={
                      activeTab === APP_ROUTES.CHAT_MESSAGE ? styles.active : ""
                    }
                  >
                    <a
                      className="nav-link"
                      href="/chat-page"
                      onClick={() => setActiveTab("/chat-message")}
                    >
                      Messages
                    </a>
                  </li>

                  {/* <li className="">
                    <a className="nav-link" href="#">Subscription</a>
                  </li> */}
                  {token ? (
                    <li className="">
                      <a onClick={handleLogout} className="nav-link" href="#">
                        Logout
                      </a>
                    </li>
                  ) : (
                    <li className="">
                      <a className="nav-link" href="/login">
                        Login/Signup
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <div className="col-lg-1 col-2">
              <div className={styles.Notify}>
                <ul className="">
                  <li>
                    <img src={noti} alt="" /> <span>1</span>
                  </li>
                  <li>
                    <img
                      onClick={_viewProfile}
                      src={userImg}
                      className="rounded-circle border border-dark"
                      alt="userImg"
                      style={{
                        objectFit: "cover",
                        height: "13px",
                        width: "13px",
                      }}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
