import React, { FC, useEffect, useState, useRef} from "react";
import styles from '../../../../../../src/components/work/reviewcontract/styles/style.module.css'
import { Dropdown, FormControl } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import Delete from "../../../../../assets/images/delete.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import moment from "moment";
import { useAppSelector } from "../../../../../redux/hooks";
import { RootState } from "../../../../../redux/store";
import { getPaymentTerms } from "../../../../paymentTerms/api";
import {
  setActiveStep,
  setBackgroundCheck,
  setCustomerDetails,
  setDrugTest,
  setDurations,
  setEligibilityQuestions,
  setInterviewQuestions,
  setKeyInformation,
  setLegalDetails,
  setLocation,
  setOccupation,
  setProceduresAndPolicies,
  setRates,
  setSoftwareLicenseDetails,
  setTechnicalSkills,
  setTravelData,
  setWorkDescription,
  setWorkLocation,
} from "../../../../../redux/reducers/addContract";
import { IOption } from "../../../../../Interfaces";
import { getMasterTypes } from "../../../../../feature/auth/signup/api";
import { getManufacturerModelList, postMasterType } from "../../../../work/api";
import { MASTER_TYPE } from "../../../../../utils/enums/masterTypes.enum";
import Header from "../../../../header/routes";
import CustomModal from "../../../../../shared/CustomModal/customModal";
import OverviewCollapseItem from "../../../../work/contractSteps/Components/OverviewCollapseItem/OverviewCollapseItem";
import { SaveAsDraftButton } from "../../../../work/contractSteps/Components";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import overviewpage from "../../../../../assets/images/overviewpage.png";
import print from "../../../../../assets/images/print_white.svg";
import download from "../../../../../assets/images/download_white.svg";
import editicon from "../../../../../assets/images/edit_icon.svg";
import Subscription from "../../../../work/contractSteps/subscription/choosePlanModal/choosePlanModal";
import { OVERVIEW_FORM_FIELDS } from "../../../../work/enums";
import { ADD_CONTRACT_STEPS } from "../../../../../shared/enums";
import TextEditorCustomComp from "./TextEditorCustomComp/TextEditorCustomComp";
import CreatableSelect from "react-select/creatable";
import { APIResponseError } from "../../../../work/interface";
import { MESSAGES } from "../../../../../utils/messages";
import { toast } from "react-toastify";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import { downloadHourlyContract } from "../../../../work/api";
import { addImagesToPdf } from "../../../../../common/api";


interface HourlyPaymentTerm {
  _id: string;
  paymentTerm: number;
}

const libraries: any = ["places"];

const OverviewContract: FC = () => {
  const dispatch = useDispatch();
  const [key, setKey] = useState(Math.random());
  
  const [occupationFilterValue, setOccupationFilterValue] =
    useState<string>("");
  const [activeItem, setActiveItem] = useState<any | null>();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [downloading,setDownloading]=useState<Boolean>(false)
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filterValue, setFilterValue] = useState<string>("");
  const [inEditField, setInEditField] = useState<OVERVIEW_FORM_FIELDS | null>(
    null
  );
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const shifts = ["days", "afternoons", "nightshift", "variable"];
  const hours = ["6", "7", "8", "9", "10", "11", "12"];
  const days = ["1", "2", "3", "4", "5", "6", "7"];
  const paymentRates = ["15", "30", "45", "60", "75", "90"];
  const backgroundCheckOptions = [
    "In the last week",
    "Month",
    "Three Months",
    "Six Months",
    "One Year",
  ];
  const workLocationOptions = ["Remote", "Hybrid", "Onsite"];
  const trips = ["1", "2", "3", "4", "5", "6"];
  const [addressDetails, setAddressDetails] = useState({
    city: "",
    state: "",
    country: "",
    zipcode: "",
    streets: "",
  });

  const [isAddressEditable, setIsAddressEditable] = useState(false);
  const [address, setAddress] = useState("");
  const [paymentTerms, setPaymentTerms] = useState<HourlyPaymentTerm[]>([]);
  const [selectedTerm, setSelectedTerm] = useState<string>(
    "Select a payment term"
  );
  useEffect(() => {
    async function fetchPaymentTerms() {
      try {
        const response = await getPaymentTerms();
        if (response.status === 200 && response.data) {
          setPaymentTerms(response.data.hourly);
        }
      } catch (error) {
        console.error("Error fetching payment terms:", error);
      }
    }
    fetchPaymentTerms();
  }, []);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_APIKEY as string,
    libraries,
  });
  const { rates } = useSelector((state: RootState) => state.addContract);
  const theNewRates = useSelector((state: RootState) => state);

  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
  };
  const contract = useAppSelector((state: RootState) => state.addContract);
  const {
    occupation,
    occupationsList,
    durations,
    keyInformation,
    travelData,
    customerDetails,
    location,
    legalDetails,
    softwareLicenseDetails,
    drugTest,
    backgroundCheck,
    proceduresAndPolicies,
    interviewQuestions,
    eligibilityQuestions,
    technicalSkillsData,
    workLocation,
    workDescription,
  } = contract;
  const { startDate, endDate, duration } = durations;
  const { isDrugTestRequired, drugRegacy } = drugTest;
  const { softwareLicenses, options, isRequired } = softwareLicenseDetails;
  const contentRef = useRef<any>(null);
  const contentRef1 = useRef<any>(null);
  const contentRef2 = useRef<any>(null);


  const {
    selectedCity,
    selectedCountry,
    selectedState,
    selectedStreet,
    selectedZipcode,
  } = location;
  const {
    primaryName,
    primaryEmail,
    primaryPhoneNumber,
    secondaryName,
    secondaryEmail,
    secondaryPhoneNumber,
  } = customerDetails;

  const { isLegalResidence, isVisa } = legalDetails;
  const { isBackgroundCheckRequired } = backgroundCheck;
  const [desc, setDesc] = useState<any>(contract?.workDescription);
  const [policy, setPolicy] = useState<any>(
    contract.proceduresAndPolicies.proceduresAndPolicies
  );
  const [eligible, setEligible] = useState<any>(contract.eligibilityQuestions);
  const [interview, setInterview] = useState<any>(contract.interviewQuestions);

  const primaryOccOptions =
    occupationFilterValue && occupationFilterValue !== ""
      ? occupationsList.filter((x) =>
        x.label.toLowerCase().includes(occupationFilterValue.toLowerCase())
      )
      : occupationsList;

  const handleOccupationChange = (value: any, key: string) => {
    dispatch(setOccupation({ ...occupation, [key]: value }));
  };
  const handleSelect = (term: HourlyPaymentTerm) => {
    setSelectedTerm(`${term.paymentTerm} days`); // Set selected payment term label
    onInputChange(term, "paymentTerms"); // Call onInputChange with selected term
    console.log("Selected:", term);
  };

  const toggleOption = (item: any) => {
    setActiveItem(item === activeItem ? null : item);
    setIsEditing(false);
  };

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  useEffect(() => {
    onDescriptionChange();
    onEditorValueChange();
  }, [desc, policy]);

  const onDescriptionChange = () => {
    dispatch(setWorkDescription(desc));
  };

  const ondateChange = (value: Date | string | number | null, key: string) => {
    let updatedDuration = duration;
    // formik.setFieldValue(key, value);
    if (key === "startDate" || key === "endDate") {
      updatedDuration = moment(endDate).diff(moment(startDate), "days");
      // formik.setFieldValue('duration', updatedDuration);
    }
    dispatch(
      setDurations({
        ...durations,
        duration: updatedDuration,
        [key]: value,
      })
    );
  };
  const onKeyInforChange = (value: string | number, key: string) => {
    dispatch(
      setKeyInformation({
        ...keyInformation,
        [key]: value,
      })
    );
  };
  const onRateChange = (value: any, key: string) => {
    dispatch(setRates({ ...rates, [key]: value }));
  };
  const onTravelDataChange = (
    value: string | number | boolean,
    key: string
  ) => {
    if (key === "isTravelRequired" && value === false) {
      dispatch(
        setTravelData({
          isTravelRequired: false,
        })
      );
      return;
    }
    dispatch(
      setTravelData({
        ...travelData,
        [key]: value,
      })
    );
  };

  const onDetailChange = async (
    value: string | boolean | number,
    key: string
  ) => {
    dispatch(setCustomerDetails({ ...customerDetails, [key]: value }));
  };

  const onInputChange = (value: any, key: string) => {
    if (value.label === "") {
      return;
    }
    console.log("Before Dispatch:", rates); // Debugging
    dispatch(setRates({ ...rates, [key]: value }));
    console.log("After Dispatch:", { ...rates, [key]: value }); // Debugging
  };

  const _addNewSkill = async (option: any, index: number, key: string) => {
    const updatedSkills = [];
    let _newSkill = {
      isRequired: false,
      subCategoriesList: [],
      category: {
        ...option,
        label: option.name,
        value: option._id,
      },
      subCategory: [],
    };
    updatedSkills.push(_newSkill);

    dispatch(
      setTechnicalSkills({ ...technicalSkillsData, skills: updatedSkills })
    );
  };

  const handleSubmitCustomOccupation = async (
    newValue?: any,
    type?: string
  ) => {
    if (type === "SOFTWARE_LICENCES") {
      const payload = {
        type: MASTER_TYPE.SOFTWARE_LICENCES,
        name: newValue || searchTerm,
        alias: newValue || searchTerm,
      };

      toast.promise(postMasterType(payload), {
        pending: {
          render() {
            return MESSAGES.POSTING_CONTRACTS.VERIFYING;
          },
        },
        success: {
          render(newSoftwareLicence) {
            const newId = newSoftwareLicence?.data?.data?._id || newValue;
            if (type === "SOFTWARE_LICENCES") {
              const _obj: any = {
                label: newSoftwareLicence?.data?.data?.name || newValue,
                value: newId,
              };
              const newArr = [...softwareLicenseDetails.softwareLicenses, _obj];
              dispatch(
                setSoftwareLicenseDetails({
                  ...softwareLicenseDetails,
                  softwareLicenses: newArr,
                })
              );
              return MESSAGES.POSTING_CONTRACTS.ADDING;
            }
          },
        },
        error: {
          render(error) {
            let errorMesage = error as APIResponseError;
            return errorMesage?.data?.data?.message;
          },
        },
      });
    }
    if (type === "_MANUFACTURERS") {
      const payload = {
        type: MASTER_TYPE.MANUFACTURERS,
        name: newValue || searchTerm,
        alias: newValue || searchTerm,
      };

      toast.promise(postMasterType(payload), {
        pending: {
          render() {
            return MESSAGES.POSTING_CONTRACTS.VERIFYING;
          },
        },
        success: {
          render(newManufacture) {
            if (type === "_MANUFACTURERS") {
              _addNewSkill(newManufacture?.data?.data, 1, "category");
              return MESSAGES.POSTING_CONTRACTS.ADDING;
            }
            setSearchTerm("");
            dispatch(
              setTechnicalSkills({
                ...technicalSkillsData,
                skillTypes: [
                  {
                    label: newManufacture.data.data.name,
                    value: newManufacture.data.data._id,
                  },
                ],
              })
            );
            return MESSAGES.POSTING_CONTRACTS.ADDING;
          },
        },
        error: {
          render(error) {
            let errorMesage = error as APIResponseError;
            return errorMesage?.data?.data?.message;
          },
        },
      });
    }
    if (type === "PRIMARY_OCCUPATION") {
      const payload = {
        type: MASTER_TYPE.OCCUPATIONS,
        name: newValue || searchTerm,
        alias: newValue || searchTerm,
      };

      toast.promise(postMasterType(payload), {
        pending: {
          render() {
            return MESSAGES.POSTING_CONTRACTS.VERIFYING;
          },
        },
        success: {
          render(newOccupation) {
            const newId = newOccupation?.data?.data?._id || newValue;
            // if (type === "SECONDARY_OCCUPATION") {
            //   const _obj: any = {
            //     label: newOccupation?.data?.data?.name || newValue,
            //     value: newId,
            //   };
            //   const newArr = [...occupation.secondaryOccupations, _obj];
            //   dispatch(setOccupation({ ...occupation, secondaryOccupations: newArr }));
            //   return MESSAGES.POSTING_CONTRACTS.ADDING;
            // } else {
            const _obj: any = {
              label: newOccupation?.data?.data?.name || newValue,
              value: newId,
            };
            dispatch(setOccupation({ ...occupation, primaryOccupation: _obj }));
            // formik.setFieldValue(
            //   "primaryOccupation",
            //   newOccupation.data.data.name
            // );
            return MESSAGES.POSTING_CONTRACTS.ADDING;
            // }
          },
        },
        error: {
          render(error) {
            let errorMesage = error as APIResponseError;
            return errorMesage?.data?.data?.message;
          },
        },
      });
    }
    if (type === "SECONDARY_OCCUPATION") {
      const payload = {
        type: MASTER_TYPE.OCCUPATIONS,
        name: newValue || searchTerm,
        alias: newValue || searchTerm,
      };

      toast.promise(postMasterType(payload), {
        pending: {
          render() {
            return MESSAGES.POSTING_CONTRACTS.VERIFYING;
          },
        },
        success: {
          render(newOccupation) {
            const newId = newOccupation?.data?.data?._id || newValue;
            const _obj: any = {
              label: newOccupation?.data?.data?.name || newValue,
              value: newId,
            };
            const newArr = [...occupation.secondaryOccupations, _obj];
            dispatch(
              setOccupation({ ...occupation, secondaryOccupations: newArr })
            );
            return MESSAGES.POSTING_CONTRACTS.ADDING;
          },
        },
        error: {
          render(error) {
            let errorMesage = error as APIResponseError;
            return errorMesage?.data?.data?.message;
          },
        },
      });
    }
  };

  const handleCreate = (inputValue: string, type?: string) => {
    handleSubmitCustomOccupation(inputValue, type);
  };

  const onLegalInputdata = (value: string | boolean, key: string) => {
    dispatch(setLegalDetails({ ...legalDetails, [key]: value }));
  };
  const onEditorValueChange = () => {
    dispatch(
      setProceduresAndPolicies({
        ...proceduresAndPolicies,
        proceduresAndPolicies: policy,
      })
    );
  };

  useEffect(() => {
    getSoftwareLicense();
  }, []);

  const getSoftwareLicense = async () => {
    const response = await getMasterTypes(MASTER_TYPE.SOFTWARE_LICENCES);
    response.data.forEach((x) => {
      x.label = x.name;
      x.value = x._id;
    });

    dispatch(
      setSoftwareLicenseDetails({
        ...softwareLicenseDetails,
        options: response.data,
      })
    );
  };

  const handleGoBack = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_CUSTOMER_CONTACT));
  };

  const onBackgroudCheck = (value: string | boolean, key: string): void => {
    const updatedData = {
      ...backgroundCheck,
      [key]: value,
    };
    dispatch(setBackgroundCheck(updatedData));
  };

  const onDrugTestValue = (value: string | boolean, key: string): void => {
    const updatedSafetyRequirements = {
      ...drugTest,
      [key]: value,
    };
    dispatch(setDrugTest(updatedSafetyRequirements));
  };
  const onWorkLocation = (value: string | boolean, key: string): void => {
    dispatch(setWorkLocation({ ...workLocation, [key]: value }));
  };

  const onSoftwareLicenseChange = (value: any, key: string) => {
    dispatch(
      setSoftwareLicenseDetails({
        ...softwareLicenseDetails,
        [key]: value,
      })
    );
  };

  const addNewQuestion = (): void => {
    const updatedQuestion = [...interviewQuestions];
    updatedQuestion.push("");
    dispatch(setInterviewQuestions([...updatedQuestion]));
  };

  const onInyterviewQuestionChange = (value: string, index: number): void => {
    const updatedQuestions = [...interviewQuestions];
    updatedQuestions[index] = value;
    dispatch(setInterviewQuestions(updatedQuestions));
  };

  const deleteQuestion = (index: number) => {
    const updatedQuestion = [...interviewQuestions];
    updatedQuestion.splice(index, 1);
    dispatch(setInterviewQuestions([...updatedQuestion]));
  };

  const addNewEligibilityQuestion = (): void => {
    const updatedQuestion = [...eligibilityQuestions];
    updatedQuestion.push("");
    dispatch(setEligibilityQuestions([...updatedQuestion]));
  };

  const onEligibilityQusChange = (value: string, index: number): void => {
    const updatedQuestions = [...eligibilityQuestions];
    updatedQuestions[index] = value;
    dispatch(setEligibilityQuestions(updatedQuestions));
  };

  const deleteEligibilityQuestion = (index: number) => {
    const updatedQuestion = [...eligibilityQuestions];
    updatedQuestion.splice(index, 1);

    dispatch(setEligibilityQuestions([...updatedQuestion]));
  };

  const deleteSkill = (i: number) => {
    const updatedSkills = [...technicalSkillsData.skills];
    updatedSkills.splice(i, 1);
    dispatch(
      setTechnicalSkills({ ...technicalSkillsData, skills: updatedSkills })
    );
  };

  useEffect(() => {
    const getManufacturerData = async () => {
      const response = await getMasterTypes(MASTER_TYPE.MANUFACTURERS);
      response.data.forEach((x) => {
        x.label = x.name;
        x.value = x._id;
      });
      dispatch(
        setTechnicalSkills({
          ...technicalSkillsData,
          skillTypes: response.data,
        })
      );
    };
    getManufacturerData();
  }, []);

  const onSkillsChange = (
    value: string | boolean | IOption[],
    key: string,
    index: number
  ): void => {
    const updatedSkills = [...technicalSkillsData.skills];
    const updatedSkill = { ...updatedSkills[index], [key]: value };
    updatedSkills[index] = updatedSkill;

    dispatch(
      setTechnicalSkills({ ...technicalSkillsData, skills: updatedSkills })
    );
  };
  const handleChangeManufacturer = async (
    option: any,
    index: number,
    key: string
  ) => {
    const updatedSkills = [...technicalSkillsData.skills];
    const updatedSkill = { ...updatedSkills[index] };

    if (key === "category") {
      const response = await getManufacturerModelList(
        MASTER_TYPE.MANUFACTURERS_MODELS,
        option.value
      );
      response.data.forEach((x) => {
        x.label = x.name;
        x.value = x._id;
      });

      updatedSkill.subCategoriesList = response.data ?? [];
      updatedSkill.category = option;
      updatedSkill.subCategory = [];
    }

    if (key === "subCategory") {
      updatedSkill.subCategory = option;
    }
    (updatedSkill as any)[key] = option;
    updatedSkills[index] = updatedSkill;
    dispatch(
      setTechnicalSkills({ ...technicalSkillsData, skills: updatedSkills })
    );
  };
  const filteredCategories = technicalSkillsData.skillTypes.filter((option) =>
    option?.label.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const handleSendForApproval = () => {
    setModalDetail({
      show: true,
      title: "plan",
      flag: "plan",
    });
    setKey(Math.random());
  };

  const onLoad = (autocomplete: any) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      const addressComponents: any = place.address_components;
      const result = {
        city: "",
        state: "",
        country: "",
        zipcode: "",
        streets: "",
      };
      const streetComponents: string[] = [];

      addressComponents.forEach(
        (component: google.maps.GeocoderAddressComponent) => {
          const types = component.types;
          if (types.includes("locality")) {
            result.city = component.long_name;
          } else if (types.includes("administrative_area_level_1")) {
            result.state = component.long_name;
          } else if (types.includes("country")) {
            result.country = component.long_name;
          } else if (types.includes("postal_code")) {
            result.zipcode = component.long_name;
          } else if (types.includes("premise")) {
            streetComponents.push(component.long_name); // Collect street numbers
          } else if (types.includes("street_number")) {
            streetComponents.push(component.long_name); // Collect street numbers
          } else if (types.includes("route")) {
            streetComponents.push(component.long_name); // Collect routes
          } else if (
            types.includes("sublocality") ||
            types.includes("sublocality_level_1") ||
            types.includes("sublocality_level_2")
          ) {
            streetComponents.push(component.long_name); // Collect sublocalities
          }
        }
      );
      // Join the collected street components into a single string
      result.streets = streetComponents.join(", ");
      // Optionally update Redux state
      dispatch(
        setLocation({
          ...location,
          selectedCity: { label: result.city, value: result.city },
          selectedState: { label: result.state, value: result.state },
          selectedCountry: { label: result.country, value: result.country },
          selectedZipcode: { label: result.zipcode, value: result.zipcode },
          selectedStreet: { label: result.streets, value: result.streets },
        })
      );
      setAddressDetails(result);
    }
  };

  const handleAddressChange = (event: any) => {
    setAddress(event.target.value);
  };

  const handleEditClick = () => {
    setIsAddressEditable(true);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      setIsAddressEditable(false);
      // Optionally, handle any additional logic on Enter press
    }
  };

  const _handleExport = async () => {
    const res = await downloadHourlyContract();
    console.log("Export", res);
  };
  const handleQuestionChange = (index: number, newContent: string) => {
    const updatedQuestions = [...interviewQuestions];
    updatedQuestions[index] = newContent; // Update the specific question content
    dispatch(setInterviewQuestions(updatedQuestions));
  };
  const handleEligibilityQuestionChange = (index: number, newContent: string) => {
    const updatedQuestions = [...eligibilityQuestions];
    updatedQuestions[index] = newContent; // Update the specific question content
    dispatch(setEligibilityQuestions(updatedQuestions));
  };
 
  
  return (
    <>
      <Header />
      <div className={styles.Reviewcontarctdetail}>
        <div className={styles.Reviewcontarct}>
          <div className="container text-center">
            <figure>
              <img src={overviewpage} alt="" className="mx-auto" />
            </figure>
            <h4 className="text-white mb-2">
              Hey {primaryName ? primaryName : ""}!
            </h4>
            <h3 className="text-white mb-3">
              We are excited to see you reach your goal!
            </h3>
            <p className="text-white text-14">
              You have done a fantastic job so far. Please take a moment to
              review your preview. If there are any changes
              <br /> needed, feel free to edit your form. Once you are ready, we
              can move forward together to the next step.
            </p>
          </div>
        </div>
        <div className="p-3">
          <div className="col-lg-10 mx-auto col-12 reviewquestion">
            <div
              className="d-flex align-items-center justify-content-end mb-3"
              style={{ gap: "15px" }}
            >
              <button
                type="button"
                className="btn btn-transparent border border-2 d-flex align-items-center rounded-pill"
              >
                <i className="pe-2">
                  <img src={print} alt="" />
                </i>
                <span className="text-white">Print</span>
              </button>
              <button
              disabled={downloading as boolean}
                type="button"
                className="btn btn-transparent border border-2 d-flex align-items-center rounded-pill"
                onClick={()=>addImagesToPdf([contentRef,contentRef1,contentRef2],setDownloading)}
              >
                <i className="pe-2">
                  <img src={download} alt="" />
                </i>
                <span className="text-white">{downloading?"Downloading...":"Download"}</span>
              </button>
            </div>
          </div>
          <div   className="col-lg-10 mx-auto col-12 p-2">
            <div
              className="position-relative p-0 rounded-4"
              style={{ background: "#E7EFFB", border: "1px solid #E7EFFB" }}
            >
              <div className={styles.Reviewcontarctform}>
                <ul ref={contentRef} className="ps-0">
                  <OverviewCollapseItem
                    toggleEditing={toggleEditing}
                    toggle={(item) => toggleOption(item)}
                    name={"Who do you need?"}
                    isActive={activeItem == "Who do you need?"}
                  >
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label> Primary Occupation</label>
                        </div>
                        <div className="col-12">

                          <CreatableSelect
                            onCreateOption={(_cb) =>
                              handleCreate(_cb, "PRIMARY_OCCUPATION")
                            }
                            name="dropdown-basic"
                            options={primaryOccOptions as any}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(val) =>
                              handleOccupationChange(val, "primaryOccupation")
                            }
                            value={occupation.primaryOccupation}
                          />
                        </div>
                      </div>
                    </div>

                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Secondary Occupation</label>
                        </div>
                        <div className="col-12 text-center">
                          <CreatableSelect
                            isMulti
                            onCreateOption={(_cb) =>
                              handleCreate(_cb, "SECONDARY_OCCUPATION")
                            }
                            name="colors"
                            options={occupationsList as any}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(val) =>
                              handleOccupationChange(
                                val,
                                "secondaryOccupations"
                              )
                            }
                            value={occupation.secondaryOccupations}
                          />
                        </div>
                      </div>
                    </div>
                  </OverviewCollapseItem>

                  <OverviewCollapseItem
                    toggleEditing={toggleEditing}
                    toggle={(item) => toggleOption(item)}
                    name={"What do you need done?"}
                    isActive={activeItem == "What do you need done?"}
                  >
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Description</label>
                        </div>

                        <div className="col-12 text-center">
                          <TextEditorCustomComp
                            data={contract?.workDescription}
                            setDesc={setDesc}
                          />
                        </div>
                      </div>
                    </div>
                  </OverviewCollapseItem>
                 
                  <OverviewCollapseItem
                    toggleEditing={toggleEditing}
                    toggle={(item) => toggleOption(item)}
                    name={"When is the work to be done?"}
                    isActive={activeItem == "When is the work to be done?"}
                  >
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Start Date</label>
                        </div>

                        <div className="col-12 text-center">
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => ondateChange(date, "startDate")}
                            placeholderText="StartDate"
                            minDate={new Date()}
                            dateFormat="MM-dd-yyyy"
                            className=""
                          />
                        </div>
                      </div>
                    </div>

                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Estimated End Date</label>
                        </div>

                        <div className="col-12 text-center">
                          <DatePicker
                            selected={endDate}
                            onChange={(date) => ondateChange(date, "endDate")}
                            placeholderText="EndDate"
                            minDate={new Date()}
                            dateFormat="MM-dd-yyyy"
                            className=""
                          />
                        </div>
                      </div>
                    </div>
                  </OverviewCollapseItem>

                  <OverviewCollapseItem
                    toggleEditing={toggleEditing}
                    toggle={(item) => toggleOption(item)}
                    name={
                      "How many people are needed and how much will they work?"
                    }
                    isActive={
                      activeItem ==
                      "How many people are needed and how much will they work?"
                    }
                  >
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label># Of People Needed</label>
                        </div>

                        <div className="col-12 text-center">
                          <input
                            type="text"
                            placeholder="Enter the number"
                            name="noOfPeople"
                            value={keyInformation.noOfPeople}
                            onChange={(e) =>
                              onKeyInforChange(
                                Number(e.target.value),
                                "noOfPeople"
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Shift</label>
                        </div>

                        <div className="col-12 text-center">
                          <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic" name="shift">
                              {keyInformation.shift || "Select shift"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <div
                                style={{
                                  maxHeight: "300px",
                                  overflowY: "scroll",
                                }}
                              >
                                {shifts.map((shiftData) => (
                                  <Dropdown.Item
                                    key={shiftData}
                                    onClick={() =>
                                      onKeyInforChange(shiftData, "shift")
                                    }
                                  >
                                    {shiftData}
                                  </Dropdown.Item>
                                ))}
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Planned hours per Day</label>
                        </div>

                        <div className="col-12 text-center">
                          <Dropdown>
                            <Dropdown.Toggle
                              id="dropdown-basic"
                              name="plannedHoursPerDay"
                            >
                              {keyInformation?.plannedHoursPerDay ||
                                "Select planned Hours per day"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <div
                                style={{
                                  maxHeight: "300px",
                                  overflowY: "scroll",
                                }}
                              >
                                {hours.map((hourData) => (
                                  <Dropdown.Item
                                    key={hourData}
                                    onClick={() =>
                                      onKeyInforChange(
                                        Number(hourData),
                                        "plannedHoursPerDay"
                                      )
                                    }
                                  >
                                    {hourData}
                                  </Dropdown.Item>
                                ))}
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>

                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Planned Days per Week</label>
                        </div>
                        <div className="col-12 text-center">
                          <Dropdown>
                            <Dropdown.Toggle
                              id="dropdown-basic"
                              name="plannedDaysPerWeek"
                            >
                              {keyInformation?.plannedDaysPerWeek ||
                                "Select planned Hours per day"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <div
                                style={{
                                  maxHeight: "300px",
                                  overflowY: "scroll",
                                }}
                              >
                                {days.map((dayData) => (
                                  <Dropdown.Item
                                    key={dayData}
                                    onClick={() =>
                                      onKeyInforChange(
                                        Number(dayData),
                                        "plannedDaysPerWeek"
                                      )
                                    }
                                  >
                                    {dayData}
                                  </Dropdown.Item>
                                ))}
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </OverviewCollapseItem>

                  <OverviewCollapseItem
                    toggleEditing={toggleEditing}
                    toggle={(item) => toggleOption(item)}
                    name={"Where will the work take place?"}
                    isActive={activeItem == "Where will the work take place?"}
                  >
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Start typing your address.....</label>
                        </div>

                        <div className="col-12 text-center">
                          {isLoaded && (
                            <Autocomplete
                              onLoad={onLoad}
                              onPlaceChanged={onPlaceChanged}
                            >
                              <input
                                type="text"
                                placeholder="Start typing your address"
                                value={address}
                                onChange={handleAddressChange}
                                onKeyPress={handleKeyPress}
                              // disabled={!isAddressEditable}
                              />
                            </Autocomplete>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Country</label>
                        </div>

                        <div className="col-12 text-center">
                          <input
                            type="text"
                            disabled
                            value={selectedCountry.label}
                          />
                        </div>
                      </div>
                    </div>

                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>State</label>
                        </div>

                        <div className="col-12 text-center">
                          <input
                            type="text"
                            disabled
                            value={selectedState?.label}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>City</label>
                        </div>

                        <div className="col-12 text-center">
                          <input
                            type="text"
                            disabled
                            value={selectedCity.label}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Street</label>
                        </div>

                        <div className="col-12 text-center">
                          <input
                            type="text"
                            disabled
                            value={selectedStreet?.label}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>ZipCode</label>
                        </div>

                        <div className="col-12 text-center">
                          <input
                            type="text"
                            disabled
                            value={selectedZipcode.label}
                          />
                        </div>
                      </div>
                    </div>
                  </OverviewCollapseItem>
                    
                  </ul> 
                  <ul ref={contentRef1} className="ps-0">
                  <OverviewCollapseItem
                    toggleEditing={toggleEditing}
                    toggle={(item) => toggleOption(item)}
                    name={"What will you pay for Hours Worked?"}
                    isActive={
                      activeItem == "What will you pay for Hours Worked?"
                    }
                  >
                    <div className={styles.ratedRadio}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>
                            Select the net payment terms that you will pay for
                            this contract
                          </label>
                        </div>

                        <div className="col-12 text-center mb-3">
                          <div className={styles.AddBtn}>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="primary"
                                id="dropdown-basic"
                              >
                                {selectedTerm}
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                {paymentTerms.map((term) => (
                                  <Dropdown.Item
                                    key={term._id}
                                    onClick={() => handleSelect(term)}
                                  >
                                    {`${term.paymentTerm}`}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={styles.fromGroup}>
                      <div className="row align-items-start justify-content-between">
                        <div className="col-12">
                          <label>Base Rate($ per hour)</label>
                        </div>

                        <div className="col-12 text-center">
                          <input
                            type="number"
                            name="baseRate"
                            placeholder="$/hour"
                            value={rates.baseRate}
                            onChange={({ target }) =>
                              onRateChange(Number(target.value), "baseRate")
                            }
                          />

                          <p className={styles.error}>
                            0-40 Hours Monday-Saturday
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={styles.fromGroup}>
                      <div className="row align-items-start justify-content-between">
                        <div className="col-12">
                          <label>Overtime Rate($ per hour)</label>
                        </div>

                        <div className="col-12 text-center">
                          <input
                            type="text"
                            name="OTrate"
                            placeholder="$/hour"
                            value={rates.otRate}
                            onChange={({ target }) =>
                              onRateChange(Number(target.value), "otRate")
                            }
                          />

                          <p className={styles.error}>
                            41+ Hours Monday-Saturday
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={styles.fromGroup}>
                      <div className="row align-items-start justify-content-between">
                        <div className="col-12">
                          <label>Sunday/Holiday Rate($ per hour)</label>
                        </div>

                        <div className="col-12 text-center">
                          <input
                            type="text"
                            name="DTrate"
                            placeholder="$/hour"
                            value={rates.dtRate}
                            onChange={({ target }) =>
                              onRateChange(Number(target.value), "dtRate")
                            }
                          />

                          <p className={styles.error}>Sunday and US Holidays</p>
                        </div>
                      </div>
                    </div>
                  </OverviewCollapseItem>
                  
                  <OverviewCollapseItem
                    toggleEditing={toggleEditing}
                    toggle={(item) => toggleOption(item)}
                    name={"Travel Time"}
                    isActive={activeItem == "Travel Time"}
                  >
                    <div className={styles.ratedRadio}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <h6>Will there be travel for this job?</h6>
                        </div>

                        <div className="col-12 text-start">
                          <div className={styles.rateradio}>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault1"
                                id="flexRadioDefault1"
                                checked={travelData.isTravelRequired}
                                onChange={() =>
                                  onTravelDataChange(true, "isTravelRequired")
                                }
                              />
                              <label className="form-check-label">Yes</label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault2"
                                id="flexRadioDefault2"
                                checked={!travelData.isTravelRequired}
                                onChange={() =>
                                  onTravelDataChange(false, "isTravelRequired")
                                }
                              />
                              <label className="form-check-label">No</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {travelData.isTravelRequired === true && (
                      <>
                        <div className={styles.fromGroup}>
                          <div className="row align-items-center justify-content-between">
                            <div className="col-12">
                              <label>Travel Time Rate</label>
                            </div>

                            <div className="col-12 text-center">
                              <input
                                type="number"
                                name="travelRate"
                                placeholder="$Enter Amount"
                                value={travelData.travelTimeRate}
                                onChange={(e) =>
                                  onTravelDataChange(
                                    Number(e.target.value),
                                    "travelTimeRate"
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className={styles.fromGroup}>
                          <div className="row align-items-center justify-content-between">
                            <div className="col-12">
                              <label>Travel Tip Allowed(per month)?</label>
                            </div>

                            <div className="col-12 text-center">
                              <Dropdown>
                                <Dropdown.Toggle
                                  id="dropdown-basic"
                                  name="travelTip"
                                >
                                  {travelData?.travelTipAllowedPerMonth ||
                                    "Please Select Travel Tip"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <div
                                    style={{
                                      maxHeight: "300px",
                                      overflowY: "scroll",
                                    }}
                                  >
                                    {trips.map((trip) => (
                                      <Dropdown.Item
                                        key={trip}
                                        onClick={(e) =>
                                          onTravelDataChange(
                                            Number(trip),
                                            "travelTipAllowedPerMonth"
                                          )
                                        }
                                      >
                                        {trip}
                                      </Dropdown.Item>
                                    ))}
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>

                        <div className={styles.fromGroup}>
                          <div className="row align-items-center justify-content-between">
                            <div className="col-12">
                              <label>Travel hours per round trip?</label>
                            </div>

                            <div className="col-12 text-center">
                              <input
                                type="number"
                                name="travelHours"
                                placeholder="$Enter Amount"
                                value={travelData.travelHoursPerRoundTrip}
                                onChange={(e) =>
                                  onTravelDataChange(
                                    Number(e.target.value),
                                    "travelHoursPerRoundTrip"
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </OverviewCollapseItem>

                  <OverviewCollapseItem
                    toggleEditing={toggleEditing}
                    toggle={(item) => toggleOption(item)}
                    name={"ON-SITE Customer Contact"}
                    isActive={activeItem == "ON-SITE Customer Contact"}
                  >
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Primary Name </label>
                        </div>

                        <div className="col-12 text-center">
                          <input
                            type="text"
                            name="primaryName "
                            placeholder="Primary Name "
                            value={primaryName}
                            onChange={(e) =>
                              onDetailChange(e.target.value, "primaryName")
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Primary Phone</label>
                        </div>

                        <div className="col-12 text-center">
                          <input
                            type="text"
                            name="primaryPhoneNumber"
                            placeholder="Primary Phone"
                            value={primaryPhoneNumber}
                            onChange={(e) =>
                              onDetailChange(
                                Number(e.target.value),
                                "primaryPhoneNumber"
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Primary Email</label>
                        </div>

                        <div className="col-12 text-center">
                          <input
                            type="email"
                            name="primaryEmail"
                            placeholder="Primary Email"
                            value={primaryEmail}
                            onChange={(e) =>
                              onDetailChange(e.target.value, "primaryEmail")
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Secondary Name</label>
                        </div>

                        <div className="col-12 text-center">
                          <input
                            type="text"
                            name="secondaryName"
                            placeholder="Secondary Name"
                            value={secondaryName}
                            onChange={(e) =>
                              onDetailChange(e.target.value, "secondaryName")
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Secondary Phone Number </label>
                        </div>

                        <div className="col-12 text-center">
                          <input
                            type="number"
                            name="secondaryPhoneNumber"
                            placeholder="Secondary Phone Number "
                            value={secondaryPhoneNumber}
                            onChange={(e) =>
                              onDetailChange(
                                Number(e.target.value),
                                "secondaryPhoneNumber"
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Secondary Email</label>
                        </div>

                        <div className="col-12 text-center">
                          <input
                            type="email"
                            name="secondaryEmail"
                            placeholder="Secondary Email"
                            value={secondaryEmail}
                            onChange={(e) =>
                              onDetailChange(e.target.value, "secondaryEmail")
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </OverviewCollapseItem>

                  <OverviewCollapseItem
                    toggleEditing={toggleEditing}
                    toggle={(item) => toggleOption(item)}
                    name={"Non Mandatory Hourly Contract Fields"}
                    isActive={
                      activeItem == "Non Mandatory Hourly Contract Fields"
                    }
                  >
                    <div className={styles.fromGroup}>
                      <div className={styles.ratedRadio}>
                        <div className="row align-items-center justify-content-between">
                          <div className="col-12">
                            <label>US Legal Residents Required?</label>
                          </div>

                          <div className="col-12 text-start">
                            <div className={styles.rateradio}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="isLegalResidence"
                                  checked={isLegalResidence}
                                  onChange={(e) =>
                                    onLegalInputdata(true, "isLegalResidence")
                                  }
                                />
                                <label className="form-check-label">Yes</label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="isLegalResidence"
                                  checked={!isLegalResidence}
                                  onChange={(e) =>
                                    onLegalInputdata(false, "isLegalResidence")
                                  }
                                />
                                <label className="form-check-label">No</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {!isLegalResidence && (
                        <div className={styles.ratedRadio}>
                          <div className="row align-items-center justify-content-between">
                            <div className="col-12">
                              <label>
                                {" "}
                                Visa that allows to work legally in the Us
                                Required?
                              </label>
                            </div>

                            <div className="col-12 text-start">
                              <div className={styles.rateradio}>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="isVisa"
                                    id="isVisa"
                                    checked={isVisa}
                                    onChange={(e) =>
                                      onLegalInputdata(true, "isVisa")
                                    }
                                  />
                                  <label className="form-check-label">
                                    Yes
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="isVisa1"
                                    id="isVisa1"
                                    checked={!isVisa}
                                    onChange={(e) =>
                                      onLegalInputdata(false, "isVisa")
                                    }
                                  />
                                  <label className="form-check-label">No</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </OverviewCollapseItem>

                  <OverviewCollapseItem
                    toggleEditing={toggleEditing}
                    toggle={(item) => toggleOption(item)}
                    name={"Eligibility Questions"}
                    isActive={activeItem == "Eligibility Questions"}
                  >
                    {eligibilityQuestions.map((question: string, index: number) => (
                      <div key={index} className="mb-4">
                        <TextEditorCustomComp
                          data={question}
                          setDesc={(newContent: string) =>
                            handleEligibilityQuestionChange(index, newContent)
                          }
                        />
                      </div>
                    ))}
                    {/* <TextEditorCustomComp
                      data={eligibilityQuestions}
                      setDesc={setEligibilityQuestions}
                    /> */}

                  </OverviewCollapseItem>
                         
                  </ul>
                  <ul ref={contentRef2} className="ps-0">
                  <OverviewCollapseItem
                    toggleEditing={toggleEditing}
                    toggle={(item) => toggleOption(item)}
                    name={"Interview Questions"}
                    isActive={activeItem == "Interview Questions"}
                  >
                    {interviewQuestions.map((question: string, index: number) => (
                      <div key={index} className="mb-4">
                        <TextEditorCustomComp
                          data={question}
                          setDesc={(newContent: string) => handleQuestionChange(index, newContent)}
                        />
                      </div>
                    ))}
                    {/* <TextEditorCustomComp
                      data={interviewQuestions}
                      setDesc={setInterviewQuestions}
                    /> */}

                  </OverviewCollapseItem>
                  
                  <OverviewCollapseItem
                    toggleEditing={toggleEditing}
                    toggle={(item) => toggleOption(item)}
                    name={"Programming / Technical Skills"}
                    isActive={activeItem == "Programming / Technical Skills"}
                  >
                    {technicalSkillsData?.skills?.map((x, i) => (
                      <>
                        <div className="text-end my-4">
                          {technicalSkillsData.skills.length > 1 && (
                            <button
                              className="p-1 btn btn-white"
                              type="button"
                              onClick={() => deleteSkill(i)}
                            >
                              <i>
                                <img
                                  src={Delete}
                                  alt=""
                                  width={25}
                                  height={25}
                                />
                              </i>
                            </button>
                          )}
                        </div>
                        <div
                          className="rounded-3 p-3"
                          style={{ background: "rgba(255, 255,255, 0.5)" }}
                        >
                          <div className={styles.fromGroup}>
                            <div className="row align-items-center justify-content-between">
                              <div className="col-12">
                                <label>Manufacturer</label>
                              </div>

                              <div className="col-12 text-start">
                                <CreatableSelect
                                  onCreateOption={(_cb) =>
                                    handleCreate(_cb, "_MANUFACTURERS")
                                  }
                                  name="dropdown-basic"
                                  options={filteredCategories as any}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={(val) =>
                                    handleChangeManufacturer(val, i, "category")
                                  }
                                  value={x.category}
                                />
                              </div>
                            </div>
                          </div>
                          <div className={styles.fromGroup}>
                            <div className="row align-items-center justify-content-between">
                              <div className="col-12">
                                <label>Model / Version / Controller</label>
                              </div>

                              <div className="col-12 text-start">
                                <CreatableSelect
                                  // onCreateOption={(_cb) => handleCreate(_cb, "PRIMARY_OCCUPATION")}
                                  name="colors"
                                  options={x.subCategoriesList}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={(val) =>
                                    onSkillsChange(val as any, "subCategory", i)
                                  }
                                  isMulti
                                  value={x.subCategory}
                                />
                              </div>
                            </div>
                          </div>
                          <div className={styles.fromGroup}>
                            <div className="row align-items-center justify-content-between">
                              <div className="col-12">
                                <label>Skills</label>
                              </div>

                              <div className="col-12 text-start">
                                <div
                                  className={`${styles.radioBTn} ${styles.radStepOne}`}
                                >
                                  <div>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name={`flexRadioDefault${i}`}
                                        id={`flexRadioDefault1${i}`}
                                        checked={x.isRequired}
                                        onChange={(e) =>
                                          onSkillsChange(true, "isRequired", i)
                                        }
                                      />
                                      <label className="form-check-label">
                                        Mandatory
                                      </label>
                                    </div>
                                  </div>
                                  <div>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name={`flexRadioDefault2${i}`}
                                        id={`flexRadioDefault2${i}`}
                                        checked={!x.isRequired}
                                        onChange={(e) =>
                                          onSkillsChange(false, "isRequired", i)
                                        }
                                      // disabled={
                                      //   inEditField !==
                                      //   OVERVIEW_FORM_FIELDS.SKILLS_REQUIRED
                                      // }
                                      />
                                      <label className="form-check-label">
                                        Good to have
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </OverviewCollapseItem>

                  <OverviewCollapseItem
                    toggleEditing={toggleEditing}
                    toggle={(item) => toggleOption(item)}
                    name={"Software Licenses "}
                    isActive={activeItem == "Software Licenses "}
                  >
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Choose Software Licenses</label>
                        </div>

                        <div className="col-12 text-start">
                          <CreatableSelect
                            isMulti
                            onCreateOption={(_cb) =>
                              handleCreate(_cb, "SOFTWARE_LICENCES")
                            }
                            name="softwareLicenses"
                            options={options as any}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(val) =>
                              onSoftwareLicenseChange(val, "softwareLicenses")
                            }
                            value={softwareLicenses}
                          />
                        </div>
                        <div className="col-12 text-start mt-3">
                          <div
                            className={`${styles.radioBTn} ${styles.radStepOne}`}
                          >
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name={`isRequired$`}
                                id={`isRequired1$`}
                                checked={isRequired}
                                onChange={() =>
                                  onSoftwareLicenseChange(true, "isRequired")
                                }
                              />
                              <label className="form-check-label">
                                Mandatory
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name={`flexRadioDefault2$`}
                                id={`flexRadioDefault2$`}
                                checked={!isRequired}
                                onChange={() =>
                                  onSoftwareLicenseChange(false, "isRequired")
                                }
                              />
                              <label className="form-check-label">
                                Good to have
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </OverviewCollapseItem>

                  <OverviewCollapseItem
                    toggleEditing={toggleEditing}
                    toggle={(item) => toggleOption(item)}
                    name={"Drug Test and Backgroud Check"}
                    isActive={activeItem == "Drug Test and Backgroud Check"}
                  >
                    <>
                      <div className={styles.fromGroup}>
                        <div className="row align-items-center justify-content-between">
                          <div className="col-12">
                            <label>Drug Test Required</label>
                          </div>
                          <div className="col-12 text-start">
                            <div
                              className={`${styles.radioBTn} ${styles.radStepOne}`}
                            >
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name={`isDrugTestRequired$`}
                                  id={`isDrugTestRequired$`}
                                  checked={isDrugTestRequired}
                                  onChange={() =>
                                    onDrugTestValue(true, "isDrugTestRequired")
                                  }
                                />
                                <label className="form-check-label">Yes</label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name={`isDrugTestRequired$`}
                                  id={`isDrugTestRequired$`}
                                  checked={!isDrugTestRequired}
                                  onChange={() =>
                                    onDrugTestValue(false, "isDrugTestRequired")
                                  }
                                />
                                <label className="form-check-label">No</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {isDrugTestRequired && (
                        <>
                          <div className={styles.fromGroup}>
                            <div className="row align-items-center justify-content-between">
                              <div className="col-12">
                                <label>Drug Test Recency</label>
                              </div>

                              <div className="col-12 text-center">
                                <div className={styles.AddBtn}>
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      id="dropdown-basic"
                                      name="travelTip"
                                    >
                                      {drugRegacy ||
                                        "How many days old can the test be?"}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <div
                                        style={{
                                          maxHeight: "300px",
                                          overflowY: "scroll",
                                        }}
                                      >
                                        {backgroundCheckOptions.map((item) => (
                                          <Dropdown.Item
                                            key={item}
                                            onClick={() =>
                                              onDrugTestValue(
                                                item,
                                                "drugRegacy"
                                              )
                                            }
                                          >
                                            {item}
                                          </Dropdown.Item>
                                        ))}
                                      </div>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                    <>
                      <div className={styles.fromGroup}>
                        <div className="row align-items-center justify-content-between">
                          <div className="col-12">
                            <label>Background check Required</label>
                          </div>
                          <div className="col-12 text-start">
                            <div
                              className={`${styles.radioBTn} ${styles.radStepOne}`}
                            >
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  placeholder="How many days old can the Check be?"
                                  name={`isBackgroundCheckRequired$`}
                                  id={`isBackgroundCheckRequired1$`}
                                  checked={isBackgroundCheckRequired}
                                  onChange={() =>
                                    onBackgroudCheck(
                                      true,
                                      "isBackgroundCheckRequired"
                                    )
                                  }
                                />
                                <label className="form-check-label">Yes</label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name={`isBackgroundCheckRequired$`}
                                  id={`isBackgroundCheckRequired1$`}
                                  checked={!isBackgroundCheckRequired}
                                  onChange={() =>
                                    onBackgroudCheck(
                                      false,
                                      "isBackgroundCheckRequired"
                                    )
                                  }
                                />
                                <label className="form-check-label">No</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {isBackgroundCheckRequired && (
                        <div className={styles.fromGroup}>
                          <div className="row align-items-center justify-content-between">
                            <div className="col-12">
                              <label>Background check Recency</label>
                            </div>

                            <div className="col-12 text-center">
                              <div className={styles.AddBtn}>
                                <Dropdown>
                                  <Dropdown.Toggle
                                    className="form-check-input"
                                    id="dropdown-basic"
                                    name="travelTip"
                                  >
                                    {backgroundCheck?.backgroundRegecy ||
                                      "How many days old can the Check be?"}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <div
                                      style={{
                                        maxHeight: "300px",
                                        overflowY: "scroll",
                                      }}
                                    >
                                      {backgroundCheckOptions.map((item) => (
                                        <Dropdown.Item
                                          key={item}
                                          onClick={() =>
                                            onBackgroudCheck(
                                              item,
                                              "backgroundRegecy"
                                            )
                                          }
                                        >
                                          {item}
                                        </Dropdown.Item>
                                      ))}
                                    </div>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  </OverviewCollapseItem>

                  <OverviewCollapseItem
                    toggleEditing={toggleEditing}
                    toggle={(item) => toggleOption(item)}
                    name={"Work Location"}
                    isActive={activeItem == "Work Location"}
                  >
                    <>
                      {workLocation && (
                        <>
                          <div className={styles.fromGroup}>
                            <div className="row align-items-center justify-content-between">
                              <div className="col-12">
                                <label>Work Location</label>
                              </div>

                              <div className="col-12 text-center">
                                <div className={styles.AddBtn}>
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      id="dropdown-basic"
                                      name="travelTip"
                                    >
                                      {workLocation?.workLocation ||
                                        "Select your work location"}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <div
                                        style={{
                                          maxHeight: "300px",
                                          overflowY: "scroll",
                                        }}
                                      >
                                        {workLocationOptions.map((item) => (
                                          <Dropdown.Item
                                            key={item}
                                            onClick={() =>
                                              onWorkLocation(
                                                item,
                                                "workLocation"
                                              )
                                            }
                                          >
                                            {item}
                                          </Dropdown.Item>
                                        ))}
                                      </div>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  </OverviewCollapseItem>

                  <OverviewCollapseItem
                    toggleEditing={toggleEditing}
                    toggle={(item) => toggleOption(item)}
                    name={"Procedures and Policies"}
                    isActive={activeItem == "Procedures and Policies"}
                  >
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Procedures and Policies</label>
                        </div>

                        <div className="col-12 text-center">
                          <TextEditorCustomComp
                            data={proceduresAndPolicies.proceduresAndPolicies}
                            setDesc={setPolicy}
                          />
                        </div>
                        {/* {inEditField !==
                          OVERVIEW_FORM_FIELDS.PROCEDURES_AND_POLICIES && (
                          <div
                            className="col-1 text-center"
                            onClick={() =>
                              setInEditField(
                                OVERVIEW_FORM_FIELDS.PROCEDURES_AND_POLICIES
                              )
                            }
                          >
                            <i className="pe-2">
                              <img src={editicon} alt="" />
                            </i>
                          </div>
                        )} */}
                      </div>
                    </div>
                  </OverviewCollapseItem>
                  </ul>
                  
                
              </div>
            </div>
          </div>
          <div className="col-lg-10 mx-auto col-12">
            <div className={styles.regisFooter}>
              <div className="col-xl-8 col-12 ms-auto">
                <div className="row align-items-center justify-content-end">
                  <div className="col-xl-3 col-lg-4 col-6 text-start my-3 btn-hover-effect">
                    <h6
                      onClick={() => handleGoBack()}
                      className="btn arrowyellow border-grey border-1 position-relative ps-5 backbutton"
                    >
                      <span className="px-lg-3 px-2 text-12 fw-semibold d-inline-block">
                        Back
                      </span>
                      <i className="bg-yellow arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                        <img src={blackarrow} alt="" />
                      </i>
                    </h6>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-6 text-center">
                    <div className={styles.DraftBtns}>
                      <SaveAsDraftButton />
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-4 col-12 text-end my-3 btn-hover-effect">
                    <h6
                      onClick={handleSendForApproval}
                      className="btn arrowblue border-grey border-1 position-relative pe-5"
                    >
                      <span className="px-lg-4 px-2 text-12 fw-semibold">
                        Send For Approval
                      </span>
                      <i className="bg-blue arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ">
                        <img src={whitearrow} alt="" />
                      </i>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalDetail.show && (
        <CustomModal
          key={key}
          show={modalDetail.show}
          backdrop="static"
          showCloseBtn={true}
          isRightSideModal={false}
          mediumWidth={false}
          size="xl"
          header="Choose the plan that fits your needs."
          className={modalDetail.flag === "plan" ? "plan" : ""}
          child={
            modalDetail.flag === "plan" ? (
              <Subscription onClosePlanModal={() => handleOnCloseModal()} />
            ) : (
              ""
            )
          }
          onCloseModal={() => handleOnCloseModal()}
        />
      )}
    </>
  );
};

export default OverviewContract;
