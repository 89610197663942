import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DIRECT_JOB_STEPS } from '../../components/contractBeforeLogin/DirectJobType/AddDirectJob/Enums';
import { AddDirectJobRootState, ChooseOccupation, } from '../../models';
import { IOption } from '../../Interfaces';
import { KeyInformations, Locations, Rate, TechnicalSkillsDataDirectJob } from '../../models/AddDirectjobRoosState';
import { WORK_STATUS_TYPE } from '../../utils/enums/status';
import { Additionfiels } from '../../Interfaces/saveDirectJobApiRequestBody';


const initialState = {
  ...new AddDirectJobRootState({
    started: false,
    activeStep: DIRECT_JOB_STEPS.ADD_OCCUPATION,
    contractType: "direct_job",
    jobStatus: WORK_STATUS_TYPE.DRAFT,
    occupationsList: [],
    occupation: {
      primaryOccupation: undefined,
      secondaryOccupations: [],
    },

    keyInformations: {
      noOfPeople: 0,
      startDate: new Date(),
      shift: "",
      plannedHoursPerDay: 0,
      plannedDaysPerWeek: 0,
    },

    location: {
      selectedCountry: { label: "", value: "" },
      selectedCity: { label: "", value: "" },
      selectedState: { label: "", value: "" },
      selectedStreet: { label: "", value: "" },
      selectedZipcode: { label: '', value: '' },
      isFilled: false,
      cities: [],
      countries: [],
      states: [],
      streets: [],
      zipcode: [],
    },
    rates: {
      baseRate: 0,
      dtRate: 0,
      otRate: 0,
    },

    technicalSkillsData: {
      skillTypes: [],
      skills: [
        {
          isRequired: false,
          category: { label: "", value: "" },
          subCategoriesList: [],
          subCategory: [],
        },
      ],
    },
    eligibilityQuestions: [""],
    interviewQuestions: [""],
  }),
};

const addDirectJobSlice = createSlice({
  name: 'addDirectJob',
  initialState,
  reducers: {
    setDirectJobActiveStep: (
      state,
      action: PayloadAction<DIRECT_JOB_STEPS>
    ) => {
      state.activeStep = action.payload;
    },
    initDirectJob: (state, action: PayloadAction<AddDirectJobRootState>) =>
      action.payload,
    setActiveStep: (state, action: PayloadAction<DIRECT_JOB_STEPS>) => {
      state.activeStep = action.payload;
    },
    setContractType: (state, action: PayloadAction<string>) => {
      state.contractType = action.payload;
    },
    setAdditionalFields: (state, action: PayloadAction<Additionfiels>) => {
      state.additionfiels = { ...action.payload };
    },
    setOccupation: (state, action: PayloadAction<ChooseOccupation>) => {
      state.occupation = { ...action.payload };
    },
    setOccupationsList: (state, action: PayloadAction<IOption[]>) => {
      state.occupationsList = [...action.payload];
    },
    setWorkDescription: (state, action: PayloadAction<string>) => {
      state.workDescription = action.payload;
    },

    setKeyInformation: (state, action: PayloadAction<KeyInformations>) => {
      state.keyInformations = { ...action.payload };
    },
    setLocation: (state, action: PayloadAction<Locations>) => {
      state.location = { ...action.payload };
    },
    setRates: (state, action: PayloadAction<Rate>) => {
      state.rates = { ...action.payload };
    },

    setEligibilityQuestions: (state, action: PayloadAction<string[]>) => {
      state.eligibilityQuestions = [...action.payload];
    },
    setInterviewQuestions: (state, action: PayloadAction<string[]>) => {
      state.interviewQuestions = [...action.payload];
    },
    setTechnicalSkills: (state, action: PayloadAction<TechnicalSkillsDataDirectJob>) => {
      state.technicalSkillsData = { ...action.payload };
    },

    setStarted: (state, action: PayloadAction<boolean>) => {
      state.started = action.payload;
    },

  },
});

export const { setDirectJobActiveStep,
  initDirectJob,
  setActiveStep,
  setOccupation,
  setOccupationsList,
  setWorkDescription,
  setKeyInformation,
  setLocation,
  setRates,
  setEligibilityQuestions,
  setInterviewQuestions,
  setStarted,
  setAdditionalFields,
  setTechnicalSkills,

} = addDirectJobSlice.actions;


export default addDirectJobSlice.reducer;
