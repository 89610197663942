import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RecommendedjobsProps } from "../../../interface";
import { APP_ROUTES } from "../../../../../utils/routes/AppRoutes";
import EligibiltyModal from "../../../../common/modals/eligibilty/page";
import location from "../../../../../assets/images/icon_location.svg";
import profilepicture from "../../../../../assets/images/professional_profile.png";
import flag from "../../../../../assets/images/flag.svg";
import fly from "../../../../../assets/images/airoplane.svg";
import avtar from "../../../../../assets/images/avatarProfile.png";
import share from "../../../../../assets/images/share_profile.svg";
import styles from "../styles/style.module.css";
import Slider from "react-slick";
import { getAllUserProfiles } from "../../../../findProfessionals/api";


const Professionals = ({ workList, refresh }: RecommendedjobsProps) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [workId, setWorkId] = useState("");
  const [professionals, setProfessionals] = useState<any>([])


  const handleViewProfile = (id: string) => {
    navigate(`${APP_ROUTES.USER_PROFILE}/${id}`);
  };
  // const handleShowModal = (value: string) => {
  //   setShowModal(true);
  //   setWorkId(value);
  //   localStorage.setItem("workId", value);
  // };
  const getProfessionalList = async () => {
    const response = await getAllUserProfiles()
    setProfessionals(response.data)
  }
  useEffect(() => {
    getProfessionalList()
  }, [])

  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 3.2,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3.2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      {showModal && (
        <EligibiltyModal onClose={() => setShowModal(false)} workId={workId} />
      )}

      <div className="recommendedjobs">
        <div className="row align-items-center justify-content-between">
          <Slider {...settings}>
            {professionals?.map((item: any, idx: number) => {
              return (
                <div key={idx} className="col border rounded-3">
                  <div className="row flex-column align-items-center justify-content-between mb-2 bg-lightblue py-3 mx-0">
                    <div className="row align-items-center justify-content-center gx-2">
                      <div className="col-3 profile_icon">
                        <figure className={styles.profileCircle}>
                          <img src={item?.profilePicture ? item?.profilePicture : avtar} alt="" className="img-fluid rounded-circle" />
                        </figure>
                      </div>
                      <div className="col-9 ps-xl-4 ps-2">
                        <div className="d-flex align-items-start justify-content-between">
                          <div className="col-8">
                            <div className="newheadingstyle">
                              <h3 className="mb-0 text-16 clamp clamp1">
                                {item?.name}
                              </h3>
                              <p className="text-12 my-1">  {item?.occupation?.name}</p>
                            </div>
                          </div>
                          <div className="col-2">
                            <i>
                              <img src={flag} alt="" />
                            </i>
                          </div>
                        </div>
                        <div className="">
                          <p className="text-12 d-flex align-items-center mb-2">
                            <i className="me-1">
                              <img
                                src={location}
                                alt=""
                                width={15}
                                height={14}
                              />
                            </i>
                            {item.address}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="row align-items-center gx-2">
                      <div className="col-10">
                        <ul className="d-flex mb-0 align-items-center justify-content-start ps-0">
                          {/* <li className="d-inline-block pe-2">
                            <p className="mb-0 bg-lightblue text-blue rounded-pill text-center text-11 d-inline-block px-2 py-1">

                            </p>
                          </li> */}
                          <li className="d-inline-block pe-2">
                            <p className="mb-0 bg-lightblue text-blue rounded-pill text-center text-11 d-inline-block px-2 py-1">
                              {item?.isAvailable === true
                                ? "Available"
                                : "UnAvailable"}
                            </p>
                            <p className="mb-0 bg-lightgreen rounded-pill text-center text-11 d-inline-flex align-items-center px-2 py-1">
                              <i className="me-1"><img src={fly} alt="" /></i>
                              Willing totravel : Yes
                            </p>
                          </li>
                        </ul>
                      </div>
                      <div className="col-2">
                        <figure className="bg-white rounded-circle p-2 py-lg-3 py-2 mb-0 d-flex align-items-center justify-content-center">
                          <img src={share} alt="" />
                        </figure>
                      </div>
                    </div>
                  </div>
                  <div className="p-3">
                    {/* <div className="">
                      <p className="alert light-danger text-12 p-2 d-inline-flex align-items-center mb-2  py-2">
                        <i className="me-1">
                          <img src={Jobs} alt="" width={15} height={14} />
                        </i>
                        Hourly Contract
                      </p>
                    </div> */}
                    <div className="skills">
                      {item?.skilledInfo?.length > 0 &&
                        <div className="newheadingstyle">
                          <h3 className="text-14">Skills</h3>
                        </div>
                      }
                      <ul className="ps-0 d-flex flex-wrap align-items-center justify-content-start gap-2 mx-0">
                        {item.skilledInfo?.map((skill: any, idx: number) => {
                          return (
                            <li
                              key={idx}
                              className="col badge text-bg-light text-grey fw-normal text-12"
                            >
                              {skill?.name}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    {item?.rates &&
                      <div className={styles.payrates}>
                        <h3 className="fw-semibold mt-4 text-14">Pay Rates</h3>
                        <ul className="row g-3 ps-0 mb-0 mt-2">
                          <li className="col mt-0">
                            <div className="">
                              <h3 className="text-13 fw-noraml mb-2">Base</h3>
                              <p className="text-primary text-16 mb-2 fw-semibold">
                                ${item?.rates?.baseRate}
                              </p>
                            </div>
                          </li>
                          <li className="col mt-0">
                            <div className="">
                              <h3 className="text-13 fw-noraml mb-2">Overtime</h3>
                              <p className="text-primary text-16 mb-2 fw-semibold">
                                ${item?.rates?.overTimeRate}
                              </p>
                            </div>
                          </li>
                          <li className="col mt-0">
                            <div className="">
                              <h3 className="text-13 fw-noraml mb-2">
                                Sun/Holidays
                              </h3>
                              <p className="text-primary text-16 mb-2 fw-semibold">
                                ${item?.rates?.doubleTimeRate}
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    }

                    <div className="mt-3">
                      <div className="row align-items-center g-2">

                        <div className="col">
                          <button
                            onClick={() => handleViewProfile(item._id)}
                            type="button"
                            className="btn btn-primary w-100 text-12"
                          >
                            View Profile
                          </button>
                        </div>

                        <div className="col">
                          <button
                            type="button"
                            className="btn btn-white w-100 text-center border text-12"
                          >
                            Request
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default Professionals;
