import React, { useEffect, useState } from 'react';
import InterViewQuestions from '../interviewQuestion/page';
import { EligibilityQuestions } from './eligibilityQuestions';
import { APP_ROUTES } from '../../../../utils/routes/AppRoutes';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { setAnswers, setInterviewAns } from '../../../../redux/reducers/dashboard';
import { COUNT_TYPE } from '../../../../utils/enums/types';
import { InterviewQus, Question } from '../../Contracts/interface';
import { getWorkDetailWithCount } from '../../Contracts/api';
import EnhanceProfileModal from './modals/EnhanceProfileModal';
import LiabilityInsuranceModal from './modals/LiabilityInsuranceModal';
import ApplyDocumentsModal from './modals/ApplyDocumentsModal';
import DocumentReviewModal from './modals/DocumentReviewModal';
import storage from '../../../../utils/storage';

enum APPLY_JOB_TABS {
  ELIGIBILITY_QUESTIONS,
  INTERVIEW_QUESTIONS,
  ENHANCE_PROFILE,
  LIABILITY_INSURANCE,
  UPLOAD_DOCUMENT,
  DOCUMENT_REVIEW
}

interface EligibilityProps {
  workId: string;
  onClose: () => void;
}

const EligibiltyModal: React.FC<EligibilityProps> = ({ onClose, workId }) => {
  const [activeTab, setActiveTab] = useState<APPLY_JOB_TABS>(
    APPLY_JOB_TABS.ELIGIBILITY_QUESTIONS
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [interviewQuestion, setInterviewQuestions] = useState<InterviewQus[]>([]);
  const { eligibilityAnswers, interviewAnswers } = useSelector(
    (state: RootState) => state.dashboard
  );
  const [showEnhanceProfileModal, setShowEnhanceProfileModal] = useState<boolean>(false);
  const [showLiabilityInsuranceModal, setShowLiabilityInsuranceModal] = useState<boolean>(false);
  const [showAlertText, setShowAlertText] = useState<boolean>(false);
  const [showSaveBtn, setShowSaveBtn] = useState<boolean>(true);
  const [showSaveInterviewBtn, setShowInterviewBtn] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(true);
  const [profileImg, setProfileImg] = useState<string>(storage.getUserProfileImg());

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    console.log("Current activeTab:", activeTab);
  }, [activeTab]);

  useEffect(() => {
    const getPostedContracts = async () => {
      const response = await getWorkDetailWithCount(workId, COUNT_TYPE.WORK_APPLY);
      setQuestions(response.data.eligibilityQuestions);
      setInterviewQuestions(response?.data?.interviewQuestions);
      setLoading(false);
    };
    getPostedContracts();
  }, [workId]);

  const onAnsChange = (value: boolean, index: number, id: string, key?: string) => {
    const updatedQuestions = [...eligibilityAnswers];
    updatedQuestions[index] = { ...updatedQuestions[index], Answers: value, questionId: id };
    dispatch(setAnswers(updatedQuestions));
    if (key === 'No') {
      setShowAlertText(true);
      setShowSaveBtn(false);
      return;
    }
    setShowAlertText(false);
    const allAnswersValid = updatedQuestions.every((question) => question.Answers !== false);
    setShowSaveBtn(allAnswersValid);
  };

  const answerChange = (value: string, index: number, id: string) => {
    const updatedInterQuestions = [...interviewAnswers];
    const updatedAns = { ...updatedInterQuestions[index], Answers: value, questionId: id };
    updatedInterQuestions[index] = updatedAns;
    dispatch(setInterviewAns(updatedInterQuestions));
    const hasData = updatedInterQuestions.some((item) => item.Answers?.trim() !== '');
    setShowInterviewBtn(hasData);
  };

  const handleEligibilityNext = () => {
    setActiveTab(APPLY_JOB_TABS.INTERVIEW_QUESTIONS);
  };

  const handleInterviewNext = () => {
    setActiveTab(APPLY_JOB_TABS.ENHANCE_PROFILE);
  };

  const handleEnhanceProfileNext = () => {
    setActiveTab(APPLY_JOB_TABS.LIABILITY_INSURANCE);
  };
  const handleLiabilityNext = () => {
    navigate(`${APP_ROUTES.JOBDETAIL}/${workId}`);
  }
  const handleSubmitDocument=()=>{
    setActiveTab(APPLY_JOB_TABS.DOCUMENT_REVIEW)
  }
  const handleContinue=()=>{
    navigate(`${APP_ROUTES.JOBDETAIL}/${workId}`);
  }
  const uploadDocument=()=>{
    setActiveTab(APPLY_JOB_TABS.UPLOAD_DOCUMENT)
  }
  const onEdit =()=>{
navigate("/editProfile")
  }
  const onApply=()=>{
    navigate('')
  }

  const handleClose = () => {
    onClose();
  };



  return (
    <>
      {activeTab === APPLY_JOB_TABS.ELIGIBILITY_QUESTIONS && (
        <EligibilityQuestions
          questions={questions}
          loading={loading}
          showModal={showModal}
          onAnsChange={onAnsChange}
          onNext={handleEligibilityNext}
          onClose={handleClose}
          showAlertText={showAlertText}
          showSaveBtn={showSaveBtn}
        />
      )}
      {activeTab === APPLY_JOB_TABS.INTERVIEW_QUESTIONS && (
        <InterViewQuestions
          onClose={handleClose}
          onNext={handleInterviewNext}
          showModal={showModal}
          interviewQus={interviewQuestion}
          answerChange={answerChange}
          showSaveInterviewBtn={showSaveInterviewBtn}
        />
      )}
      {activeTab === APPLY_JOB_TABS.ENHANCE_PROFILE && (
        <>
          {console.log("EnhanceProfileModal should render")}
          <EnhanceProfileModal onClose={handleClose} onNext={handleEnhanceProfileNext} onEdit={onEdit} showModal={showModal} profileImg={profileImg}/>
        </>
      )}
      {activeTab === APPLY_JOB_TABS.LIABILITY_INSURANCE && (
        <LiabilityInsuranceModal onNext={handleLiabilityNext} onUpload={uploadDocument} onApply={onApply} showModal={showModal} onClose={handleClose} />
      )}
      {activeTab === APPLY_JOB_TABS.UPLOAD_DOCUMENT && (
        <ApplyDocumentsModal onClose={handleClose}  showModal={showModal}  onNext={handleSubmitDocument} />
      )}
      {activeTab===APPLY_JOB_TABS.DOCUMENT_REVIEW && (
        <DocumentReviewModal onClose={handleClose}  showModal={showModal} onNext={handleContinue}/>
      )}

    </>
  );
};

export default EligibiltyModal;
