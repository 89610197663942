import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface BusinessDetails {
  businessName: string;
  _id: string;
  groupName: string; 
}

const initialState: BusinessDetails = {
  businessName: "",
  _id: "",
  groupName: "", 
};

const businessSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    setBusinessDetails(state, action: PayloadAction<BusinessDetails>) {
      state.businessName = action.payload.businessName;
      state._id = action.payload._id;
      state.groupName = action.payload.groupName; 
    },
    setGroupName(state, action: PayloadAction<string>) {
      state.groupName = action.payload; 
    },
  },
});

export const { setBusinessDetails, setGroupName } = businessSlice.actions;

export default businessSlice.reducer;
