import React, { useState } from "react";
import styles from "../styles/style.module.css";
import Search from "../../search/routes";
import info from "../../../assets/images/info_icon.svg";
import Plus from "../../../assets/images/plus.png";
import DateTime from "../../Datetimefilter/routes";
import SortFilter from "../../Sortfilter/routes";
import Ongoingcontracts from "../../ongoingcontracts/routes";
import { FEED } from "../../../utils/feed";
import { ReccommendedWork } from "../interface";
import { getRecommendedWork } from "../api";
import { CONSTANT_MANAGER } from "../../../utils/constans/constants";
import Toltip from "../../common/toltip/page";
import tip from "../../../assets/images/tip.png";
const OngoingContractsComp = () => {
  const [contracts, setContracts] = useState<ReccommendedWork[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [rfq, setRfq] = useState<ReccommendedWork[]>([]);
  const [jobs, setJobs] = useState<ReccommendedWork[]>([]);
  const [filterValue, setFilterValue] = useState<string>("");

  const refresh = () => {
    setLoading(true);
    getRecommendedWork(FEED.WORK_TYPES.RFQ).then((res) => {
      setRfq(res.data);
    });
    getRecommendedWork(FEED.WORK_TYPES.DIRECT_JOB).then((res) => {
      setJobs(res.data);
    });
    getRecommendedWork(FEED.WORK_TYPES.HOURLY_CONTRACT).then(
      (res) => {
        setContracts(res.data);
        setLoading(false);
      }
    );
  };
  return (
    <div className={styles.ongoingcontracts}>
      <div className="container">
        <div className={styles.new_feeds}>
          <div className="">
            <div className="row align-items-center justify-content-between mb-3">
              <div className="col-6">
              <Search filterValue={filterValue} setFilterValue={setFilterValue} />
              </div>
              <div className="col-6 ">
                <div className="text-end">
                  <div className="col-xl-4 col-12 ms-auto">
                    <button className="btn btn-primary h-40 text-14  d-flex align-items-center justify-content-center">
                      <i className="me-2">
                        <img src={Plus} alt="" width={10} height={10} />
                      </i>
                      Post New Contract
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center justify-content-between mb-3 g-3">
              <div className="col-lg-6 col-12">
                <div className="newheadingstyle">
                  <div className="topTipHover">
                    <div className="d-flex align-items-center">
                      <h4 className="form-check-label text-20 fw-bold  font-inter">
                        Ongoing Contracts
                      </h4>
                      <i>
                        <img className="customeTolImg" src={tip} alt="" />
                      </i>
                      <Toltip
                        slug={CONSTANT_MANAGER.SLUG_FEED.FEED}
                        labelId={CONSTANT_MANAGER.FEED_DATA.ONGOING_CONTRACTS}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="filter_sort">
                  <div className="ms-auto">
                    <div className="row align-items-center justify-content-end">
                      <div className="col-md-5">
                        <DateTime />
                      </div>

                      {/* <div className="col-md-5">
                        <SortFilter />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Ongoingcontracts
              workList={contracts}
              refresh={refresh}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OngoingContractsComp;
