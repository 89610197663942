import React, { useRef, useState } from "react";
import Header from "../../header/routes";
import { useNavigate } from "react-router-dom";
import search from "../../../assets/images/search.svg";
import styles from "../styles/style.module.css";
import { FormControl } from "react-bootstrap";

interface Props {
  filterValue: string;
  setFilterValue: (value: string) => void;
  placeholder?: string;
}

const Search: React.FC<Props> = ({filterValue, setFilterValue, placeholder}) => {
  const [inputValue, setInputValue] = useState<string>(filterValue);
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value); // Update input field value

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      setFilterValue(e.target.value); // Update the filter value with debounce
    }, 500); // 500ms debounce delay
  };

  // const navigate = useNavigate();
  // const handleGoToNext = () => {
  //   navigate("/feeds");
  // };

  return (
    <>
      <div className="form-search col-10">
        <span className="input-search bg-white">
          <i>
            <img src={search} alt="" width={15} height={15} />
          </i>
        </span>
        {/* <input
          type="text"
          className="form-control text-13 text-grey"
          placeholder="Search"
          aria-label="Username"
          aria-describedby="" 
          value={value}
          onChange={(e) => setValue(e.target.value)}
        /> */}
        <FormControl
          autoFocus
          className="form-control text-13 text-grey"
          placeholder={placeholder}
          onChange={handleChange}
          value={inputValue}
        />
      </div>
    </>
  );
}

export default Search;
