import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface BusinessGroup {
  _id: string;
  name: string;
  createdBy: string;
  businessId: string;
  status: string;
}

interface BusinessGroupState {
  businessGroupData: BusinessGroup[];
}

const initialState: BusinessGroupState = {
  businessGroupData: [],
};

const businessGroupSlice = createSlice({
  name: 'businessGroup',
  initialState,
  reducers: {
    setBusinessGroupData(state, action: PayloadAction<BusinessGroup[]>) {
      state.businessGroupData = action.payload;
    },
    addBusinessGroup(state, action: PayloadAction<BusinessGroup>) {
      state.businessGroupData.push(action.payload);
    },
    clearBusinessGroupData(state) {
      state.businessGroupData = [];
    },
  },
});

export const { setBusinessGroupData, addBusinessGroup, clearBusinessGroupData } = businessGroupSlice.actions;

export default businessGroupSlice.reducer;
