import { axios } from "../../../lib/axios";
import { AxiosResponse } from "../../../shared/models";
import { API_ROUTES } from "../../../utils/routes/apiRoutes";
import { ApiResponse } from "../interface";


  export const getRecommendedWork = async (data:any): Promise<ApiResponse> => {
    const { search, limit, page, viewType , startDate, endDate,occupationId,workType,workTypeState,locationSearch} = data;
    let url = `${API_ROUTES.FEED.GET_RECCOMENDED}?limit=${limit}&page=${page}&type=${viewType}`

    if (search) {
      url += `&search=${data.search}`;
    }
    if (locationSearch) {
      url += `&locationSearch=${data.locationSearch}`;
    }


    // const response =await  axios.get(url);

    console.log("...", startDate)
  if (startDate && endDate) {
    url += `&startDate=${startDate}&endDate=${endDate}`;
  }
  if (occupationId) {
    url += `&occupationId=${data.occupationId}`;
  }
  if (workType) {
    url += `&workType=${data.workType}`;
  }
  if (workTypeState) {
    url += `&workTypeState=${data.workTypeState}`;
  }
      const response = await axios.get(url);
      // Restructure the response to match ApiResponse
      console.log(response)
    return  response
  };



  export const getWorkDetailById = async (id: any ): Promise<AxiosResponse<ApiResponse>> => {
    return axios.get(`${API_ROUTES.WORK.GET_WORKDETAILS_COUNTS}/${id}`);
  };