import React from "react";
import styles from "../../css/styles/style.module.css"
import loc from "../../../../assets/images/loc.png";
import gprs from "../../../../assets/images/gprs.png";
import blsearch from "../../../../assets/images/blsearch.png";
import view from "../../../../assets/images/view.png";
import timeiing from "../../../../assets/images/timeiing.png";
import man from "../../../../assets/images/man.png";
import clocl from "../../../../assets/images/clocl.png";
import bags from "../../../../assets/images/bags.png";
import rdinfo from "../../../../assets/images/rdinfo.png"
import pro from "../../../../assets/images/pro.png"
import Header from "../../../header/routes";



const ContractOpen = () => {
    return (
        <>
            <Header />

            <div className={styles.FondOpportuniy}>
                <div className="container">

                    <div className="row mt-4">

                        <div className="col-md-12">
                            <div className={styles.RightOpportunity}>

                                <div className={styles.ContarctOuter}>
                                    <div className={styles.opprBox}>
                                        <div className={styles.PendingCOnatctList}>
                                            <h6 className={styles.name_robo}>Robot Programming <span>White glove Contract</span></h6>
                                            <div className={styles.Loca}>
                                                <p className={styles.Locate}>
                                                    <img src={loc} alt="" /> City, State
                                                </p>
                                            </div>
                                            <span className={styles.Hrrate}>Hourly Contract</span>
                                            <div className={styles.PenigSkil}>
                                                <h5>Job Description</h5>
                                                <p className={styles.Peras}>
                                                    It is a long established fact that a reader will be
                                                    distracted by the readable content of a page when
                                                    looking at its layout. The point of using.
                                                </p>
                                            </div>
                                            <div className={styles.PenigSkilRate22}>
                                                <h5>Software Licenses</h5>
                                                <div className={styles.SoftWareLicenece}>
                                                    <div className="row align-items-center">
                                                        <div className="col-lg-6">
                                                            <ul className={`nav ${styles.TaglinesButton}`}>
                                                                <li><span className={styles.orangeButtons}>After Effects </span></li>
                                                                <li><span className={styles.greenButtons}>Figma </span></li>
                                                                <li><span className={styles.blueButtons}>Adobe </span></li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <ul className={`nav ${styles.Taglines}`}>
                                                                <li><span className={styles.greenBOx}></span>Good to have</li>
                                                                <li><span className={styles.blueBOx}> </span>Mandatory</li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                            <div className={styles.bordeerBottom}></div>
                                            <div className={styles.PenigSkilRate}>
                                                <h5 className={styles.payrate}>Pay Rates</h5>
                                                <ul>
                                                    <li>
                                                        <span>Base</span>
                                                        <label>$85</label>
                                                    </li>
                                                    <li>
                                                        <span>Overtime</span>
                                                        <label>$85</label>
                                                    </li>
                                                    <li>
                                                        <span>Sun/Holidays</span>
                                                        <label>$85</label>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className={styles.bordeerBottom}></div>
                                            <h5 className={styles.payrate}>Other Details</h5>
                                            <div className="row">
                                                <div className="col-lg-7 col-sm-12">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-sm-6">
                                                            <div className={styles.datesFlex}>
                                                                <h6>Start date </h6>
                                                                <p>11/13/2023</p>
                                                                <i className="far fa-calendar-alt"></i>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6 col-sm-6">
                                                            <div className={styles.datesFlex}>
                                                                <h6>Duration</h6>
                                                                <p>1 week </p>
                                                                <i className="far fa-calendar-alt"></i>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6 col-sm-6">
                                                            <div className={styles.datesFlex}>
                                                                <h6>People Needed </h6>
                                                                <p className="mb-0">1</p>
                                                                <i className="far fa-calendar-alt"></i>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6 col-sm-6">
                                                            <div className={styles.datesFlex}>
                                                                <h6>Day Shift </h6>
                                                                <p className="mb-0">1 week</p>
                                                                <i className="far fa-calendar-alt"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className={styles.bordeerBottom}></div>
                                                    <h5 className={styles.payrate}>Expenses</h5>
                                                    <div className="row">
                                                        <div className="col-lg-7 col-sm-12">
                                                            <div className="row">
                                                                <div className="col-lg-6 col-sm-6">
                                                                    <div className={styles.datesFlex2}>
                                                                        <h6>Daily Per Diam </h6>
                                                                        <p className={styles.dollar}>$500</p>
                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-6 col-sm-6">
                                                                    <div className={styles.datesFlex2}>
                                                                        <h6>Duration</h6>
                                                                        <p className={styles.dollar}>$200 </p>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className={styles.bordeerBottom}></div>
                                                    <h5 className={styles.payrate}>Tools, Machines and equipment</h5>
                                                    <div className={styles.PenigSkil}>
                                                        <ul>
                                                            <li>Lorem Ipsum</li>
                                                            <li>Figma</li>
                                                            <li>Adobe</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className={styles.bordeerBottom}></div>
                                                    <h5 className={styles.payrate}>Procedure and Policies</h5>
                                                    <p className={styles.Peras}>
                                                        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using.
                                                    </p>
                                                </div>
                                                <div className={styles.ClientProjects}>
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <div className={styles.clientProjectInner}>
                                                                <h5>Applications Received</h5>
                                                                <span>20</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className={styles.clientProjectInner}>
                                                                <h5>Shortlisted Applicants</h5>
                                                                <span>
                                                                    20
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className={styles.clientProjectInner}>
                                                                <h5>Offers Pending</h5>
                                                                <span>20</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div
                                                                className={`${styles.clientProjectInner} ${styles.RejectedAppl}`}
                                                            >
                                                                <h5>Rejected Applicants</h5>
                                                                <span>20</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={styles.commonNav}>
                                                    <ul className="mb-3">

                                                        <li
                                                            className={styles.active}
                                                        >
                                                            <span>Applications Received</span>
                                                        </li>
                                                        <li
                                                            className=""
                                                        >

                                                            <span>Offer Pending</span>
                                                        </li>
                                                        <li
                                                            className=""
                                                        >
                                                            <span>Shortlisted Applicants</span>
                                                        </li>
                                                        <li
                                                            className=""
                                                        >
                                                            <span>Applications Selected</span>
                                                        </li>
                                                        <li
                                                            className=""
                                                        >
                                                            <span>Rejected Applications</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className={styles.ContarctOuter}>
                                                            <div className={styles.ContarctHead}>
                                                                <div className={styles.leftContract}>
                                                                    <h5>Contract No.: #457</h5>
                                                                    <span>Start Date : 12 Jan 2024</span>
                                                                </div>
                                                                <div className={styles.leftContract}>
                                                                    <button className={styles.CoptText}>
                                                                        <i className="far fa-copy"></i> Copy
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className={styles.outerNameDetail}>
                                                                <div className='row align-items-end'>
                                                                    <div className='col-md-6'>
                                                                        <div className={styles.leftName}>
                                                                            <h4>Contractor Details</h4>
                                                                            <div className={styles.PrileImg}>
                                                                                <span><img src={pro} alt="" /></span>
                                                                                <div>
                                                                                    <h5>Bradely Innes</h5>
                                                                                    <h6>Pia Automation . inc</h6>
                                                                                    <p><img src={loc} alt="" /> Evansville. IN</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-6'>
                                                                        <div className={styles.rightName}>
                                                                            <p>Start Date: 10/18/2023</p>
                                                                            <p>End Date: 12/18/2023</p>
                                                                            <p>Rate Per Hour : $70.84(net 15)</p>
                                                                            <p>Expense Terms : Net 15</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={styles.VideoCallBtn}>
                                                                <button><i className="far fa-times-circle"></i> Cancel Contract</button>
                                                                <button className='videBtn'><i className="fas fa-video"></i> Request for Video Call</button>
                                                            </div>
                                                            <div className={styles.Infor}>
                                                                <p><img src={rdinfo} alt="" /> If you cancel a project that you committed to, your account will be subject to cancelation.</p>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className={styles.ContarctOuter}>
                                                            <div className={styles.ContarctHead}>
                                                                <div className={styles.leftContract}>
                                                                    <h5>Contract No.: #457</h5>
                                                                    <span>Start Date : 12 Jan 2024</span>
                                                                </div>
                                                                <div className={styles.leftContract}>
                                                                    <button className={styles.CoptText}>
                                                                        <i className="far fa-copy"></i> Copy
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className={styles.outerNameDetail}>
                                                                <div className='row align-items-end'>
                                                                    <div className='col-md-6'>
                                                                        <div className={styles.leftName}>
                                                                            <h4>Contractor Details</h4>
                                                                            <div className={styles.PrileImg}>
                                                                                <span><img src={pro} alt="" /></span>
                                                                                <div>
                                                                                    <h5>Bradely Innes</h5>
                                                                                    <h6>Pia Automation . inc</h6>
                                                                                    <p><img src={loc} alt="" /> Evansville. IN</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-6'>
                                                                        <div className={styles.rightName}>
                                                                            <p>Start Date: 10/18/2023</p>
                                                                            <p>End Date: 12/18/2023</p>
                                                                            <p>Rate Per Hour : $70.84(net 15)</p>
                                                                            <p>Expense Terms : Net 15</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={styles.VideoCallBtn}>
                                                                <button><i className="far fa-times-circle"></i> Cancel Contract</button>
                                                                <button className='videBtn'><i className="fas fa-video"></i> Request for Video Call</button>
                                                            </div>
                                                            <div className={styles.Infor}>
                                                                <p><img src={rdinfo} alt="" /> If you cancel a project that you committed to, your account will be subject to cancelation.</p>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className={styles.ContarctOuter}>
                                                            <div className={styles.ContarctHead}>
                                                                <div className={styles.leftContract}>
                                                                    <h5>Contract No.: #457</h5>
                                                                    <span>Start Date : 12 Jan 2024</span>
                                                                </div>
                                                                <div className={styles.leftContract}>
                                                                    <button className={styles.CoptText}>
                                                                        <i className="far fa-copy"></i> Copy
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className={styles.outerNameDetail}>
                                                                <div className='row align-items-end'>
                                                                    <div className='col-md-6'>
                                                                        <div className={styles.leftName}>
                                                                            <h4>Contractor Details</h4>
                                                                            <div className={styles.PrileImg}>
                                                                                <span><img src={pro} alt="" /></span>
                                                                                <div>
                                                                                    <h5>Bradely Innes</h5>
                                                                                    <h6>Pia Automation . inc</h6>
                                                                                    <p><img src={loc} alt="" /> Evansville. IN</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-6'>
                                                                        <div className={styles.rightName}>

                                                                            <p>Start Date: 10/18/2023</p>
                                                                            <p>End Date: 12/18/2023</p>
                                                                            <p>Rate Per Hour : $70.84(net 15)</p>
                                                                            <p>Expense Terms : Net 15</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={styles.VideoCallBtn}>
                                                                <button><i className="far fa-times-circle"></i> Cancel Contract</button>
                                                                <button className='videBtn'><i className="fas fa-video"></i> Request for Video Call</button>
                                                            </div>
                                                            <div className={styles.Infor}>
                                                                <p><img src={rdinfo} alt="" /> If you cancel a project that you committed to, your account will be subject to cancelation.</p>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className={styles.ContarctOuter}>
                                                            <div className={styles.ContarctHead}>
                                                                <div className={styles.leftContract}>
                                                                    <h5>Contract No.: #457</h5>
                                                                    <span>Start Date : 12 Jan 2024</span>
                                                                </div>
                                                                <div className={styles.leftContract}>
                                                                    <button className={styles.CoptText}>
                                                                        <i className="far fa-copy"></i> Copy
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className={styles.outerNameDetail}>
                                                                <div className='row align-items-end'>
                                                                    <div className='col-md-6'>
                                                                        <div className={styles.leftName}>
                                                                            <h4>Contractor Details</h4>
                                                                            <div className={styles.PrileImg}>
                                                                                <span><img src={pro} alt="" /></span>
                                                                                <div>
                                                                                    <h5>Bradely Innes</h5>
                                                                                    <h6>Pia Automation . inc</h6>
                                                                                    <p><img src={loc} alt="" /> Evansville. IN</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-6'>
                                                                        <div className={styles.rightName}>
                                                                            <p>Start Date: 10/18/2023</p>
                                                                            <p>End Date: 12/18/2023</p>
                                                                            <p>Rate Per Hour : $70.84(net 15)</p>
                                                                            <p>Expense Terms : Net 15</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={styles.VideoCallBtn}>
                                                                <button><i className="far fa-times-circle"></i> Cancel Contract</button>
                                                                <button className='videBtn'><i className="fas fa-video"></i> Request for Video Call</button>
                                                            </div>
                                                            <div className={styles.Infor}>
                                                                <p><img src={rdinfo} alt="" /> If you cancel a project that you committed to, your account will be subject to cancelation.</p>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-12">
                                                    <div className={styles.bordeerBottom}></div>
                                                    <div className={styles.flexbtns}>
                                                        <button type="button" className={`btn ${styles.commonbtn} ${styles.applybtn}`}>Apply now ›</button>
                                                        <button type="button" className={`btn ${styles.commonbtn} ${styles.sharebtn}`}>Share </button>
                                                    </div>
                                                </div> */}
                                            </div>

                                        </div>
                                    </div>
                                </div>





                                {/* end */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="col-md-9">
        <div className="RightOpportunity">
          <div className={styles.ContarctOuter}>
            <div className={styles.opprBox}>
              <div className={styles.PendingCOnatctList}>
                <h6>Robot Programming</h6>
                <div className={styles.Loca}>
                  <p>
                    <img src={loc} alt="" /> City, State
                  </p>
                </div>
                <span className={styles.Hrrate}>Hourly Contract</span>
                <div className={styles.PenigSkil}>
                  <h5>Job Description</h5>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using.
                  </p>
                </div>
                <div className={styles.PenigSkilRate}>
                  <h5>Software Licenses</h5>
                  <div className={styles.SoftWareLicenece}>
                    <div>
                      <span>After Effects</span>
                      <span>Figma</span>
                      <span>Adobe</span>
                    </div>
                    <div className={styles.Tagline}>
                      <label>
                        <span></span>Good to have
                      </label>
                      <label>
                        <span></span>Mandatory
                      </label>
                    </div>
                  </div>
                </div>
                <div className={styles.PenigSkilRate}>
                  <h5>Pay Rates</h5>
                  <ul>
                    <li>
                      <span>Base</span>
                      <label>$85</label>
                    </li>
                    <li>
                      <span>Base</span>
                      <label>$85</label>
                    </li>
                    <li>
                      <span>Base</span>
                      <label>$85</label>
                    </li>
                  </ul>
                </div>
                <div className={styles.OtherDetails}>
                  <h5>Other Details</h5>
                  <ul>
                    <li>
                      <span>
                        <img src={timeiing} alt="" />
                      </span>
                      <div>
                        <span>Start date </span>
                        <label>11/13/2023</label>
                      </div>
                    </li>
                    <li>
                      <span>
                        <img src={timeiing} alt="" />
                      </span>
                      <div>
                        <span>Duration </span>
                        <label>1 week</label>
                      </div>
                    </li>
                    <li>
                      <span>
                        <img src={man} alt="" />
                      </span>
                      <div>
                        <span>People Needed </span>
                        <label>1</label>
                      </div>
                    </li>
                    <li>
                      <span>
                        <img src={clocl} alt="" />
                      </span>
                      <div>
                        <span>Day Shift </span>
                        <label>1 week</label>
                      </div>
                    </li>
                    <li>
                      <span>
                        <img src={clocl} alt="" />
                      </span>
                      <div>
                        <span>Planned Hrs Per Day </span>
                        <label>5 hrs</label>
                      </div>
                    </li>
                    <li>
                      <span>
                        <img src={clocl} alt="" />
                      </span>
                      <div>
                        <span>Planned Days per week </span>
                        <label>1 week</label>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className={styles.PenigSkilRate}>
                  <h5>Expenses </h5>
                  <ul>
                    <li>
                      <span>Daily Per Diam</span>
                      <label>$500</label>
                    </li>
                    <li>
                      <span>Airfare Allowance</span>
                      <label>$200</label>
                    </li>
                  </ul>
                </div>
                <div className={`${styles.borderadd} ${styles.PenigSkil}`}>
                  <h5>Tools, Machines and equipment</h5>
                  <ul>
                    <li>Lorem Ipsum</li>
                    <li>Figma</li>
                    <li>Adobe</li>
                  </ul>
                </div>
                <div className={styles.PenigSkil}>
                  <h5>Procedure and Policies</h5>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using.
                  </p>
                </div>
                <div className={styles.ApplyBtn}>
                  <button className="cmbtn">Apply now ›</button>
                  <button className="cmbtntwo">Share</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
        </>
    );
}

export default ContractOpen;
