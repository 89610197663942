export enum DIRECT_JOB_STEPS {
  ADD_OCCUPATION,
  ADD_DESCIPTION,
  ADD_TIMINGS,
  ADD_LOCATIONS,
  ADD_RATES,
  ADD_KEYINFO,
  ADD_DURATION,
  ADD_PRIMARY_CONTACTS,
  ADD_INTERVIEWQUESTIONS,
  ADD_ELIGIBILITYQUESTIONS,
  ADD_ADDITIONALS,
  ADD_TECHNICAL_SKILLS,
  OVERVIEW_FORM
}
