import React, { useState } from "react";
import styles from "../styles/style.module.css";
import penbl from "../../../assets/images/penbl.png";
import tip from "../../../assets/images/tip.png";
import { Link } from "react-router-dom";
import EditBusinessClassModal from "../modals/EditBusinessClassModal";

const BusinessClassification = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const openModal = () => {
    setShowModal(true);
  }
  const closeModal = () => { setShowModal(false)};
  return (
    <div className={styles.AboutEdit}>
      <div className="row align-items-center justify-content-between mb-3">
        <div className="col-10">
          <div className="topTipHover">
            <div className="d-flex align-items-center">
              <h4 className="text-20 fw-bold  font-inter mb-0">
                Business Classification
              </h4>
              <i>
                <img className="customeTolImg" src={tip} alt="" />
              </i>
            </div>
          </div>
        </div>
        <div className="col-2 text-end">
          <span>
            <img src={penbl} alt="" className="cursor-pointer ms-auto" onClick={openModal}/>
          </span>
        </div>
      </div>
      <div className=" mb-3">
        <div className="row align-items-center mb-3">
          <div className="col-12">
            <h4 className="fw-semibold text-14 mb-0">Website Link</h4>
          </div>
          {/* <div className="col-4 text-end">
            <span>
              <img src={penbl} alt="" className="cursor-pointer ms-auto" />
            </span>
          </div> */}
        </div>
        <div className="row align-items-center border rounded-2 h-44 mx-0">
          <div className="col-xl-10 col-8">
            <input
              type="text"
              placeholder="Website"
              className="py-2 w-100 border-0"
            />
          </div>
          <div className="col-xl-2 col-4 text-end">
            <button
              type="button"
              className="btn text-blue fw-medium text-14 w-auto py-2"
            >
              +Add Link
            </button>
          </div>
        </div>
      </div>
      <div className=" mb-3 mt-2">
        <div className="row align-items-center mb-3">
          <div className="col-12">
            <h4 className="fw-semibold text-14 mb-0">
              Company Social Media Links
            </h4>
          </div>
          {/* <div className="col-4 text-end">
            <span>
              <img src={penbl} alt="" className="cursor-pointer ms-auto" />
            </span>
          </div> */}
        </div>
        <div className=" mb-3">
          <div className="row align-items-center border rounded-2 h-44 mx-0">
            <div className="col-xl-10 col-8">
              <input
                type="text"
                placeholder="Instagram"
                // value={insta}
                className="py-2 w-100 border-0"
                // onChange={(e) => setInsta(e.target.value)}
              />
            </div>
            <div className="col-xl-2 col-4 text-end">
              <button
                type="button"
                // onClick={handleInsta}
                className="btn text-blue fw-medium text-14 w-auto py-2"
              >
                +Add Link
              </button>
            </div>
          </div>
          {/* {instaLink ? (
          <Link to={instaLink}>{instaLink}</Link>
        ) : (
          <p className="text-12">No Link Added yet!</p>
        )} */}
        </div>
        <div className="mb-3">
          <div className="row align-items-center border rounded-2 h-44 mx-0">
            <div className="col-xl-10 col-8">
              <input
                type="text"
                placeholder="LinkedIn"
                // value={linkedin}
                className="py-2 w-100 border-0"
                // onChange={(e) => setLinkedin(e.target.value)}
              />
            </div>
            <div className="col-xl-2 col-4 text-end">
              <button
                type="button"
                // onClick={handleLinkedin}
                className="btn text-blue fw-medium text-14 w-auto py-2"
              >
                +Add Link
              </button>
            </div>
          </div>
          {/* {linkedinLink ? (
          <Link to={linkedinLink}>{linkedinLink}</Link>
        ) : (
          <p className="text-12">No Link Added yet!</p>
        )} */}
        </div>
        <div className="mb-3">
          <div className="row align-items-center border rounded-2 h-44 mx-0">
            <div className="col-xl-10 col-8">
              <input
                type="text"
                placeholder="Facebook"
                // value={facebook}
                className="py-2 w-100 border-0"
                // onChange={(e) => setFacebook(e.target.value)}
              />
            </div>
            <div className="col-xl-2 col-4 text-end">
              <button
                type="button"
                // onClick={handleFacebook}
                className="btn text-blue fw-medium text-14 w-auto py-2"
              >
                + Add Link
              </button>
            </div>
          </div>
          {/* {fbLink ? (
          <Link to={fbLink}>{fbLink}</Link>
        ) : (
          <p className="text-12">No Link Added yet!</p>
        )} */}
        </div>
        <div className="mb-3">
          <div className="row align-items-center border rounded-2 h-44 mx-0">
            <div className="col-xl-10 col-8">
              <input
                type="text"
                placeholder="youtube"
                // value={Youtube}
                className="py-2 w-100 border-0"
                // onChange={(e) => setFacebook(e.target.value)}
              />
            </div>
            <div className="col-xl-2 col-4 text-end">
              <button
                type="button"
                // onClick={handleFacebook}
                className="btn text-blue fw-medium text-14 w-auto py-2"
              >
                + Add Link
              </button>
            </div>
          </div>
          {/* {fbLink ? (
          <Link to={fbLink}>{fbLink}</Link>
        ) : (
          <p className="text-12">No Link Added yet!</p>
        )} */}
        </div>
        <div className="mb-3">
          <div className="row align-items-center border rounded-2 h-44 mx-0">
            <div className="col-xl-10 col-8">
              <input
                type="text"
                placeholder="Twiter"
                // value={twiter}
                className="py-2 w-100 border-0"
                // onChange={(e) => setTwiter(e.target.value)}
              />
            </div>
            <div className="col-xl-2 col-4 text-end">
              <button
                type="button"
                // onClick={handleTwiter}
                className="btn text-blue fw-medium text-14 w-auto py-2"
              >
                + Add Link
              </button>
            </div>
          </div>
          {/* {twiterLink ? (
          <Link to={twiterLink}>{twiterLink}</Link>
        ) : (
          <p className="text-12">No Link Added yet!</p>
        )} */}
        </div>
      </div>
      <EditBusinessClassModal showModal={showModal} closeModal={closeModal} title="Business Classification"/>
    </div>
  );
};

export default BusinessClassification;